import './styles.scss';
import { FC } from 'react';
import { AcquirePlatformBarInterface } from './indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LinksConstants from '../../../../core/constants/links-contants';

const AcquirePlatformBar: FC<AcquirePlatformBarInterface> = (props) => {
  return (
    <div className="acquire-platform-bar-component">
      <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('tooltip')}</Tooltip>}>
        <a target='_blank' href={LinksConstants.homeBuild(props.userName, props.subdomain)}>
          <span className="acquire-platform-bar-message">
            {props.t('message_1')} <span>{props.t(props.limitDays === 1 ? 'message_2_singular' : 'message_2_plural', { limitDays: props.limitDays})}</span>
          </span>
        </a>
      </OverlayTrigger>
    </div>
  );
};

export default AcquirePlatformBar;
