import MainScreen from ".";
import { FC, useContext, useState } from "react";
import { CallcenterContext } from "../../../../../../core/context/callcenter-context";
import { MainScreenControllerInterface, MainScreenSceneType } from "./indexModel";
import { useTranslation } from "react-i18next";

const MainScreenController: FC<MainScreenControllerInterface> = (props) => {
  const callcenterContext = useContext(CallcenterContext);
  const {t} = useTranslation()

  const [scene, setScene] = useState<MainScreenSceneType>('keyboard');
  
  const disconnect = () => {
    callcenterContext?.instance?.unregister();
  }

  const minimize = () => {
    props.show(false);
  }

  const getMainActionsClass = (option: MainScreenSceneType) => {
    let classes = 'action-icon';
    
    if (option === scene) {
      classes += ' active';
    }
    return classes;
  }

  return (
    <MainScreen 
      t={t}
      callcenterContext={callcenterContext}
      scene={scene}
      setScene={setScene}
      disconnect={disconnect}
      minimize={minimize}
      getMainActionsClass={getMainActionsClass}
    />
  );
}

export default MainScreenController;