import './styles.scss';
import { FC } from 'react';
import { CustomFileAttributes, CustomIconUpload, ToolbarFileUploaderInterface } from './indexModel';
import { IconCloudCheck, IconCloudExclamation, IconX } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FileWithPath } from 'react-dropzone';
import FileViewerController from '../../../messagesComponent/components/fileViewer/indexController';

const ToolbarFileUploader: FC<ToolbarFileUploaderInterface> = (props) => {

  return (
    <div className="toolbar-file-uploader-component" ref={props.fileUploaderRef}>
      { props.showFileUploader &&
        <div className="toolbar-file-uploader-drop-area" {...props.getRootProps({ className: 'toolbar-file-uploader-drop-area' })}>
          <input {...props.getInputProps({})} />
          <span className="toolbar-file-uploader-drop-info">
            { !props.isDragging && <>{props.t('drag_or_click_to_upload')}</> }
            { props.isDragging && <>{props.t('drop_to_upload')}</> }
            { !props.hasTriggerTemplate && props.currentAttachmentSize > 0 && 
              <span className="toolbar-file-uploader-limit">{props.bytesToSize(props.currentAttachmentSize)}/15 Mb</span>
            }
            { props.hasTriggerTemplate && 
              <span className="toolbar-file-uploader-limit">{props.files?.length}/1</span>
            }
          </span>
        </div>
      }
      { props.hasFileUploader && 
        <div className="toolbar-file-uploader-list-files">
          <FileViewerController 
            type='preview-upload' 
            showFileViewer={props.showFilePreview}
            setShowFileViewer={props.setShowFilePreview}
            filesUpload={props.files}
            previewFirstFileUpload={props.previewFirstFileUpload}
            >
            {props.files.map((file: FileWithPath & CustomFileAttributes) => {
              const errorUploadClass = file.errorUpload ? 'toolbar-file-uploader-list-item-error' : '';

              return (
                <OverlayTrigger key={file.name} overlay={props.getFilePreview(file)} placement='left-end'>
                  <div className={`toolbar-file-uploader-list-item ${errorUploadClass}`} onClick={(e) => props.previewFile(file, e)}>
                    { props.getIconFileType(file.type) }
                    <span className="toolbar-file-uploader-list-item-name">{props.getFileInfo(file.name).name}</span>
                    <span className="toolbar-file-uploader-list-item-extension">{props.getFileInfo(file.name).extension}</span>
                    { file.isLoading && !file.dateUploaded && CustomIconUpload() }
                    { !file.isLoading && file.dateUploaded && 
                      <OverlayTrigger overlay={<Tooltip>{props.t('upload_success')}</Tooltip>}>
                        <IconCloudCheck className='toolbar-file-uploader-icon-status' /> 
                      </OverlayTrigger>
                    }
                    { !file.isLoading && !file.dateUploaded && file.errorUpload && 
                      <OverlayTrigger overlay={<Tooltip>{props.t('upload_failure')}</Tooltip>}>
                        <IconCloudExclamation className='toolbar-file-uploader-icon-status' /> 
                      </OverlayTrigger>
                    }
                    {
                      (!props.editorDisabled && (!file.isLoading || file.dateUploaded)) &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('remove_attachment')}</Tooltip>}>
                        <span className="toolbar-file-uploader-action-delete" onClick={(e) => props.removeFile(file, e)} ref={props.removeUploadRef}>
                          <IconX className='toolbar-file-uploader-icon-delete' />
                        </span>
                      </OverlayTrigger>
                    }
                  </div>
                </OverlayTrigger>
              );
            })}
          </FileViewerController>
          
        </div>
      }
      { props.hasTemplateMessageAttachment && props.files?.length === 0 &&
        <div className="toolbar-file-uploader-list-files">
          <FileViewerController 
            type='template-message-attachment' 
            showFileViewer={props.showFilePreview}
            setShowFileViewer={props.setShowFilePreview}
            previewTemplateMessageAttachment={props.previewTemplateMessageAttachment}
            >
              <OverlayTrigger key={props.previewTemplateMessageAttachment?.fileName} overlay={props.getFilePreview(null, props.previewTemplateMessageAttachment)} placement='left-end'>
                <div className="toolbar-file-uploader-list-item" onClick={(e) => props.previewFile(null, e, props.getSelectedTemplateMessageInfo())}>
                  { props.getIconFileType(props.getSelectedTemplateMessageInfo()?.mimeType) }
                  <span className="toolbar-file-uploader-list-item-name">{props.getSelectedTemplateMessageInfo()?.fileName}</span>
                  <span className="toolbar-file-uploader-list-item-extension">{props.getSelectedTemplateMessageInfo()?.extension}</span>
                </div>
              </OverlayTrigger>
            </FileViewerController>
        </div>
      }
    </div>
  );
};

export default ToolbarFileUploader;
