import { FC, useContext } from 'react';
import CallcenterLoginFormController from './components/LoginForm/indexController';
import ConnectLoading from './components/ConnectLoading';
import MainScreenController from './components/MainScreen/indexController';
import { CallcenterContext } from '../../../../core/context/callcenter-context';
import { PhoneCallcenterInterface } from './indexModel';

const CONNECTED_STATUS = ['connected', 'sent_request'];
const CONNECTING_STATUS = ['connecting'];
const DISCONNECTED_STATUS = ['disconnected'];

const PhoneCallcenter: FC<PhoneCallcenterInterface> = (props) => {
  const callcenterContext = useContext(CallcenterContext);

  return (
    <>
      <div className='phoneCallcenter'>
        { DISCONNECTED_STATUS.includes(callcenterContext.sessionStatus) && <CallcenterLoginFormController show={props.show} />}
        { CONNECTING_STATUS.includes(callcenterContext.sessionStatus) && <ConnectLoading />}
        { CONNECTED_STATUS.includes(callcenterContext.sessionStatus) && <MainScreenController show={props.show} /> }
      </div>
    </>
  );
}

export default PhoneCallcenter;
