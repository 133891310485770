import { FC, useContext } from "react";
import PhoneCallcenter from ".";
import { PhoneCallcenterControllerInterface } from "./indexModel";
import './styles.scss';
import { CallcenterContext } from "../../../../core/context/callcenter-context";
import ReceivingCallController from "./components/ReceivingCall/indexController";


const PhoneCallcenterController: FC<PhoneCallcenterControllerInterface> = (props) => {
  const callcenterContext = useContext(CallcenterContext);

  return (
    <div className="phoneContainer">
      { props.showPopup && <PhoneCallcenter show={props.show} /> }
      { !props.showPopup && ['ringing', 'in-call'].includes(callcenterContext.callStatusEvent) && <ReceivingCallController show={props.show}  /> }
    </div>
  );
}

export default PhoneCallcenterController;
