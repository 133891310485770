import { useEffect, useRef, useState } from 'react';
import StatusAgentComponent from '.';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { StatusAgent } from '../../../../core/enums/status-agent';
import { getEmailUser, getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../../store/user';
import { getIdCompany } from '../../../../store/company';
import { getIdStatusEmployee, setIdStatusEmployee } from '../../../../store/employee';
import { useSocketV2Context } from '../../../../core/context/socket-context-v2';
import { UserProfile } from '../../../../core/enums/user-profile';

const StatusAgentComponentController = () => {
  const { t } = useTranslation('StatusAgentComponent');
  const dispatch = useDispatch();
  const { socketInitialized, emitSocketAppEvent } = useSocketV2Context();

  const values = {
    user_id: useSelector(getIdUser),
    user_name: useSelector(getNameUser),
    user_email: useSelector(getEmailUser),
    user_lastname: useSelector(getUserLastName),
    profile_id: useSelector(getProfileId),
    company_id: useSelector(getIdCompany),
    employee_status_id: useSelector(getIdStatusEmployee) || localStorage.getItem('status_employee'),
  }

  const popupStatusRef = useRef<HTMLDivElement>(null);

  const [showPopup, setShowPopup] = useState(false);
  
  useEffect(() => {
    if (showPopup) {
      const handleClickOutsideStatus = (event: any) => {
        if (popupStatusRef?.current && !popupStatusRef?.current?.contains(event.target)) {
          setShowPopup(false);
        }
      }
  
      if (popupStatusRef?.current) {
        document.addEventListener('mousedown', handleClickOutsideStatus);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideStatus);
      }
    }
  }, [showPopup]);

  useEffect(() => {
    if (socketInitialized) {
      const data = {
        user_id: values.user_id,
        status_employee: StatusAgent.Online,
        company_id: values.company_id
      };
  
      localStorage.setItem('status_employee', StatusAgent.Online);
      dispatch(setIdStatusEmployee(StatusAgent.Online));
  
      if (socketInitialized) {
        setTimeout(() => {
          emitSocketAppEvent('update-status-employee', data);
        }, 500);
      }
    }
  }, [socketInitialized]);

  const show = () => {
    setShowPopup(!showPopup);
  }

  const handleChangeStatus = (status_id: string) => {
    const data = {
      user_id: values.user_id,
      status_employee: status_id,
      company_id: values.company_id
    }
    if (socketInitialized) {
      emitSocketAppEvent('update-status-employee', data);
    }

    localStorage.setItem('status_employee', status_id);
    dispatch(setIdStatusEmployee(status_id));
    setShowPopup(false);
  }

  const getStatusClass = () => {
    if (values.employee_status_id === StatusAgent.Online) {
      return 'online';
    } else if (values.employee_status_id === StatusAgent.Ausente) {
      return 'away';
    } else {
      return 'offline';
    }
  }

  const getShortName = (): string => {
    const name = values.user_name;

    if (values.profile_id === UserProfile.Master) {
      return t('master_agent');
    } else {
      return name?.split(' ')[0];
    }
  }

  const getFullName = (): string => {
    const name = values.user_name;
    const lastname = values.user_lastname;

    return name && lastname ? `${name} ${lastname}` : name;
  }
  
  return (
    <StatusAgentComponent 
      t={t}
      popupStatusRef={popupStatusRef}
      showPopup={showPopup}
      show={show}
      currentStatus={values.employee_status_id}
      values={values}
      getStatusClass={getStatusClass}
      getShortName={getShortName}
      getFullName={getFullName}
      handleChangeStatus={handleChangeStatus}
    />
  );
}

export default StatusAgentComponentController;