import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { TokenInterface, UserInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowConfirmationModal, getShowMeetButton, setShowAlertFeedback } from "../../../../store/internal";
import { getOwner_id } from "../../../../store/callcenter";
import ModalListTasks from ".";
import UserService from "../../../../services/user-service";
import constsApi from "../../../../services/constsApi";
import BackendConstants from "../../../../core/constants/backend-constants";
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Utils from "../../../../core/shared/utils";
import { optionSelect } from "../selectComponent/indexModel";

const ModalListTasksController = ({ show, handleClose, data, callBackModalCustomField, updateListfunction }) => {
    const { t } = useTranslation();

    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackplaceholder, setShowFeedbackplaceholder] = useState(false);
    const [type, setType] = useState([
        { 'label': t("ticket_status.open"), 'value': 'b58c1105-0ffe-4d07-a7d6-751389fc3008' },
        { 'label': t("ticket_status.resolved"), 'value': '2b45ba71-fa68-4ce5-bf16-14f31d43ba1a' },
        { 'label': t("ticket_status.pending"), 'value': '0ec35dbb-398e-4c2f-996c-933af162c950' },
        { 'label': t("ticket_status.on_hold"), 'value': '3dd9b9ac-7399-4ce8-b22c-a513eb473803' },
        { 'label': t("ticket_status.new"), 'value': 'daddbd66-1dd2-4008-94c9-7fcb9b1a5800' },
        { 'label': t("ticket_status.abandoned"), 'value': '2f955a38-e8cf-4033-bf6c-c5e4f68c8ea9' }]);
    const [selectedType, setSelectedType] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [labelledOptions, setLabelledOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [currentTicketId, setCurrentTicketId] = useState('');
    const [showEdit, setShowEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [showButton, setShowButton] = useState(true);
    const [showAdd, setShowAdd] = useState(false);
    const [currentAssigned_employee_user_id, setCurrentAssigned_employee_user_id] = useState("");
    const [allConsumers, setAllConsumers] = useState([])
    const [allAgents, setAllAgents] = useState([])
    const [allTags, setAllTags] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [tasks, setTasks] = useState([]);
    const [taskId, setTaskId] = useState("");

    const [date, setDate] = useState<string>(null);
    const [showfeedbackDate, setShowfeedbackDate] = useState(false);
    const [feedbackNotificationAlertTime, showFeedbackNotificationAlertTime] = useState(false)

    const [hour, setHour] = useState<string>(null);
    const [showfeedbackHour, setShowfeedbackHour] = useState(false);

    const [description, setDescription] = useState('');
    const [observation, setObservation] = useState('');
    const [showFeedbackDescription, setShowFeedbackDescription] = useState(false);
    const [dataEdit, setDataEdit] = useState([])
    const [selectedAssigned, setSelectedAssigned] = useState([])
    const [selectedRequester, setSelectedRequester] = useState([])
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedFollowers, setSelectedFollowers] = useState([])
    const [alertRegister, setAlertRegister] = useState(false)
    const [notificationAlertTime, setNotificationAlertTime] = useState("")
    const [errorSelect, setErrorSelect] = useState(false);

    const [allPeriod, setAllPeriod] = useState([
        { label: t('home_tasks.modal_task.notify_config.option_hours'), value: "hours" },
        { label: t('home_tasks.modal_task.notify_config.option_days'), value: "days" },
        { label: t('home_tasks.modal_task.notify_config.option_weeks'), value: "weeks" }
    ])
    const [selectdPeriod, setSelectedPeriod] = useState([])
    const [selectOnPeriod, setOnSelectPeriod] = useState([])

    const zerarCampos = () => {
        setErrorSelect(false)
        setSelectedAssigned([])
        setSelectedRequester([])
        setSelectedTags([])
        setSelectedFollowers([])
        setSelectedType([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setShowfeedbackHour(false)
        setShowfeedbackDate(false)
        setShowFeedbackDescription(false)
        showFeedbackNotificationAlertTime(false)
        setShowEdit(false);
        setShowAdd(false);
        setShowDelete(false);
        setShowModal(true);
        setShowButton(true);
        setDate('');
        setTaskId('');
        setDescription('');
        setObservation('');
        setAlertRegister(false)
        setNotificationAlertTime("")
        setSelectedPeriod([])
        setOnSelectPeriod([])
        if (showEdit === false && showAdd === false && showDelete === false) {
            handleClose();
        }
        setTasks([])
        const controller = new AbortController()
        getTaskById({ limit: currentLimit, page: currentPage, ticket_id: data }, controller)
    }

    const alterarVisibilidadeEdit = () => {
        setShowEdit(true);
        setShowModal(false);
        setShowButton(false);
        setShowDelete(false);
        const controller = new AbortController()
        getTaskByIdEdit({ limit: currentLimit, page: currentPage }, controller)
    }

    const alterarVisibilidadeAdd = () => {
        setShowEdit(false);
        setShowModal(false);
        setShowButton(false);
        setShowAdd(true);
        setShowDelete(false);
    }

    const alterarVisibilidadeDelete = () => {
        setShowEdit(false);
        setShowModal(false);
        setShowDelete(true);
        setShowButton(false);
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const AppRequesterConst = new AppRequesterController()

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfTickets");
    }, [tasks]);

    useEffect(() => {
        if (selectedOptions?.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }
    }, [selectedOptions])

    useEffect(() => {
        const controller = new AbortController()
        if(data)
            getTaskById({ limit: currentLimit, page: currentPage, ticket_id: data }, controller)

    }, [data])

    useEffect(() => {
        if(show === true) {
            getAllAgents()
            getTags()
        }
    }, [showModal])

    const getTaskById = async (params: { limit: number; page: number, ticket_id: string }, controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await AppRequesterConst.Get(
            `/task/`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200 && data) {
                    setTasks(data?.data?.tasks)
                } else {
                    setTasks([])
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {
                    dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                }
                if (error?.response?.status === 401) {
                    dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const deleteTask = async (id: string) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Delete(
            '/task/' + id, { headers: headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tasks.list_of_tasks.delete_task_alert.deleted_task'), visibility: true, signalIcon: true }));
                zerarCampos()
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
        );
    }

    const updateSelectedTaskStatus = (status_task_id: string) => {
        switch (status_task_id) {
            case TicketStatus.Open:
                setSelectedType([{ label: t("ticket_status.open"), value: TicketStatus.Open }]);
                break;
            case TicketStatus.Resolved:
                setSelectedType([{ label: t("ticket_status.resolved"), value: TicketStatus.Resolved }]);
                break;
            case TicketStatus.Pending:
                setSelectedType([{ label: t("ticket_status.pending"), value: TicketStatus.Pending }]);
                break;
            case TicketStatus.OnHold:
                setSelectedType([{ label: t("ticket_status.on_hold"), value: TicketStatus.OnHold }]);
                break;
            case TicketStatus.New:
                setSelectedType([{ label: t("ticket_status.new"), value: TicketStatus.New }]);
                break;
            case TicketStatus.Abandoned:
                setSelectedType([{ label: t("ticket_status.abandoned"), value: TicketStatus.Abandoned }]);
                break;
            case TicketStatus.Overdue:
                setSelectedType([{ label: t("ticket_status.delayed"), value: TicketStatus.Overdue }]);
                break;
            case TicketStatus.OverdueTicketTask:
                setSelectedType([{ label: t("ticket_status.delayed"), value: TicketStatus.OverdueTicketTask }]);
                break;
            default:
                break;
        }
    }

    const updateSelectPeriod = (status_pediod_id: string) => {
        switch(status_pediod_id) {
            case "hours":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_hours'), value: "hours" }])
                break;
            case "days":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_days'), value: "days" }])
                break;
            case "weeks":
                setSelectedPeriod([{ label: t('home_tasks.modal_task.notify_config.option_weeks'), value: "weeks" }])
                break;
            default:
                break;

        }
    }

    const updateSelectedTaskTags = (tags) => {
        var tagsEdit = tags.map((tag) => ({ label: tag.name, value: tag.tag_id }))
        setSelectedTags(tagsEdit)
    }

    const updateSelectedAssignedEmployee = (data) => {
        setSelectedAssigned([{ label: data.assigned_employee_name, value: data.assigned_employee_id }])
    }

    const getTaskByIdEdit = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await AppRequesterConst.Get(
            `/task/${taskId}`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data.status === 200) {
                    setDataEdit(data.data.task)
                    updateSelectedTaskStatus(data.data.task.status_task_id)
                    updateSelectPeriod(data.data.task.notification_alert_type)
                    // Alert aqui
                    setAlertRegister(data.data.task.should_notificate)
                    updateSelectedTaskTags(data.data.task.tags)
                    updateSelectedAssignedEmployee(data.data.task)
                    var aux = data.data.task.followers.map((fol) => ({ label: fol.employee_name, value: fol.employee_id }))
                    setSelectedFollowers(aux)
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const multiselectFilterAgents = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let agents = [];// [agentNotItem];
            await AppRequesterConst.Get(
                '/agent', { headers, params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if ((((data.status === 200) && (values.user.profile_id === constsApi.profile[0].id)) || (values.user.profile_id === constsApi.profile[2].id))) {
                        if (data.data.employees) {
                            data.data.employees.map((item) => {
                                agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    } else {
                        if (data.data.employees) {
                            data.data.employees.filter(elem => elem.user_id === values.user.id).map((item) => {
                                agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );

            return agents;
        }
    }

    const onSelectAssigned = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === "NA") {
                setCurrentAssigned_employee_user_id("");
            } else {
                setCurrentAssigned_employee_user_id(value[value.length - 1].value);
            }
        } else {
            setCurrentAssigned_employee_user_id("");
        }
    }

    const onSelectPeriod = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === "NA") {
                setOnSelectPeriod([])
            } else {
                setOnSelectPeriod([value[value.length - 1]])
            }
        } else {
            setOnSelectPeriod([])
        }
    }

    const converterData = (data: string) => {
        let separaData = data.split("/")
        let dataFormatada = separaData[1] + "/" + separaData[0] + "/" + separaData[2]
        let Data = new Date(dataFormatada)
        return (Data)
    }

    const converterHora = (hora: string) => {
        let separaHora = hora.split(":")
        let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
        return (Data)
    }


    const getAllConsumers = async () => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/consumer', { headers },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data.data.consumers) {
                    let consumers = [];
                    data.data.consumers.map((item) => {
                        consumers.push({ label: item.name, value: item.user_id, user_id: item.user_id });
                    });
                    setAllConsumers(consumers);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllAgents = async () => {
        const headers = UserService.getHeaders();
        await AppRequesterConst.Get(
          '/agent', { headers },
          (response: Object) => {
            return response;
          },
          (data) => {
            if(data.status === 200 && data.data.employees){
                setAllAgents(data.data.employees.length > 0 ? data.data.employees.map((item) => ({ label: `${item.name} ${item.lastname ? item.lastname : ""}`, value: item.id })) : []);
            }
          },
          (error: Object) => {
          }, navigate, dispatch, setIsLoading, { values: values }
        );
      }

    const getTags = async () => {
        const headers = UserService.getHeaders()
        await AppRequesterConst.Get(
            `/tag?is_active=true`, { headers },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200) {
                    data.data.tags.forEach((element) => {
                        auxObj.push({ label: element.name, value: element.id });
                    });
                }

                setAllTags(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const getStatusText = (status_id: string) => {
        switch (status_id) {
            case TicketStatus.Open: 
                return { tooltip: t("ticket_status.open"), symbol: t('ticket_status_symbol.open'), color: '#4A69BD' };
            case TicketStatus.Resolved: 
                return { tooltip: t("ticket_status.resolved"), symbol: t('ticket_status_symbol.resolved'), color: '#707070' };
            case TicketStatus.Pending: 
                return { tooltip: t("ticket_status.pending"), symbol: t('ticket_status_symbol.pending'), color: '#DE2450' };
            case TicketStatus.OnHold: 
                return { tooltip: t("ticket_status.on_hold"), symbol: t('ticket_status_symbol.on_hold'), color: '#FBBD4E' };
            case TicketStatus.New: 
                return { tooltip: t("ticket_status.new"), symbol: t('ticket_status_symbol.new'), color: '#17a589' };
            case TicketStatus.Abandoned: 
                return { tooltip: t("ticket_status.abandoned"), symbol: t('ticket_status_symbol.abandoned'), color: '#2D3233' };
            case TicketStatus.OverdueTicketTask: 
                return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
            default:
                return { tooltip: 'N/A', symbol: 'N/A', color: '#707070' };
        }
    }

    const getStatusElement = (status_id: string): JSX.Element => {
        const status_text = getStatusText(status_id);

        return (
            <Button style={{ cursor: 'default', backgroundColor: status_text.color, border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                <OverlayTrigger key='top' placement='top' overlay={<Tooltip id='tooltip'>{status_text.tooltip}</Tooltip>}>
                    <span className='FilterTicketsRow '>{status_text.symbol}</span>
                </OverlayTrigger>
            </Button>
        );
    }

    return <ModalListTasks
        show={show}
        zerarCampos={zerarCampos}
        data={data}
        selectedType={selectedType}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        callBackModalCustomField={callBackModalCustomField}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        setDescription={setDescription}
        showFeedbackDescription={showFeedbackDescription}
        setShowFeedbackDescription={setShowFeedbackDescription}
        type={type}
        setType={setType}
        setSelectedType={setSelectedType}
        isLoading={isLoading}
        updateListfunction={updateListfunction}
        setCurrentTicketId={setCurrentTicketId}
        currentTicketId={currentTicketId}
        showModal={showModal}
        showEdit={showEdit}
        alterarVisibilidadeEdit={alterarVisibilidadeEdit}
        alterarVisibilidadeDelete={alterarVisibilidadeDelete}
        multiselectFilterAgents={multiselectFilterAgents}
        onSelectAssigned={onSelectAssigned}
        setDate={setDate}
        setHour={setHour}
        setShowfeedbackDate={setShowfeedbackDate}
        setShowfeedbackHour={setShowfeedbackHour}
        converterData={converterData}
        converterHora={converterHora}
        showfeedbackDate={showfeedbackDate}
        showfeedbackHour={showfeedbackHour}
        alterarVisibilidadeAdd={alterarVisibilidadeAdd}
        showButton={showButton}
        showAdd={showAdd}
        showDelete={showDelete}
        tasks={tasks}
        taskId={taskId}
        setTaskId={setTaskId}
        allAgents={allAgents}
        setAllAgents={setAllAgents}
        allTags={allTags}
        setAllTags={setAllTags}
        dataEdit={dataEdit}
        selectedAssigned={selectedAssigned}
        setSelectedAssigned={setSelectedAssigned}
        selectedRequester={selectedRequester}
        setSelectedRequester={setSelectedRequester}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedFollowers={selectedFollowers}
        setSelectedFollowers={setSelectedFollowers}
        deleteTask={deleteTask}
        requesterId={values.employee.id}
        date={date}
        setAlertRegister={setAlertRegister}
        alertRegister={alertRegister}
        allPeriod={allPeriod}
        setAllPeriod={setAllPeriod}
        selectdPeriod={selectdPeriod}
        setSelectedPeriod={setSelectedPeriod}
        onSelectPeriod={onSelectPeriod}
        showFeedbackNotificationAlertTime={showFeedbackNotificationAlertTime}
        feedbackNotificationAlertTime={feedbackNotificationAlertTime}
        selectOnPeriod={selectOnPeriod}
        setNotificationAlertTime={setNotificationAlertTime}
        notificationAlertTime={notificationAlertTime}
        getStatusElement={getStatusElement}
        t={t}
        setObservation={setObservation}
        errorSelect={errorSelect}
        setErrorSelect={setErrorSelect}
    />
}

export default ModalListTasksController;