import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from 'react';
import ToolbarLinkify from './index';
import { ToolbarLinkifyControllerInterface } from './indexModel';
import { DraftModel, EditorState, Modifier, RichUtils } from 'draft-js';

const ToolbarLinkifyController: FC<ToolbarLinkifyControllerInterface> = (props) => {
  const { t } = useTranslation('ToolbarLinkify');
  const [selectedText, setSelectedText] = useState<string>(null);
  const [url, setUrl] = useState<string>(null);
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [hasLink, setHasLink] = useState(false);

  const linkifyPopupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (linkifyPopupRef?.current && !linkifyPopupRef?.current?.contains(event.target)) {
        handleClose();
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    if (props.showLinkify) {
      loadSelected();
      
      if (linkifyPopupRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.showLinkify]);

  const loadSelected = () => {
    const selection = props.editorState.getSelection();
    const contentState = props.editorState.getCurrentContent();

    if (!selection.isCollapsed()) {
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const endOffset = selection.getEndOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let selectedText = '';
      let url = '';

      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        if (linkInstance.getType() === 'LINK') {
          url = linkInstance.getData().url;
          selectedText = blockWithLinkAtBeginning.getText().slice(startOffset, endOffset);
          setHasLink(true);
        }
      } else {
        setHasLink(false);
        selectedText = blockWithLinkAtBeginning.getText().slice(startOffset, endOffset);
      }

      setUrl(url);
      setIsValidUrl(validateUrl(url));
      setSelectedText(selectedText);
    } else {
      setSelectedText('');
      setUrl('');
      setHasLink(false);
      setIsValidUrl(false);
    }
  }

  const handleToggle = () => {
    props.setShowLinkify(!props.showLinkify);
  }

  const handleClose = () => {
    props.setShowLinkify(false);
    setSelectedText('');
    setUrl('');
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedText(e.target.value);
  };

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    setIsValidUrl(validateUrl(e.target.value));
  };

  const validateUrl = (url: string) => {
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
    return urlPattern.test(url);
  };

  const handleSubmit = () => {
    if (selectedText.length > 0 && isValidUrl) {
      const selection = props.editorState.getSelection();
      const contentState = props.editorState.getCurrentContent();
  
      const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url, target: '_blank' });
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  
      let newContentState: DraftModel.ImmutableData.ContentState;
  
      if (!selection.isCollapsed()) {
        newContentState = Modifier.applyEntity(contentState, selection, entityKey);
        newContentState = Modifier.replaceText(newContentState, selection, selectedText, undefined, entityKey);
      } else {
        const blockMap = contentState.getBlockMap();
        const lastBlock = blockMap.last();
        const newBlockKey = lastBlock.getKey();
        const newBlockLength = lastBlock.getLength();
        const newSelection = selection.merge({
          anchorKey: newBlockKey,
          anchorOffset: newBlockLength,
          focusKey: newBlockKey,
          focusOffset: newBlockLength,
          isBackward: false,
        });
        newContentState = Modifier.insertText(contentState, newSelection, selectedText, undefined, entityKey);
      }
  
      const newEditorState = EditorState.push(props.editorState, newContentState, 'apply-entity');
      const finalEditorState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
      props.setEditorState(finalEditorState);
      handleClose();
    }
  };

  const handleRemoveLink = () => {
    const selection = props.editorState.getSelection();
    const contentState = props.editorState.getCurrentContent();
    let newContentState = contentState;

    if (!selection.isCollapsed()) {
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const linkKey = contentState.getBlockForKey(startKey).getEntityAt(startOffset);

      if (linkKey) {
        newContentState = Modifier.applyEntity(contentState, selection, null);
      }
    }

    const newEditorState = EditorState.push(props.editorState, newContentState, 'apply-entity');
    props.setEditorState(newEditorState);
    handleClose();
  };

  return (
    <ToolbarLinkify 
      t={t}
      linkifyPopupRef={linkifyPopupRef}
      showLinkifyPopup={props.showLinkify}
      handleToggle={handleToggle}
      handleClose={handleClose}
      selectedText={selectedText}
      url={url}
      handleUrlChange={handleUrlChange}
      handleSelectChange={handleSelectChange}
      handleSubmit={handleSubmit}
      isValidUrl={isValidUrl}
      hasLink={hasLink}
      handleRemoveLink={handleRemoveLink}
    />
  );
};

export default ToolbarLinkifyController;
