import { FC, useEffect, useState, MouseEvent, useCallback, useRef } from 'react';
import ListOfConsumers from './index';
import { ConsumerInterface, FetchListConsumersInterface, ListOfConsumersControllerInterface } from './indexModel';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAppOpenSidebar } from '../../../../store/app_sidebar';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import {
  getShowModalConsumersSimilarData,
  getShowRegisterCustomersModal,
  setShowAlertFeedback,
  setShowModalConsumersSimilarData
} from '../../../../store/internal';
import { useMainAppContext } from '../../../../core/context/main-app-context';
import UserService from '../../../../services/user-service';
import { ListConsumerInterface } from './indexModel';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../components-fixed/searchComponent/indexModel';
import { OrderByType } from '../../../../core/enums/order-sort-by';
import { CustomFieldEntity } from '../../../../core/enums/custom-field-type';
import { CustomSelectOptionType } from '../../../../components/customSelect/indexModel';
import emitter from '../../../../core/shared/emitter';
import { Popover } from 'react-bootstrap';
import constsRouters from '../../../../routes/constsRouter';
import { IconEdit, IconFileDownload, IconTicket, IconTrashX } from '@tabler/icons-react';
import { getConsumerListOrderBy, setConsumerListOrderBy } from '../../../../store/table_sort_order';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const NAME_SIZE = '170px';
const NAME_MAX_SIZE = '200px';
const EMAIL_SIZE = '200px';
const EMAIL_MAX_SIZE = '210px';
const DOCUMENT_SIZE = '170px';
const PHONE_SIZE = '170px';
const ADDRESS_SIZE = '210px';

const ListOfConsumersController: FC<ListOfConsumersControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfConsumers');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const sidebarOpened = useSelector(getAppOpenSidebar);
  const consumerListOrderBy = useSelector(getConsumerListOrderBy);

  // Dado legado
  const showConsumerSimilarData: boolean = useSelector(getShowModalConsumersSimilarData);

  const user = {
    id: useSelector(getIdUser),
    name: useSelector(getNameUser),
    profile: useSelector(getProfileId)
  }

  const {
    setMainHeader, 
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    filteredMode,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
    setIsSearchedDashCounter,
  } = useMainAppContext();

  const {
    tagId, tagValue
  } = location.state || {};

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<ListConsumerInterface[]>([]);
  const [dataObj, setDataObj] = useState({});
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalConsumer, setShowModalConsumer] = useState(false);
  const [showModalDeleteConsumer, setShowModalDeleteConsumer] = useState(false);
  const [showModalExportConsumer, setShowModalExportConsumer] = useState(false);

  const [currentConsumerForSimilarData, setCurrentConsumerForSimilarData] = useState(null);
  const [similarConsumers, setSimilarConsumers] = useState(undefined);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);
  const [itemView, setItemView] = useState<{ id: string, value: string }>();

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (tagId && tagValue) {
      setItemView({ id: tagId, value: tagValue });
    }
  }, [tagId, tagValue]);

  useEffect(() => {
    if (data) {
      setDataObj(getDataObject(data));
    }
  }, [data]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    setListHeaders([
      { 
        title: t("list_headers.name"), 
        field: 'name', 
        width: NAME_SIZE, 
        maxWidth: NAME_MAX_SIZE,
        hasOrderBy: true, 
        orderByFn: getConsumersOrdered, 
        orderBy: consumerListOrderBy || 'asc',
        orderType: 'text',
        hasTooltip: true,
      },
      { title: t("list_headers.email"), field: 'email', width: EMAIL_SIZE, maxWidth: EMAIL_MAX_SIZE, hasTooltip: true },
      { title: t("list_headers.document_number"), field: 'document_number', width: DOCUMENT_SIZE, hasTooltip: true },
      { title: t("list_headers.phone"), field: 'phone', width: PHONE_SIZE },
      { title: t("list_headers.address"), field: 'address', width: ADDRESS_SIZE, hasTooltip: true },
      { title: t("list_headers.actions"), field: 'action', width: 'auto' },
    ]);
  }, []);

  useEffect(() => {
    if (apiHeader) {
      setCurrentPage(1);
      getConsumers({ limit: LIMIT_PAGINATION, page: currentPage || 1, order_by: consumerListOrderBy });
    }
  }, [apiHeader]);

  useEffect(() => {
    configMainHeader();
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = () => {
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');

    if (props.pageType === 'tag-consumers') {
      setMainHeader({
        pageTitle: t('header.welcome_page_tag_consumers'),
        pageSubtitle: t('header.sub_info_tag_consumers'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: false, // Desativado, pois não tem filtro na rota /consumer-tag
        searchPage: 'tag-consumers',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchConsumers,
        clearSearchFunction: clearSearchConsumers,
        hasCreateButton: false,
        createButtonText: t('header.button_create'),
        createButtonTooltip: t('header.button_create_tooltip'),
        createButtonAction: () => {},
      });
    } else {
      setMainHeader({
        pageTitle: t('header.welcome_page'),
        pageSubtitle: t('header.sub_info'),
        hasSearchComponent: true,
        hasSearchInput: true,
        hasSearchFilter: true,
        searchPage: 'consumers',
        searchPlaceholder: t('header.search_placeholder'),
        searchFunction: searchConsumers,
        clearSearchFunction: clearSearchConsumers,
        hasCreateButton: true,
        createButtonText: t('header.button_create'),
        createButtonTooltip: t('header.button_create_tooltip'),
        createButtonAction: openModalNewConsumer,
      });
    }
  }

  const getConsumers = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);

    const config = { headers: apiHeader, params };
    let path = '/consumer';

    if (props.pageType === 'tag-consumers') {
      path = `/consumer-tag/${tagId}/consumers`;
    }

    AppRequesterConst.Get(
      path, config,
      () => {},
      (response: FetchListConsumersInterface) => {
        if (response.status === 200 && response.data.consumers.length > 0) {
          if (response.data.consumers.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }

          if (isScrollPagination) {
            const new_array = [...data, ...response.data.consumers];
            setData(new_array);
          } else {
            setData(response.data.consumers || []);
            setDataObj(getDataObject(response.data.consumers));
          }

          processPaginationDetails(response.data.pagination);
        } else {
          setData([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getConsumersPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, order_by: consumerListOrderBy, ...currentFilter };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      getConsumers(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getConsumers(params, true);
      }
    }
  }

  const getConsumersOrdered = async (field: string, order: OrderByType) => {
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, order_by: order, ...currentFilter };
    setHeaderOrderBy(field, order);
    getConsumers(params);
  }

  const setHeaderOrderBy = (field: string, order: 'asc' | 'desc'): void => {
    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === field) {
          item.orderBy = order;
        }
      });

      dispatch(setConsumerListOrderBy(order));
      return prevState;
    });
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const getDataObject = (data: ListConsumerInterface[]) => {
    const obj = {};
    data.forEach(item => {
      obj[item.user_id] = item.created_by_employee !== 'true' ? item.user_id : 'disabled';
    });
    return obj;
  }

  const reloadConsumers = (preserveFilter?: boolean) => {
    let params = { limit: LIMIT_PAGINATION, page: 1, order_by: consumerListOrderBy };

    if (preserveFilter) {
      const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
      params = { ...params, ...currentFilter };
    }

    getConsumers(params, false);
  }

  const selectItem = (item: ListConsumerInterface, event: MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    event.stopPropagation();
    if (item.created_by_employee !== 'true') {
      if (selectedItems.includes(item.user_id)) {
        const new_selected = [...selectedItems];
        const index = new_selected.indexOf(item.user_id);
        if (index > -1) {
          new_selected.splice(index, 1);
          setSelectedItems(new_selected);
        }
      } else {
        const new_selected = [...selectedItems, item.user_id];
        setSelectedItems(new_selected);
      }
    }
  }

  const removeSelectedItem = useCallback((ids: string[]): void => {
    setSelectedItems(prevSelected => prevSelected.filter(id => !ids.includes(id)));
  }, []);

  const selectAllItems = (): void => {
    if (data.length > 0) {
      const localSelect = (id: string, array: string[]) => {
        if (array.includes(id)) {
          const index = array.indexOf(id);
          array.splice(index, 1);
        } else {
          array = [...array, id];
        }
        return array;
      }
  
      let count = 0;
  
      let selected = Object.assign([], selectedItems);
  
      selected.forEach(id => {
        if (dataObj[id] && dataObj[id] !== 'disabled') {
          count += 1;
        }
      });
  
      if (count === data.filter(item => item.created_by_employee !== 'true').length) { // Se todos na página tiver selecionados, remove apenas o da página
        Object.keys(dataObj).forEach(id => {
          if (dataObj[id] !== 'disabled') {
            const item = data.find(item => item.user_id === id);

            if (item?.created_by_employee !== 'true') {
              selected = localSelect(id, selected);
            }
          }
        });
      } else { // Senão, marca apenas os que estão desmarcados
        Object.keys(dataObj).forEach(id => {
          const item = data.find(item => item.user_id === id);

          if (item?.created_by_employee !== 'true' && !selectedItems.includes(id)) {
            selected = localSelect(id, selected);
          }
        });
      }
  
      setSelectedItems(selected);
    }
  }

  const isSelectedItem = (id: string): boolean => {
    return selectedItems.includes(id);
  }

  const isAllSelectedItems = (): boolean => {
    if (selectedItems.length > 0) {
      let count = 0;

      Object.keys(dataObj).forEach(id => {
        if (selectedItems.includes(id)) {
          count += 1;
        }
      });

      return data.filter(item => item.created_by_employee !== 'true').length === count;
    } else {
      return false;
    }
  }

  const hasSomeSelected = (): boolean => {
    return selectedItems.length > 0;
  }

  const hasSomeSelectedCheck = (): boolean => {
    let hasSome = false;

    const pageIds = Object.keys(dataObj);

    for (let i = 0; i < pageIds.length; i++) {
      if (selectedItems.includes(pageIds[i])) {
        hasSome = true;
        break;
      }
    }

    return hasSome;
  }

  const exportAll = () => {
    openModalExportConsumer('table-action', []);
  }

  const exportSelected = () => {
    openModalExportConsumer('table-action', selectedItems.map(userId => ({ userId })));
  }

  const deleteSelected = () => {
    if (selectedItems.length === 1) { // Se for só um, se comportar como o menu de ações
      const agent = data.find(item => item.user_id === selectedItems[0]);
      openModalDeleteConsumer('menu-action', [{ userId: selectedItems[0], consumerName: agent?.name || 'N/A' }]);
    } else {
      openModalDeleteConsumer('table-action', selectedItems.map(userId => ({ userId })));
    }
  }

  const clearSelected = () => {
    setSelectedItems([]);
  }

  const clearPageSelected = () => {
    let selected = Object.assign([], selectedItems);

    Object.keys(dataObj).forEach(id => {
      if (selected.includes(id)) {
        const index = selected.indexOf(id);
        selected.splice(index, 1);
      }
    });
    setSelectedItems(selected);
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders;
  }

  const searchConsumers = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const params = { limit: LIMIT_PAGINATION, page: 1, order_by: consumerListOrderBy, ...query };
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    setIsSearchedDashCounter(false);

    getConsumers(params);
  }

  const clearSearchConsumers = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1, order_by: consumerListOrderBy };

    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    setIsSearchedDashCounter(false);

    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }

    getConsumers(params);
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };

    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (filterMode === 'main') {
        if (key === 'tags') {
          tempFilterApplied.selectedTag = [];
        } else if (key === 'employee') {
          tempFilterApplied.selectedAgent = [];
        } else if (key === 'search') {
          currentSearchTerm = '';
          clearFilterTermFunction();
        }
      } else if (filterMode === 'custom') {
        delete tempFilterApplied.selectedCustomFieldListValuesObject[key];

        const indexToDelete = tempFilterApplied.selectedCustomFieldListFilter.findIndex(item => item.id === key);

        if (indexToDelete >= 0) {
          tempFilterApplied.selectedCustomFieldListFilter.splice(indexToDelete, 1);
        }
      }

      setFilterApplied(tempFilterApplied);
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, 'consumers'), tempFilterApplied);
      setIsSearchedDashCounter(false);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters: AppliedFiltersBarType[] = [];
    // let hasDate = false;

    if (props.pageType === 'tag-consumers' && itemView?.id) {
      const item = { key: 'tags', tooltip_key: 'filter_applied.tags', value: itemView.value, fixed: true };
      filters.push(item);
    }

    if (filteredMode === 'advanced') {

      Object.keys(filterApplied?.selectedCustomFieldListValuesObject || {}).forEach(key => {
        const item = { key, tooltip_key: 'filter_applied.custom_field_ticket', value: null };
        
        const value = filterApplied.selectedCustomFieldListValuesObject[key];

        const customField = filterApplied.selectedCustomFieldListFilter.find(item => item.id === key);

        if (customField?.entity_type_id === CustomFieldEntity.Consumer) {
          item.tooltip_key = 'filter_applied.custom_field_consumer';
        }

        item.value = value.toString().startsWith('[') ? `@${customField.name}: (${(value as CustomSelectOptionType[]).map(item => item.value)?.join(', ')})` : `@${customField.name}: (${value})`;
        filters.push(item);
      });

      setFilterMode('custom');
    } else {
      Object.keys(queryFilterRef.current).forEach(key => {
        const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
        
        if (key === 'search') {
          item.value = queryFilterRef.current[key];
        } 
  
        if (key === 'employee') {
          item.value = filterApplied.selectedAgent?.map(item => item.value).join(', ');
          item.tooltip_key = 'filter_applied.assigned_employee';
        }

        if (key === 'tags') {
          item.value = filterApplied.selectedTag?.map(item => item.value).join(', ');
        }

        filters.push(item);
      });

      setFilterMode('main');
    }

    if (queryFilterRef.current.start_date && queryFilterRef.current.end_date) {
      const item = { key: 'date', tooltip_key: 'filter_applied.period', value: '' };

      if (filterApplied.selectedFilterDate.id === 'custom') {
        item.value = `${filterApplied.selectedDate.start.toLocaleDateString()} - ${filterApplied.selectedDate.end.toLocaleDateString()}`
      } else {
        item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
      }

      filters.push(item);
    }

    return filters;
  }

  const openModalEditConsumer = (item: ListConsumerInterface, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (item.created_by_employee && item.created_by_employee !== 'true') {
      setShowModalConsumer(true);
      setTimeout(() => {
        emitter.emit('open-modal-consumer', { userId: item.user_id });
      }, 50);
    }
  }

  const openModalNewConsumer = () => {
    setShowModalConsumer(true);
    setTimeout(() => {
      emitter.emit('open-modal-consumer', { userId: null });
    }, 50);
  }

  const closeModalConsumer = (reload: boolean, data?: { current: ConsumerInterface, duplicated: ConsumerInterface[] }) => {
    if (reload) {
      reloadConsumers(true);
    }
    if (data) {
      dispatch(setShowModalConsumersSimilarData(true));
      setTimeout(() => {
        emitter.emit('open-modal-consumer-similar-data', data);
      }, 50);
    }
    setShowModalConsumer(false);
  }

  const openModalDeleteConsumer = (type: 'menu-action' | 'table-action', userInfo: { userId: string, consumerName?: string}[]) => {
    setShowModalDeleteConsumer(true);
    setTimeout(() => {
      if (type === 'menu-action') {
        emitter.emit('open-modal-delete-consumer', userInfo);
      } else {
        const formatted = selectedItems.map(item => ({ userId: item }));
        emitter.emit('open-modal-delete-consumer', formatted);
      }
    }, 50);
  }

  const closeModalDeleteConsumer = (reload: boolean, userIds: string[]) => {
    if (reload) {
      reloadConsumers(true);
      
      if (userIds?.length > 0) {
        removeSelectedItem(userIds);
      }
    }
    setShowModalDeleteConsumer(false);
  }

  const openModalExportConsumer = (type: 'menu-action' | 'table-action', userInfo: { userId: string, consumerName?: string}[]) => {
    setShowModalExportConsumer(true);
    setTimeout(() => {
      if (type === 'menu-action') {
        emitter.emit('open-modal-export-consumer', userInfo);
      } else {
        const formatted = selectedItems.map(item => ({ userId: item }));
        emitter.emit('open-modal-export-consumer', formatted);
      }
    }, 50);
  }

  const closeModalExportConsumer = () => {
    setShowModalExportConsumer(false);
  }

  const handlePopoverConsumersListItemAction = (event: any, data: ListConsumerInterface, type: 'open-consumer-tickets' | 'export-consumer' | 'edit-consumer' | 'delete-consumer') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-consumers');
    setTimeout(() => {
      if (type === 'open-consumer-tickets') {
        navigate(
          `${constsRouters.routers.consumerTickets.path}`, 
          { 
            state: { 
              consumerUserId: data.user_id, 
              consumerName: data.name,
              page: 'consumers', 
              prevPath: constsRouters.routers.consumers.path 
            } 
          }
        );
      } else if (type === 'export-consumer') {
        openModalExportConsumer('menu-action', [{ userId: data.user_id, consumerName: data.name }]);
      } else if (type === 'edit-consumer') {
        openModalEditConsumer(data, event);
      } else if (type === 'delete-consumer') {
        openModalDeleteConsumer('menu-action', [{ userId: data.user_id, consumerName: data.name }]);
      } 
    }, 50);
  }

  const popoverItemAction = (data: ListConsumerInterface) => {
    const showOpenConsumerTicket = props.pageType !== 'tag-consumers';
    const showExportConsumer = true;
    const showEditConsumer = data.created_by_employee && data.created_by_employee !== 'true';
    const showDeleteConsumer = data.created_by_employee && data.created_by_employee !== 'true';

    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenConsumerTicket &&
            <span className='popover-item' onClick={(e) => handlePopoverConsumersListItemAction(e, data, 'open-consumer-tickets')}>
              <IconTicket />{t('list_popover_actions.open_consumer_tickets')}
            </span>
          }
          { showExportConsumer &&
            <span className='popover-item' onClick={(e) => handlePopoverConsumersListItemAction(e, data, 'export-consumer')}>
              <IconFileDownload />{t('list_popover_actions.export_consumer')}
            </span>
          }
          { showEditConsumer &&
            <span className='popover-item' onClick={(e) => handlePopoverConsumersListItemAction(e, data, 'edit-consumer')}>
              <IconEdit />{t('list_popover_actions.edit_consumer')}
            </span>
          }
          { showDeleteConsumer &&
            <span className='popover-item' onClick={(e) => handlePopoverConsumersListItemAction(e, data, 'delete-consumer')}>
              <IconTrashX />{t('list_popover_actions.delete_consumer')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfConsumers 
      t={t}
      pageType={props.pageType || 'consumers'}
      listHeaders={getListHeaders}
      listData={data}
      paginationDetails={paginationDetails}
      getConsumersPagination={getConsumersPagination}
      isLoading={isLoading}
      errorLoading={errorLoading}
      reloadConsumers={reloadConsumers}
      selectItem={selectItem}
      selectAllItems={selectAllItems}
      isSelectedItem={isSelectedItem}
      selectedItemsLength={selectedItems.length}
      hasSomeSelected={hasSomeSelected}
      hasSomeSelectedCheck={hasSomeSelectedCheck}
      isAllSelectedItems={isAllSelectedItems}
      exportAll={exportAll}
      exportSelected={exportSelected}
      deleteSelected={deleteSelected}
      clearSelected={clearSelected}
      clearPageSelected={clearPageSelected}
      sidebarOpened={sidebarOpened}
      showModalConsumer={showModalConsumer}
      closeModalConsumer={closeModalConsumer}
      showModalDeleteConsumer={showModalDeleteConsumer}
      closeModalDeleteConsumer={closeModalDeleteConsumer}
      showModalExportConsumer={showModalExportConsumer}
      closeModalExportConsumer={closeModalExportConsumer}
      filters={filters}
      clearSearchConsumers={clearSearchConsumers}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}
      openModalEditConsumer={openModalEditConsumer}

      // Dados de componentes legados
      showConsumerSimilarData={showConsumerSimilarData}
      currentConsumerForSimilarData={currentConsumerForSimilarData}
      setCurrentConsumerForSimilarData={setCurrentConsumerForSimilarData}
      similarConsumers={similarConsumers}
    />
  );
};

export default ListOfConsumersController;
