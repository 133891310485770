import { Button } from "react-bootstrap"
import { setQuickMessageId, setShowAlertFeedback, setShowMessageSend } from "../../../../store/internal"
// import draftToHtml from 'draftjs-to-html';
import ColorPicker from "./components/colorPicker/indexController";
import {Editor, EditorState, Modifier, RichUtils, convertToRaw} from 'draft-js';
import { IconPalette } from '@tabler/icons-react';
import { Channels } from "../../../../core/enums/channels";

export class ChatEditorController {
  private setEditorState: Function
  private props
  private stateToHTML: Function
  private parentRef
  private setHtmlMessageContent: Function
  private htmlToDraftEditorState: Function
  private setTemplateMessageId: Function
  private showDropZoneVisibility: boolean
  private setShowDropZoneVisibility: Function
  private files
  private setMaxSize: Function
  private maxSize: number
  private fileIds
  private fileLoading
  private setFiles: Function
  private setState: Function
  private state
  private setFileIds: Function
  private setFileLoading: Function
  private editorState
  private dispatch: Function
  private setShowAlertFeedback: Function
  private editorRef
  private setAudio: Function
  private setAudioBlob
  private setImgUrl
  public setPermission
  public setStream
  public setRecordingsStatus
  public setRunning
  public mimeType
  public setAudioChunks
  public stream
  public mediaRecorder
  public setTime
  public audioChunks
  public sendAudio
  public audio
  public audioBlob  
  public setColorPicker
  public disabled
  public setDisable
  private toggleColor
  public t
  public setImgUrlList
  public setUrls
  public currentRequester_user
  public templateParamsGeneralType

  constructor(setEditorState: Function, props, stateToHTML: Function, parentRef, setHtmlMessageContent: Function, htmlToDraftEditorState: Function, setTemplateMessageId: Function,
    showDropZoneVisibility: boolean, setShowDropZoneVisibility: Function, files, setMaxSize: Function, maxSize: number, fileIds, fileLoading, setFiles: Function, setState: Function,
    state, setFileIds: Function, setFileLoading: Function, editorState: EditorState, dispatch: Function, setShowAlertFeedback: Function, editorRef, setAudio, setAudioBlob, setImgUrl, setPermission, setStream, 
    setRecordingsStatus, setRunning, mimeType, setAudioChunks, stream, mediaRecorder, setTime, audioChunks, sendAudio, audio, audioBlob, setColorPicker, disabled, setDisable, toggleColor, t, setImgUrlList: Function, setUrls: Function,
    currentRequester_user, templateParamsGeneralType) {
    this.setEditorState = setEditorState
    this.props = props
    this.stateToHTML = stateToHTML
    this.parentRef = parentRef
    this.setHtmlMessageContent = setHtmlMessageContent
    this.htmlToDraftEditorState = htmlToDraftEditorState
    this.setTemplateMessageId = setTemplateMessageId
    this.showDropZoneVisibility = showDropZoneVisibility
    this.setShowDropZoneVisibility = setShowDropZoneVisibility
    this.files = files
    this.setMaxSize = setMaxSize
    this.maxSize = maxSize
    this.fileIds = fileIds
    this.setFiles = setFiles
    this.fileLoading = fileLoading
    this.setState = setState
    this.state = state
    this.setFileIds = setFileIds
    this.setFileLoading = setFileLoading
    this.editorState = editorState
    this.dispatch = dispatch
    this.setShowAlertFeedback = setShowAlertFeedback
    this.editorRef = editorRef
    this.setAudio = setAudio
    this.setAudioBlob = setAudioBlob
    this.setImgUrl = setImgUrl
    this.setPermission = setPermission
    this.setStream = setStream
    this.setRecordingsStatus = setRecordingsStatus
    this.setRunning = setRunning
    this.mimeType = mimeType
    this.setAudioChunks = setAudioChunks
    this.stream = stream
    this.mediaRecorder = mediaRecorder
    this.setTime = setTime
    this.audioChunks = audioChunks
    this.sendAudio = sendAudio
    this.audio = audio
    this.audioBlob = audioBlob
    this.setColorPicker = setColorPicker
    this.disabled = disabled
    this.setDisable = setDisable
    this.toggleColor = toggleColor
    this.t = t
    this.setImgUrlList = setImgUrlList
    this.setUrls = setUrls
    this.currentRequester_user = currentRequester_user
    this.templateParamsGeneralType = templateParamsGeneralType
  }

  onChange(editorState) {
    this.setEditorState(editorState);

    /*if (props.isAllowedMessage === false) {
      setHtmlMessageContent(null);
    }*/

    if (this.props.messageTriggerModal === true) {
      let html = this.stateToHTML(editorState.getCurrentContent(),{ defaultBlockTag: 'span' });
      this.props.callbackGetStringHtml(this.parentRef.current, html);
    }

    // envio usando o botao de salvar ticket
    if (editorState.getCurrentContent().hasText()) {
      let html = this.stateToHTML(editorState.getCurrentContent(),{ defaultBlockTag: 'span' });
      this.setHtmlMessageContent(html);
    } else {
      this.setHtmlMessageContent(null);
    }

    // Envio de áudio usando o botão salvar

  };

  replacePlaceholders(message, tags) {
    let updatedMessage = message;
    let sortedTags = [];
    if (tags && Array.isArray(tags)) {
      sortedTags = tags.sort((a, b) => a.tag_number - b.tag_number);
    }

    const placeholders = updatedMessage.match(/{{.*?}}/g);

    if (placeholders) {
      placeholders.map((placeholder, index) => {
        if (sortedTags[index]) {
          const tag = sortedTags[index];

          if (tag.tag === "consumer.name") {
            updatedMessage = updatedMessage.replace(placeholder, this.currentRequester_user?.name);
          } else if (tag.tag === "consumer.phone") {
            if (this.currentRequester_user?.phone !== null && this.currentRequester_user?.phone !== undefined) {
              updatedMessage = updatedMessage.replace(placeholder, this.currentRequester_user?.phone);
            } else {
              updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
            }
          } else if (tag.tag === "consumer.email") {
            if (this.currentRequester_user?.email !== null && this.currentRequester_user?.email !== undefined) {
              updatedMessage = updatedMessage.replace(placeholder, this.currentRequester_user?.email);
            } else {
              updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
            }
          } else if (tag.tag === "general.type") {
            updatedMessage = updatedMessage.replace(placeholder, this.templateParamsGeneralType[0]?.value);
          } else {
            updatedMessage = updatedMessage.replace(placeholder, `{{${tag.tag}}}`);
          }
        }
      });
    }
    return updatedMessage;
  }

  templateMessage(templateMessage: { message: string; id: string, tags }, ticketInfo?: any) {
    let message
    if (ticketInfo !== undefined) {
      if (ticketInfo === null) {
        this.setEditorState(this.htmlToDraftEditorState(this.replacePlaceholders(templateMessage?.message, templateMessage?.tags)))
        message = this.replacePlaceholders(templateMessage?.message, templateMessage?.tags)
      } else {
        this.setEditorState(this.htmlToDraftEditorState(templateMessage?.message?.replace("{consumidor}", ticketInfo?.requester_user?.name).replace("{ticket}", ticketInfo?.ticket_reference_id)))
      }
    } else if (ticketInfo !== null) {
      this.setEditorState(this.htmlToDraftEditorState(templateMessage?.message))
    } else {
      this.setEditorState([])
    }
    
    // this.setEditorState(this.htmlToDraftEditorState(templateMessage.message));
    this.setTemplateMessageId(templateMessage.id)
    this.setHtmlMessageContent(templateMessage.message)
    this.props.callbackGetStringHtml(this.parentRef.current, this.stateToHTML(this.htmlToDraftEditorState(templateMessage.message).getCurrentContent(),{ defaultBlockTag: 'span' }));
  }

  quickMessage(quickMessage : { message: string; id: string; quick_message_attachment: string }, ticketInfo?: any){
    if (ticketInfo !== undefined) {
      this.setEditorState(this.htmlToDraftEditorState(quickMessage?.message?.replace("{consumidor}", ticketInfo?.requester_user?.name).replace("{ticket}", ticketInfo?.ticket_reference_id)))
      this.setImgUrl(quickMessage?.quick_message_attachment)

      //this.setUrls(quickMessage?.message?.match(regexUrls)? quickMessage?.message?.match(regexUrls): [])
    } else if (ticketInfo !== null) {
      this.setEditorState(this.htmlToDraftEditorState(quickMessage?.message))
    } else {
      this.setEditorState([])
    }
    
    // this.setEditorState(this.htmlToDraftEditorState(quickMessage.message));
    //this.setQuickMessageId(quickMessage.id)
    this.dispatch(setQuickMessageId(quickMessage?.id))
    this.setHtmlMessageContent(quickMessage.message)
    this.props.callbackGetStringHtml(this.parentRef.current, this.stateToHTML(this.htmlToDraftEditorState(quickMessage.message).getCurrentContent()));
  }

  onOfDropZone(e: Object) {
    if (this.showDropZoneVisibility) {
      this.setShowDropZoneVisibility(false)
    } else {
      this.setShowDropZoneVisibility(true)
    }
  }

  deleteFile(fileName: string) {
    let newFiles = this.files.filter((item) => item.path !== fileName);
    let sizeDeletedFile = this.files.filter((item) => item.path === fileName);
    let newFileIds = this.fileIds.filter((item) => item.name !== fileName);
    this.setMaxSize(this.maxSize - sizeDeletedFile[0].size)
    this.setFiles(newFiles);
    this.setFileIds(newFileIds);
  }

  cleanAllFiles() {
    this?.props?.afterMessageSent();
    this.setFiles([]);
    this.setFileIds([]);
    this.setFileLoading([]);
    this.setMaxSize(0);
    if (this.audioBlob && this.audio) {
      this.setAudio(null)
      this.setAudioBlob(null)
    }
    this.setImgUrl(null)
    this.setImgUrlList([])
  }

  colorPicker(colorPicker) {
    let itemClasses = 'bi09khh colorPicker'
    if (colorPicker) {
      itemClasses += ' colorPickerActive'
    } 
    return (
    <div className={itemClasses}>
      <button
        className="buttonAttachmentBlur"
        onClick={() => this.setColorPicker(!colorPicker)}
      >
        <IconPalette/>
      </button>
      {colorPicker && <ColorPicker colors={this.getColors()} editorState={this.editorState} onToggle={this.toggleColor}/>}
  </div>)
  }

  buttonAttachment(stateVisibility: boolean) {
    if (stateVisibility) {
      return (
        <div className="bi09khh">
          <button
            className="buttonAttachmentFocus"
            onClick={(e) => this.onOfDropZone(e)}
          >
            <svg id="Grupo_11416" data-name="Grupo 11416" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path id="Caminho_9714" data-name="Caminho 9714" d="M0,0H24V24H0Z" fill="none" />
              <path id="Caminho_9715" data-name="Caminho 9715" d="M15,7,8.5,13.5a2.121,2.121,0,0,0,3,3L18,10a4.243,4.243,0,1,0-6-6L5.5,10.5a6.364,6.364,0,0,0,9,9L21,13" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg>
          </button>
        </div>
      );
    } else {
      return (
        <div className="bi09khh">
          <button
            // disabled={this.disabled}
            className="buttonAttachmentBlur"
            onClick={
              (e) => {
                // this.onOfDropZone(e);

                if (this.disabled) {
                  this.dispatch(setShowAlertFeedback({ message: this.t("home_tickets.chat_editor.whatsapp_limit_files"), visibility: true, signalIcon: false }));
                } else {
                  this.onOfDropZone(e);
                }
              }
            }
          >
            <svg id="Grupo_11416" data-name="Grupo 11416" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path id="Caminho_9714" data-name="Caminho 9714" d="M0,0H24V24H0Z" fill="none" />
              <path id="Caminho_9715" data-name="Caminho 9715" d="M15,7,8.5,13.5a2.121,2.121,0,0,0,3,3L18,10a4.243,4.243,0,1,0-6-6L5.5,10.5a6.364,6.364,0,0,0,9,9L21,13" fill="none" stroke="#707070" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
            </svg>
          </button>
        </div>
      );
    }
  }

  buttonAttachmentImage(stateVisibility: boolean) {
    if (stateVisibility) {
      return (
        <div className="bi09khh">
          {/* <button
                className="buttonAttachmentFocus"
                onClick={(e) => this.onOfDropZone(e)}
              > */}
          <Button className="buttonAttachmentFocus d-flex" style={{ padding: '1px 6px' }} onClick={(e) => this.onOfDropZone(e)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#444" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 8h.01" />
              <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
              <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
              <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
            </svg>
          </Button>
          {/* </button> */}
        </div>
      );
    } else {
      return (
        <div className="bi09khh">
          <Button className="buttonAttachmentBlur d-flex" style={{ padding: '1px 6px' }} onClick={(e) => this.onOfDropZone(e)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#707070" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M15 8h.01" />
              <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
              <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
              <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
            </svg>
          </Button>
          {/* <button
                className="buttonAttachmentBlur"
                onClick={(e) => this.onOfDropZone(e) }
              >oioioi
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#707070" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path d="M15 8h.01" />
                  <path d="M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z" />
                  <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
                  <path d="M14 14l1 -1c.928 -.893 2.072 -.893 3 0l3 3" />
                </svg>
              </button> */}
        </div>
      );
    }
  }

  getMicrophonePermission = async () => {
    if("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });
        this.setPermission(true)
        this.setStream(streamData)
      } catch (err) {
        console.log(err.message);
      }
    } else {
      alert ("The MediaRecorder API is not suported in your browser.")
    }
  }

  startRecording = async () => {
    this.setRecordingsStatus("recording")
    this.setRunning(true)

    // create new Media redorcer instance using the stream
    const media = new MediaRecorder(this.stream)
    // set the MediaRecorder instance to the mediaRecorder ref
    this.mediaRecorder.current = media
    // invokes the start method to start the recording process
    this.mediaRecorder.current.start()

    let localAudioChunks = []
    this.mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data)
    }
    this.setAudioChunks(localAudioChunks)
  }

  stopRecording = () => {
    this.setRecordingsStatus("inactive")
    this.setRunning(false)
    this.setTime(0)

    // stops the recording instance
    this.mediaRecorder.current.stop()
    this.mediaRecorder.current.onstop = () => {
      // creates a blob file from the audiochunks data
      const audioBlob = new Blob(this.audioChunks, { type: this.mimeType })
      // creates a playable URL from the blob file.
      const audioUrl = URL.createObjectURL(audioBlob)
      const returnedTarget = Object.assign(audioBlob, { isLoading: true, name: "audio.aac", path: "audio.aac" });
      let file = new File([returnedTarget], returnedTarget.name, {type: returnedTarget.type})
      this.sendAudio(Object.assign(file, { path: returnedTarget.name }))
      this.setAudio(audioUrl)
    }
  }

  exportStateToStringHtml = async (editorState: EditorState, channel) => {
    this.dispatch(setShowMessageSend(true))
    let isLoadingFiles = false;
    this.files.map(file => {
      if (file.isLoading === true) {
        isLoadingFiles = true;
      }
    });

    if (!isLoadingFiles) {
      if (this.files.length > 1 && channel === Channels.WhatsApp) {
        this.dispatch(setShowAlertFeedback({ message: this.t("home_tickets.chat_editor.whatsapp_limit_files"), visibility: true, signalIcon: false }));
      }else if (this.editorState.getCurrentContent().hasText()) {
        let content = convertToRaw(editorState.getCurrentContent())
        this.props.callbackGetStringHtml(this.parentRef.current, this.stateToHTML(this.editorState.getCurrentContent(),{ defaultBlockTag: 'span' }));
        this.props.sendMessage();
        this.cleanAllFiles();
      } else {
        if (this.files.length > 0) {
          this.props.callbackGetStringHtml(this.parentRef.current, `<p>${this.t('home_tickets.chat_editor.attachment_follows')}</p>`);
          this.props.sendMessage();
          this.cleanAllFiles();
          // this.setDisable(false)

        } else {
          this.dispatch(this.setShowAlertFeedback({ visibility: true, message: this.t('home_tickets.chat_editor.empty_message_attachment'), signalIcon: false }));
        }
      }
    } else {
      this.dispatch(this.setShowAlertFeedback({ visibility: true, message: this.t('home_tickets.chat_editor.attachments_not_loaded'), signalIcon: false }));
    }
  }

  getColors() {
    return [
      {label: 'color-#ff0000', color: '#ff0000'},
      {label: 'color-#ff7f00', color: '#ff7f00'},
      {label: 'color-#b4b400', color: '#b4b400'},
      {label: 'color-#00b400', color: '#00b400'},
      {label: 'color-#0000ff', color: '#0000ff'},
      {label: 'color-#4b0082', color: '#4b0082'},
    ];
  }

  getColorStyleMap() {
    let colors = this.getColors()
    const colorStyleMap = {};
    colors.map(item => colorStyleMap[item.label] = {color: item.color})
    return colorStyleMap;
  }

  focusEditor() {
    try {
      this.editorRef.current?.focus();
    } catch(err) {
      console.log(err.message)
    }
  }

  // myKeyBindingFn(e: React.KeyboardEvent<Element>): string | null {
  //   console.log(e.key);
  //   if (e.key === 'Tab') {
  //     setTimeout(() => { 
  //       document.getElementById("sendButtonMessage").focus(); 
  //     }, 50);
  //     return 'handled';
  //   }
  //   return 'not-handled';
  // }

}