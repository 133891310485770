import { useEffect, useState, useRef } from 'react';

const useSingleTabDetection = () => {
  const [isSingleTab, setIsSingleTab] = useState(true);
  const tabIdRef = useRef<string>(Math.random().toString(36).substring(2, 9)); // Gera o ID e o armazena em useRef
  const TAB_KEY = 'single_tab_open';

  const updateTabStatus = () => {
    const existingTab = localStorage.getItem(TAB_KEY);

    if (!existingTab) {
      localStorage.setItem(TAB_KEY, tabIdRef.current); // Usa o tabId gerado com useRef
      setIsSingleTab(true);
    } else if (existingTab !== tabIdRef.current) {
      setIsSingleTab(false); // Indica que outra aba já está aberta
    }
  };

  useEffect(() => {
    updateTabStatus();
    const existingTab = localStorage.getItem(TAB_KEY);

    // Verifica se já existe outra aba aberta
    if (!existingTab) {
      localStorage.setItem(TAB_KEY, tabIdRef.current); // Usa o tabId gerado com useRef
      setIsSingleTab(true);
    } else if (existingTab !== tabIdRef.current) {
      setIsSingleTab(false);
    }

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === TAB_KEY && e.newValue !== tabIdRef.current) {
        setIsSingleTab(false);
      }
    };

    const handleBeforeUnload = () => {
      if (localStorage.getItem(TAB_KEY) === tabIdRef.current) {
        localStorage.removeItem(TAB_KEY);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);

      if (localStorage.getItem(TAB_KEY) === tabIdRef.current) {
        localStorage.removeItem(TAB_KEY);
      }
    };
  }, []);

  // Retorna isSingleTab e a função que troca para esta aba
  const handleClear = () => {
    localStorage.removeItem(TAB_KEY); // Atualiza o localStorage com o tabId atual
    window.location.reload(); // Recarrega a página para fazer esta aba ser a ativa
  };

  return { isSingleTab, handleClear }; // Agora retorna o handleUseThisTab também
};

export default useSingleTabDetection;
