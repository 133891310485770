import React, { useState, useEffect, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
} from 'chart.js';

import { useNavigate } from 'react-router-dom';

import { getToken } from '../../../../store/token'
import { getIdCompany } from '../../../../store/company'
import { getIdEmployee } from '../../../../store/employee'

import { getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../../store/user'

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  getShowEditTicketsModal, setShowAlertFeedback,
} from '../../../../store/internal'

import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import UserService from '../../../../services/user-service';
import HomeDashboardChatbot from './index';
import { MetricInterface, TicketsPeriodMetric, TokenInterface } from '../../../../services/requestsInterfacesModel';
import { ErrorRequestController } from '../../../../services/errorsInterfaceModel';
import { getChatbot } from '../../../../store/chatbot';
import { useMainAppContext } from '../../../../core/context/main-app-context';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend
);

const AppRequesterConst = new AppRequesterController();

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    name?: string;
  }
}

const HomeDashboardChatbotController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { setMainHeader } = useMainAppContext();

  const [/*aux*/, setAux] = useState([]);
  const [currentState, SetCurrentState] = useState("finalized");
  const [resetingFilters, setResetingFilters] = useState(false);
  const [yoursTickets, setYoursTickets] = useState(false);
  const [/*currentParams*/, SetCurrentParams] = useState<any>({});
  const [currentParamsNames, SetCurrentParamsNames] = useState({});
  const [currentFilterDatas, setcurrentFilterDatas] = useState(undefined);
  const [currentFilterSearch, SetCurrentFilterSearch] = useState(null);
  // const [firstDayDate, setFirstDayDate] = useState(new Date());
  const [currentDayDate, /*setCurrentDayDate*/] = useState(new Date());

  const [openModalPhone, setOpenModalPhone] = useState(undefined);
  const [phoneNumber, /*setPhoneNumber*/] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [dataChartTicketsHour, setDataChartTicketsHour] = useState([]);
  const [sessionBot, setSessionBot] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [retentionRate, setRetetntionRate] = useState(0.0);
  const [nps, setNps] = useState(0.0);
  const [labelDays, setLabelDays] = useState([]);
  const [dataBotAttendance, setDataBotAttendance] = useState([]);
  const [dataHumanAttendance, setDataHumanAttendance] = useState([]);
  const [topTermos, setTopTermos] = useState([]);

  const MetricModal = {} as MetricInterface;
  const [dataChart, setDataChart] = useState(MetricModal?.data?.value);
  const [dataNpsPieChart, setDataNpsPieChart] = useState(null);

  const [/*currentFilterDates*/, setCurrentFilterDates] = useState([null, null]);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [channel, setChannel] = useState(null);
  // const [departmentId, setDepartmentId] = useState(null);
  // const [tag, setTag] = useState(null);
  const [employee, /*setEmployee*/] = useState(null);
  // const [consumer, setConsumer] = useState(null);
  const [bot, setBot] = useState(null);

  const [/*currentPage*/, SetCurrentPage] = useState(1);

  const componentRef = useRef();
  const [printPDF, setPrintPDF] = useState(false);

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    chatbot: {
      hasBot: useSelector(getChatbot)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      lastname: useSelector(getUserLastName),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    internal: {
      getShowEditTicketsModal: useSelector(getShowEditTicketsModal),
    }
  };

  useEffect(() => {
    setMainHeader({
        pageTitle: t('dashboard_chatbot.title'),
        pageSubtitle: t('dashboard_chatbot.subtitle'),
        hasSearchComponent: false,
        hasSearchInput: false,
        hasSearchFilter: false,
        searchPage: null,
        searchPlaceholder: null,
        searchFunction: null,
        clearSearchFunction: null,
        hasCreateButton: false,
        createButtonText: null,
        createButtonTooltip: null,
        createButtonAction: null,
        customBackgroundColor: '#f9fbff'
    });
  }, []);

  useEffect(() => {
    if (currentFilterDatas !== undefined) {
      SetCurrentParams((state2) => {
        getAllTicketsToPaginationFinalized_Deleted(Object.assign(state2));
        return state2;
      });
    }
  }, [printPDF, currentState, currentFilterDatas]);

  const addLeftZeros = (value: number) => value.toString().padStart(2, '0');

  const getDayMonth = (date: Date) => {
    let data = new Date(date);
    const day = addLeftZeros(data.getDate());
    const month = addLeftZeros(data.getMonth() + 1);
    return day + '/' + month
  }


  const addValueaLabelDays = (days) => {
    setLabelDays([])
    let daysData = [];
    days.map((item) => { 
      daysData.push(getDayMonth(item));
    });
    setLabelDays(state => {
      return state.concat(daysData);
    });
  }

  const getAllMetrics = async (params: any) => {

    const headers = UserService.getHeaders()

    await AppRequesterConst.Get(
      '/analytics', { headers, params: params },

      (response: Object) => { },
      (data) => {
        if (data.status === 200) {
          setSessionBot(data.data.value.sessions.analyticsResults.value);
          setTotalMessages(data.data.value['messages-total'].analyticsResults.value)
          setRetetntionRate(data.data.value['retention-rate'].analyticsResults.value)
          setNps(data.data.value.NPS.analyticsResults.value)
          setDataChart(data.data.value.NPS.npsScoredTicketsResult)
          setDataChartTicketsHour(data.data.value['sessions-hour'].analyticsResults.value)
          setTopTermos(data.data.value['top-terms'].analyticsResults.value)
          addValueaLabelDays(data.data.value['attendance-bots'].analyticsResults.value.todos_os_dias)
          setDataBotAttendance(data.data.value['attendance-bots'].analyticsResults.value.resultadoAtendimentosBots)
          setDataHumanAttendance(data.data.value['attendance-bots'].analyticsResults.value.resultadoAtendimentosHumanos)
        }
      },
      (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
    );
  }
  const exportTickets = async () => {
    const headers = UserService.getHeaders();

    let params = {};
    if (startDate !== null) {
      params["start_date"] = startDate.toString();
    }

    if (endDate !== null) {
      params["end_date"] = endDate.toString();
    }

    if (bot !== null && bot !== undefined && bot.length > 0) {
      params["bot_nlp_id"] = bot.toString();
    }

    if (channel === "NA") {
      params["channel"] = null;
    } else if (channel !== null) {
      // console.log(channel.toString(), "Filter")
      params["channel"] = channel.toString();
    }

    if (employee === "NA") {
      params["employee"] = null;
    } else if (employee !== null) {
      // console.log(employee.toString(), "Employee")
      params["employee"] = employee.toString();
    }

    if (values?.chatbot?.hasBot) {
      params["is_chatbot"] = "true"
    }

    await AppRequesterConst.Get(
      '/ticket/period/tickets', { headers, params: params },
      (response: Object) => { },
      (data: TicketsPeriodMetric) => {
        if (data.status === 200 && data.data) {
          var decoder = new TextDecoder();
          var uintData = Uint8Array.from(data.data.content.data);
          var arrayBufferData = uintData.buffer;
          var decoded = decoder.decode(arrayBufferData);

          var hiddenElement = document.createElement('a');
          hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(decoded);
          hiddenElement.target = '_blank';

          hiddenElement.download = data.data.fileName;
          hiddenElement.click();
          dispatch(setShowAlertFeedback({ message: t('dashboard_chatbot.sucess_export'), visibility: true, signalIcon: true }));

        } else if (data.status === 204) {
          dispatch(setShowAlertFeedback({ message: t('dashboard_chatbot.no_data_export'), visibility: true, signalIcon: false }));
        }
      },
      (error: ErrorRequestController) => {
      }, navigate, dispatch, setIsLoading,
      {
        errorsFunctions: {
          error400: () => {
            dispatch(setShowAlertFeedback({ message: `${t('dashboard.error400')}`, visibility: true, signalIcon: false }));
          },
          error422: () => {
            dispatch(setShowAlertFeedback({ message: `${t('dashboard.error422')}`, visibility: true, signalIcon: false }));
          }
        },
        values: values
      },
    );
  }
  const options = {
    type: 'bar',
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: "black",
        display: true,
        anchor: 'end' as const,
        offset: -14,
        align: "start" as const,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: false,
        grid: {
          color: 'transparent',
        },
      },
      y: {
        stacked: false,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  const labels = ["00h", "01h", "02h", "03h", "04h", "05h", "06h", "07h", "08h", "09h", "10h", "11h", "12h", "13h", "14h", "15h", "16h", "17h", "18h", "19h", "20h", "21h", "22h", "23h"];

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: dataChartTicketsHour,
        backgroundColor: 'rgb(79,105,189)',
      },
    ]
  };

  const data2 = {
    labels: [],
    datasets: [
      {
        label: "Bot",
        data: [],
        backgroundColor: 'rgb(79,105,189)',
      },
      {
        label: `${t('dashboard_chatbot.human_label')}`,
        data: [],
        backgroundColor: 'rgb(255,129,25)',
      },
    ]
  }; 

  useEffect(() => {
    if (dataChart) {
        getResultNpsScore();
    }
}, [dataChart]);

const optionsPieChart = {
  elements: {
    arc: {
      borderWidth: 0
    }
  },
  plugins: {
      legendDistance: {
          margin: '0px 20px 0px 0px'
      },
      legend: {
          display: true,
          position: "right",
          align: "center",
          labels: {
                font: {
                  size: '13px',
                }
          },
          onClick: () => {},
          onHover: (evt, item, legend) => {
              const chart = legend.chart;
              const tooltip = chart.tooltip;
              const chartArea = chart.chartArea;

              tooltip.setActiveElements([{
                datasetIndex: 0,
                index: item.index,
              }], {
                x: (chartArea.left + chartArea.right) / 2,
                y: (chartArea.top + chartArea.bottom) / 2,
              });
    
              chart.update();
            }
      },
      datalabels: {
          color: '#fff',
          labels: {
              title: {
                font: {
                  size: '15px',
                }
              },
          },
          anchor: 'end',
          align: 'start',
          offset: '30',
      },
      tooltip: {
          callbacks: {
              label: function(tooltipItem, data) {
                  let sum = 0;
                  let dataArr: number[]; 
                  dataArr = tooltipItem.dataset.data as unknown as number[];

                  dataArr.map((data) => {
                      sum += data;
                  });
                  let percentage = ((Number(tooltipItem.formattedValue) * 100) / sum).toFixed(2) + "%";
                  return percentage;
              },
          }
        }
  },
  responsive: true,
  maintainAspectRatio: false,
}

const getResultNpsScore = () => {
  setDataChart((datas) => {
      setDataNpsPieChart((chart) => {
          const npsScores = {
              '0. Não Respondidos': datas?.totalNotResponded,
              '1. Muito insatisfeito': datas?.npsScores1,
              '2. Insatisfeito': datas?.npsScores2,
              '3. Regular': datas?.npsScores3,
              '4. Satisfeito': datas?.npsScores4,
              '5. Muito satisfeito': datas?.npsScores5,
          };
          
          const filteredNpsScores = Object.values(npsScores).filter(score => score > 0);
          const filteredLabels = Object.keys(npsScores).filter(label => npsScores[label] > 0);
  
          chart = {
              labels: filteredLabels,
              datasets: [{
                  label: 'NPS Scores',
                  data: filteredNpsScores,
                  backgroundColor: [
                      "#2C3789",
                      "#1D60AE",
                      "#495BA7",
                      "#1E91C8",
                      "#0D92B1",
                      "#6F8EC7",
                  ],
                  borderColor: '#fff',
                  borderWidth: 1
              }]
          };
  
          return chart;
      });
  
      return datas;
  
  });
};

  const [dataChart2, setDataChart2] = useState(data2)

  //useEffect que atualiza o gráfico de barra dupla toda vez que o valor muda, o componente não faz isso nativamente
  useEffect(()=>{
    data2.datasets[0].data = dataBotAttendance
    data2.datasets[1].data = dataHumanAttendance
    data2.labels = labelDays
    setDataChart2(data2)
  },[labelDays, dataBotAttendance, dataHumanAttendance])

  const getAllTicketsToPaginationFinalized_Deleted = (params: any,) => {
    return getAllMetrics(params);
  }

  const filteringTickets = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj, dataObj: Object, action: string) => {
    SetCurrentPage(1);
    setAux([]);
    setcurrentFilterDatas(undefined);
    if (document.getElementById("listOfTickets") != null) {
      document.getElementById("listOfTickets").scrollTop = 0;
    }

    let name = "";
    if (e.currentTarget) {
      name = e.currentTarget.name;
    } else if (e.target) {
      name = e.target.name;
    }

    if (name === "cleanSearchButtonName" || action === "clean") {
      SetCurrentFilterSearch("");
      auxObj = {};
      SetCurrentState("searching");
      if (currentState === "searching") {
        SetCurrentState("");
      }
    } else if (name === "searchButtonName" || name === "searchInputName") {
      auxObj = {};

      SetCurrentState("searching");
      if (currentState === "searching") {
        SetCurrentState("");
      }

      if (currentFilterSearch !== null && currentFilterSearch !== "") {
        auxObj["search"] = currentFilterSearch;
      }
    } else {
      SetCurrentState("filtering");

      if (currentState === "filtering") {
        SetCurrentState("");
      }
    }
    setBot(auxObj?.bot_id)
    setEndDate(auxObj?.end_date)
    setStartDate(auxObj?.start_date)
    SetCurrentParams(auxObj);
    SetCurrentParamsNames(dataObj);
    setcurrentFilterDatas("filtered");
  }

  const cleanList = () => {
    SetCurrentParams({});
    SetCurrentPage(1);
    setAux([]);
    setcurrentFilterDatas(undefined);
    setResetingFilters(true);

    SetCurrentState("filtering");

    if (currentState === "filtering") {
      SetCurrentState("");
    }
  }

  const getDate = () => {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    return (firstDay);
  }

  const printIsValid = () => {
    setPrintPDF(true)
    return Promise.resolve()
  }

  const printIsInvalid = () => {
    setPrintPDF(false)
  }

  return (
    <HomeDashboardChatbot
      isLoading={isLoading}
      printPDF={printPDF}
      phoneNumber={phoneNumber}
      openModalPhone={openModalPhone}
      setOpenModalPhone={setOpenModalPhone}
      componentRef={componentRef}
      currentParamsNames={currentParamsNames}
      currentState={currentState}
      SetCurrentState={SetCurrentState}
      values={values}
      filteringTickets={filteringTickets}
      cleanList={cleanList}
      resetingFilters={resetingFilters}
      setResetingFilters={setResetingFilters}
      yoursTickets={yoursTickets}
      setYoursTickets={setYoursTickets}
      getDate={getDate}
      currentDayDate={currentDayDate}
      sessionBot={sessionBot}
      totalMessages={totalMessages}
      retentionRate={retentionRate}
      nps={nps}
      printIsValid={printIsValid}
      options={options}
      data={data}
      data2={dataChart2}
      printIsInvalid={printIsInvalid}
      topTermos={topTermos}
      exportTickets={exportTickets}
      setCurrentFilterDates={setCurrentFilterDates}
      setBot={setBot}
      setChannel={setChannel}
      dataNpsPieChart={dataNpsPieChart}
      optionsPieChart={optionsPieChart}
      t={t}    
    />
  );
}

export default HomeDashboardChatbotController;
