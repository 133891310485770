import './styles.scss';
import { FC } from "react";
import { MessagesComponentInterface } from "./indexModel";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconAlertCircle, IconCheck, IconChevronDown, IconClock, IconCloudX, IconMessages, IconPaperclip, IconPhoto } from '@tabler/icons-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import FileViewerController from './components/fileViewer/indexController';
import { CustomIconUpload } from '../chatEditorComponent/components/toolbarFileUploader/indexModel';

const MessagesComponent: FC<MessagesComponentInterface> = (props) => {

  const emptyClass = !props.ticketMessages || props.ticketMessages?.length === 0 ? 'ticket-chat-messages-empty' : '';

  return (
    <div id="ticket-chat-messages"
      className={`ticket-chat-messages ${props.transitionStyleClass} ${emptyClass}`} style={props.dynamicStyle} ref={props.chatMessagesRef}>
      { props.ticketMessages?.length > 0 &&
        <InfiniteScroll
          dataLength={props.ticketMessages?.length || 0}
          next={props.fetchMore}
          hasMore={props.hasMoreMessage}
          loader={<></>}
          scrollableTarget="ticket-chat-messages"
          scrollThreshold="0px"
          inverse={true}
        >
          {props.ticketMessages?.map(message => {
            const itemClass = props.getMessageStyleClass(message);
            const user = props.getUserMessage(message);

            if (message.date) {
              return (
                <div className={itemClass} key={new Date(message.date).getTime()} style={{ zIndex: props.loadingFetchData ? '-1' : 'unset' }}>
                  <span className="ticket-chat-line-system"></span>
                  <span className="ticket-chat-value-system">
                    <OverlayTrigger placement='top' overlay={<Tooltip>{props.getDateFormatted(message.date).date}</Tooltip>}>
                      <span id="message-date-text">{ props.getDateSystemMsg(message.date) }</span>
                    </OverlayTrigger>
                  </span>
                </div>
              );
            } else {
              return (
                <div className={itemClass} key={message._id}>
                  <span className="ticket-chat-message-info">
                    <span className='ticket-chat-message-info-name'>{props.getUserName(message.user_id, message.user_name)}</span>
                    <OverlayTrigger placement='top' overlay={<Tooltip>{user.tooltip}</Tooltip>}>
                      <span className='ticket-chat-message-info-type'>{user.icon}</span>
                    </OverlayTrigger>
                  </span>
                  <div className={`ticket-chat-message-container ${props.openedPopoverId === message._id ? 'ticket-chat-message-options-clicked' : ''}`}>
                    { !props.ticketIsBlocked && false && // >>>>> Só remover o "false" quando for implementar a função de responder mensagem <<<<<
                      <div className="ticket-chat-message-options">
                        <OverlayTrigger 
                          trigger={['click']} 
                          placement='left'
                          overlay={props.popoverItemAction(message)} 
                          rootClose={true}
                          show={props.openedPopoverId === message._id}
                          onToggle={() => props.setOpenedPopoverId(null)}
                        >
                          <span className="ticket-chat-message-options-icon">
                            <IconChevronDown onClick={(e) => props.handlePopoverMenuAction(e, message._id)} />
                          </span>
                        </OverlayTrigger>
                      </div>
                    }
                    <div className="ticket-chat-message-content" dangerouslySetInnerHTML={{ __html: props.sanitizeContent(message)}}></div>
                    {
                      !message.isMockedMessage && message.attachments?.length > 0 &&
                      <FileViewerController 
                        type='messages'
                        hasMessageContent={message.content !== '<p></p>' &&  message.content !== '<span></span>'}
                        showFileViewer={props.showFilePreview}
                        setShowFileViewer={props.setShowFilePreview}
                        attachments={message.attachments}
                        currentAudioPlayingUrl={props.currentAudioPlayingUrl}
                        setCurrentAudioPlayingUrl={props.setCurrentAudioPlayingUrl}
                        previewFirstAttachment={props.previewFirstAttachment} 
                      />
                    }
                    { message.isMockedMessage && message.status === 'sending' && message.attachments?.length > 0 &&
                      <div className="ticket-chat-message-attachment-sending">
                        {message.attachments.map((itemId: any) => {
                          return (
                            <span className='ticket-chat-message-attachment-sending-mock' key={itemId}>
                              {CustomIconUpload()}
                            </span>
                          );
                        })}
                      </div>
                    }
                    { message.isMockedMessage && message.status === 'not sent' && message.attachments?.length > 0 &&
                      <div className="ticket-chat-message-attachment-sending">
                        {message.attachments.map((itemId: any) => {
                          return (
                            <span className='ticket-chat-message-attachment-sending-mock' key={itemId}>
                              <IconPaperclip className='icon-sent-error' />
                            </span>
                          );
                        })}
                      </div>
                    }
                    <span className="ticket-chat-message-date">
                      <span title={props.getDateFormatted(message.createdAt).datetime}>{props.getDateFormatted(message.createdAt).time}</span>
                      <span className="ticket-chat-message-status">
                        { message.status === 'sending' &&
                          <OverlayTrigger placement='left' overlay={<Tooltip>{props.t('sending_message')}</Tooltip>}>
                            <IconClock /> 
                          </OverlayTrigger>
                        }
                        { message.status === 'sent' &&
                          <OverlayTrigger placement='left' overlay={<Tooltip>{props.t(props.currentUserId === message.user_id ? 'message_sent' : 'message_received')}</Tooltip>}>
                            <IconCheck /> 
                          </OverlayTrigger>
                        }
                        { message.status === 'not sent' &&
                          <OverlayTrigger placement='left' overlay={<Tooltip>{message.whyNotSend || props.t('message_not_sent')}</Tooltip>}>
                            <IconAlertCircle /> 
                          </OverlayTrigger>
                        }
                      </span>
                    </span>
                  </div>
                </div>
              );
            }
          })}
        </InfiniteScroll>
      }
      {
        !!emptyClass &&
        <>
          <IconMessages className='tabler-icon-messages' />
          { props.loadingFetchData && <span className="ticket-chat-messages-info">{props.t('loading_messages')}</span>}
          { !props.loadingFetchData && <span className="ticket-chat-messages-info">{props.t('no_messages_found')}</span>}
        </>
      }
    </div>
  );
}

export default MessagesComponent;