import { FC, useEffect, useRef, useState } from 'react';
import PopupRecipientsEmail from './index';
import { PopupRecipientsEmailControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';
import UserService from '../../../../../../../../services/user-service';
import { AppRequesterController } from '../../../../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../../../../store/internal';

const PopupRecipientsEmailController: FC<PopupRecipientsEmailControllerInterface> = (props) => {
  const { t } = useTranslation('PopupRecipientsEmail');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { currentTicket, setCurrentTicket, currentConsumer } = useViewTicketContext();
  const AppRequesterConst = new AppRequesterController();

  const [isLoading, setIsLoading] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [mainEmail, setMainEmail] = useState('');
  const [tempListCC, setTempListCC] = useState<string[]>([]);
  const [tempListCCO, setTempListCCO] = useState<string[]>([]);
  
  const tagifySettings = {
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }

  useEffect(() => {
    if (props.showPopupRecipientsEmail) {
      const handleClickOutside = (event: any) => {
        if (popupRef?.current && !popupRef?.current?.contains(event.target)) {
          props.setShowPopupRecipientsEmail(false);
        }
      }
  
      if (popupRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [props.showPopupRecipientsEmail]);

  useEffect(() => {
    if (props.showPopupRecipientsEmail) {
      if (props.type === 'view-ticket') {
        setMainEmail(currentConsumer?.email);
        setTempListCC(currentTicket.cc || []);
        setTempListCCO(currentTicket.cco || []);
      } else {
        setMainEmail(props.createSelectedConsumer?.email);
        setTempListCC(props.listCC || []);
        setTempListCCO(props.listCCO || []);
      }
    }
  }, [currentTicket, props.showPopupRecipientsEmail, currentConsumer, props.createSelectedConsumer]);

  useEffect(() => {
    setTempListCC([]);
    setTempListCCO([]);
  }, [props.createSelectedChannel]);

  const handleShowPopup = () => {
    props.setShowPopupRecipientsEmail(!props.showPopupRecipientsEmail);
  }

  const handleSave = async () => {
    if (props.type === 'view-ticket') {
      const data = {
        id: currentTicket?.id,
        cc: tempListCC || [],
        cco: tempListCCO || []
      }
  
      const headers = UserService.getHeaders();
  
      await AppRequesterConst.Put(
        '/ticket/cc/cco', data, { headers },
        (response: any) => {},
        (response: { data: { message: string, cc: string[], cco: string[], code_cxpress: number } }) => {
          dispatch(setShowAlertFeedback({ message: t('handle_save_success'), visibility: true, signalIcon: true }));
          setCurrentTicket({ ...currentTicket, cc: response.data.cc || [], cco: response.data.cco || [] });
          props.setShowPopupRecipientsEmail(false);
        },
        (error: { response: { status: number; data: { message: string } } }) => {
          if (error.response?.status === 400) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
          }
        }, navigate, dispatch, setIsLoading
      );
    } else {
      props.setShowPopupRecipientsEmail(false);
      props.setListCC(tempListCC);
      props.setListCCO(tempListCCO);
    }
  }

  return (
    <PopupRecipientsEmail
      t={t}
      popupRef={popupRef}
      showPopup={props.showPopupRecipientsEmail}
      handleShowPopup={handleShowPopup}
      handleSave={handleSave}
      isLoading={isLoading}
      mainEmail={mainEmail}
      listCC={tempListCC}
      setListCC={setTempListCC}
      listCCO={tempListCCO}
      setListCCO={setTempListCCO}
      tagifySettings={tagifySettings}
    />
  );
};

export default PopupRecipientsEmailController;
