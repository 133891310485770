import { createSlice } from '@reduxjs/toolkit';
import { SidebarPagesId } from '../pages/homeApp/components-fixed/sidebarAppComponent/indexModel';

export const appSidebarSlice = createSlice({
  name: 'app_sidebar',
  initialState: {
    appOpenSidebar: true as boolean,
    appSidebarPage: null as SidebarPagesId | null,
    confirmChangePage: false as boolean,
  },
  reducers: {
    setAppOpenSidebar: (state, action) => {
      state.appOpenSidebar = action.payload;
    },
    setAppSidebarPage: (state, action: { payload: SidebarPagesId, type: string }) => {
      state.appSidebarPage = action.payload;
    },
    setConfirmChangePage: (state, action) => {
      state.confirmChangePage = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setAppOpenSidebar,
  setAppSidebarPage,
  setConfirmChangePage,
} = appSidebarSlice.actions;

export const getAppOpenSidebar = (state: { app_sidebar: { appOpenSidebar: boolean } }) => (state.app_sidebar?.appOpenSidebar);
export const getAppSidebarPage = (state: { app_sidebar: { appSidebarPage: SidebarPagesId } }): SidebarPagesId => (state.app_sidebar?.appSidebarPage);
export const getConfirmChangePage = (state: { app_sidebar: { confirmChangePage: boolean } }) => (state.app_sidebar?.confirmChangePage);

export default appSidebarSlice.reducer;