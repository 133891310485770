import { FC } from 'react';
import FilteredViewBar from './index';
import { FilteredViewBarControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import constsRouters from '../../../../../routes/constsRouter';
import { PagesType } from '../../sidebarAppComponent/indexModel';
import { useMainAppContext } from '../../../../../core/context/main-app-context';

const FilteredViewBarController: FC<FilteredViewBarControllerInterface> = (props) => {
  const { t } = useTranslation('FilteredViewBar');
  const navigate = useNavigate();
  const location = useLocation();

  const { isSearched } = useMainAppContext();

  const backToEntityList = () => {
    if (location.pathname.includes('consumer-tickets')) {
      navigate(constsRouters.routers.consumers.path);
    } else if (location.pathname.includes('agent-tickets')) {
      navigate(constsRouters.routers.agents.path);
    } else if (location.pathname.includes('task-ticket')) {
      navigate(constsRouters.routers.tasks.path);
    } else if (location.pathname.includes('messages-trigger-tickets')) {
      navigate(constsRouters.routers.activeTrigger.path);
    } else if (location.pathname.includes('sector-tickets')) {
      navigate(constsRouters.routers.configurationSectors.path);
    } else if (location.pathname.includes('tag-tickets') || location.pathname.includes('tag-consumers')) {
      navigate(constsRouters.routers.configurationTags.path);
    }
  }

  const getEntityName = () => {
    if (props.pageType === 'consumer-tickets') {
      return 'consumers';
    } else if (props.pageType === 'agent-tickets') {
      return 'agents';
    } else if (props.pageType === 'task-ticket') {
      return 'tasks';
    } else if (props.pageType === 'messages-trigger-tickets') {
      return 'messages_trigger';
    } else if (props.pageType === 'sector-tickets') {
      return 'sectors';
    } else if (props.pageType === 'tag-tickets' || props.pageType === 'tag-consumers') {
      return 'tags';
    } else {
      return '';
    }
  }

  const showBackEntity = () => {
    const filterBackPages: PagesType[] = [
      'consumer-tickets', 
      'agent-tickets', 
      'task-ticket',
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
      'tag-consumers'
    ];

    return filterBackPages.includes(props.pageType);
  }

  return (
    <FilteredViewBar 
      t={t}
      pageType={props.pageType}
      filters={props.filters}
      filterMode={props.filterMode}
      clearSearch={props.clearSearch}
      clearSpecificFilter={props.clearSpecificFilter}
      showBackEntity={showBackEntity}
      backToEntityList={backToEntityList}
      getEntityName={getEntityName}
      isSearched={isSearched}
    />
  );
};

export default FilteredViewBarController;
