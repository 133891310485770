import './styles.scss';
import { FC } from 'react';
import { ModalChangeStatusTagInterface } from './indexModel';
import { IconX } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Loading from '../../../../../../components/loading2';

const ModalChangeStatusTag: FC<ModalChangeStatusTagInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-change-status-tag-component"
    >
      <Modal.Header>
        { props.type === 'ticket' &&
          <Modal.Title>
            { props.mode === 'single' ? 
              props.t(props.newStatus ? 'title_tag_ticket_activate' : 'title_tag_ticket_inactivate') : 
              props.t(props.newStatus ? 'title_multi_tag_ticket_activate' : 'title_multi_tag_ticket_inactivate')
            }
          </Modal.Title>
        }
        { props.type === 'consumer' &&
          <Modal.Title>
            { props.mode === 'single' ? 
              props.t(props.newStatus ? 'title_tag_consumer_activate' : 'title_tag_consumer_inactivate') : 
              props.t(props.newStatus ? 'title_multi_tag_consumer_activate' : 'title_multi_tag_consumer_inactivate')
            }
          </Modal.Title>
        }
        <div className="modal-change-status-tag-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingChange) && <Loading blur={true}/>}
        { props.data && props.data.length === 1 &&
          <span className="modal-change-status-tag-message">
            <Trans 
              i18nKey={props.newStatus ? 'ModalChangeStatusTag:message_single_activate' : 'ModalChangeStatusTag:message_single_inactivate'}
              values={{ tagName: props.data[0].name }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data && props.data.length > 1 &&
          <span className="modal-change-status-tag-message">
            <Trans 
              i18nKey={props.newStatus ? 'ModalChangeStatusTag:message_multi_activate' : 'ModalChangeStatusTag:message_multi_inactivate'}
              values={{ changedCount: props.data.length }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-change-status-tag-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        { props.data && props.newStatus && 
          <button className='modal-change-status-tag-btn-activate' disabled={props.loadingChange} onClick={() => props.handleSubmit()}>{props.t('btn_activate')}</button>
        }
        { props.data && !props.newStatus && 
          <button className='modal-change-status-tag-btn-inactivate' disabled={props.loadingChange} onClick={() => props.handleSubmit()}>{props.t('btn_inactivate')}</button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeStatusTag;
