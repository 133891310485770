import { useContext, useEffect, useRef, useState } from "react";
import { CallcenterContext } from "../../../../../../core/context/callcenter-context";
import { useDispatch, useSelector } from "react-redux";
import { getExtension, getPassword, getOwner_id } from "../../../../../../store/callcenter";
import { getNameUser } from "../../../../../../store/user";
import { IconPhoneOff, IconPhoneCalling, IconPhoneCall, IconPhone } from "@tabler/icons-react";
import { CallcenterService } from "../../../../../../services/callcenter/callcenter-service";
import PhoneButton from ".";
import { useTranslation } from "react-i18next";
import { useSocketV2Context } from "../../../../../../core/context/socket-context-v2";

const PhoneButtonController = () => {
  const callcenterCtx= useContext(CallcenterContext);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { socketInitialized, registerSocketAppEvent, unregisterSocketAppEvent } = useSocketV2Context();
  const socketInitializedRef = useRef(false);

  const values = {
    user: {
      name: useSelector(getNameUser)
    },
    callcenter: {
        extension: useSelector(getExtension),
        password: useSelector(getPassword),
        owner_id: useSelector(getOwner_id),
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    socketInitializedRef.current = socketInitialized;
  }, [socketInitialized]);

  const show = (value?: boolean) => {
    if (value !== undefined) {
      setShowPopup(value);
    } else {
      setShowPopup(!showPopup);
    }
  }

  const getPhoneClass = () => {
    const red = ['disconnected', 'connecting'];
    let phoneClass = '';
    if (red.includes(callcenterCtx.sessionStatus)) {
      phoneClass = showPopup ? 'disconnected opened' : 'disconnected hidden';
    } else {
      phoneClass = showPopup ? 'connected opened' : 'connected hidden';
    }

    if (callcenterCtx.callStatusEvent === 'ringing') {
      phoneClass += showPopup ? ' ringing-live opened' : ' ringing-live hidden';
    }

    if (callcenterCtx.callStatusEvent === 'in-call') {
      phoneClass += showPopup ? ' in-call opened' : ' in-call hidden';
    }

    return phoneClass;
  }

  const getPhoneIcon = () => {
    if (callcenterCtx.sessionStatus === 'disconnected') {
      return <IconPhoneOff id="callcenter-content-svg" stroke={2} size={15} color="white" className={getPhoneIconClass()} />
    } else {
      switch (callcenterCtx.callStatusEvent) {
        case 'calling':
        case 'trying':
          return <IconPhoneCalling id="callcenter-content-svg" stroke={2} size={15} color="white" className={getPhoneIconClass()} />
        case 'in-call':
          return <IconPhoneCall id="callcenter-content-svg" stroke={2} size={15} color="white" className={getPhoneIconClass()} />
        default:
          return <IconPhone id="callcenter-content-svg" stroke={2} size={15} color="white" className={getPhoneIconClass()} />
      }
    }
  }

  const getPhoneIconClass = (): string => {
    return callcenterCtx.callStatusEvent === 'ringing' ? 'ringing' : '';
  }

  const getTooltip = () => {
    const red = ['disconnected', 'connecting'];
    if (red.includes(callcenterCtx.sessionStatus)) {
      return showPopup ? <>{t('phone_callcenter.phone_button.close_dialer')} <br></br>({t('phone_callcenter.phone_button.terminal_offline')})</> : <>{t('phone_callcenter.phone_button.open_dialer')} <br></br>({t('phone_callcenter.phone_button.terminal_offline')})</>;
    } else {
      return showPopup ? <>{t('phone_callcenter.phone_button.close_dialer')} <br></br>({t('phone_callcenter.phone_button.terminal_online')})</> : <>{t('phone_callcenter.phone_button.open_dialer')} <br></br>({t('phone_callcenter.phone_button.terminal_online')})</>;
    }
  }

  useEffect(() => {
    const data = {
      dispatch, 
      socketInitializedRef, registerSocketAppEvent, unregisterSocketAppEvent,
      context: callcenterCtx
    }
    
    const callcenterInstance = new CallcenterService(data);

    const credentials = {
      username: values.callcenter.extension,
      password: values.callcenter.password,
      display_name: values.user.name
    }

    callcenterInstance.setCredentials(credentials, values.callcenter.owner_id);
    callcenterInstance.initEngine();

    callcenterCtx.setInstance(callcenterInstance);
  }, [values.callcenter.owner_id]);

  return (
    <PhoneButton 
      callcenterCtx={callcenterCtx}
      getTooltip={getTooltip}
      show={show}
      showPopup={showPopup}
      getPhoneClass={getPhoneClass}
      getPhoneIcon={getPhoneIcon}
    />
  );
}

export default PhoneButtonController;