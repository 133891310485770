import './styles.scss';
import { FC } from 'react';
import { ModalTicketLogsInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import Loading from '../../../../../../components/loading2';
import { IconArticle, IconEdit, IconFileMinus, IconFilePlus, IconPencilMinus, IconPencilPlus, IconRefresh, IconUserMinus, IconUserPlus } from '@tabler/icons-react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TicketLogChange } from '../../../../../../core/enums/ticket-log-change';

const ModalTicketLogs: FC<ModalTicketLogsInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="lg"
      centered={true}
      fullscreen='sm-down'
      dialogClassName='modal-ticket-logs-component'
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {props.t('ticket_history')}
          { props.ticketReferenceId &&
            <span>#{props.ticketReferenceId}</span>
          }
        </Modal.Title>
      </Modal.Header>
      { props.loading &&
        <Modal.Body>
          <div className="modal-ticket-logs-loader">
            <Loading />
          </div>
        </Modal.Body>
      }
      {
        !props.loading && 
        <Modal.Body>
          <div className="modal-ticket-logs-list" id="modal-ticket-logs-list">
            { props.ticketLogs?.length === 0 &&
              <span className="modal-ticket-logs-list-empty">
                <IconArticle />
                {props.t('no_updates')}
              </span>
            }
            { props.ticketLogs?.length > 0 && 
              <InfiniteScroll
                dataLength={props.ticketLogs.length}
                next={props.getTicketLogs().fetchTicketLogsPagination}
                hasMore={props.getTicketLogs().hasMoreTicketLogs}
                loader={null}
                scrollableTarget="modal-ticket-logs-list"
                scrollThreshold="5px"
              >
                {props.ticketLogs?.map((item, index) => {
                  const logMessage = props.getLogMessage(item);
                  const iconClass = `modal-ticket-logs-icon-${item.action}`;

                  return (
                    <div className="modal-ticket-logs-list-item" key={`ticket_history_${index}`}>
                      <span className={`modal-ticket-logs-icon ${iconClass}`}>
                        { item.action === TicketLogChange.Add && ['seguidores', 'atribuído'].includes(item.field.toLowerCase()) &&
                          <IconUserPlus />
                        }
                        { item.action === TicketLogChange.Add && ['tags'].includes(item.field.toLowerCase()) &&
                          <IconPencilPlus />
                        }
                        { item.action === TicketLogChange.Add && ['setor'].includes(item.field.toLowerCase()) &&
                          <IconFilePlus />
                        }
                        { item.action === TicketLogChange.Remove && ['seguidores', 'atribuído'].includes(item.field.toLowerCase()) &&
                          <IconUserMinus />
                        }
                        { item.action === TicketLogChange.Remove && ['tags'].includes(item.field.toLowerCase()) &&
                          <IconPencilMinus />
                        }
                        { item.action === TicketLogChange.Remove && ['setor'].includes(item.field.toLowerCase()) &&
                          <IconFileMinus />
                        }
                        { item.action === TicketLogChange.Replace &&
                          <IconEdit />
                        }
                      </span>
                      <div className="modal-ticket-logs-list-item-content">
                        <span className="modal-ticket-logs-list-item-content-header">
                          <span className="modal-ticket-logs-list-agent">{item.employee_name}</span>
                        </span>
                        <span className="modal-ticket-logs-list-item-content-footer">
                          {logMessage.element}
                          <span className="modal-ticket-logs-list-item-date" title={props.getFormattedDate(item.created_at, true)}>
                            {props.getFormattedDate(item.created_at)}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            }
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        <button className='modal-ticket-logs-btn-cancel' onClick={() => props.hide()}>{props.t('close')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTicketLogs;
