import './styles.scss';
import { FC } from 'react';
import { ModalSendTicketCopyInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomInputController from '../../../../../components/customInput/indexController';

const ModalSendTicketCopy: FC<ModalSendTicketCopyInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-send-ticket-copy-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-send-ticket-copy-header-actions">
          <IconX onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingSend) && <Loading blur={true}/>}
        { props.data &&
          <div className="modal-send-ticket-copy-form">
            <div className="modal-send-ticket-copy-form-header">
              <span className="modal-send-ticket-copy-form-header-title">{props.t('subject_label')}</span>
              <span className="modal-send-ticket-copy-form-header-value">
                {props.t('subject_value')} "{props.data.subject}" (#{props.data.ticketReferenceId})
              </span>
            </div>
            <div className="modal-send-ticket-copy-form-item">
              <span className="modal-send-ticket-copy-form-item-label">
                {props.t('emails_label')}
                <span>*</span>
              </span>
              <CustomInputController 
                type='tags'
                tagsValue={props.listEmails}
                placeholder={props.t('emails_placeholder')}
                setTagsValue={props.setListEmails}
                tagifySettings={props.tagifySettings}
                required={true}
              />
            </div>
            <div className="modal-send-ticket-copy-form-item">
              <span className="modal-send-ticket-copy-form-item-label">
                {props.t('message_label')}
              </span>
              <CustomInputController 
                type='textarea'
                value={props.message}
                placeholder={props.t('message_placeholder')}
                setValue={props.setMessage}
              />
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-send-ticket-copy-btn-cancel' onClick={() => props.hide()}>{props.t('btn_cancel')}</button>
        <button className='modal-send-ticket-copy-btn-save' disabled={props.loadingSend || !props.isValid()} onClick={() => props.handleSubmit()}>{props.t('btn_send')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSendTicketCopy;
