import { FC, useEffect, useState } from 'react';
import ModalChangeStatusTag from './index';
import { ModalChangeStatusTagControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../../core/shared/emitter';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalChangeStatusTagController: FC<ModalChangeStatusTagControllerInterface> = (props) => {
  const { t } = useTranslation('ModalChangeStatusTag');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ id: string, name?: string }[]>(null);
  const [newStatus, setNewStatus] = useState(true);
  const [loadingChange, setLoadingChange] = useState(false);
  const [mode, setMode] = useState<'single' | 'multiple'>('single');

  useEffect(() => {
    emitter.on('open-modal-change-status-tag', data => {
      if (data) {
        setData(data.ids);
        setNewStatus(data.newStatus);
  
        if (data.ids.length > 1) {
          setMode('multiple');
        } else {
          setMode('single');
        }
      }
    });

    return () => {
      emitter.off('open-modal-change-status-tag');
    }
  }, []);

  const handleSubmit = () => {
    if (data) {
      const headers  = UserService.getHeaders();
      const params = { type: props.type };
      const ids = data.map(item => item.id);

      const payload = {
        active: newStatus,
        ids
      };

      const config = {
        headers,
        params
      };

      AppRequesterConst.Put(
        '/tag/status', payload, config,
        () => {},
        () => {
          if (newStatus) {
            if (data.length === 1) {
              dispatch(setShowAlertFeedback({ message: t('alert.activate_successfully_single', { tagName: data[0].name }), visibility: true, signalIcon: true }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.activate_successfully_multi', { changedCount: data.length }), visibility: true, signalIcon: true }));
            }
          } else {
            if (data.length === 1) {
              dispatch(setShowAlertFeedback({ message: t('alert.inactivate_successfully_single', { tagName: data[0].name }), visibility: true, signalIcon: true }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.inactivate_successfully_multi', { changedCount: data.length }), visibility: true, signalIcon: true }));
            }
          }
          props.hide(true);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingChange
      );
    }
  }

  return (
    <ModalChangeStatusTag 
      t={t}
      mode={mode}
      type={props.type}
      show={props.show}
      hide={props.hide}
      data={data}
      newStatus={newStatus}
      loadingChange={loadingChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalChangeStatusTagController;
