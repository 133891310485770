import { Button, Col, Container, ListGroup, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import ButtonOrderByList from "../../../components/buttonOrderByList";
import { useState } from "react";
import { ListName, SortBy } from "../../../../../core/enums/order-sort-by";
import InfiniteScroll from "react-infinite-scroll-component";
import Utils from "../../../../../core/shared/utils";
import Loading from "../../../../../components/loading";
import "../style.scss";

const ListOfSummary = ({
    viewSummaryPage,
    callbackOrderBy,
    summaries,
    updateListfunction,
    HasMoreInformation,
    isLoading,
    dateConvert,
    getChannelName,
    showRegisterSummaryModal,
    t,
    setShowSummaryFail
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <div style={{ marginTop: '2vh', marginLeft: '1vw' }} className="ps-4">
                {
                    summaries !== undefined && summaries !== null && summaries.length > 0 ?

                        <ListGroup variant="flush" >
                            <ListGroup.Item className="TicketsRowCollums">
                                <Row name="ListOfSummaryHead">
                                    <Col md={2} className="nopadding">
                                        <p>{t("summary_dashboard.summary_list.title")}</p>
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <p>{t("summary_dashboard.summary_list.period")}</p>
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <ButtonOrderByList title={t("summary_dashboard.summary_list.date_generated")} columnIdName={SortBy.created_date} callbackOrderBy={callbackOrderBy} listName={ListName.ListOfSummary} />
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <p>{t("summary_dashboard.summary_list.channel")}</p>
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <p>{t("summary_dashboard.summary_list.tags")}</p>
                                    </Col>
                                    <Col md={2} className="nopadding">
                                        <p>{t("summary_dashboard.summary_list.status")}</p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                        :
                        <></>
                }

                {
                    summaries !== undefined && summaries !== null && summaries.length > 0 ?
                        <div id="ListOfSummary" style={{ overflowY: 'auto', height: '50vh' }} >
                            <InfiniteScroll
                                dataLength={summaries.length}
                                next={updateListfunction}
                                hasMore={HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfSummary"}
                                onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfSummary) }}
                            >
                                <ListGroup variant="flush" className="TicketsRow" >
                                    {summaries.map((item, index) => (
                                        <ListGroup.Item key={`summary_list_${item.id}_${index}`} style={{ cursor: "pointer" }} >
                                            <Row className="TicketsRowHeight" onClick={item.status === "failed" ? () => setShowSummaryFail(true) : item.status === "processing" ? () => {} : (e) => viewSummaryPage(e) } id={"*ID*" + item.id}>
                                                <Col md={2} className='align-self-center nopadding'>
                                                    <div className='oneLineClampEllipsis'>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    {item.title != null ?
                                                                        <>{item.title}</>
                                                                        :
                                                                        <>{t('home_consumers.list_of_consumers.na')}</>
                                                                    }
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span>{item.title}</span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                                <Col md={2} className="align-self-center nopadding">
                                                    <span>{`${dateConvert(item.start_date)} - ${dateConvert(item.end_date)}`}</span>
                                                </Col>
                                                <Col md={2} className="align-self-center nopadding">
                                                    <span style={{ marginLeft: "0.5rem" }}>{`${dateConvert(item.created_at)}`}</span>
                                                </Col>
                                                <Col md={2} className="align-self-center nopadding" >
                                                    <div className='oneLineClampEllipsis'>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    <>{getChannelName(item.channel_ids, true, item.id)}</>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span style={{ marginLeft: "0.5rem" }}>
                                                                <>{getChannelName(item.channel_ids, false, item.id)}</>
                                                            </span>
                                                            {/* <span>{item.channel}</span> */}
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                                <Col md={2} className="align-self-center nopadding">
                                                    <div className='oneLineClampEllipsis'>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    <>{(item.tags ? item.tags.map(({ name }) => name).toString() : "-")}</>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span style={{ marginLeft: "1rem" }}>
                                                                <>{(item.tags ? item.tags.map(({ name }) => name).toString() : "-")}</>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                                <Col md={2} className="align-self-center nopadding">
                                                    <div className='oneLineClampEllipsis'>
                                                        <OverlayTrigger
                                                            key='top'
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id='tooltip'>
                                                                    <>{t(`summary_dashboard.summary_list.${item.status}`)}</>
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <span style={{width: "100%"}}>
                                                                <div className={item.status === 'finished' ? 'summary-status-list-finalized' : item.status === 'failed' ? 'summary-status-list-failure' : 'summary-status-list-processing'}>{t(`summary_dashboard.summary_list.${item.status}`)}</div>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </InfiniteScroll>
                        </div>

                        :
                        /* sem registros */
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={2} className="d-flex justify-content-center">
                                    <svg id="Grupo_11214" data-name="Grupo 11214" xmlns="http://www.w3.org/2000/svg " width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9570" data-name="Caminho 9570" d="M0,0H24V24H0Z" fill="none" />
                                        <rect id="Retângulo_159" data-name="Retângulo 159" x="5" y="3" width="14" height="18" rx="2" ry="2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_960" data-name="Linha 960" x1="10" transform="translate(7 8)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_961" data-name="Linha 961" x1="10" transform="translate(7 12)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_962" data-name="Linha 962" x1="6" transform="translate(7 16)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ backgroundColor: '#F9F9F9' }}>
                                <Col md={4} className='d-flex justify-content-center align-content-center'>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t("summary_dashboard.no_results")} <Button className="buttonAsText3 nopadding mb-1" onClick={showRegisterSummaryModal}>{t("summary_dashboard.add_summary")}</Button></p>
                                </Col>
                            </Row>
                        </div>

                }
            </div>
        </>
    )


}

export default ListOfSummary;