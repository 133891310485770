import { FC, useCallback, useEffect, useState } from 'react';
import { FadeInOutInterface } from './indexModel';

const UNMOUNTED = 'unmounted';
const EXITED = 'exited';
const ENTERING = 'entering';
const ENTERED = 'entered';
const EXITING = 'exiting';

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

const FadeInOut: FC<FadeInOutInterface> = ({ show, duration, children, className, style, customRef }) => {

  const [status, setStatus] = useState(UNMOUNTED);

  const performEnter = useCallback(() => {
    setStatus(ENTERING);
    setTimeout(() => {
      setStatus(ENTERED);
    }, 0);
  }, []);

  const performExit = useCallback(() => {
    setStatus(EXITING);
    setTimeout(() => {
      setStatus(EXITED);
    }, duration);
  }, [duration]);

  useEffect(() => {
    if (show) {
      performEnter();
    }
  }, [show, performEnter]);

  useEffect(() => {
    if (status === ENTERING || status === ENTERED) {
      if (!show) {
        performExit();
      }
    } else if (status === EXITING && show) {
      performEnter();
    } else if (status === EXITED) {
      setStatus(UNMOUNTED);
    }
  }, [show, status, performEnter, performExit]);

  if (status === UNMOUNTED) {
    return null;
  }

  return (
    <div 
      className={className} 
      style={{ ...style, transition: `opacity ${duration}ms ease-in-out`, ...transitionStyles[status]}}
      ref={customRef}
    >
      {children}
    </div>
  );
}

FadeInOut.defaultProps = {
  show: false,
  duration: 300
};

export default FadeInOut;