import './styles.scss';
import { FC } from 'react';
import { FilteredViewBarInterface } from './indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconStarFilled, IconX } from '@tabler/icons-react';

const FilteredViewBar: FC<FilteredViewBarInterface> = (props) => {
  return (
    <div className="filtered-view-bar-component">
      <div className="filtered-view-bar-info-left">
        <div className="filtered-view-bar-info-label">
          { (props.showBackEntity() || props.filterMode === 'simple') && <span>{props.t('filter_by')}</span>}
          { (!props.showBackEntity() && props.filterMode === 'main') && <span>{props.t('filter_by_label_main')}</span>}
          { (!props.showBackEntity() && props.filterMode === 'custom') && <span>{props.t('filter_by_label_custom')}</span>}
        </div>
        <div className="filtered-view-bar-info-list">
          { props.filters?.map(item => {
            const fixedFilterClass = item.fixed ? 'filtered-view-bar-item-fixed' : '';

            return (
              <OverlayTrigger key={`${item.key}_${item.value}`} overlay={<Tooltip>{props.t(item.tooltip_key)}</Tooltip>}>
                <span className={`filtered-view-bar-item ${fixedFilterClass}`}>
                  { item.key === 'search' &&
                    <span className="filtered-view-bar-item-value">"{item.value}"</span>
                  }
                  {
                    item.key === 'date' &&
                    <span className="filtered-view-bar-item-value">{props.t(item.value)}</span>
                  }
                  { item.key !== 'search' && item.key !== 'date' &&
                    <span className="filtered-view-bar-item-value">{item.value}</span>
                  }
                  { !item.fixed &&
                    <IconX onClick={() => props.clearSpecificFilter(item.key)} />
                  }
                  {
                    item.fixed &&
                    <IconStarFilled className='filtered-view-bar-item-icon-fixed' />
                  }
                </span>
              </OverlayTrigger>
            );
          })}
        </div>
      </div>
      <div className="filtered-view-bar-actions">
        { props.showBackEntity() &&
          <span className='filtered-view-bar-info-back' onClick={props.backToEntityList}>
            {props.t('back_text')} {props.t(`back_entity.${props.getEntityName()}`)}
          </span>
        }
        { props.isSearched &&
          <span className="filtered-view-bar-info-clear" onClick={props.clearSearch}>{props.t('filter_clear')}</span>
        }
      </div>
    </div>
  );
};

export default FilteredViewBar;
