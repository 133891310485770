import { FC, useEffect, useState } from 'react';
import ModalAgentConsumers from './index';
import { ModalAgentConsumersControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { getProfileId } from '../../../../../store/user';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../../components/customTableList/indexModel';
import { ConsumerInterface, FetchConsumerInterface, FetchListConsumersInterface, ListConsumerInterface } from '../../../homeConsumers2/listOfConsumers/indexModel';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { Popover } from 'react-bootstrap';
import { IconTrashX } from '@tabler/icons-react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { ResponsePostConsumer } from '../../../homeConsumers2/components/modalConsumer/indexModel';
import { UserProfile } from '../../../../../core/enums/user-profile';

const AppRequesterConst = new AppRequesterController();

const NAME_SIZE = '200px';
const EMAIL_SIZE = '200px';
const PHONE_SIZE = '170px';
const LIMIT_PAGINATION = 25;

const ModalAgentConsumersController: FC<ModalAgentConsumersControllerInterface> = (props) => {
  const { t } = useTranslation('ModalAgentConsumers');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userProfile = useSelector(getProfileId);

  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<ListConsumerInterface[]>([]);
  const [agentInfo, setAgentInfo] = useState<{ userId: string, agentName: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [itemUnlinkConfirmation, setItemUnlinkConfirmation] = useState<ListConsumerInterface>();
  const [consumerInfo, setConsumerInfo] = useState<ConsumerInterface>();
  const [isLoadingUnlink, setIsLoadingUnlink] = useState(false);
  const [isLoadingConsumer, setIsLoadingConsumer] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-list-agent-consumers', data => {
      setAgentInfo({ userId: data.userId, agentName: data.agentName });
    });

    return () => {
      emitter.off('open-modal-list-agent-consumers');
    }
  }, []);

  useEffect(() => {
    if (agentInfo?.userId) {
      setCurrentPage(1);
      fetchAgentConsumers({ limit: LIMIT_PAGINATION, page: 1, employee_user_ids: agentInfo.userId });
      setItemUnlinkConfirmation(null);
    }
  }, [agentInfo]);

  useEffect(() => {
    setListHeaders([
      { title: t("list_headers.name"), field: 'name', width: NAME_SIZE, hasTooltip: true },
      { title: t("list_headers.email"), field: 'email', width: EMAIL_SIZE, hasTooltip: true },
      { 
        title: t("list_headers.phone"), 
        field: 'phone', 
        width: userProfile === UserProfile.Employee ? 'auto' : PHONE_SIZE, 
        hasTooltip: true 
      },
      { 
        title: t("list_headers.actions"), 
        field: 'action', 
        width: 'auto',
        hidden: userProfile === UserProfile.Employee
      },
    ]);
  }, []);

  const fetchAgentConsumers = (params?: any) => {
    if (agentInfo?.userId) {
      setErrorLoading(false);

      const headers = UserService.getHeaders();
      const config = { params, headers };

      AppRequesterConst.Get(
        '/consumer', config,
        () => {},
        (response: FetchListConsumersInterface) => {
          if (response.data?.consumers?.length > 0) {
            if (response.data.consumers.length === LIMIT_PAGINATION) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }
            setData(response.data.consumers || []);
            processPaginationDetails(response.data.pagination);
          } else {
            setData([]);
          }
        },
        () => {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
          setErrorLoading(true);
          setIsLoading(false);
        },
        navigate, dispatch, setIsLoading, {}
      );
    } else {
      setErrorLoading(true);
      setIsLoading(false);
    }
  }

  const getAgentConsumersPagination = async (page?: number) => {
    const params = { limit: LIMIT_PAGINATION, page: currentPage, employee_user_ids: agentInfo.userId };

    if (page) {
      params.page = page;
      setCurrentPage(page);
      fetchAgentConsumers(params);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
        fetchAgentConsumers(params);
      }
    }
  }

  const reloadAgentConsumers = () => {
    const params = { limit: LIMIT_PAGINATION, page: 1, employee_user_ids: agentInfo.userId };
    fetchAgentConsumers(params);
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders.filter(item => !item.hidden);
  }

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const showUnlinkConfirmation = (data: ListConsumerInterface, value: boolean) => {
    if (value) {
      setItemUnlinkConfirmation(data);
      fetchConsumerData(data);
    } else {
      setConsumerInfo(null);
      setItemUnlinkConfirmation(null);
    }
  }

  const handlePopoverModalListAgentConsumersItemAction = (event: any, data: ListConsumerInterface, type: 'unlink-consumer') => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-modal-list-agent-consumers');
    setTimeout(() => {
    if (type === 'unlink-consumer') {
        showUnlinkConfirmation(data, true);
      }
    }, 50);
  }

  const popoverItemAction = (data: any) => {
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          <span className='popover-item' onClick={(e) => handlePopoverModalListAgentConsumersItemAction(e, data, 'unlink-consumer')}>
            <IconTrashX />{t('list_popover_actions.unlink_consumer')}
          </span>
        </Popover.Body>
      </Popover>
    );
  }

  const fetchConsumerData = (data: ListConsumerInterface) => {
    if (data) {
      setErrorLoading(false);
      setIsLoadingConsumer(true);

      const headers = UserService.getHeaders();
      const config = { headers };

      AppRequesterConst.Get(
        `/consumer/${data.user_id}`, config,
        () => {},
        (response: FetchConsumerInterface) => {
          if (response.data?.consumers?.length > 0) {
            setConsumerInfo(response.data.consumers[0]);
          } else {
            setConsumerInfo(null);
          }
        },
        () => {
          dispatch(setShowAlertFeedback({ message: t("alert.unexpected_error"), visibility: true, signalIcon: false }));
          setErrorLoading(true);
          setIsLoadingConsumer(false);
        },
        navigate, dispatch, setIsLoadingConsumer, {}
      );
    } else {
      setErrorLoading(true);
      setIsLoadingConsumer(false);
    }
  }

  const handleUnlinkConsumer = () => {
    if (consumerInfo) {
      const headers = UserService.getHeaders();
      const config = { headers };
      const form = {};

      const ignore_keys = ['created_by_employee', 'employee_user_id', 'id'];

      Object.keys(consumerInfo).forEach(key => {
        if (!ignore_keys.includes(key) && consumerInfo[key]) {
          if (key === 'tags') {
            form[key] = consumerInfo.tags.filter(item => item).map(item => item?.id);
          } else if (key === 'custom_fields') {
            form['custom_fields'] = consumerInfo.custom_fields?.filter(item => item).map(item => ({
              custom_field_id: item?.custom_field_id,
              value: item?.value
            }));
          } else if (key === 'user_id') {
            form['id'] = consumerInfo.user_id;
          } else {
            form[key] = consumerInfo[key];
          }
        }
      });

      AppRequesterConst.Put(
        '/consumer', form, config,
        () => {},
        (response: ResponsePostConsumer) => {
          dispatch(setShowAlertFeedback({ message: t('alert.successfully_unlink'), visibility: true, signalIcon: true }));
          fetchAgentConsumers({ limit: LIMIT_PAGINATION, page: 1, employee_user_ids: agentInfo.userId });
          setItemUnlinkConfirmation(null);
        },
        (error: { response: { status: number; data?: { message: any; code_cxpress?: number } } }) => {
          if (error.response?.data?.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: t('alert.error_unlink', { code_cxpress: error.response?.data?.code_cxpress }), visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          }
        },
        navigate, dispatch, setIsLoadingUnlink
      );
    }
  }

  const showLoading = () => {
    return isLoading || isLoadingConsumer || isLoadingUnlink;
  }

  const formatValue = (field: string, value: any) => {
    if (value) {
      if (field === 'phone') {
        return formatPhoneNumberIntl(`+${value}`);
      } else {
        return value;
      }
    } else {
      return 'N/A';
    }
  }

  return (
    <ModalAgentConsumers 
      t={t}
      show={props.show}
      hide={props.hide}
      listHeaders={getListHeaders}
      agentInfo={agentInfo}
      data={data}
      paginationDetails={paginationDetails}
      getAgentConsumersPagination={getAgentConsumersPagination}
      reloadAgentConsumers={reloadAgentConsumers}
      isLoading={isLoading}
      isLoadingConsumer={isLoadingConsumer}
      isLoadingUnlink={isLoadingUnlink}
      errorLoading={errorLoading}
      showLoading={showLoading}
      handleUnlinkConsumer={handleUnlinkConsumer}
      consumerInfo={consumerInfo}
      showUnlinkConfirmation={showUnlinkConfirmation}
      itemUnlinkConfirmation={itemUnlinkConfirmation}
      popoverItemAction={popoverItemAction}
      formatValue={formatValue}
    />
  );
};

export default ModalAgentConsumersController;
