import './styles.scss';
import { FC } from "react";
import { AppSidebarInterface } from "./indexModel";
import { IconChevronDown, IconLogout2, IconMenu2, IconMenuDeep } from "@tabler/icons-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IconInfoSquareRounded } from '@tabler/icons-react';
import ModalSelectLanguageController from '../../components/modalSelectLanguage/indexController';

import ModalConfirmation from '../../../../components/modalConfirmation';

const SidebarApp: FC<AppSidebarInterface> = (props) => {

  return (
    <>
      {
        props.confirmationModal.visibility &&
        <ModalConfirmation 
          text={props.confirmationModal.text} 
          visibility={props.confirmationModal.visibility} 
          funtionConfirmation={props.confirmationModal.functionConfirmation} 
          functionConfirmation2={props.confirmationModal.functionConfirmation2} 
          functionCancellation={props.confirmationModal.functionCancellation} 
        />
      }
      <nav className={props.sidebarOpened ? 'app-sidebar' : 'app-sidebar app-sidebar-closed'} id="app-sidebar">
        <div className="header">
          <div className="logo" onClick={() => props.clickLogo()}>
            <img src="https://storage.googleapis.com/cxpress-assets/images/Logo2.svg" alt="" />
          </div>
          { props.sidebarOpened && 
            <OverlayTrigger key='menu-close' placement='right' overlay={<Tooltip id='tooltip'>{props.t("menu.menu_compact")}</Tooltip>}>
              <IconMenuDeep className='showHideIcon' color='#505050' stroke={1.5} onClick={() => props.showHideSidebar()} />
            </OverlayTrigger>
          }
          { !props.sidebarOpened && 
            <OverlayTrigger key='menu-open' placement='right' overlay={<Tooltip id='tooltip'>{props.t("menu.menu_expanded")}</Tooltip>}>
              <IconMenu2 className='showHideIcon' color='#505050' stroke={1.5} onClick={() => props.showHideSidebar()} />
            </OverlayTrigger>
          }
        </div>
        <ul className="menu">
          {props.getPages().filter(menu => menu.show).map(menu => {
            let class_li = 'menu-item-li';

            if ((menu.id === props.currentPage || menu.id === props.currentPage?.split('_')[0]) && !['configuration_tag_tickets', 'configuration_tag_consumers', 'configuration_sector_tickets'].includes(props.currentPage)) {
              class_li += ' active';
            }

            if (['consumer-tickets', 'agent-tickets', 'task-ticket', 'trigger-tickets', 'configuration_tag_tickets', 'configuration_sector_tickets'].includes(props.currentPage) && menu.id === 'tickets') {
              class_li += ' active';
            }

            if (props.currentPage === 'configuration_tag_consumers' && menu.id === 'consumers') {
              class_li += ' active';
            }

            const class_expand_li = menu.subpage_opened ? 'submenu-item-ul expand-subpages' : 'submenu-item-ul';

            if (menu.subpage_opened) {
              class_li += ' subpage-opened';
            }

            if (props.sidebarOpened) {
              return (
                <div className="menu-item-container" key={menu.id}>
                  <li className={class_li} onClick={() => props.menuAction(menu.id, !!menu.subpages)}>
                    <div className="menu-item">
                      {menu.icon}
                      <span className="menu-name">{props.getMenuTitle(menu.i18n_key)}</span>
                    </div>
                    { menu.subpages && 
                      <span className={ menu.subpage_opened ? "chevron-icon rotate-icon" : "chevron-icon"}>
                        <IconChevronDown color='#505050' stroke={1.5} />
                      </span>
                    }
                  </li>
                  { menu.subpages && 
                    <ul className={class_expand_li}>
                      { !props.sidebarOpened && <span className="subpage-title">{props.getMenuTitle(menu.i18n_key)}</span> }
                      {props.getSubpages(menu.id).filter(submenu => submenu.show).map(submenu => {
                        const class_sub_li = submenu.id === props.currentPage ? 'sub-item-li sub-active' : 'sub-item-li';

                        return (
                          <li key={submenu.id} className={class_sub_li} onClick={() => props.menuAction(submenu.id, false, menu.id)}>
                            <div className="submenu-item">
                              <span className="menu-name">{props.getMenuTitle(submenu.i18n_key)}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  }
                </div>
              );
            } else {

              return (
                <div key={menu.id} className="menu-item-container">
                  { menu.subpages && menu.subpage_opened ? 
                    <li className={class_li} onClick={() => props.menuAction(menu.id, !!menu.subpages)}>
                      <div className="menu-item">
                        {menu.icon}
                        <span className="menu-name">{props.getMenuTitle(menu.i18n_key)}</span>
                      </div>
                    </li>
                    :
                    <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip'>{props.getMenuTitle(menu.i18n_key)}</Tooltip>}>
                      <li className={class_li} onClick={() => props.menuAction(menu.id, !!menu.subpages)}>
                        <div className="menu-item">
                          {menu.icon}
                          <span className="menu-name">{props.getMenuTitle(menu.i18n_key)}</span>
                        </div>
                      </li>
                    </OverlayTrigger>
                  }
                  { menu.subpages && 
                    <ul className={class_expand_li}>
                      { !props.sidebarOpened && <span className="subpage-title">{props.getMenuTitle(menu.i18n_key)}</span> }
                      {props.getSubpages(menu.id).filter(submenu => submenu.show).map(submenu => {
                        const class_sub_li = submenu.id === props.currentPage ? 'sub-item-li sub-active' : 'sub-item-li';
  
                        return (
                          <li key={submenu.id} className={class_sub_li} onClick={() => props.menuAction(submenu.id)}>
                            <div className="submenu-item">
                              <span className="menu-name">{props.getMenuTitle(submenu.i18n_key)}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  }
                </div>
              );
            }
          })}
          {
            props.sidebarOpened ?
            <>
              <div className="menu-item-container">
                <li className='menu-item-li' onClick={() => props.menuAction('tutorials')}>
                  <div className="menu-item">
                    <IconInfoSquareRounded className="menu-icon" stroke={1.5} />
                    <span className="menu-name">{props.t("menu.tutorials")}</span>
                  </div>
                </li>
              </div>
              <div className="menu-item-container">
                <li className='menu-item-li' onClick={() => props.menuAction('logout')}>
                  <div className="menu-item">
                    <IconLogout2 className="menu-icon" stroke={1.5} />
                    <span className="menu-name">{props.t("menu.signout")}</span>
                  </div>
                </li>
              </div>
            </>
            :
            <>
              <OverlayTrigger key='tutorials' placement='right' overlay={<Tooltip id='tooltip'>{props.t("menu.tutorials")}</Tooltip>}>
                <div className="menu-item-container">
                  <li className='menu-item-li' onClick={() => props.menuAction('tutorials')}>
                    <div className="menu-item">
                      <IconInfoSquareRounded className="menu-icon" stroke={1.5} />
                      <span className="menu-name">{props.t("menu.tutorials")}</span>
                    </div>
                  </li>
                </div>
              </OverlayTrigger>
              <OverlayTrigger key='logout' placement='right' overlay={<Tooltip id='tooltip'>{props.t("menu.signout")}</Tooltip>}>
                <div className="menu-item-container">
                  <li className='menu-item-li' onClick={() => props.menuAction('logout')}>
                    <div className="menu-item">
                      <IconLogout2 className="menu-icon" stroke={1.5} />
                      <span className="menu-name">{props.t("menu.signout")}</span>
                    </div>
                  </li>
                </div>
              </OverlayTrigger>
            </>
          }
          <div className="language-selector">
            <ModalSelectLanguageController isLogged={true} logoutBySetLanguage={props.logoutBySetLanguage} />
          </div>
        </ul>
      </nav>
    </>
  );
}

export default SidebarApp;