import './styles.scss';
import { Outlet } from 'react-router';
import SidebarAppController from './components-fixed/sidebarAppComponent/indexController';
import { FC } from 'react';
import { HomeAppInterface } from './indexModel';
import MainHeaderController from './components-fixed/mainHeader/indexController';

const HomeApp: FC<HomeAppInterface> = (props) => {

  return (
    <div className='home-app-component'>
      <SidebarAppController />
      <div className="outlet-page">
        <MainHeaderController />
        <Outlet />
      </div>
    </div>
  );
};

export default HomeApp;
