import { FC, useEffect, useState } from 'react';
import ModalChangeStatusSector from './index';
import { ModalChangeStatusSectorControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../../core/shared/emitter';
import UserService from '../../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { I_FetchSectorDetails, I_SectorDetails } from '../modalSector/indexModel';

const AppRequesterConst = new AppRequesterController();

const ModalChangeStatusSectorController: FC<ModalChangeStatusSectorControllerInterface> = (props) => {
  const { t } = useTranslation('ModalChangeStatusSector');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ id: string, name?: string }>(null);
  const [sector, setSector] = useState<I_SectorDetails>(null);
  const [loadingSector, setLoadingSector] = useState(false);
  const [errorLoadingSector, setErrorLoadingSector] = useState(false);
  const [newStatus, setNewStatus] = useState(true);
  const [loadingChange, setLoadingChange] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-change-status-sector', data => {
      if (data) {
        setData({ id: data.sectorId, name: data.sectorName });
        setNewStatus(data.newStatus);

        fetchSector(data.sectorId).then(sector => {
          setSector(sector);
        }).catch(() => {
          dispatch(setShowAlertFeedback({ message: t('alert.error_loading'), visibility: true, signalIcon: false }));
        });
      }
    });

    return () => {
      emitter.off('open-modal-change-status-sector');
    }
  }, []);

  const fetchSector = async (id: string): Promise<I_SectorDetails> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingSector(true);
    setErrorLoadingSector(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/sector/${id}`, config,
        () => {},
        (response: I_FetchSectorDetails) => {
          setErrorLoadingSector(false);
          if (response.status === 200 && response.data?.sectors.length > 0) {
            resolve(response.data.sectors[0]);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingSector(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingSector, { }
      );
    });
  }

  const handleSubmit = () => {
    if (data && sector) {
      const headers  = UserService.getHeaders();

      const payload = { // É preciso enviar todos os dados do setor mesmo para atualizar só o status
        id: data.id,
        active: newStatus,
        name: data.name,
        emails_id: sector.emails?.map(item => item.id),
        facebook_keys_id: sector.facebook_keys?.map(item => item.id),
        instagram_keys_id: sector.instagram_keys?.map(item => item.id),
        whatsapp_keys_id: sector.whatsapp_keys?.map(item => item.id)
      };

      if (sector.agents?.length > 0) { // Só envia o campo se tiver pelo menos 1
        payload['employees_user_ids'] = sector.agents?.map(item => item.user_id);
      }

      if (sector.keyword?.length > 0) { // Só envia o campo se tiver pelo menos 1
        payload['key_words'] = sector.keyword;
      }

      const config = {
        headers
      };

      AppRequesterConst.Put(
        '/sector', payload, config,
        () => {},
        () => {
          if (newStatus) {
            dispatch(setShowAlertFeedback({ message: t('alert.activate_successfully', { sectorName: data.name }), visibility: true, signalIcon: true }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.inactivate_successfully', { sectorName: data.name }), visibility: true, signalIcon: true }));
          }
          props.hide(true);
        },
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingChange
      );
    }
  }

  return (
    <ModalChangeStatusSector 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      newStatus={newStatus}
      loadingSector={loadingSector}
      errorLoadingSector={errorLoadingSector}
      loadingChange={loadingChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalChangeStatusSectorController;
