export enum OrderBy {
    asc = "asc",
    desc = "desc",
}

export type OrderByType = 'desc' | 'asc';

export enum SortBy {
    created_date = "created_at",
    updated_date = "updated_at",
    finished_date = "finished_at",
    deleted_date = "deleted_at",
    updated_level2_date = "updated_level2_at",
    consumer_last_message = "consumer_last_message",
    home_ticket_update_at = "home_ticket_update_at",
    date_scheduled = "date_scheduled",
    name = "name",
    status = "status"
}

export type SortByType = (
    'consumer_last_message' | 'updated_at' | 'updated_level2_at' | 'created_at' | 'home_ticket_update_at' |
    'finished_at' | 'deleted_at' | 
    'name' | 'profile_id' |
    'message_sending_type' | 'date_scheduled' | 'message_sending_status' |
    'reference_id' | 'active'
);

export enum ListName {
    ListOfConsumers = "ListOfConsumers",
    ListOfEmails = "listOfEmails",
    ListOfTickets = "listOfTickets",
    ListOfFacebooks = "listOfFacebooks",
    listOfInstagram = "listOfInstagram",
    ListOfPhones = "listOfPhones",
    ListOfTags = "listOfTags",
    ListOfAgents = "ListOfAgents",
    ListOfTriggers = "listOfTriggers",
    ListOfTriggerTypes = "listOfTriggerTypes",
    ListOfDepartments = "ListOfDepartments",
    ListOfConsumersCustomFields = "ListOfConsumersCustomFields",
    ListOfTicketsCustomFields = "ListOfTicketsCustomFields",
    ListOfTasks = "ListOfTasks",
    ListOfTemplateMessages = "ListOfTemplateMessages",
    ListOfQuickMessage = "listOfQuickMessage",
    ListOfSummary = "ListOfSummary",
    ListOfBlockEmails = "ListOfBlockEmails"
}