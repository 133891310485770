import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef, useState } from 'react';
import FileViewer from './index';
import { AttachmentFile, FileViewerControllerInterface } from './indexModel';
import { FileWithPath } from 'react-dropzone';
import { CustomFileAttributes } from '../../../chatEditorComponent/components/toolbarFileUploader/indexModel';
import { TemplateMessageAttachmentInfoType } from "../../../chatEditorComponent/components/popupTemplateMessages/indexModel";

const FileViewerController: FC<FileViewerControllerInterface> = (props) => {
  const { t } = useTranslation('FileViewer');
  
  const [selectedAttachment, setSelectedAttachment] = useState<AttachmentFile>();
  const [selectedFileUpload, setSelectedFileUpload] = useState<FileWithPath & CustomFileAttributes>();
  const [selectedTemplateMessageAttachment, setSelectedTemplateMessageAttachment] = useState<TemplateMessageAttachmentInfoType>();
  const [showFileViewerAttachment, setShowFileViewerAttachment] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedRef = useRef<HTMLImageElement>(null);
  const selectedFilenameRef = useRef<HTMLImageElement>(null);
  const thumbnailsRef = useRef<HTMLDivElement>(null);
  const navigationLeftRef = useRef<HTMLSpanElement>(null);
  const navigationRightRef = useRef<HTMLSpanElement>(null);
  const prevewActionsRef = useRef<HTMLSpanElement>(null);
  const paginationInfoRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {

    const handleClickOutside = (event: any) => {
      if (
        (selectedRef?.current && !selectedRef.current.contains(event.target)) &&
        (selectedFilenameRef?.current && !selectedFilenameRef.current.contains(event.target)) &&
        (thumbnailsRef?.current && !thumbnailsRef.current.contains(event.target)) &&
        (prevewActionsRef?.current && !prevewActionsRef.current.contains(event.target)) &&
        (paginationInfoRef?.current && !paginationInfoRef.current.contains(event.target)) &&
        (!navigationLeftRef?.current || (navigationLeftRef.current && !navigationLeftRef.current.contains(event.target))) &&
        (!navigationRightRef?.current || (navigationRightRef.current && !navigationRightRef.current.contains(event.target)))
      ) {
        handleClose();
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    if (props.showFileViewer || showFileViewerAttachment) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.showFileViewer, showFileViewerAttachment, selectedRef.current]);

  useEffect(() => { // Arquivos de upload
    if (props.showFileViewer) {
      if (props.previewFirstFileUpload) {
        setSelectedFileUpload(props.previewFirstFileUpload);
      } else {
        setSelectedFileUpload(null);
      }
    }
  }, [props.showFileViewer, props.previewFirstFileUpload, props.filesUpload]);

  useEffect(() => { // arquivos do chat
    if (showFileViewerAttachment) {
      if (props.previewFirstAttachment) {
        setSelectedAttachment(props.previewFirstAttachment);
      }
    } else {
      setSelectedAttachment(null);
    }
  }, [showFileViewerAttachment, props.attachments, props.previewFirstAttachment]);

  useEffect(() => {
    if (selectedAttachment) {
      const index = filterImages()?.map((item: any) => item.url).indexOf(selectedAttachment.url);
      setSelectedIndex(index);
    }
  }, [selectedAttachment, props.attachments]);

  useEffect(() => {
    if (selectedFileUpload) {
      const index = filterImages()?.map((item: any) => item.preview).indexOf(selectedFileUpload.preview);
      setSelectedIndex(index);
    }
  }, [selectedFileUpload, props.filesUpload]);

  useEffect(() => {
    if (props.previewTemplateMessageAttachment) {
      setSelectedTemplateMessageAttachment(props.previewTemplateMessageAttachment);
    }
  }, [props.previewTemplateMessageAttachment]);

  const handleSelectAttachment = (attachment: AttachmentFile) => {
    if (attachment.type.includes('image')) {
      setSelectedAttachment(attachment);
      setShowFileViewerAttachment(true);
    } else {
      window.open(attachment.url);
    }
  }

  const handleSelectFileUpload= (file: FileWithPath & CustomFileAttributes) => {
    setSelectedFileUpload(file);
  }

  const handleClose = () => {
    if (props.type === 'preview-upload' || props.type === 'template-message-attachment') {
      props.setShowFileViewer(false);
    } else {
      setShowFileViewerAttachment(false);
    }
  }

  const handleDownloadFile = (fileAttachment?: AttachmentFile, fileUpload?: FileWithPath & CustomFileAttributes) => {
    let href = '';
    let filename = '';
    if (props.type === 'messages') {
      href = fileAttachment.url;
      filename = fileAttachment.filename;
    } else if (props.type === 'preview-upload') {
      href = fileUpload.preview;
      filename = fileUpload.name;
    } else if (props.type === 'template-message-attachment') {
      href = props.previewTemplateMessageAttachment?.url;
      filename = props.previewTemplateMessageAttachment?.fileName;
    }

    if (href) {
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handleNavigation = (index: number): void => {
    setSelectedIndex(index);
    if (props.type === 'messages') {
      setSelectedAttachment(props.attachments.filter(item => item.type.includes('image'))[index]);
    } else if (props.type === 'preview-upload') {
      setSelectedFileUpload(props.filesUpload.filter(item => item.type.includes('image'))[index]);
    }
  }

  const filterImages = () => {
    if (props.type === 'messages') {
      return props.attachments.filter(item => item.type.includes('image'));
    } else if (props.type === 'preview-upload') {
      return props.filesUpload.filter(item => item.type.includes('image'));
    } else {
      return [];
    }
  }

  const getShowFileViewer = () => {
    if (props.type === 'preview-upload' || props.type === 'template-message-attachment') {
      return props.showFileViewer;
    } else if (props.type === 'messages') {
      return showFileViewerAttachment;
    } else {
      return false;
    }
  }

  return (
    <FileViewer 
      t={t}
      type={props.type}
      attachments={props.attachments}
      filesUpload={props.filesUpload}
      selectedAttachment={selectedAttachment}
      handleSelectAttachment={handleSelectAttachment}
      selectedFileUpload={selectedFileUpload}
      handleSelectFileUpload={handleSelectFileUpload}
      parentComponent={props.children}
      showFileViewer={getShowFileViewer()}
      handleClose={handleClose}
      handleDownloadFile={handleDownloadFile}
      selectedRef={selectedRef}
      selectedFilenameRef={selectedFilenameRef}
      thumbnailsRef={thumbnailsRef}
      navigationLeftRef={navigationLeftRef}
      navigationRightRef={navigationRightRef}
      prevewActionsRef={prevewActionsRef}
      paginationInfoRef={paginationInfoRef}
      hasMessageContent={props.hasMessageContent}
      currentAudioPlayingUrl={props.currentAudioPlayingUrl}
      setCurrentAudioPlayingUrl={props.setCurrentAudioPlayingUrl}
      handleNavigation={handleNavigation}
      filterImages={filterImages}
      selectedIndex={selectedIndex}
      selectedTemplateMessageAttachment={selectedTemplateMessageAttachment}
    />
  );
};

export default FileViewerController;
