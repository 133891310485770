import './styles.scss';
import CustomSelectController from '../../../../../../../../components/customSelect/indexController';
import { TicketEditInterface } from './indexModel';
import { FC } from 'react';
import CustomInputController from '../../../../../../../../components/customInput/indexController';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CustomFieldsType } from '../../../../../../../../core/enums/custom-field-type';
import Loading from '../../../../../../../../components/loading2';
import ModalSectorController from '../../../../../../homeConfigurations/homeSectors/components/modalSector/indexController';
import ModalTagController from '../../../../../../homeConfigurations/homeTags/components/modalTag/indexController';

const TicketEdit: FC<TicketEditInterface> = (props) => {
  const mainActiveClass = props.tab === 'main' ? 'details-ticket-edit-component-header-active' : '';
  const customActiveClass = props.tab === 'custom' ? 'details-ticket-edit-component-header-active' : '';
  const scrollClass = props.hasScroll ? 'details-ticket-edit-scroll' : '';
  const invalidMainTabClass = !props.getSubjectInput().isValidSubject() ? 'details-ticket-edit-header-invalid' : '';
  const invalidCustomTabClass = !props.getCustomFieldsData().isValidCustomFields() ? 'details-ticket-edit-header-invalid' : '';

  return (
    <div className={`details-ticket-edit-component ${scrollClass}`} style={{ height: `calc(100vh - ${props.getComponentHeighDiff()}px)` }}>
      { props.showModalSector && <ModalSectorController show={props.showModalSector} hide={props.closeModalSector} />}
      { props.showModalTag && <ModalTagController show={props.showModalTag} hide={props.closeModalTag} type='ticket' />}
      
      { props.isLoadingEdit && <Loading blur={true} /> }
      <div className="details-ticket-edit-component-header">
        <div className="details-ticket-edit-component-header-buttons">
          <OverlayTrigger overlay={<Tooltip>{props.t(invalidMainTabClass ? 'show_main_fields_invalid' : 'show_main_fields')}</Tooltip>}>
            <span className={`details-ticket-edit-component-header-option ${mainActiveClass} ${invalidMainTabClass}`} onClick={() => props.setTab('main')}>
              {props.t('main')}
              { !props.getSubjectInput().isValidSubject() && <span className="details-ticket-mandatory-field">*</span> }
            </span>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip>{props.t(invalidCustomTabClass ? 'show_custom_fields_invalid' : 'show_custom_fields')}</Tooltip>}>
            <span className={`details-ticket-edit-component-header-option ${customActiveClass} ${invalidCustomTabClass}`} onClick={() => props.setTab('custom')}>
              {props.t('custom')}
              { !props.getCustomFieldsData().isValidCustomFields() && <span className="details-ticket-mandatory-field">*</span> }
            </span>
          </OverlayTrigger>
        </div>
        { !props.isValidForm() && <span className='details-ticket-edit-component-invalid'>{props.t('mandatory_field_alert')}</span> }
      </div>
      {
        props.tab === 'main' &&
        <div className="details-ticket-edit-form-items" ref={props.inputItemsRef}>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <div className="details-ticket-edit-label" title={props.t('mandatory_field')}>
                <span className="details-ticket-edit-item-label-value">{props.t('subject')}</span>
                <span className="details-ticket-edit-item-label-required">*</span>
              </div>
            </div>
            <CustomInputController 
              type='textarea'
              value={props.getSubjectInput().subject}
              setValue={props.getSubjectInput().setSubject}
              placeholder={props.t('subject_placeholder')}
              disabled={props.ticketIsBlocked}
              required={true}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('consumer')}</span>
              <span className="details-ticket-edit-item-action" onClick={() => props.openEditConsumerModal()}>{props.t('edit')}</span>
            </div>
            <CustomSelectController 
              type='simple'
              hasFilter={true}
              hasAction={true}
              placeholder={props.t('consumer_placeholder')}
              options={props.getConsumerSelect().consumersList || []}
              selected={props.getConsumerSelect().selectedConsumer}
              setSelected={props.getConsumerSelect().setSelectedConsumer}
              loading={props.isLoadingSelectedConsumer}
              disabled={true}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('sector')}</span>
            </div>
            <CustomSelectController 
              type='pagination'
              dropdownPosition='body'
              hasFilter={true}
              hasAction={true}
              tooltipAction={props.t('sector_add')}
              action={props.openModalSector}

              placeholder={props.t('sector_placeholder')}
              placeholderFilter={props.t('sector_search')}
              options={props.getSectorSelect().sectorsList || []}
              selected={props.getSectorSelect().selectedSector}
              setSelected={props.getSectorSelect().setSelectedSector}
              fetchMore={props.getSectorSelect().fetchSectorsPagination}
              hasMore={props.getSectorSelect().hasMoreSectorsList}
              loading={props.getSectorSelect().isLoadingSectorsSelect}
              search={props.getSectorSelect().fetchSearchSectors}
              disabled={props.ticketIsBlocked}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('assigned')}</span>
              { false &&
                !props.getEmployeeSelect().selectedEmployee &&
                <span className="details-ticket-edit-item-action">{props.t('accept')}</span>
              }
            </div>
            <CustomSelectController 
              type='pagination'
              dropdownPosition='body'
              hasFilter={true}
              showTooltipOptions={true}
              tooltipOptionsPosition='left'
              placeholder={props.t('assigned_placeholder')}
              placeholderFilter={props.t('assigned_search')}
              options={props.getEmployeeSelect().employeesList || []}
              selected={props.getEmployeeSelect().selectedEmployee}
              setSelected={props.getEmployeeSelect().setSelectedEmployee}
              fetchMore={props.getEmployeeSelect().fetchEmployeesPagination}
              hasMore={props.getEmployeeSelect().hasMoreEmployeesList}
              loading={props.getEmployeeSelect().isLoadingEmployeesSelect}
              search={props.getEmployeeSelect().fetchSearchEmployees}
              disabled={props.ticketIsBlocked}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('status')}</span>
            </div>
            <CustomSelectController 
              type='simple'
              dropdownPosition='body'
              placeholder={props.t('status_placeholder')}
              options={props.getStatusSelect().statusList || []}
              selected={props.getStatusSelect().selectedStatus}
              setSelected={props.getStatusSelect().setSelectedStatus}
              loading={false}
              disabled={props.ticketIsBlocked}
              required={true}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('followers')}</span>
              { false && <span className="details-ticket-edit-item-action">{props.t('follow')}</span> }
            </div>
            <CustomSelectController 
              type='pagination'
              dropdownPosition='body'
              hasFilter={true}
              isMultiselect={true}
              showTooltipOptions={true}
              tooltipOptionsPosition='left'
              placeholder={props.t('follower_placeholder')}
              placeholderFilter={props.t('follower_search')}
              options={props.getFolowersSelect().followersList || []}
              selectedMulti={props.getFolowersSelect().selectedFollowers}
              setSelectedMulti={props.getFolowersSelect().setSelectedFollowers}
              fetchMore={props.getFolowersSelect().fetchFollowersPagination}
              hasMore={props.getFolowersSelect().hasMoreFollowersList}
              loading={props.getFolowersSelect().isLoadingFollowersSelect}
              search={props.getFolowersSelect().fetchSearchFollowers}
              disabled={props.ticketIsBlocked}
            />
          </div>
          <div className="details-ticket-edit-item">
            <div className="details-ticket-edit-item-header">
              <span className="details-ticket-edit-item-label">{props.t('tags')}</span>
            </div>
            <CustomSelectController 
              type='pagination'
              dropdownPosition='body'
              hasFilter={true}
              hasAction={true}
              tooltipAction={props.t('tags_add')}
              action={props.openModalTag}
              isMultiselect={true}
              placeholder={props.t('tags_placeholder')}
              placeholderFilter={props.t('tags_search')}
              options={props.getTagsSelect().tagsList || []}
              selectedMulti={props.getTagsSelect().selectedTags}
              setSelectedMulti={props.getTagsSelect().setSelectedTags}
              fetchMore={props.getTagsSelect().fetchTagsPagination}
              hasMore={props.getTagsSelect().hasMoreTagsList}
              loading={props.getTagsSelect().isLoadingTagsSelect}
              search={props.getTagsSelect().fetchSearchTags}
              disabled={props.ticketIsBlocked}
            />
          </div>
        </div>
      }
      {
        props.tab === 'custom' &&
        <div className="details-ticket-edit-form-items" ref={props.inputItemsRef}>
          { props.getCustomFieldsData()?.formattedCustomFields?.length === 0 &&
            <span className="details-ticket-edit-form-empty">{props.t('no_custom_fields')}</span>
          }
          {props.getCustomFieldsData().formattedCustomFields.map(item => {
            return (
              <div className="details-ticket-edit-item" key={item.id}>
                <div className="details-ticket-edit-item-header">
                  { item.required && 
                    <div className="details-ticket-edit-label" title={props.t('mandatory_field')}>
                      <span className="details-ticket-edit-item-label" title={item.name}>{item.name}</span>
                      <span className="details-ticket-edit-item-label-required">*</span>
                    </div>
                  }
                  { !item.required && 
                    <span className="details-ticket-edit-item-label" title={item.name}>{item.name}</span>
                  }
                </div>
                {
                  item.custom_field_type_id === CustomFieldsType.Text &&
                  <CustomInputController 
                    type='input'
                    value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                    setCustomValue={item.setValue}
                    placeholder={item.placeholder}
                    disabled={props.ticketIsBlocked}
                    required={item.required}
                    id={item.id}
                  />
                }
                {
                  item.custom_field_type_id === CustomFieldsType.Textarea &&
                  <CustomInputController 
                    type='textarea'
                    value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                    setCustomValue={item.setValue}
                    placeholder={item.placeholder}
                    disabled={props.ticketIsBlocked}
                    required={item.required}
                    id={item.id}
                  />
                }
                {
                  item.custom_field_type_id === CustomFieldsType.Dropdown &&
                  <CustomSelectController 
                    type='simple'
                    placeholder={item.placeholder}
                    options={item.options || []}
                    selected={props.getCustomFieldsData().customFieldsValuesObject[item.id]}
                    setCustomSelected={item.setValue}
                    loading={false}
                    disabled={props.ticketIsBlocked}
                    required={item.required}
                    id={item.id}
                  />
                }
                {
                  item.custom_field_type_id === CustomFieldsType.DropdownMultiselect &&
                  <CustomSelectController 
                    type='simple'
                    isMultiselect={true}
                    placeholder={item.placeholder}
                    options={item.options || []}
                    selectedMulti={props.getCustomFieldsData().customFieldsValuesObject[item.id] || []}
                    setCustomSelected={item.setValue}
                    loading={false}
                    disabled={props.ticketIsBlocked}
                    required={item.required}
                    id={item.id}
                  />
                }
              </div>
            );
          })}
        </div>
      }
      { !props.ticketIsBlocked &&
        <div className="details-ticket-edit-form-action">
          <button onClick={() => props.handleSubmit()} disabled={!props.isValidForm() || props.isLoadingEdit || props.isLoadingRequest}>{props.t('save')}</button>
        </div>
      }
    </div>
  );
}

export default TicketEdit;