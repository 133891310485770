import { FC, useEffect, useState } from 'react';
import ModalDeleteAgent from './index';
import { ModalDeleteAgentControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../core/shared/emitter';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalDeleteAgentController: FC<ModalDeleteAgentControllerInterface> = (props) => {
  const { t } = useTranslation('ModalDeleteAgent');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ userId: string, agentName?: string }[]>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [mode, setMode] = useState<'single' | 'multiple'>('single');

  useEffect(() => {
    emitter.on('open-modal-delete-agent', data => {
      setData(data);
      if (data.length > 1) {
        setMode('multiple');
      } else {
        setMode('single');
      }
    });

    return () => {
      emitter.off('open-modal-delete-agent');
    }
  }, []);

  const handleSubmit = () => {
    deleteAgent();
  }

  const deleteAgent = () => {
    if (data && data.length > 0) {
      const headers  = UserService.getHeaders();

      const ids = data.map(item => item.userId);

      const config = {
        headers,
        data: { ids }
      }

      AppRequesterConst.Delete(
        `/agent`, config,
        () => {},
        () => {
          if (data.length === 1) {
            dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully_single', { agentName: data[0].agentName }), visibility: true, signalIcon: true }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully_multi', { deletedCount: data.length }), visibility: true, signalIcon: true }));
          }
          props.hide(true, ids);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingDelete
      );
    }
  }

  return (
    <ModalDeleteAgent 
      t={t}
      mode={mode}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingDelete={loadingDelete}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalDeleteAgentController;
