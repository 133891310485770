import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import Api from "../api";
import constsApi from "../constsApi";
import { ErrorsInterface } from "../errorsInterfaceModel";
import { AppRequesterInterface } from "./appRequesterModel";
import { setForceLogout, setForceLogoutExpired, setShowAcquirePlatformComponent, setShowAlertFeedback } from "../../store/internal";
import { NavigateFunction } from "react-router-dom";
import { ContractStatus } from "../../core/enums/contract-status";
import { setNameUser } from "../../store/user";
import { setSubdomainCompany } from "../../store/company";
import { ErrorRequestController } from "../errorsInterfaceModel";
import UserService from "../user-service";
import { TokenInterface } from "../requestsInterfacesModel";

export class AppRequesterController implements AppRequesterInterface {

    /**
     * obtendo header de outras formas caso seja indefinido
     * @param headersConfig header inicial pré-configurado na chamada da requisição 
     * @param values usado para obter o token no caso do header ser indefinido
     * @returns header definido
     */
    adjustHeaders = (headersConfig: AxiosRequestHeaders | any, values: TokenInterface) => {
        const headers = UserService.getHeaders();
        if (headersConfig === undefined) {
            if (headers !== undefined) {
                headersConfig = headers;
            } else if (values !== undefined) {
                headersConfig = {
                    "Content-Type": "application/json; charset=utf-8",
                    "Authorization": "Bearer " + values.token.value, 
                };
            }
        }

        return headersConfig;
    }

    /**
     * 
     * @param url 
     * @param config 
     * @param responseFunction 
     * @param thenFunction 
     * @param catchFunction 
     * @param navigate 
     * @param dispatch 
     * @param setIsLoading 
     * @param finallyFunction 
     * @param errorsFunctions 
     * @param values 
     * @param options parametros opcionais
     */
    Get = async (url: string, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, options: { finallyFunction?: Function, errorsFunctions?: ErrorsInterface, values?: TokenInterface }) => {
        if (setIsLoading) {
            setIsLoading(true);
        }

        config.headers = this.adjustHeaders(config.headers, options.values);

        await Api.get(url, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            if (setIsLoading) {
                setIsLoading(false);
            }
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            if (setIsLoading) {
                setIsLoading(false);
            }
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (options.errorsFunctions !== undefined) {
                            options.errorsFunctions.error400(error);
                        }
                        break;
                    case constsApi.errors.error401.number:
                        if (error.response.data.code_cxpress === 1011) {
                            // Não exibir o alerta de erro quando a sessão for expirada, pra mostrar apenas o modal
                            dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: true }));
                            dispatch(setForceLogoutExpired(true));
                            break;
                        } else {
                            // Não exibir o alerta de erro quando a sessão for expirada, pra mostrar apenas o modal
                            dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: true }));
                            dispatch(setForceLogout(true));
                            break;
                        }
                    case constsApi.errors.error422.number:
                        if (options.errorsFunctions !== undefined) {
                            options.errorsFunctions.error422(error);
                        }
                        break;
                    case constsApi.errors.error404.number:
                        if (options.errorsFunctions !== undefined) {
                            options.errorsFunctions.error404(error);
                        }
                        break;
                    case constsApi.errors.error403.number:
                        if (error.response !== undefined) {
                            if (error.response.data !== undefined) {
                                if (error.response.data.status_id === ContractStatus.EndTrial || error.response.data.status_id === ContractStatus.Inactive) {
                                    dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': error.response.data.status_id }));
                                    navigate("/end-trial");
                                }
                            }
                        } else {
                            dispatch(setForceLogout(true));
                        }
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (options.finallyFunction !== undefined) {
                options.finallyFunction();
            }
        });
    }

    Post = async (url: string, data: Object, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.post(url, data, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error400(error);
                        }
                        break;
                    case constsApi.errors.error401.number:
                        if (error.response.data.code_cxpress === 1011) {
                            dispatch(setForceLogoutExpired(true));
                            break;
                        } else {
                            dispatch(setForceLogout(true));
                            break;
                        }
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error422(error);
                        }
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error404(error);
                        }
                        break;
                    case constsApi.errors.error403.number:
                        if (error.response !== undefined &&
                            error.response.data !== undefined &&
                            (error.response.data.status_id === ContractStatus.EndTrial || error.response.data.status_id === ContractStatus.Inactive)) {
                            dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': error.response.data.status_id }));
                            dispatch(setNameUser(error.response.data.name_user));
                            dispatch(setSubdomainCompany(error.response.data.subdomain));
                            navigate("/end-trial");
                        } else if (errorsFunctions !== undefined) {
                            errorsFunctions.error403();
                        } else {
                            dispatch(setForceLogout(true));
                        }
                        break;
                    case constsApi.errors.error500.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error500(data);
                        }
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Put = async (url: string, data: Object, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.put(url, data, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error400(error);    
                        }
                        break;
                    case constsApi.errors.error401.number:
                        if (error.response.data.code_cxpress === 1011) {
                            dispatch(setForceLogoutExpired(true));
                            break;
                        } else {
                            dispatch(setForceLogout(true));
                            break;
                        }
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error422(error);
                        }
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error404(error);
                        }
                        break;
                    case constsApi.errors.error403.number:
                        if (error.response !== undefined) {
                            if (error.response.data !== undefined) {
                                if (error.response.data.status_id === ContractStatus.EndTrial || error.response.data.status_id === ContractStatus.Inactive) {
                                    dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': error.response.data.status_id }));
                                    navigate("/end-trial");
                                }
                            }
                        } else {
                            dispatch(setForceLogout(true));
                        }
                        break;
                    case constsApi.errors.error500.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error500(error);
                        }
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Delete = async (url: string, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.delete(url, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error400(error);
                        }
                        break;
                    case constsApi.errors.error401.number:
                        if (error.response.data.code_cxpress === 1011) {
                            dispatch(setForceLogoutExpired(true));
                            break;
                        } else {
                            dispatch(setForceLogout(true));
                            break;
                        }
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error422(error);
                        }
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error404(error);
                        }
                        break;
                    case constsApi.errors.error403.number:
                        if (error.response !== undefined) {
                            if (error.response.data !== undefined) {
                                if (error.response.data.status_id === ContractStatus.EndTrial || error.response.data.status_id === ContractStatus.Inactive) {
                                    dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': error.response.data.status_id }));
                                    navigate("/end-trial");
                                }
                            }
                        } else {
                            dispatch(setForceLogout(true));
                        }
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }

    Patch = async (url: string, data: Object, config: AxiosRequestConfig, responseFunction: Function, thenFunction: Function, catchFunction: Function, navigate: NavigateFunction, dispatch: Function, setIsLoading: React.Dispatch<React.SetStateAction<boolean>>, finallyFunction?: Function, errorsFunctions?: ErrorsInterface) => {
        setIsLoading(true)
        await Api.patch(url, data, config).then((response: Object) => {
            responseFunction(response);
            return response;
        }).then(data => {
            setIsLoading(false)
            thenFunction(data);
        }).catch((error: ErrorRequestController) => {
            setIsLoading(false)
            catchFunction(error);
            if (error.response !== undefined) {
                switch (error.response.status) {
                    case constsApi.errors.error400.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error400(error);
                        }
                        break;
                    case constsApi.errors.error401.number:
                        if (error.response.data.code_cxpress === 1011) {
                            dispatch(setForceLogoutExpired(true));
                            break;
                        } else {
                            dispatch(setForceLogout(true));
                            break;
                        }
                    case constsApi.errors.error422.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error422(error);
                        }
                        break;
                    case constsApi.errors.error404.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error404(error);
                        }
                        break;
                    case constsApi.errors.error403.number:
                        if (error.response !== undefined) {
                            if (error.response.data !== undefined) {
                                if (error.response.data.status_id === ContractStatus.EndTrial || error.response.data.status_id === ContractStatus.Inactive) {
                                    dispatch(setShowAcquirePlatformComponent({ 'currentContractStatusId': error.response.data.status_id }));
                                    navigate("/end-trial");
                                }
                            }
                        } else {
                            dispatch(setForceLogout(true));
                        }
                        break;
                    case constsApi.errors.error500.number:
                        if (errorsFunctions !== undefined) {
                            errorsFunctions.error500(error);
                        }
                        break;
                    default:
                        break;
                }
            }
        }).finally(() => {
            if (finallyFunction !== undefined) {
                finallyFunction();
            }
        });
    }
}