import { createSlice } from '@reduxjs/toolkit';

export const companySlice = createSlice({
  name: 'company',
  initialState: {
    id: null as string | null,
    name: null as string | null,
    cnpj: null as string | null,
    email: null as string | null,
    subdomain: null as string | null,
    contractId: null as string | null,
    contractStatusId: null as string | null,
    enable_2fa: false as boolean,
    authentications: [] as any[] | null,
    has_app_2fa: false as boolean,
    platform_language: null as string | null,
    has_summary: false as boolean,
    enable_summary: false as boolean

  },
  reducers: {
    setIdCompany: (state, action) => {
      state.id = action.payload;
    },
    setNameCompany: (state, action) => {
      state.name = action.payload;
    },
    setCNPJCompany: (state, action) => {
      state.cnpj = action.payload;
    },
    setEmailCompany: (state, action) => {
      state.email = action.payload;
    },
    setSubdomainCompany: (state, action) => {
      state.subdomain = action.payload;
    },
    setContractIdCompany: (state, action) => {
      state.contractId = action.payload;
    },
    setContractStatusId: (state, action) => {
      state.contractStatusId = action.payload;
    },
    setCompanyHas2fa: (state, action) => {
      state.enable_2fa = action.payload;
    },
    setCompanyAuthentications: (state, action) => {
      state.authentications = action.payload;
    },
    setCompanyHasApp2FA: (state, action) => {
      state.has_app_2fa = action.payload;
    },
    setLanguageCompany: (state, action) => {
      state.platform_language = action.payload;
    },
    setHasSummary: (state, action) => {
      state.has_summary = action.payload;
    },
    setEnableSummary: (state, action) => {
      state.enable_summary = action.payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const { 
  setIdCompany, 
  setNameCompany, 
  setCNPJCompany, 
  setEmailCompany,
  setSubdomainCompany, 
  setContractIdCompany, 
  setContractStatusId,
  setCompanyHas2fa,
  setCompanyAuthentications,
  setCompanyHasApp2FA,
  setLanguageCompany,
  setHasSummary,
  setEnableSummary
} = companySlice.actions

export const getIdCompany = (state: { company: { id: string } }) => (state.company?.id);
export const getNameCompany = (state: { company: { name: string } }) => (state.company?.name);
export const getCNPJCompany = (state: { company: { cnpj: string } }) => (state.company?.cnpj);
export const getEmailCompany = (state: { company: { email: string } }) => (state.company?.email);
export const getSubdomainCompany = (state: { company: { subdomain: string } }) => (state.company?.subdomain);
export const getContractIdCompany = (state: { company: { contractId: string } }) => (state.company?.contractId);
export const getContractStatusId = (state: { company: { contractStatusId: string } }) => (state.company?.contractStatusId);
export const getCompanyHas2fa = (state: { company: { enable_2fa: boolean } }) => (state.company?.enable_2fa);
export const getCompanyAuthentications = (state: { company: { authentications: any[] | null } }) => (state.company?.authentications);
export const getCompanyHasApp2FA = (state: { company: { has_app_2fa: boolean } }) => (state.company?.has_app_2fa);
export const getLanguageCompany = (state: { company: { platform_language: string } }) => (state.company?.platform_language);
export const getHasSummary = (state: { company: { has_summary: boolean } } ) => (state.company?.has_summary);
export const getEnableSummary = (state: { company: { enable_summary: boolean } } ) => (state.company?.enable_summary);


export default companySlice.reducer;
