import './styles.scss';
import { FC } from 'react';
import {PopupRecipientsEmailInterface} from './indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconMailPlus } from '@tabler/icons-react';
import FadeInOut from '../../../../../../../../components/fadeInOut';
import CustomInputController from "../../../../../../../../components/customInput/indexController";
import Loading from '../../../../../../../../components/loading2';

const PopupRecipientsEmail: FC<PopupRecipientsEmailInterface> = (props) => {
  const showPopupClass = props.showPopup ? 'popup-recipients-email-component-popup-opened' : '';

  return (
    <div className="popup-recipients-email-component" ref={props.popupRef}>
      <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('popup_title')}</Tooltip>} show={props.showPopup ? false : undefined}>
        <span className={`popup-recipients-email-trigger ${showPopupClass}`} onClick={() => props.handleShowPopup()}>
          <IconMailPlus />
        </span>
      </OverlayTrigger>
      <FadeInOut duration={200} show={props.showPopup} style={{ zIndex: 20 }}>
        <div className="popup-recipients-email-popup">
          <div className="popup-recipients-email-popup-header">
            <span className="popup-recipients-email-popup-header-title">{props.t('popup_title')}</span>
          </div>
          <div className="popup-recipients-email-popup-body">
            { props.isLoading && <Loading /> }
            <div className="popup-recipients-email-popup-body-item">
              <span className="popup-recipients-email-popup-body-item-label">{props.t('popup_to')}</span>
              <CustomInputController type={"input"} value={props.mainEmail} disabled={true}/>
            </div>
            <div className="popup-recipients-email-popup-body-item">
              <span className="popup-recipients-email-popup-body-item-label">{props.t('popup_copy')}</span>
              <CustomInputController 
                type='tags'
                tagsValue={props.listCC}
                placeholder={props.t('popup_tags_placeholder')}
                setTagsValue={props.setListCC}
                tagifySettings={props.tagifySettings}
                required={true}
              />
            </div>
            <div className="popup-recipients-email-popup-body-item">
              <span className="popup-recipients-email-popup-body-item-label">{props.t('popup_blind_copy')}</span>
              <CustomInputController 
                type='tags'
                tagsValue={props.listCCO}
                placeholder={props.t('popup_tags_placeholder')}
                setTagsValue={props.setListCCO}
                tagifySettings={props.tagifySettings}
              />
            </div>
          </div>
          <div className="popup-recipients-email-popup-footer">
            <button className='popup-recipients-email-popup-footer-cancel' onClick={() => props.handleShowPopup()}>{props.t('popup_btn_cancel')}</button>
            <button className='popup-recipients-email-popup-footer-save' disabled={props.isLoading} onClick={() => props.handleSave()}>{props.t('popup_btn_save')}</button>
          </div>
        </div>
      </FadeInOut>
    </div>
  );
};

export default PopupRecipientsEmail;
