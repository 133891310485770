import { useSelector } from 'react-redux'
import { Container, Row, Col, Tab, Tabs, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import TabComponent from "../../components/tab/index";
import ButtonComponent from "../../components/tabButton/index"
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowCreateEmailModal,
    getShowCallNotificationBar,
    getShowAcquirePlatformComponent
} from '../../../../store/internal'
import Loading from '../../../../components/loading/index'
import CallNotificationBar from '../../components/callNotificationBar';
import ListOfPhonesController from './listOfPhones/indexController';
import AcquirePlatformComponent from '../../components/acquirePlatformComponent';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ListOfEmailsController from './listOfEmails/indexController';
import ListOfFacebookController from './listOfFacebook/indexController';
import ListWebchat from './listOfWebchat';
import ChannelLabel from '../../components/channelLabel';
import { Channels } from '../../../../core/enums/channels';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import GeneralController from './general/indexController';
import ModalRegisterEmailController from '../../components/modalRegisterEmail/indexController';
import { IconInfoCircle } from '@tabler/icons-react';

const HomeConfigurationsChannels = ({
    t,
    isLoading,
    setIsLoading,
    values,
    callbackModalEmails,
    departmentsTuples,
    showCreateEmail,
    changeEmailConfirmation,
    emailConfirmation,
    currentEmails,
    updateListfunction,
    updateListfunctionFacebook,
    updateListfunctionInstagram,
    currentHasMoreInformation,
    updateListAfterEdit,
    updateListAfterEditFacebook,
    updateListAfterEditInstagram,
    callbackOrderBy,
    hasWhatsapp,
    showRequestWhatsapp,
    listWhatsapp,
    hasFacebook,
    showRequestFacebook,
    listOfPagesFacebook,
    hasInstagram,
    showRequestInstagram,
    listOfPagesInstagram,
    hasWebchat,
    showRequestWebChat,
    listWebchat,
    copyToClipboard,
    hasPhone,
    showRequestTelefone,
    listPhone,
    showName,
    setShowName,
    disabledAcquirePlatform,
    isOwner,
    isAdmin,
    isMaster,
    isAgent,
    initialTab,
    getListWhatsapp,
    company_channels,
    whatsapp_channel,
    instagram_channel,
    phone_channel,
    facebook_channel,
    email_channel,
    webchat_channel,
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalRegisterEmailController token={values.token.value} company={values.company.id} visibility={useSelector(getShowCreateEmailModal)} callback={callbackModalEmails} departments={departmentsTuples} />
            <Container fluid className="nopadding" style={{ overflowY: 'hidden' }}>
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubChannels} className="nopadding"></SidebarController> */}
                    <div className="content ps-0 pt-0" style={{maxHeight: '97vh'}}>
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={disabledAcquirePlatform} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        {/* <Row className="nopadding mt-3 ps-4">
                            <Col md={7} style={{ marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start ps-0">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t("home_channels.welcome_home_channels")}</div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_channels.welcome_home_channels_subtitle')}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonListNotificationController />
                                <ButtonStatusAgent />
                            </Col>
                        </Row> */}
                        <Row className="ms-0 me-1 ps-4 ContentTabs">
                            <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey={initialTab} id="channelsTabs" style={{ padding: 0 }}>
                                {(isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"general"} title={ChannelLabel(t("home_channels.channel_tabs.general.general_title_tab"), 'general', { iconLarger: false })} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh', display: 'flex', alignItems: 'center' }}>
                                            {t("home_channels.channel_tabs.general.general_title_internal")}
                                        </p>
                                        <GeneralController isLoading={isLoading} setIsLoading={setIsLoading} showName={showName} setShowName={setShowName} />
                                    </Tab>
                                }
                                {(isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"email"} title={ChannelLabel(t("home_channels.channel_tabs.email.email_title"), Channels.Email, { iconLarger: false })} className='ps-0 ms-0'>
                                        <Row id="buttonCreateConsumer" style={{ marginTop: '4vh', padding: 0, marginRight: 0 }}>
                                            <Col md={2}>
                                                <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                                    {t("home_channels.email.channel_email")}
                                                    <ButtonComponent
                                                        titleTootip={t("home_channels.channel_tabs.email.register_email")}
                                                        onClick={() => showCreateEmail()}
                                                    ></ButtonComponent>
                                                </p>
                                            </Col>
                                            <Row>
                                                <Col className='d-flex  align-self-center'>
                                                    <div className="switch-input" style={{ display: 'flex', alignItems: 'center' }} >
                                                        <span style={{ marginRight: '-0.5rem', fontWeight: '500' }}>{t("home_channels.email.channel_email_confirmation")}</span>
                                                        {
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {t("home_channels.email.channel_selected")}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <IconInfoCircle size={24} color="#2e3039" style={{ marginLeft: '2px', marginRight: '10px' }} />
                                                            </OverlayTrigger>
                                                        }
                                                        <label className="switch">
                                                            <input
                                                                disabled={isLoading}
                                                                type="checkbox"
                                                                checked={emailConfirmation}
                                                                onChange={emailConfirmation ? () => changeEmailConfirmation(false) : () => changeEmailConfirmation(true)}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Row>
                                        <ListOfEmailsController
                                            datas={currentEmails}
                                            departmentsTuples={departmentsTuples}
                                            updateListfunction={updateListfunction}
                                            HasMoreInformation={currentHasMoreInformation}
                                            showCreateEmail={showCreateEmail}
                                            token={values.token.value}
                                            updateListAfterEdit={updateListAfterEdit}
                                            callbackModalEmails={callbackModalEmails}
                                            callbackOrderBy={(column, order) => callbackOrderBy(column, order)}
                                        />
                                    </Tab>
                                }
                                {(isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"whatsapp"} title={ChannelLabel(t("home_channels.channel_tabs.whatsapp.whatsapp_title"), Channels.WhatsApp, { iconLarger: false })} className={"disabledTabButton"} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh' }}>{t("home_channels.channel_tabs.whatsapp.whatsapp_title_internal")}</p>
                                        <TabComponent
                                            hasActivation={!whatsapp_channel}
                                            principalTextFalse={t("home_channels.channel_tabs.whatsapp.request_integration_whatsapp")}
                                            secondaryTextFalse={t("home_channels.channel_tabs.whatsapp.needs_integration_with_whatsapp")}
                                            onClick={() => showRequestWhatsapp(Channels.WhatsApp)}
                                            textButton={t("home_channels.channel_tabs.whatsapp.needs_integration_channel")}
                                            principalTextTrue={t("home_channels.channel_tabs.whatsapp.support_list")}
                                            secondaryTextTrue={t("home_channels.channel_tabs.whatsapp.check_the_listing")}
                                        >
                                            <ListOfPhonesController datas={listWhatsapp} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} activeWhatsapp={true} activePhone={false} callbackOrderBy={(column, order) => callbackOrderBy(column, order)} departaments={departmentsTuples} getListWhatsapp={getListWhatsapp} />
                                        </TabComponent >
                                        {/* {hasWhatsapp === true ?
                                                    <>
                                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#1E1E1E', marginTop: '5vh' }}>Solicitar integração com WhatsApp</p>
                                                        <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '2vh' }}>Precisa de integração com WhatsApp para melhorar seu atendimento ao cliente? Entre em contato com nosso suporte e vamos encontrar o plano de integração com WhatsApp ideal para sua empresa!</p>
                                                        <hr style={{ border: "1px solid #E8E8E8" }} />
                                                        <Button type='submit' size='lg' className="buttonBlue" style={{ width: '15%' }} onClick={showRequestWhatsapp}>Solicitar contato</Button>
                                                    </>
                                                    :
                                                    <>
                                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#1E1E1E', marginTop: '5vh' }}>Lista de suporte pelo WhatsApp</p>
                                                        <p style={{ font: 'normal normal normal calc(0.3rem + 0.55vw) Roboto', color: '#707070', marginTop: '2vh' }}>Confira a listagem com os números de WhatsApp associados ao suporte da empresa</p>
                                                        <hr style={{ border: "1px solid #E8E8E8" }} />
                                                        <ListPhones datas={listWhatsapp} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation} token={props.token} activeWhatsapp={true} activePhone={false}></ListPhones>
                                                    </>
                                                } */}

                                    </Tab >
                                }
                                {
                                    (isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"facebook"} title={ChannelLabel(t("home_channels.channel_tabs.facebook.facebook_title"), Channels.Facebook, { iconLarger: false })} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>

                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh', display: 'flex', alignItems: 'center' }}>
                                            {t("home_channels.channel_tabs.facebook.facebook_title_internal")}
                                        </p>
                                        <TabComponent
                                            hasActivation={!facebook_channel}
                                            principalTextFalse={t("home_channels.channel_tabs.facebook.request_integration_with_facebook")}
                                            secondaryTextFalse={t("home_channels.channel_tabs.facebook.needs_integration_with_facebook")}
                                            onClick={() => showRequestFacebook(Channels.Facebook)}
                                            textButton={t("home_channels.channel_tabs.facebook.needs_integration_channel")}
                                        >
                                            <Row style={{ marginTop: '2vh', padding: 0, marginRight: 0 }}>
                                                <Col>
                                                    <ListOfFacebookController
                                                        updateListAfterEdit={updateListAfterEditFacebook}
                                                        datas={listOfPagesFacebook}
                                                        updateListFunction={updateListfunctionFacebook}
                                                        activeFacebook={true}
                                                        activeInstragram={false}
                                                        HasMoreInformation={hasFacebook}
                                                        callbackOrderBy={(column, order) => callbackOrderBy(column, order)}
                                                        //hasListOf={hasFacebook}
                                                    />
                                                </Col>
                                            </Row>
                                        </TabComponent>
                                    </Tab>
                                }
                                {
                                    (isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"instagram"} title={ChannelLabel(t("home_channels.channel_tabs.instagram.instagram_title"), Channels.Instagram, { iconLarger: false })} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh', display: 'flex', alignItems: 'center' }}>
                                            {t("home_channels.channel_tabs.instagram.instagram_title_internal")}
                                        </p>
                                        <TabComponent
                                            hasActivation={!instagram_channel}
                                            principalTextFalse={t("home_channels.channel_tabs.instagram.request_integration_with_instagram")}
                                            secondaryTextFalse={t("home_channels.channel_tabs.instagram.needs_integration_with_facebook")}
                                            onClick={() => showRequestInstagram(Channels.Instagram)}
                                            textButton={t("home_channels.channel_tabs.instagram.needs_integration_with_channel")}
                                        >
                                            <ListOfFacebookController
                                                updateListAfterEdit={updateListAfterEditInstagram}
                                                datas={listOfPagesInstagram}
                                                updateListFunction={updateListfunctionInstagram}
                                                activeFacebook={false}
                                                activeInstragram={true}
                                                HasMoreInformation={hasInstagram}
                                                callbackOrderBy={(column, order) => callbackOrderBy(column, order)}
                                                //hasListOf={hasInstagram}
                                            />
                                        </TabComponent>
                                    </Tab>
                                }
                                {
                                    (isAdmin() || isOwner() || isMaster()) &&
                                    <Tab eventKey={"webchat"} title={ChannelLabel(t("home_channels.channel_tabs.webchat.webchat_title"), Channels.Webchat, { iconLarger: false })} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh' }}>
                                            {/* WEBCHAT */}
                                            {t("home_channels.channel_tabs.webchat.webchat_title_internal")}
                                            {hasWebchat ?
                                                <></>
                                                :
                                                <ButtonComponent
                                                    titleTootip={t("home_channels.channel_tabs.webchat.request_webchat")}
                                                    onClick={() => showRequestWebChat(Channels.Webchat)}
                                                >
                                                </ButtonComponent>
                                            }
                                        </p>
                                        <TabComponent
                                            hasActivation={!webchat_channel}
                                            principalTextFalse={t("home_channels.channel_tabs.webchat.request_integration_with_webchat")}
                                            secondaryTextFalse={t("home_channels.channel_tabs.webchat.needs_integration_with_webchat")}
                                            onClick={() => showRequestWebChat(Channels.Webchat)}
                                            textButton={t("home_channels.channel_tabs.webchat.request_integration")}
                                            principalTextTrue=""
                                            secondaryTextTrue=""
                                        >
                                            <ListWebchat
                                                datas={listWebchat}
                                                copyToClipboard={(e) => copyToClipboard(e)}
                                                updateListFunction={updateListfunction}
                                                HasMoreInformation={currentHasMoreInformation}
                                            />
                                        </TabComponent>
                                    </Tab>
                                }
                                {
                                    (isAdmin() || isOwner() || isMaster() || (isAgent() && hasPhone === true)) &&
                                    <Tab eventKey={"telefone"} title={ChannelLabel(t("home_channels.channel_tabs.telefone.telephone_title"), Channels.Telefonia, { iconLarger: false })} disabled={disabledAcquirePlatform} style={{ marginTop: '4vh', padding: 0 }}>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '5vh' }}>{t("home_channels.channel_tabs.telefone.telephone_title_internal")}</p>
                                        <TabComponent
                                            hasActivation={!phone_channel}
                                            principalTextFalse={t("home_channels.channel_tabs.telefone.request_telephone")}
                                            secondaryTextFalse={t("home_channels.channel_tabs.telefone.needs_integration_with_telephone")}
                                            onClick={() => showRequestTelefone(Channels.Telefonia)}
                                            textButton={t("home_channels.channel_tabs.telefone.request_integration_with_channel_telephone")}
                                            principalTextTrue={t("home_channels.channel_tabs.telefone.list_telephone_support")}
                                            secondaryTextTrue={t("home_channels.channel_tabs.telefone.list_associate_number")}
                                        >
                                            <ListOfPhonesController
                                                datas={listPhone}
                                                updateListfunction={updateListfunction}
                                                HasMoreInformation={currentHasMoreInformation}
                                                activeWhatsapp={false}
                                                activePhone={true}
                                                callbackOrderBy={(column, order) => callbackOrderBy(column, order)}
                                                departaments={departmentsTuples}
                                                getListWhatsapp={getListWhatsapp}
                                            />
                                        </TabComponent >
                                    </Tab >
                                }
                            </Tabs >
                        </Row >
                        <Row style={{ marginTop: '25vh' }}>
                            <AcquirePlatformComponent visibility={disabledAcquirePlatform} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} text={t("home_channels.acquire_platform")}></AcquirePlatformComponent>
                        </Row>
                    </div >

                </div >
            </Container >
        </>
    );
}

export default HomeConfigurationsChannels;
