import React from 'react';
import { useContext, useEffect, useState } from 'react';
import i18n from '../../i18next';
import { BodyInterface, ErrorLoginInterface } from './indexModel';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../services/appRequester/appRequesterController';
import constsRouters from '../../routes/constsRouter';
import { DataLoginInterface, TokenInterface } from '../../services/requestsInterfacesModel';

import { getToken, setToken } from '../../store/token'
import {
  setIdCompany,
  setNameCompany,
  setSubdomainCompany,
  setContractIdCompany,
  setCNPJCompany,
  setContractStatusId,
  setCompanyAuthentications,
  setLanguageCompany,
  getCNPJCompany,
  getContractStatusId,
  getIdCompany,
  getCompanyAuthentications,
  setCompanyHasApp2FA,
  setEnableSummary,
  setHasSummary,
  getHasSummary,
  getEnableSummary,
  setEmailCompany,
} from '../../store/company';
import { getIdEmployee, getIdStatusEmployee, setDefaultAuthMethodEmployee, setHasAuthAppEmployee, setIdEmployee, setIdStatusEmployee, setLanguageEmployee } from '../../store/employee';
import { getEmailUser, getIdUser, getNameUser, getProfileId, getUserLastName, setEmailUser, setIdUser, setNameUser, setProfileId, setUserLastName } from '../../store/user';
import { getChatbot, setChatbot } from '../../store/chatbot';
import { getExtension, getOwner_id, getPassword, setExtension, setOwner_id, setPassword, setPhoneNumber } from '../../store/callcenter';
import { setShowAcquirePlatformComponent, setShowAlertFeedback, setForceLogout, setShowConfirmationModal, getShowConfirmationModal, setSoundNotification, setVisualNotification } from '../../store/internal';
import { ContractStatus } from '../../core/enums/contract-status';
import { AuthenticationTypes } from '../../core/enums/authentication-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SocketContext } from '../../core/context/socket-context';
import { StatusAgent } from '../../core/enums/status-agent';
import LoginHome from '.';
import useUpdateStatusEmployee from '../../hooks/useUpdateStatusEmployee';

const LoginHomeController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appRequesterConst = new AppRequesterController();

  const host: string = window.location.host;
  const arr = host.split(".").slice(0, host.includes('localhost') ? -1 : -2);

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany),
      cnpj: useSelector(getCNPJCompany),
      contractStatusId: useSelector(getContractStatusId),
      authentications: useSelector(getCompanyAuthentications),
      configs: {
        has_summary: useSelector(getHasSummary),
        enable_summary: useSelector(getEnableSummary)
      }
    },
    employee: {
      id: useSelector(getIdEmployee),
      status_employee_id: useSelector(getIdStatusEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      email: useSelector(getEmailUser),
      profile_id: useSelector(getProfileId),
      lastname: useSelector(getUserLastName),
    },
    token: {
      value: useSelector(getToken)
    },
    callcenter: {
      extension: useSelector(getExtension),
      password: useSelector(getPassword),
      owner_id: useSelector(getOwner_id),
    },
    chatbot: {
      hasBot: useSelector(getChatbot)
    },
    internal: {
      getShowConfirmationModal: useSelector(getShowConfirmationModal),
    }
  };

  const [userEmail, setUserEmail] = useState(null as string);
  const [companyName, setCompanyName] = useState(null as string);
  const [userPassword, setUserPassword] = useState(null as string);
  const [subdomain] = useState(arr[0]);
  const [showFeedbackEmail, setShowFeedbackEmail] = useState(false);
  const [showFeedbackPassword, setShowFeedbackPassword] = useState(false);
  const [showFeedback2FA, setShowFeedback2FA] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timezone, setTimezone] = useState(null as string);
  const [stepLogin, setStepLogin] = useState(true);
  const [step2FA, setStep2FA] = useState(false);
  const [stepConfig2FA, setStepConfig2FA] = useState(false);
  const [stepConfigApp, setStepConfigApp] = useState(false);
  const [dataConfigApp, setDataConfigApp] = useState({ is_app_authenticated: false, qr: 'https://chart.googleapis.com/chart?chs=166x166&chld=L|0&cht=qr&chl=otpauth://totp/Cxpress%3Ajohanny.santos%40elife.com.br%3Fsecret=IMK4PX7C5LDN25LOYEWHK4XT62RRV3JF%26issuer=Cxpress', secret: 'IMK4PX7C5LDN25LOYEWHK4XT62RRV3JF' });
  const [tempToken, setTempToken] = useState(null as string);
  const [loginData, setLoginData] = useState(null);
  const [companyHasEmail2FA, setCompanyHasEmail2FA] = useState(false);
  const [stateCompanyHasApp2FA, setStateCompanyHasApp2FA] = useState(false);
  const [employeeHasApp2FA, setEmployeeHasApp2FA] = useState(false);
  const [defaultIsEmail2FA, setDefaultIsEmail2FA] = useState(false);
  const [defaultIsApp2FA, setDefaultIsApp2FA] = useState(false);
  const [idEmail2FA, setIdEmail2FA] = useState('');
  const [idApp2FA, setIdApp2FA] = useState('');
  const [loginForm, setLoginForm] = useState({ email: '', password: '', timezone: '' });
  const [isLoadingQR, setIsLoadingQR] = useState(true);

  const socket = useContext(SocketContext);

  const { requestAgentStatus } = useUpdateStatusEmployee()

  useEffect(() => {
    checkSubdomain();
  }, [subdomain]);

  useEffect(() => {
    changeLanguage();
  }, [timezone])

  useEffect(() => {
    socket.setNotificationLogin(true);

    if (values.internal.getShowConfirmationModal.visibility) {
      dispatch(setShowConfirmationModal({ visibility: false, text: { title: '', body: '', id: '' }, functionConfirmation: () => { } }));
    }
  }, [])

  // useEffect(() => {
  //   localStorage.setItem('status_employee', StatusAgent.Offline);
  // }, [])

  const configApp = async () => {
    await generateCode2FA(tempToken);
  }

  const onLoadQR = () => {
    setIsLoadingQR(false);
  }

  const backLogin = () => {
    setStepLogin(true);
    setStep2FA(false);
    setStepConfig2FA(false);
  }

  const checkSubdomain = async () => {
    const JsonSend = {
      subdomain: subdomain,
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };

    await appRequesterConst.Post(
      '/company/subdomain-validation', JsonSend, { headers },
      (_response: Object) => { },
      (data: { data: { message: string; company_name: string; }; }) => {
        setCompanyName(data.data.company_name);
        if (values.token.value !== null && values.token.value !== undefined) {
          navigate(constsRouters.routers.tickets.path);
        }
      },
      (error: { response: { status: number; }; }) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            window.location.assign('http://' + window.location.hostname.replace('http://', '').replace(subdomain + '.', '') + ':' + window.location.port)
          }
        }
      }, navigate, dispatch, setIsLoading
    );
  }

  const functionConfirmation = async (userEmail: string, userPassword: string, timezone: string) => {
    setShowFeedbackEmail(false);
    const body = {
      email: userEmail,
      password: userPassword,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      force_login: true
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };

    await appRequesterConst.Post(
      '/authentication/login', body, { headers },
      (_response: Object) => { },
      (resp_data: DataLoginInterface) => {
        try {
          if (resp_data?.status === 200) {
            dispatch(setForceLogout(false))
          }
          if (resp_data.data !== undefined) {
            dispatch(setSoundNotification(resp_data?.data?.employee?.notification_sound_origin))
            dispatch(setVisualNotification(resp_data?.data?.employee?.notification_visual_origin))
            if (resp_data.data.employee_master === true) {
              processDispatchLogin(resp_data);
            }
            else if (resp_data.data.company.enable_2fa) {
              setTempToken(resp_data.data.token);
              setLoginData(resp_data);
              dispatch(setIdEmployee(resp_data.data.employee.id));
              dispatch(setLanguageEmployee(resp_data.data.employee.employee_language));
              dispatch(setLanguageCompany(resp_data.data.company.platform_language));
              dispatch(setIdCompany(resp_data.data.employee.company_id));
              dispatch(setDefaultAuthMethodEmployee(resp_data.data.employee.authentication_method_default));
              dispatch(setHasAuthAppEmployee(resp_data.data.employee.is_app_authenticated));
              dispatch(setCompanyAuthentications(resp_data.data.company.authentications));
              dispatch(setCompanyHasApp2FA(false));
              dispatch(setEnableSummary(resp_data.data.company.configs?.enable_summary))
              dispatch(setHasSummary(resp_data.data.company.configs?.has_summary))
              /**
               * Status do agente
               */
              requestAgentStatus(resp_data.data.employee.id, StatusAgent.Online, resp_data.data.company.id)
              dispatch(setIdStatusEmployee(StatusAgent.Online))

              setCompanyHasEmail2FA(false);
              setStateCompanyHasApp2FA(false);
              setDefaultIsApp2FA(false);
              setDefaultIsEmail2FA(false);
              setEmployeeHasApp2FA(resp_data.data.has_app_authentication);

              resp_data.data?.company?.authentications?.forEach(item => {
                if (item.name === AuthenticationTypes.Email) {
                  setIdEmail2FA(item.id);
                  setCompanyHasEmail2FA(true);
                  if (resp_data.data.employee.authentication_method_default === item.id) {
                    setDefaultIsEmail2FA(true);
                    setStep2FA(true);
                    setStepLogin(false);
                    setStepConfig2FA(false);
                  }
                }
                if (item.name === AuthenticationTypes.App) {
                  setStateCompanyHasApp2FA(true);
                  dispatch(setCompanyHasApp2FA(true));
                  setIdApp2FA(item.id);
                  if (resp_data.data.employee.authentication_method_default === item.id) {
                    setDefaultIsApp2FA(true);
                  }
                  if (resp_data.data.has_app_authentication) {
                    setStepLogin(false);
                    setStep2FA(true);
                    setStepConfig2FA(false);
                  } else {
                    setStepLogin(false);
                    setStep2FA(false);
                    setStepConfig2FA(true);
                  }
                }
              })
            } else {
              processDispatchLogin(resp_data);
            }
          }

        } catch (error) {
          console.log(error);
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            document.getElementById('feedbackemail').innerHTML = t('login.error_email_not_found');
            setShowFeedbackEmail(true);
          }
          else if (error.response.status === 401) {
            document.getElementById('feedbackemail').innerHTML = t('login.error_wrong_credential');
            document.getElementById('feedbackpassword').innerHTML = t('login.error_wrong_credential');
            setShowFeedbackEmail(true);
          }
          else if (error.response.status === 403) {
            if (error.response.data.message === 'Acesso negado.') {
              dispatch(setContractIdCompany(error.response.data.contract_id));
            }
            else {
              document.getElementById('feedbackemail').innerHTML = t('login.error_unconfirmed_email');
              setShowFeedbackEmail(true);
            }
          }
        }
      },
      navigate,
      dispatch,
      setIsLoading,
    )
  }

  const submitLogin = async (userEmail: string, userPassword: string, timezone: string, resend?: boolean) => {
    setShowFeedbackEmail(false);
    const body = {
      email: userEmail,
      password: userPassword,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    setLoginForm(body)

    const headers = {
      'Content-Type': 'application/json; charset=utf-8'
    };

    await appRequesterConst.Post(
      '/authentication/login', body, { headers },
      (_response: Object) => { },
      (resp_data: DataLoginInterface) => {
        try {
          if (resp_data?.status === 200) {
            dispatch(setForceLogout(false))
          }
          if (resp_data.data !== undefined) {
            dispatch(setSoundNotification(resp_data?.data?.employee?.notification_sound_origin))
            dispatch(setVisualNotification(resp_data?.data?.employee?.notification_visual_origin))
            if (resp_data.data.employee_master === true) {
              processDispatchLogin(resp_data);
            }
            if (resp_data.data.company.enable_2fa) {
              setTempToken(resp_data.data.token);
              setLoginData(resp_data);
              dispatch(setIdEmployee(resp_data.data.employee.id));
              dispatch(setIdCompany(resp_data.data.employee.company_id));
              dispatch(setDefaultAuthMethodEmployee(resp_data.data.employee.authentication_method_default));
              dispatch(setHasAuthAppEmployee(resp_data.data.employee.is_app_authenticated));
              dispatch(setCompanyAuthentications(resp_data.data.company.authentications));
              dispatch(setCompanyHasApp2FA(false));
              dispatch(setEnableSummary(resp_data.data.company?.configs?.enable_summary))
              dispatch(setHasSummary(resp_data.data.company?.configs?.has_summary))

              setCompanyHasEmail2FA(false);
              setStateCompanyHasApp2FA(false);
              setDefaultIsApp2FA(false);
              setDefaultIsEmail2FA(false);
              setEmployeeHasApp2FA(resp_data.data.has_app_authentication);

              resp_data.data?.company?.authentications?.forEach(item => {
                if (item.name === AuthenticationTypes.Email) {
                  setIdEmail2FA(item.id);
                  setCompanyHasEmail2FA(true);
                  if (resp_data.data.employee.authentication_method_default === item.id) {
                    setDefaultIsEmail2FA(true);
                    setStep2FA(true);
                    setStepLogin(false);
                    setStepConfig2FA(false);
                  }
                }
                if (item.name === AuthenticationTypes.App) {
                  setStateCompanyHasApp2FA(true);
                  dispatch(setCompanyHasApp2FA(true));
                  setIdApp2FA(item.id);
                  if (resp_data.data.employee.authentication_method_default === item.id) {
                    setDefaultIsApp2FA(true);
                  }
                  if (resp_data.data.has_app_authentication) {
                    setStepLogin(false);
                    setStep2FA(true);
                    setStepConfig2FA(false);
                  } else {
                    setStepLogin(false);
                    setStep2FA(false);
                    setStepConfig2FA(true);
                  }
                }
              })
            } else {
              processDispatchLogin(resp_data);
            }
          }

        } catch (error) {
          console.log(error);
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response !== undefined) {
          if(error.response.status === 400){
            document.getElementById('feedbackpassword').innerHTML = t('login.error_wrong_credential');
            setShowFeedbackEmail(true);
            setShowFeedbackPassword(true);
            if(error.response.data.message === "Email do usuário não foi confirmado.")
              dispatch(setShowAlertFeedback({ message: t('login.error_unconfirmed_email'), visibility: true, signalIcon: false }))
            if(error.response.data.message === "Login ou senha incorretos.")
              dispatch(setShowAlertFeedback({ message: t('login.error_wrong_credential'), visibility: true, signalIcon: false }))
          }else if (error.response.status === 401) {
            document.getElementById('feedbackpassword').innerHTML = t('login.error_wrong_credential');
            setShowFeedbackEmail(true);
            setShowFeedbackPassword(true);
          } else if (error.response.status === 403) {
            if (error.response.data.message === 'Acesso negado.') {
              dispatch(setContractIdCompany(error.response.data.contract_id));

            } else if (error.response.data.code_cxpress === 1012) {
              dispatch(setShowConfirmationModal({
                visibility: true,
                text: {
                  "title": t('login.modal_active_session.title'),
                  "body": t('login.modal_active_session.body'),
                  "buttonReturnText": t('login.modal_active_session.btnReturnText'),
                  "buttonConfirmationText": t('login.modal_active_session.btnConfirmationText')
                },
                functionConfirmation: () => functionConfirmation(userEmail, userPassword, timezone)
              }));
            } else if (error.response.data.code_cxpress === 1022) {
              dispatch(setShowAlertFeedback({ visibility: true, message: t("login.error_attempt_limit"), signalIcon: false }));
            } else {
              document.getElementById('feedbackemail').innerHTML = t('login.error_unconfirmed_email');
              setShowFeedbackEmail(true);
            }
          } else if (error.response.status === 422) {
            document.getElementById('feedbackemail').innerHTML = t('login.error_invalid_email');
            setShowFeedbackEmail(true);
          }
        }
      },
      navigate,
      dispatch,
      setIsLoading,
    )
  }

  const verify2FA = async (loginData: any, code_2fa: string, tempToken: string, type: 'normal' | 'config_app') => {
    setShowFeedback2FA(false);
    const JsonSend = {
      code: String(code_2fa),
      token: tempToken,
      autenticationType: defaultIsApp2FA ? idApp2FA : idEmail2FA
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    await appRequesterConst.Post(
      '/two-factor-authentication/verify-code', JsonSend, { headers },
      (_response: Object) => { },
      (data: any) => {
        if (data.data !== undefined) {
          const newData = Object.assign({}, { data: loginData.data });
          newData.data.token = String(data.data.token);
          setLoginData(null);
          setTempToken(null);
          processDispatchLogin(newData);

          if (type === 'config_app') {
            dispatch(setHasAuthAppEmployee(true));
          }
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response?.status === 449) {
          document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_invalid_code');
          setShowFeedback2FA(true);
        } else if (error.response?.status === 400) {
          if (String(error.response?.data).includes('números')) {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_only_numbers');
          } else if (String(error.response?.data).includes('caracteres')) {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_length');
          } else {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_generic');
          }
          setShowFeedback2FA(true);
        }
      },
      navigate,
      dispatch,
      setIsLoading,
    )
  }

  const changeDefault2FA = async (type: 'email' | 'app') => {
    const body = {
      company_id: values.company.id,
      employee_id: values.employee.id,
      authentication_method_default: type === 'email' ? idEmail2FA : idApp2FA,
    };

    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic ' + process.env.REACT_APP_TOKEN_BASIC_AUTH,
    };

    await appRequesterConst.Put(
      '/two-factor-authentication/set-authentication_method_default', body, { headers },
      (_response: Object) => { },
      async (data: any) => {
        if (type === 'email') {
          await submitLogin(loginForm.email, loginForm.password, loginForm.timezone);
          setDefaultIsEmail2FA(true);
          setDefaultIsApp2FA(false);
        } else {
          setDefaultIsEmail2FA(false);
          setDefaultIsApp2FA(true);
        }
      },
      (error: ErrorLoginInterface) => {
        setIsLoading(false);
        if (error.response?.status === 401) {
          document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_invalid_code');
          setShowFeedback2FA(true);
        } else if (error.response?.status === 400) {
          if (String(error.response?.data).includes('números')) {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_only_numbers');
          } else if (String(error.response?.data).includes('caracteres')) {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_2fa_length');
          } else {
            document.getElementById('feedback_code_2fa').innerHTML = t('login.error_generic');
          }
          setShowFeedback2FA(true);
        }
      },
      navigate,
      dispatch,
      setIsLoading,
    );

  }

  const generateCode2FA = async (tempToken) => {
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${tempToken}`
    };

    setIsLoadingQR(true);

    await appRequesterConst.Get(
      'two-factor-authentication/generate-code-aplication', { headers },
      (response: Object) => { },
      (resp_data: any) => {
        setDataConfigApp(resp_data.data.token);
        setStepLogin(false);
        setStep2FA(false);
        setStepConfig2FA(false);
        setStepConfigApp(true);
      },
      (error: any) => {
        dispatch(setShowAlertFeedback({
          message: t('configurations.security.tab_app.alert_error_generate_code'),
          visibility: true, signalIcon: false
        })
        );
      }, navigate, dispatch, setIsLoading, {}
    );
  }

  const validateLogin = (values: BodyInterface) => {
    const errors = {};
    if (!values.email) {
      errors['email'] = t('login.error_fill_field');
      setShowFeedbackEmail(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)) {
      errors['email'] = t('login.error_invalid_email');
      setShowFeedbackEmail(true);
    } else {
      setShowFeedbackEmail(false);
    }

    if (errors['email'] === undefined) {
      if (!values.password) {
        errors['password'] = t('login.error_fill_field');
        setShowFeedbackPassword(true);
      } else if (values.password.length > 0 && values.password.length <= 4) {
        errors['password'] = t('login.error_password_length');
        setShowFeedbackPassword(true);
      } else {
        setShowFeedbackPassword(false);
      }
    } else {
      setShowFeedbackPassword(false);
    }
    return errors;
  }

  const validate2FA = (values: any) => {
    const errors = {};
    if (isNaN(values.code_2fa)) {
      errors['code_2fa'] = t('login.error_2fa_only_numbers');
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'login.error_2fa_only_numbers');
    } else if (!values.code_2fa || String(values.code_2fa).length === 0) {
      errors['code_2fa'] = t('login.error_fill_field');
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'login.error_fill_field');
    } else if (String(values.code_2fa).length !== 6) {
      errors['code_2fa'] = t('login.error_2fa_length');
      setShowFeedback2FA(true);
      changeDocument('feedback_code_2fa', 'login.error_2fa_length');
    } else {
      setShowFeedback2FA(false);
      changeDocument('feedback_code_2fa', '');
    }
    return errors;
  }

  const clickPassword = () => {
    if ((document.getElementById('userPassword') as HTMLInputElement).type === 'text') {
      (document.getElementById('userPassword') as HTMLInputElement).type = 'password';
    } else {
      (document.getElementById('userPassword') as HTMLInputElement).type = 'text';
    }
    setShowPassword(!showPassword);
  };

  const changeLanguage = () => {
    switch (i18n.language) {
      case 'pt-PT':
        setTimezone('Portugal')
        break;
      default:
        setTimezone('America/Sao_Paulo')
        break;
    }
  }

  const processDispatchLogin = (data): void => {
    const employee = data.data.employee;
    const company = data.data.company;
    const callcenter = data.data.callcenter;
    const chatbot = data.data.has_acess_to_bot_cxpress_panel;
    const contractStatusId = company.contract.contract_status_id;

    localStorage.setItem('hasLogin', 'true');
    dispatch(setToken(data.data.token));
    dispatch(setIdCompany(employee.company_id));
    dispatch(setCNPJCompany(company.cnpj));
    dispatch(setEmailCompany(company.email));
    dispatch(setNameCompany(company.name));
    dispatch(setSubdomainCompany(company.subdomain));
    dispatch(setContractStatusId(contractStatusId));
    dispatch(setIdEmployee(employee.id));
    dispatch(setIdUser(employee.user_id));
    dispatch(setNameUser(employee.name));
    dispatch(setEmailUser(employee.email));
    dispatch(setUserLastName(employee.lastname));
    dispatch(setProfileId(employee.profile_id));
    dispatch(setIdStatusEmployee(employee.status_employee_id))
    dispatch(setLanguageEmployee(employee.employee_language));
    dispatch(setLanguageCompany(company.platform_language));
    dispatch(setChatbot(chatbot));
    dispatch(setDefaultAuthMethodEmployee(employee.authentication_method_default));
    dispatch(setHasAuthAppEmployee(employee.is_app_authenticated));
    // Requisição de status do agente
    requestAgentStatus(employee.user_id, StatusAgent.Online, company.id);
    dispatch(setIdStatusEmployee(StatusAgent.Online))
    
    dispatch(setEnableSummary(company.configs?.enable_summary));
    dispatch(setHasSummary(company.configs?.has_summary));

    if (callcenter) {
      dispatch(setExtension(callcenter.extension));
      dispatch(setPhoneNumber(callcenter.preferred_number));
      dispatch(setPassword(callcenter.password));
      dispatch(setOwner_id(callcenter.owner_id));
    }

    let is_trial = false;
    if (contractStatusId === ContractStatus.Trial) {
      is_trial = true;
    }

    dispatch(setShowAcquirePlatformComponent({
      currentContractStatusId: contractStatusId,
      visibility: is_trial,
      limitDays: company.contract.remaining_days,
      quantityAgents: company.contract.quantity_agents
    })); // limitDays == remaining_days

    // Página default
    navigate('/tickets');
  }

  const changeDocument = (element_id: string, tag_i18n: string) => {
    if (tag_i18n) {
      document.getElementById(element_id).innerHTML = t(tag_i18n);
    } else {
      document.getElementById(element_id).innerHTML = '';
    }
  }

  const onSubmitLogin = () => {
    submitLogin(userEmail, userPassword, timezone);
  }

  const onSubmitVerify2FA = async (req_values: { code_2fa: string, type: 'normal' | 'config_app' }) => {
    await verify2FA(loginData, req_values.code_2fa, tempToken, req_values.type);
  }

  return <LoginHome
    isLoading={isLoading}
    companyName={companyName}
    stepLogin={stepLogin}
    setUserEmail={setUserEmail}
    setUserPassword={setUserPassword}
    validateLogin={validateLogin}
    submitLogin={onSubmitLogin}
    t={t}
    showFeedbackEmail={showFeedbackEmail}
    showFeedbackPassword={showFeedbackPassword}
    showPassword={showPassword}
    clickPassword={clickPassword}
    step2FA={step2FA}
    validate2FA={validate2FA}
    verify2FA={onSubmitVerify2FA}
    defaultIsEmail2FA={defaultIsEmail2FA}
    defaultIsApp2FA={defaultIsApp2FA}
    showFeedback2FA={showFeedback2FA}
    stateCompanyHasApp2FA={stateCompanyHasApp2FA}
    employeeHasApp2FA={employeeHasApp2FA}
    changeDefault2FA={changeDefault2FA}
    companyHasEmail2FA={companyHasEmail2FA}
    stepConfig2FA={stepConfig2FA}
    configApp={configApp}
    stepConfigApp={stepConfigApp}
    backLogin={backLogin}
    isLoadingQR={isLoadingQR}
    dataConfigApp={dataConfigApp}
    onLoadQR={onLoadQR}
  />
}

export default LoginHomeController;
