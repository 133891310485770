import React, { FC } from "react";
import { IViewAnalysisSimilarConsumers, excludedFieldsFromUnification } from "./indexModel";
import { Button, Col, Container, Form, ListGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Loading from "../../../../../components/loading";
import CallNotificationBar from "../../../components/callNotificationBar";
import { useSelector } from "react-redux";
import { getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowModalUnifyConsumers } from "../../../../../store/internal";
import AcquirePlatformNotificationBar from "../../../components/acquirePlatformNotificationBar";
import ButtonSearchCleanInput from "../../../components/buttonSearchCleanInputComponent";
import ButtonStatusAgent from "../../../components/buttonStatusAgent";
import './styles.css';
import ModalUnifyConsumersController from "../modalUnifyConsumers/indexController";
import ButtonListNotificationController from "../../../../../components/buttonListNotification/indexController";

const ViewAnalysisSimilarConsumers: FC<IViewAnalysisSimilarConsumers> = (props) => {
    return (
        <>
            <Container fluid className="nopadding" style={{overflow: 'hidden'}}>
                <Loading visibility={props.isLoading}></Loading>
                <ModalUnifyConsumersController originRedirectPage="unify_consumers" visibility={useSelector(getShowModalUnifyConsumers)} callbackModalCustomers={() => {}} similarConsumers={props.similarConsumers} currentConsumer={props.currentConsumer}/>
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.Consumers} /> */}
                    <div className="content py-0 ps-0" >
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)} setCurrentTicketFunction={(value) => {}} updateListfunction={() => {}}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        {/* <Row className="nopadding ps-3 mt-3">
                            <Col md={7} style={{ marginRight: '-8vw' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{props.t('home_consumers.unification.view_analysis.welcome_main')}</div>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{props.t('home_consumers.unification.view_analysis.welcome_sub')}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end" >
                                <ButtonSearchCleanInput inputPlaceholder={props.t('home_consumers.unification.view_analysis.search_consumer')} filteringTickets={props.filteringConsumers} setCurrentFilterSearch={props.setCurrentFilterSearch} currentFilterSearch={props.currentFilterSearch} ></ButtonSearchCleanInput>
                                <ButtonListNotificationController/>
                                <ButtonStatusAgent/>
                            </Col>
                        </Row> */}
                        <Row className="ps-4" style={{ marginTop: '1.5vh' }}>
                            <Col className="d-flex" style={{ marginTop: "0vh", marginLeft: '0.5vw' }} >
                                <p style={{ font: 'var(--px16_19-font)', color: '#1E1E1E', textDecoration: 'underline', cursor: 'pointer' }} onClick={props.gotoConsumersPage}>
                                    <span>
                                        <svg id="Grupo_11161" data-name="Grupo 11161" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 2 24 24">
                                            <path id="Caminho_9641" data-name="Caminho 9641" d="M0,0H24V24H0Z" fill="none" />
                                            <path id="Caminho_9642" data-name="Caminho 9642" d="M9,13,5,9,9,5M5,9H16a4,4,0,0,1,0,8H15" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </span>
                                    {props.t('home_consumers.unification.view_analysis.back_to_list')}
                                </p>
                            </Col>
                        </Row>
                        <Row className="ps-4" style={{ margin: '1vh 0vw 0vh 0vw' }}>
                            <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }}>
                                {props.t('home_consumers.unification.view_analysis.conflict_analysis')}
                            </p>
                        </Row>
                        <Row className="ms-4 me-5 d-flex mt-3 ps-3">
                            <Col className="nopadding">
                                <Row>
                                    <ListGroup className="d-flex pe-0" style={{ width: '14vw' }}>
                                        <Row className="nopadding" style={{ display: 'inline-block', height: '100%', backgroundImage: 'linear-gradient(transparent, transparent, #E5ECFF)' }}>
                                            <ListGroup.Item key={"fields"} className="pt-0 px-0 pb-0" style={{ border: 'none', backgroundColor: 'transparent' }} >
                                                <ListGroup className="d-flex" variant="flush" >
                                                    <ListGroup.Item key={"field_item-data"} className="nopadding ps-4 pe-4" style={{ border: `none`, background: `#fff` }}>
                                                        <Row className="nopadding pb-4" style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat',  }} >
                                                            <div className="nopadding d-flex align-items-center" style={{ minHeight: '24px' }}>
                                                                <span>
                                                                    {props.t('home_consumers.unification.view_analysis.data')}
                                                                </span>
                                                            </div>
                                                        </Row>
                                                    </ListGroup.Item>

                                                    <div className="nopadding" name='consumerListClass' id='fieldsListClass' onScroll={props.scrollAll} style={{ overflowY: 'hidden', height: '50vh', borderRadius: '10px 10px 0px 0px' }}>
                                                        { 
                                                            props.data && props.data[1] && 
                                                                (Object.keys(props.data[1])
                                                                    .filter((field) => !excludedFieldsFromUnification.includes(field))
                                                                ).map((field, i_field) => {
                                                                    return (
                                                                        <ListGroup.Item 
                                                                            className="nopadding ps-4 pe-4"
                                                                            style={{
                                                                                borderColor: /* field === 'custom_fields'? 'var(--bs-list-group-border-color)': */ 'transparent',
                                                                                // borderTop: field === 'custom_fields'? 'var(--bs-list-group-border-width) solid var(--bs-list-group-border-color)': '',
                                                                                // borderRadius: i_field === 0? '10px 10px 0px 0px': '0px',
                                                                                backgroundColor: field === 'custom_fields'? '#D8E2FF': '#E5ECFF',
                                                                            }}
                                                                            key={"field_item-" + i_field + field.toString()}
                                                                        >
                                                                            <Row 
                                                                                style={{ 
                                                                                    height: field === "custom_fields"? '6vh':'5vh', 
                                                                                    color: '#0C2461', 
                                                                                    font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat'
                                                                                }} 
                                                                                className="oneLineClampEllipsis nopadding d-flex align-items-center"
                                                                            >
                                                                                { props.consumerFieldName(field) }
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    )
                                                                })
                                                        }

                                                        {/* mostrando campos customizaveis */}
                                                        { 
                                                            // props.data[1] && props.data[1].custom_fields && props.data[1].custom_fields.length > 0 && props.data[1].custom_fields.map((custom_field, i_c_field) => {
                                                            props.customFields && props.customFields.length > 0 && props.customFields.map((custom_field, i_c_field) => {
                                                                    return (
                                                                        <ListGroup.Item 
                                                                            className="nopadding ps-4 pe-4"
                                                                            style={{
                                                                                borderColor: 'transparent',
                                                                                // borderRadius: i_field === 0? '10px 10px 0px 0px': '0px',
                                                                                backgroundColor: '#E5ECFF',
                                                                            }}
                                                                            key={"field_item-custom-" + i_c_field + custom_field.name}
                                                                        >
                                                                            <Row 
                                                                                style={{ 
                                                                                    height: '5vh', 
                                                                                    color: '#0C2461', 
                                                                                    font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat'
                                                                                }} 
                                                                                className=" nopadding d-flex align-items-center"
                                                                            >
                                                                                <OverlayTrigger key={"overlay-custom-"+i_c_field} placement='top' 
                                                                                    overlay={
                                                                                        <Tooltip>
                                                                                            { custom_field.name }
                                                                                        </Tooltip>
                                                                                    } 
                                                                                >
                                                                                    <span className='oneLineClampEllipsis px-0'>
                                                                                        { custom_field.name }
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                                {/* { custom_field.custom_field_id } */}
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    )
                                                                })
                                                        }
                                                    </div>
                                                </ListGroup>
                                            </ListGroup.Item>
                                        </Row>
                                    </ListGroup>
                                    <ListGroup className="d-flex pe-0" style={{ width: '55vw' }}>
                                        <div id="list_consumers_custom_field" className="nopadding"
                                            style={{
                                                overflowX: 'auto', 
                                                // overflowY: 'scroll',
                                                // overflowY: 'hidden',
                                                // height: '60vh',
                                                display: 'inline-block',
                                                whiteSpace: 'nowrap',
                                                backgroundImage: 'linear-gradient(transparent, transparent, #F9F9F9)'
                                            }}
                                        >
                                            {
                                                props.data && props.data.map((consumer, i_consumer) => {
                                                    return (
                                                        consumer?
                                                        <Row className="nopadding" key={'row-consumer-' + consumer.id + i_consumer}
                                                            style={{ 
                                                                display: 'inline-block', 
                                                                // width: data.length === 2? '50%': (!isLargerList && data.length - 1 === i_consumer? `calc(100% - ${i_consumer*14}vw)`: '14vw') 
                                                                // listagem com 3 consumidores tem tamanhos iguais para os itens
                                                                width: 
                                                                    props.isLargerList? 
                                                                        '14vw':
                                                                    props.data.length <= 3 && !props.isLargerList? 
                                                                        `calc(100% / ${props.data.length})`: 
                                                                    // data.length === 4 && !isLargerList && (data.length - 1) == i_consumer? 
                                                                        `calc(100% - ${i_consumer*14}vw)`/* : '14vw' */
                                                            }}>
                                                            <ListGroup.Item key={"consumers-fields" + consumer.id + i_consumer} className="pb-0 px-0 pt-0" style={{ border: 'none' }} >
                                                                <Row className="TicketsRow nopadding" >
                                                                    <ListGroup className="d-flex pe-0" variant="flush" name="consumers_custom_list" id={"consumer_list_" + consumer.id}>
                                                                        <ListGroup.Item name="consumer_name_action" className="nopadding ps-4 pe-4" id={"consumer_name_" + consumer.id} 
                                                                            key={'consumer-name-action-' + consumer.id}
                                                                            style={{ 
                                                                                border: `none`, 
                                                                                background: `transparent`, 
                                                                                /* position: 'fixed',
                                                                                right: document.getElementById('consumers-fields-form' + consumer.id)?.getBoundingClientRect()?.left? document.getElementById('consumers-fields-form' + consumer.id).getBoundingClientRect().left: ''  */
                                                                            }}
                                                                        >
                                                                            <Row className="nopadding pb-4" style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat' }} >
                                                                                <div className={"d-flex px-0 align-items-center " + ( /* !isLargerList && data.length - 1 === i_consumer? */ /* !isLargerList? 'pe-3': */ '')} /* style={{ width: !isLargerList? '':'14vw' }} */>
                                                                                    <span className="pe-2 oneLineClampEllipsis">
                                                                                        <OverlayTrigger
                                                                                            key='top'
                                                                                            placement='top'
                                                                                            overlay={
                                                                                                <Tooltip id={'tooltip*ID*' + consumer.id}>
                                                                                                    { consumer.name + (consumer.lastname? consumer.lastname: "") }
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <span>
                                                                                                { consumer.name + (consumer.lastname? consumer.lastname: "") }
                                                                                            </span>
                                                                                        </OverlayTrigger>
                                                                                    </span>
                                                                                    {i_consumer !== 0 && (consumer.id !== null && consumer.id !== undefined)?
                                                                                        <span>
                                                                                            <OverlayTrigger key={'overlay-trigger' + consumer.id + i_consumer} rootClose={true} trigger={['click']} placement="bottom" overlay={props.popoverConsumer} >
                                                                                                <Button id={i_consumer + "*ID*" + consumer.name} name={consumer.id} onClick={ (e) => props.setCurrentConsumerInfo(e) } className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                                                        <circle cx="12" cy="12" r="1" />
                                                                                                        <circle cx="12" cy="19" r="1" />
                                                                                                        <circle cx="12" cy="5" r="1" />
                                                                                                    </svg>
                                                                                                </Button>
                                                                                            </OverlayTrigger>
                                                                                        </span>:
                                                                                        <span style={{ height: '24px' }}></span>
                                                                                    }
                                                                                </div>
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                        <ListGroup.Item className="nopadding">
                                                                            <ListGroup className="d-flex pe-0" variant="flush" name='consumerListClass' id='consumerListClass' onScroll={props.scrollAll} style={{ overflowY: 'auto', height: '50vh', border: 'none',  }}>
                                                                            {/* <div className="nopadding" name='consumerListClass' id='consumerListClass' onScroll={scrollAll} style={{ overflowY: 'auto', height: '50vh', border: 'none' }}> */}
                                                                                { // percorrendo os campos dos consumidores
                                                                                    props.data[1] && 
                                                                                    (Object.keys(props.data[1])
                                                                                        .filter((field) => !excludedFieldsFromUnification.includes(field))
                                                                                    ).map((field, i_field) => {
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    <ListGroup.Item 
                                                                                                        key={'consumers-fields-form-check' + field + consumer.id + i_field + i_consumer}
                                                                                                        id={'consumers-fields-form' + consumer.id}
                                                                                                        className="nopadding ps-4 pe-4"
                                                                                                        style={{
                                                                                                            backgroundColor: field === 'custom_fields' ? '#E8E8E8': '#F9F9F9',
                                                                                                            borderColor: field === 'custom_fields' ? 'transparent': '',
                                                                                                            borderRadius: i_field === 0 && i_consumer === props.data.length - 1? '0px 10px 0px 0px' : '0px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Row style={{ height: field === "custom_fields"? '6vh': '5vh' }} className="oneLineClampEllipsis nopadding d-flex align-items-center">
                                                                                                            { field === 'custom_fields' ? // campos customizaveis fica vazio, servindo de marcador para os campos customizáveis listados abaixo
                                                                                                                <></>:
                                                                                                                <div className="d-flex px-0 TicketsRow" style={{ color: '#707070' }}>
                                                                                                                    <span className="pe-2">
                                                                                                                        <Form.Check type="radio" id={"form-" + field + "-" + consumer.id + "-" + consumer.name} name={"form-"+field} value={consumer[field]} defaultChecked={i_consumer === 0} /> 
                                                                                                                    </span>
                                                                                                                    <OverlayTrigger key={field} placement='top' 
                                                                                                                        overlay={
                                                                                                                            <Tooltip>
                                                                                                                                { field === "name" && consumer.name && consumer.lastname ?
                                                                                                                                    (consumer.name + " " + consumer.lastname):

                                                                                                                                    consumer[field]?
                                                                                                                                        consumer[field]:
                                                                                                                                        <>&mdash;</>
                                                                                                                                }
                                                                                                                            </Tooltip>
                                                                                                                        } 
                                                                                                                    >
                                                                                                                        <span className='oneLineClampEllipsis'>
                                                                                                                            { field === "name" && consumer.name && consumer.lastname ?
                                                                                                                                (consumer.name + " " + consumer.lastname):

                                                                                                                                consumer[field]?
                                                                                                                                    consumer[field]:
                                                                                                                                    <>&mdash;</>
                                                                                                                            }
                                                                                                                        </span>
                                                                                                                    </OverlayTrigger>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </Row>
                                                                                                    </ListGroup.Item>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }

                                                                                {/* Listagem de campos customizáveis */}
                                                                                { // percorrendo os campos customizaveis dos consumidores
                                                                                    props?.customFields?.length > 0 && props.customFields.map((custom_field, i_c_field) => {
                                                                                        let consumerCustomFields = consumer.custom_fields && consumer.custom_fields.length > 0? consumer.custom_fields: null;
                                                                                        let consumerCustomField = consumerCustomFields && consumerCustomFields.find((field, i_field) => 
                                                                                            field.custom_field_id === custom_field.id 
                                                                                        );

                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    <ListGroup.Item 
                                                                                                        key={'consumers-fields-form-check'+ custom_field.id + consumer.id + i_c_field + i_consumer}
                                                                                                        className="nopadding ps-4 pe-4"
                                                                                                        style={{
                                                                                                            backgroundColor: '#F9F9F9',
                                                                                                            borderRadius: i_consumer === props.data.length - 1? '0px 10px 0px 0px' : '0px',
                                                                                                        }}
                                                                                                    >
                                                                                                        <Row style={{ height: '5vh' }} className="oneLineClampEllipsis nopadding d-flex align-items-center">
                                                                                                            <div className="d-flex px-0 TicketsRow" style={{ color: '#707070' }}>
                                                                                                                <span className="pe-2">
                                                                                                                    <Form.Check type="radio" id={"form-" + custom_field.id + "-" + consumer.id + "-" + consumer.name} name={"form-"+custom_field.name} value={consumerCustomField?.value[0]} defaultChecked={i_consumer === 0} /> 
                                                                                                                </span>
                                                                                                                <OverlayTrigger key={custom_field.id} placement='top' 
                                                                                                                    overlay={
                                                                                                                        <Tooltip>
                                                                                                                            { consumerCustomField?.value ?
                                                                                                                                consumerCustomField.value[0]:
                                                                                                                                <>&mdash;</>
                                                                                                                            }
                                                                                                                        </Tooltip>
                                                                                                                    } 
                                                                                                                >
                                                                                                                    <span className='oneLineClampEllipsis'>
                                                                                                                        { consumerCustomField?.value ?
                                                                                                                            consumerCustomField.value[0]:
                                                                                                                            <>&mdash;</>
                                                                                                                        }
                                                                                                                    </span>
                                                                                                                </OverlayTrigger>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                    </ListGroup.Item>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            {/* </div> */}
                                                                            </ListGroup>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                                </Row>
                                                            </ListGroup.Item>
                                                        </Row>: <></>
                                                    )
                                                })
                                            }
                                        </div>
                                    </ListGroup>
                                </Row>
                                <Row className="" style={{ backgroundColor: '#F9F9F9', borderRadius: '0px 0px 10px 1px', width: '69vw' }}>
                                    <div className="d-flex justify-content-center pb-4 pt-3" style={{ width: '14vw', backgroundColor: '#E5ECFF', borderRadius: '0px 0px 10px 10px' }}>
                                    </div>
                                    <div className="d-flex pb-4 pt-3 ps-4" style={{  width: '14vw' }}>
                                        <Button 
                                            style={{
                                                background: `#4A69BD 0% 0% no-repeat padding-box`,
                                                border: `1px solid #4A69BD`,
                                                borderRadius: `4px`,
                                                textAlign: `center`,
                                                font: 'normal calc(0.2em + 0.8vw) Montserrat',
                                                letterSpacing: `0px`,
                                                color: `#FFFFFF`,
                                                opacity: `1`,
                                            }}
                                            onClick={(e) => props.unifyConsumerConfirm(e)}
                                        >
                                            {props.t('home_consumers.unification.view_analysis.unify_registrations')}
                                        </Button>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default ViewAnalysisSimilarConsumers;