import { FC, useEffect, useState } from "react";
import ListOfTasks from "."
import Utils from "../../../../core/shared/utils";
import { useDispatch, useSelector } from "react-redux";
import { getShowConfirmationModal, getShowMeetButton, setShowAlertFeedback, setShowConfirmationModal, setShowEditTaskModal, setShowEditTicketsModal } from "../../../../store/internal";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { useLocation, useNavigate } from "react-router-dom";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getProfileId } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getOwner_id } from "../../../../store/callcenter";
import BackendConstants from "../../../../core/constants/backend-constants";
import { IListOfTasksController } from "./indexModel";
import { Button, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { TicketStatus } from "../../../../core/enums/ticket-status";
import { ConsumerData } from "../../homeConsumers/indexModel";
import { ListName } from "../../../../core/enums/order-sort-by";

const AppRequesterConst = new AppRequesterController();

const ListOfTasksController: FC<IListOfTasksController> = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const [currentTaskId, setCurrentTaskId] = useState(null);
    const [currentTicketId, setCurrentTicketId] = useState(null);

    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)

    const [dataEdit, setDataEdit] = useState([])

    const [isTaskOwner, setIsTaskOwner] = useState(false)
    const [isNotEditable, setIsNotEditable] = useState(false)
    const [view, setView] = useState(false)
    const [openModalPhone, setOpenModalPhone] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [currentConsumer, setCurrentConsumer] = useState(null);
    const [currentParams, setCurrentParams] = useState({});
    const [currentState, setCurrentState] = useState("");
    const [aux, setAux] = useState([]);
    const [currentTicketsConsumers, setCurrentTicketsConsumers] = useState(undefined);

    const { t } = useTranslation()
    const emp = useLocation();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        setView(false)
    }, []);


    useEffect(() => {
        const controller = new AbortController();
        if (values.internal.getShowEditTicketsModal === false) {
            setCurrentPage((state) => {
                setCurrentParams((state2) => {
                    getAllTicketsToPagination(Object.assign(state2, (Object.assign({ user_id: emp.state['user_id'] }, { limit: currentLimit, page: state }))));
                    return state2;
                });
                return state;
            });
        }

        return () => {
            controller.abort();
        }
    }, [currentPage, currentState]);

    // atualizando recuo causado pela barra de rolagem
    useEffect(() => {
        Utils.changeActiveScrollBar("ListOfTasks");
    }, [props?.tasks]);

    const getAllTicketsToPagination = (params: { user_id: string; } & { limit: number; page: number; }) => {
        getAllTickets(params, setCurrentTicketsConsumers, aux, setAux);
    }

    const getTaskById = async (params: { limit: number; page: number }) => {
        const headers = UserService.getHeaders()
        const controller = new AbortController()
        const signal = controller.signal

        await AppRequesterConst.Get(
            `/task/${currentTaskId}`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data?.status === 200 && data) {
                    setDataEdit(data.data.task)
                    dispatch(setShowEditTaskModal(true));
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                }
                if (error?.response?.status === 401) {
                    dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const updateListFunction = () => {
        props.setCurrentPage(props.currentPage + 1)
    }

    const setCurrentTaskInfo = (e: { currentTarget: { id: string }; }, user_id: string, ticket_id) => {
        setCurrentTaskId(e.currentTarget.id);
        setCurrentTicketId(ticket_id)
        setIsTaskOwner(false)
        if (user_id === values.user.id) {
            setIsTaskOwner(true)
        }
    }

    const confirmDeleteTask = () => {
        dispatch(setShowConfirmationModal({
            visibility: true, text: { "title": t('home_tasks.list_of_tasks.confirm_delete_task.title'), "body": t('home_tasks.list_of_tasks.confirm_delete_task.body'), "buttonConfirmationText": t('home_tasks.list_of_tasks.confirm_delete_task.delete'), "buttonReturnText": t('home_tasks.list_of_tasks.confirm_delete_task.cancel') },
            functionConfirmation: deleteTask
        }));
    }

    const deleteTask = async () => {
        const headers = UserService.getHeaders()
        await AppRequesterConst.Delete(
            '/task/' + currentTaskId, { headers: headers },
            (response: Object) => { },
            (data: Object) => {
                const controller = new AbortController()
                dispatch(setShowAlertFeedback({ message: t('home_tasks.list_of_tasks.delete_task_alert.deleted_task'), visibility: true, signalIcon: true }));
                props.setAux([])
                props.getTasks({ limit: currentLimit, page: props.currentPage }, controller)
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_tasks.list_of_tasks.delete_task_alert.unexpected_error'), visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
        );
    }

    const showEditModal = () => {
        // this.props.setCurrentTicketFunction(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        getTaskById({ page: props.currentPage, limit: currentLimit })
    }

    const viewTaskTicket = () => {
        setView(true)
        props.setViewFilter(false)
        dispatch(setShowEditTicketsModal(true))
    }

    const popoverAction = (
        <Popover id={currentTaskId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                {/* {values.user.profile_id === '1d9a359a-910e-45a0-9bfd-29eedf814b12' || isTaskOwner === true ?
                    <> */}
                        <Button onClick={() => { showEditModal(); setIsNotEditable(false) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t('home_tasks.list_of_tasks.edit_task')} </Button>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button onClick={() => confirmDeleteTask()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t('home_tasks.list_of_tasks.delete_task')} </Button>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button onClick={() => viewTaskTicket()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t('home_tasks.list_of_tasks.view_ticket')} </Button>
                    {/* </>
                    :
                    <>
                        <Button onClick={() => { showEditModal(); setIsNotEditable(true) }} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t('home_tasks.list_of_tasks.view_info')} </Button>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button onClick={() => viewTaskTicket()} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}> {t('home_tasks.list_of_tasks.view_ticket')} </Button>
                    </>
                } */}
            </Popover.Body>
        </Popover>
    );

    const widthCols = {
        requester: '10%',
        assigned: '10%',
        date: '13%',
        description: '14%',
        tags: '13%',
        followers: '15%',
        status: '7%',
        action: '7%'
    }

    const concatenateTags = (tags) => {
        if (tags.length === 0) {
            return 'N/A';
        }

        const tagNames = tags.map(tag => tag?.name);
        return tagNames.join(', ');
    }

    const getStatusText = (status_id: string) => {
        switch (status_id) {
            case TicketStatus.Open: 
                return { tooltip: t("ticket_status.open"), symbol: t('ticket_status_symbol.open'), color: '#4A69BD' };
            case TicketStatus.Resolved: 
                return { tooltip: t("ticket_status.resolved"), symbol: t('ticket_status_symbol.resolved'), color: '#707070' };
            case TicketStatus.Pending: 
                return { tooltip: t("ticket_status.pending"), symbol: t('ticket_status_symbol.pending'), color: '#DE2450' };
            case TicketStatus.OnHold: 
                return { tooltip: t("ticket_status.on_hold"), symbol: t('ticket_status_symbol.on_hold'), color: '#FBBD4E' };
            case TicketStatus.New: 
                return { tooltip: t("ticket_status.new"), symbol: t('ticket_status_symbol.new'), color: '#17a589' };
            case TicketStatus.Abandoned: 
                return { tooltip: t("ticket_status.abandoned"), symbol: t('ticket_status_symbol.abandoned'), color: '#2D3233' };
            case TicketStatus.OverdueTicketTask:
                return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
            case TicketStatus.Overdue: 
                return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
            default:
                return { tooltip: 'N/A', symbol: 'N/A', color: '#707070' };
        }
    }

    const getStatusElement = (status_id: string): JSX.Element => {
        const status_text = getStatusText(status_id);

        return (
            <Button style={{ cursor: 'default', backgroundColor: status_text.color, border: 'none', width: '20px', height: '20px', lineHeight: '1', textIndent: '-2', padding: '0rem 0rem' }}>
                <OverlayTrigger key='top' placement='top' overlay={<Tooltip id='tooltip'>{status_text.tooltip}</Tooltip>}>
                    <span className='FilterTicketsRow '>{status_text.symbol}</span>
                </OverlayTrigger>
            </Button>
        );
    }

    const getAllTickets = async (params: { user_id: string; } & { limit: number; page: number; }, setData: Function, aux: any[], setAux: Function) => {
        if (values.internal.getSortByList?.listName === ListName?.ListOfConsumers && values.internal.getSortByList.sort_by !== null && values.internal.getOrderByList !== null) {
            params = Object.assign(params, { sort_by: values.internal.getSortByList.sort_by, order_by: values.internal.getOrderByList });
        }
        const headers = UserService.getHeaders() /*,signal = controller.signal*/;

        await AppRequesterConst.Get(
            // '/consumer/' + (params?.user_id !== undefined ? params.user_id : currentUserId) + '/tickets', { headers, /*signal,*/ params: params },
            'ticket/' + currentTicketId, { headers, /*signal,*/ params: params },
            (response: Object) => { },
            (data: ConsumerData) => {
                if (data?.status === 200) {
                    setAux(data.data.tickets);
                    setData(data.data.tickets);
                } else if (aux.length === 0) {
                    setData(undefined);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    return <ListOfTasks
        isLoading={isLoading}
        tasks={props?.tasks}
        updateListFunction={updateListFunction}
        setCurrentTaskInfo={setCurrentTaskInfo}
        dataEdit={dataEdit}
        setIsLoading={setIsLoading}
        getTasks={props.getTasks}
        isNotEditable={isNotEditable}
        widthCols={widthCols}
        concatenateTags={concatenateTags}
        popoverAction={popoverAction}
        getStatusElement={getStatusElement}
        t={t}
        currentTicketId={currentTicketId}
        setView={setView}
        view={view}
        setOpenModalPhone={setOpenModalPhone}
        setPhoneNumber={setPhoneNumber}
        getAllTickets={getAllTickets}
        currentParams={currentParams}
        currentParamsTask={props?.currentParams}
        callbackModalTasks={props.callbackModalTasks}
    />
}

export default ListOfTasksController;