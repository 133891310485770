import { FC, useCallback, useEffect, useRef, useState } from 'react';
import ModalActiveTrigger from './index';
import { 
  I_ActiveTriggerDetails,
  I_FetchActiveTrigger,
  I_FetchAuthenticatedEmails,
  I_FetchCompanyEmails,
  I_TemplateMessageWhatsapp,
  I_CompanyEmails,
  ModalActiveTriggerControllerInterface,
  I_FetchTemplateMessageWhatsapp,
  I_FetchUploadRecipientFile
} from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import emitter from '../../../../../core/shared/emitter';
import { MessageTriggerStatus } from '../../../../../core/enums/message-trigger-status';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { FetchMessageTriggerType, MessageTriggerType } from '../../listOfMessageTriggers/indexModel';
import { Channels } from '../../../../../core/enums/channels';
import { IconBrandWhatsapp, IconCircleCheckFilled, IconListCheck, IconMail, IconPaperclip, IconRobotFace, IconStar, IconUserBolt } from '@tabler/icons-react';
import { getEmailCompany, getIdCompany } from '../../../../../store/company';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import { CurrentAudioInfo } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/components/toolbarAudioRecorder/indexModel';
import draftToHtml from 'draftjs-to-html';
import { ErrorCode, useDropzone, FileWithPath, FileRejection, DropEvent } from 'react-dropzone';
import { CustomFile, CustomFileAttributes, getFileDetailsFromURL } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/components/toolbarFileUploader/indexModel';
import { getToken } from '../../../../../store/token';
import { verifyCode } from '../../../../../services/codeCxpressInterface';
import { WhatsappMessageInterface } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/components/popupTemplateMessages/indexModel';

const AppRequesterConst = new AppRequesterController();

const LIMIT_ITEMS_PAGINATION = 20;
const selectIconSize = 20;
const selectIconColor = '#707070';
const MAX_FILE_SIZE = 15728640; // 15MB

const ModalActiveTriggerController: FC<ModalActiveTriggerControllerInterface> = (props) => {
  const { t } = useTranslation('ModalActiveTrigger');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const companyId = useSelector(getIdCompany);
  const companyEmail = useSelector(getEmailCompany);
  const userToken = useSelector(getToken);

  const [activeTrigger, setActiveTrigger] = useState<I_ActiveTriggerDetails>(null);
  const [loadingActiveTrigger, setLoadingActiveTrigger] = useState(false);
  const [errorLoadingActiveTrigger, setErrorLoadingActiveTrigger] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [mode, setMode] = useState<'create' | 'edit' | 'info'>(null);
  const reloadOnClose = useRef<boolean>(false);

  const [title, setTitle] = useState<string>('');

  // Tipos de disparo ativo
  const [selectedTriggerType, setSelectedTriggerType] = useState<CustomSelectOptionType>(null);
  const [triggerTypesList, setTriggerTypesList] = useState<MessageTriggerType[]>([]);
  const [triggerTypesListPage, setTriggerTypesListPage] = useState(1);
  const [hasMoreTriggerTypes, setHasMoreTriggerTypes] = useState(false);
  const [isLoadingTriggerTypesSelect, setIsLoadingTriggerTypesSelect] = useState(false);

  // Canal do disparo
  const [selectedChannel, setSelectedChannel] = useState<CustomSelectOptionType>(null);
  const selectedChannelRef = useRef(null);

  // Date
  const [dateScheduled, setDateScheduled] = useState<Date>();
  const [hourScheduled, setHourScheduled] = useState<Date>();

  // Email autenticado (ou principal da empresa)
  const [selectedSenderEmail, setSelectedSenderEmail] = useState<CustomSelectOptionType>(null);
  const [senderEmailsList, setSenderEmailsList] = useState<{id: string; email: string}[]>([]);
  const [senderEmailsListPage, setSenderEmailsListPage] = useState(1);
  const [hasMoreSenderEmails, setHasMoreSenderEmails] = useState(false);
  const [isLoadingSenderEmailsSelect, setIsLoadingSenderEmailsSelect] = useState(false);
  const [defaultCompanyEmail, setDefaultCompanyEmail] = useState<I_CompanyEmails>(null);

  // Arquivo com destinatários
  const [selectedFileRecipients, setSelectedFileRecipients] = useState<{ id: string, name: string}>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentRecipientSize, setCurrentRecipientSize] = useState(0);
  const [isLoadingUploadRecipients, setIsLoadingUploadRecipients] = useState(false);
  
  // Template Messages
  const [selectedTemplateWhatsapp, setSelectedTemplateWhatsapp] = useState<CustomSelectOptionType>(null);
  const [templatesWhatsappList, setTemplatesWhatsappList] = useState<WhatsappMessageInterface[]>([]);
  const [templatesWhatsappListPage, setTemplatesWhatsappListPage] = useState(1);
  const [hasMoreTemplatesWhatsapp, setHasMoreTemplatesWhatsapp] = useState(false);
  const [isLoadingTemplatesWhatsappSelect, setIsLoadingTemplatesWhatsappSelect] = useState(false);
  const [hasTriage, setHasTriage] = useState(false);
  const [makeTriage, setMakeTriage] = useState(false);
  const [templateWhatsappKey, setTemplateWhatsappKey] = useState<string>(null);
  const [hasTemplateAttachment, setHasTemplateAttachment] = useState(false);
  const [templateMimetypesAttachment, setTemplateMimetypesAttachment] = useState<{[key: string]: string[]}>({});
  const [triggerTemplate, setTriggerTemplate] = useState<WhatsappMessageInterface>(null);

  // Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState<FileWithPath[]>([]);
  const [hasFileUploader, setHasFileUploader] = useState(false);
  const [audioRecordedFiles, setAudioRecordedFiles] = useState<File[]>([]);
  const [currentAudioInfo, setCurrentAudioInfo] = useState<CurrentAudioInfo>();

  // Template com Atendimento após disparo - response_message_level
  const [selectedPostAttendanceType, setSelectedPostAttendanceType] = useState<CustomSelectOptionType>(null);
  const [hasPostScheduleAttendance, setHasPostScheduleAttendance] = useState(false);

  // Channels - Disparo ativo
  const channelsList = [
    { id: Channels.Email, value: t("form.channels.email"), icon: <IconMail stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.WhatsApp, value: t("form.channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  const postAttendanceTypeList = [
    { id: '1', value: t("form.response_level.bot"), icon: <IconRobotFace stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: '2', value: t("form.response_level.agent"), icon: <IconUserBolt stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  useEffect(() => {
    emitter.on('open-modal-active-trigger', data => {
      if (data?.id) {
        if (data.statusId === MessageTriggerStatus.Scheduled) {
          setMode('edit');
        } else {
          setMode('info');
        }

        fetchActiveTrigger(data.id).then(response => {
          if (response.message_sending.status_id !== data.statusId) {
            reloadOnClose.current = true;
          }

          if (response.message_sending.status_id === MessageTriggerStatus.Scheduled) {
            setMode('edit');
          } else {
            setMode('info');
          }
          setActiveTrigger(response.message_sending);

          setTitle(response.message_sending.title || '');
          setSelectedTriggerType({ id: response.message_sending.type_id || response.message_sending.type, value: response.message_sending.type });
          setSelectedChannel({ id: response.message_sending.channel_id, value: response.message_sending.channel });
          setSelectedSenderEmail({ id: response.message_sending.email_id, value: response.message_sending.email });

          setDateScheduled(new Date(response.message_sending.date_scheduled));
          setHourScheduled(new Date(response.message_sending.date_scheduled));

          setSelectedFileRecipients({ id: null, name: response.message_sending.file_name });

          setHasTriage(!!response.message_sending.is_active_triage);
          setMakeTriage(!!response.message_sending.has_triage);

          if (response.template_message) {
            setTriggerTemplate(response.template_message)
            setSelectedTemplateWhatsapp({ id: response.template_message.id, value: response.template_message.title });
            fillTemplateMessage(response.template_message.message);
            setTemplateWhatsappKey(response.template_message.whatsapp_keys_id);
            setHasTemplateAttachment(!!response.template_message.attachment_type);

            if (response.template_message.attachment_type) {
              setTemplateMimetypesAttachment(getWhatsappAttachmentType(response.template_message.attachment_type));
            }
          }

          if (response.message_sending.response_message_level) {
            setHasPostScheduleAttendance(true);
            const responseLevel = postAttendanceTypeList.find(item => Number(item.id) === response.message_sending.response_message_level);
            setSelectedPostAttendanceType({ id: responseLevel.id, value: responseLevel.value });
          }

          if (response.message_sending.attachments?.length > 0) {
            const newFiles = [];
            response.message_sending.attachments.forEach(item => {
              const fileInfo = getFileDetailsFromURL(item.url);
              const currentFile = {
                name: item.filename,
                bucketId: item._id,
                preview: item.url,
                path: item.url,
                type: fileInfo.mimeType
              };
              newFiles.push(currentFile);
            });
            setFiles(newFiles);
            setHasFileUploader(true);
          }
        }).catch(error => {
          console.log(error);
          dispatch(setShowAlertFeedback({ message: t('alert.error_loading'), visibility: true, signalIcon: false }));
        });
      } else {
        setMode('create');
      }
    });

    return () => {
      emitter.off('open-modal-active-trigger');
    }
  }, []);

  useEffect(() => {
    if (mode && mode !== 'info') {
      fetchTriggerTypes(false, 1);
      fetchAuthenticatedEmails(false, 1);
      fetchTemplatesWhatsapp(false, 1);
  
      fetchCompanyEmail().then(emails => {
        emails.forEach(item => {
          if (item.email_suport) {
            setDefaultCompanyEmail(item);
          }
        });
      }).catch(error => {
        console.log(error);
      });
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'create') {
      setEditorState(EditorState.createEmpty());
      setSelectedFileRecipients(null);
    }
    if (selectedChannel?.id) {
      selectedChannelRef.current = selectedChannel.id;
    }
  }, [mode, selectedChannel]);

  useEffect(() => {
    if (mode !== 'info' && selectedChannel?.id) {
      if (selectedChannel.id === Channels.Email && selectedTemplateWhatsapp?.id) {
        setSelectedTemplateWhatsapp(null);
        setTemplateWhatsappKey(null);
      }
    }
  }, [mode, selectedChannel, selectedTemplateWhatsapp]);

  useEffect(() => {
    if (selectedTemplateWhatsapp?.id) {
      const item = templatesWhatsappList.find(item => item.id === selectedTemplateWhatsapp.id);

      if (item) {
        setTriggerTemplate(item);
        fillTemplateMessage(item.message);
        setHasPostScheduleAttendance(item.bot_enable && item.is_bot_nlp);
        setHasTriage(item.has_triage);
        setTemplateWhatsappKey(item.whatsapp_keys_id);
        setHasTemplateAttachment(!!item.attachment_type);

        if (item.attachment_type) {
          setTemplateMimetypesAttachment(getWhatsappAttachmentType(item.attachment_type));
        }
      }
    }
  }, [selectedTemplateWhatsapp]);

  // =============================================
  // FILE UPLOADER

  const validator = useCallback(<T extends File>(file: T) => {
    const validateArray = [
      'application/octet-stream', 
      'application/x-msdownload', 
      'vnd.microsoft.portable-executable',
      'application/x-msi',
      'application/x-ms-dos-executable',
      'application/vnd.debian.binary-package',
    ];

    if (file) {
      if (!file.type || validateArray.includes(file.type)) {
        return { message: t('chat_editor.unknown_file'), code: ErrorCode.FileInvalidType };
      }
    }
    return null;
  }, []);

  const onDrop = useCallback(<T extends File>(acceptedFiles: T[], fileRejections?: FileRejection[], event?: DropEvent) => {
    setIsDragging(false);
    try {
      if (acceptedFiles.length > 0) {
        setSelectedFileRecipients({ id: null, name: acceptedFiles[0].name });
        uploadFiles(acceptedFiles);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const onDropRejected = (fileRejections: FileRejection[], event?: DropEvent) => {
    if (fileRejections[0].errors[0].code === ErrorCode.FileTooLarge) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_file_length'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileTooSmall) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_empty_files'), signalIcon: false }));
    } else if (fileRejections[0].errors[0].code === ErrorCode.FileInvalidType) {
      dispatch(setShowAlertFeedback({ visibility: true, message: t('alert.recipients_only_csv'), signalIcon: false }));
    }
  }

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    minSize: 1,
    maxSize: MAX_FILE_SIZE,
    disabled: !selectedChannelRef.current || !!selectedFileRecipients?.name || mode === 'info',
    noClick: !!selectedFileRecipients?.name || mode === 'info',
    noDrag: !!selectedFileRecipients?.name || mode === 'info',
    validator,
    accept: { 'text/csv': ['.csv'] },
    onDrop,
    onDropRejected,
    onDragEnter: () => { setIsDragging(true); },
    onDragLeave: () => { setIsDragging(false); },
  });

  const uploadFiles = (files: CustomFile[]) => {
    if (files && selectedChannelRef?.current) {
      const headers = {
        'Content-Type': 'multipart/form-data; charset=utf-8',
        'Authorization': 'Bearer ' + userToken
      };

      const formData = new FormData();

      let files_size = 0;
  
      formData.append('file', files[0]);
      formData.append('chanel_id', selectedChannelRef?.current); // O back espera receber "chanel_id" mesmo em vez de "channel_id"

      const currentSize = currentRecipientSize || 0;
      const updatedSize = currentSize + files_size;
      setCurrentRecipientSize(updatedSize);
  
      AppRequesterConst.Post(
        '/message-sending/upload', formData, { headers },
        () => { },
        (response: I_FetchUploadRecipientFile) => {
          if (response.status === 200) {
            setSelectedFileRecipients({ id: response.data.id, name: files[0].name });
          }
  
          dispatch(setShowAlertFeedback({ message: t('alert.recipients_success_upload'), visibility: true, signalIcon: true }));
        },
        (error: {response: {status: number; data: { message: any []; code_cxpress: number}}}) => {
          setSelectedFileRecipients(null);

          if (error.response?.data?.code_cxpress) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error.response?.data?.message && error.response?.data?.message[0]) {
            dispatch(setShowAlertFeedback({ message: error.response.data.message[0], visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.recipients_failure_upload'), visibility: true, signalIcon: false }))
          }
        }, navigate, dispatch, setIsLoadingUploadRecipients,
      );
    }
  }

  // =========================================================
  // ========================================================

  const isValid = () => {
    let valid = true;

    if (!title) {
      valid = false;
    }

    if (!selectedTriggerType?.id) {
      valid = false;
    }

    if (!selectedChannel?.id) {
      valid = false;
    }

    if (!dateScheduled || !hourScheduled) {
      valid = false;
    }

    if (!isValidDate()) {
      valid = false;
    }

    if (selectedChannel?.id === Channels.Email) {
      if (!selectedSenderEmail?.id) {
        valid = false;
      }

      const isEmptyEditor = !editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled';

      if (isEmptyEditor) {
        valid = false;
      }
    }

    if (selectedChannel?.id === Channels.WhatsApp) {
      if (!selectedTemplateWhatsapp?.id) {
        valid = false;
      }

      if (hasPostScheduleAttendance && !selectedPostAttendanceType?.id) {
        valid = false;
      }
    }

    if (!selectedFileRecipients?.name) {
      valid = false;
    }

    return valid;
  }

  const isValidDate = () => {
    if (dateScheduled && hourScheduled) {
      const date = new Date(dateScheduled);
      const hour = hourScheduled.toLocaleTimeString();
      const formattedHour = hour.slice(0, 5).split(':').map((value: string) => Number(value));

      date.setHours(formattedHour[0], formattedHour[1], 0, 0);
      const valid = date > new Date(Date.now());

      return valid;
    } else {
      return false;
    }
  }

  const handleSubmit = () => {
    if (isValid()) {
      const payload = {
        title,
        messages_sending_type_id: selectedTriggerType?.id,
        chanel_id: selectedChannel?.id, // O back espera receber "chanel_id" mesmo em vez de "channel_id"
        date_scheduled: null,
        file_id: selectedFileRecipients?.id || '',
        attachment_ids: []
      }

      if (dateScheduled && hourScheduled) {
        const date = new Date(dateScheduled);
        const hour = hourScheduled.toLocaleTimeString();
        const formattedHour = hour.slice(0, 5).split(':').map((value: string) => Number(value));
    
        date.setHours(formattedHour[0], formattedHour[1], 0, 0);

        payload.date_scheduled = date;
      }
  
      if (hasTriage) {
        payload['has_triage'] = !!makeTriage;
      }

      if (selectedChannel?.id === Channels.Email && selectedSenderEmail?.id) {
        payload['email_id'] = selectedSenderEmail.id;
      }
  
      if (selectedChannel?.id === Channels.WhatsApp && selectedTemplateWhatsapp?.id) {
        payload['template_message_id'] = selectedTemplateWhatsapp.id;
        payload['whatsapp_keys_id'] = templateWhatsappKey;
  
        if (selectedPostAttendanceType?.id) {
          payload['response_message_level'] = Number(selectedPostAttendanceType.id);
        }
      }
  
      const isEmptyEditor = !editorState.getCurrentContent().hasText() && editorState.getCurrentContent().getBlockMap().first().getType() === 'unstyled';
  
      if (!isEmptyEditor) {
        const contentState = editorState.getCurrentContent();
        const html = draftToHtml(convertToRaw(contentState));
        
        // Regex para alterar o target de links para abrir em nova aba
        const regexLinkTarget = /target="_self"/g;
        const formattedHtml = html.replace(regexLinkTarget, 'target="_blank"').replace(/\n$/, '');
  
        payload['message'] = formattedHtml;
      }
  
      if (files.length > 0) {
        payload.attachment_ids = files.map((item: FileWithPath & CustomFileAttributes) => item.bucketId);
      }

      const headers  = UserService.getHeaders();
  
      const config = { headers };

      if (mode === 'create') {
        AppRequesterConst.Post(
          '/message-sending', payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.trigger_successfully_create'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: any) => {
            if (error?.response?.data?.code_cxpress) {
              // dispatch(setShowAlertFeedback({ message: t('alert.trigger_failure_create', { code_cxpress: error.response.data.code_cxpress}), visibility: true, signalIcon: false }));
              dispatch(setShowAlertFeedback({ message: `${error.response.data.message} (${error.response.data.code_cxpress})`, visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      } else if (mode === 'edit') {
        payload['id'] = activeTrigger.id;

        if (!selectedFileRecipients?.id) {
          delete payload['file_id'];
        }

        if (selectedFileRecipients?.name) {
          payload['recipients_file_name'] = selectedFileRecipients.name;
        }

        if (selectedTriggerType.value === selectedTriggerType.id) {
          delete payload['messages_sending_type_id'];
        }

        AppRequesterConst.Put(
          '/message-sending', payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.trigger_successfully_edit'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: any) => {
            if (error?.response?.data?.code_cxpress) {
              dispatch(setShowAlertFeedback({ message: t('alert.trigger_failure_edit', { code_cxpress: error.response.data.code_cxpress}), visibility: true, signalIcon: false }));
            } else {
              dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
            }
          },
          navigate, dispatch, setLoadingRequest
        );
      }
    } else {
      if (!isValidDate()) {
        dispatch(setShowAlertFeedback({ message: t('alert.trigger_invalid_hour'), visibility: true, signalIcon: false }));
      }
    }
  }

  // ==================================
  // Request Message Sending
  const fetchActiveTrigger = async (id: string): Promise<{ message_sending: I_ActiveTriggerDetails; template_message: WhatsappMessageInterface }> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingActiveTrigger(true);
    setErrorLoadingActiveTrigger(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/message-sending/${id}`, config,
        () => {},
        (response: I_FetchActiveTrigger) => {
          setErrorLoadingActiveTrigger(false);
          if (response.status === 200 && response.data?.message_sending) {
            resolve({ message_sending: response.data.message_sending, template_message: response.data?.template_message }); // colocar o template_message aqui tbm
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingActiveTrigger(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingActiveTrigger, { }
      );
    });
  }

  // ===================================
  // Request Trigger Types

  const fetchTriggerTypes = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || triggerTypesListPage, active: true };
    // const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setTriggerTypesListPage(newPage);

    AppRequesterConst.Get('/message-sending-type', config, 
      () => {},
      (response: FetchMessageTriggerType) => {
        if (response.status === 200 && response.data.messageSendingTypes.length > 0) {
          if (response.data.messageSendingTypes.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTriggerTypes(true);
          } else {
            setHasMoreTriggerTypes(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...triggerTypesList, ...response.data.messageSendingTypes];
            setTriggerTypesList(new_array);
          } else {
            setTriggerTypesList(response.data.messageSendingTypes || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTriggerTypesList([]);
          }
        }
      },
      (error: any) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingTriggerTypesSelect, {}
    );
  }

  const fetchTriggerTypesPagination = () => {
    if (hasMoreTriggerTypes) {
      const newPage = triggerTypesListPage + 1;
      setTriggerTypesListPage(newPage);

      fetchTriggerTypes(true, newPage);
    }
  }

  const fetchSearchTriggerTypes = (term: string) => {
    if (term) {
      fetchTriggerTypes(false, 1, term);
    } else {
      fetchTriggerTypes(false, 1, '');
    }
  }

  // ===================================
  // Emails
  const fetchAuthenticatedEmails = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || senderEmailsListPage };
    // const signal = abortController?.signal;

    const config = { headers, params };

    setSenderEmailsListPage(newPage);

    AppRequesterConst.Get('/company/email/authenticated', config, 
      () => {},
      (response: I_FetchAuthenticatedEmails) => {
        if (response.status === 200 && response.data.emails.length > 0) {
          if (response.data.emails.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreSenderEmails(true);
          } else {
            setHasMoreSenderEmails(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...senderEmailsList, ...response.data.emails];
            setSenderEmailsList(new_array);
          } else {
            setSenderEmailsList(response.data.emails || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setSenderEmailsList([]);
          }
        }
      },
      (error: any) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingSenderEmailsSelect, {}
    );
  }

  const fetchAuthenticatedEmailsPagination = () => {
    if (hasMoreSenderEmails) {
      const newPage = senderEmailsListPage + 1;
      setSenderEmailsListPage(newPage);

      fetchAuthenticatedEmails(true, newPage);
    }
  }

  const fetchSearchAuthenticatedEmails = (term: string) => {
    if (term) {
      fetchAuthenticatedEmails(false, 1, term);
    } else {
      fetchAuthenticatedEmails(false, 1, '');
    }
  }

  const fetchCompanyEmail = async (): Promise<I_CompanyEmails[]> => {
    const headers = UserService.getHeaders();
    const params = { search: companyEmail };
    const config = { params, headers };
    setLoadingActiveTrigger(true);
    setErrorLoadingActiveTrigger(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get('/company/email', config,
        () => {},
        (response: I_FetchCompanyEmails) => {
          setErrorLoadingActiveTrigger(false);
          if (response.status === 200 && response.data?.emails) {
            resolve(response.data.emails);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingActiveTrigger(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingActiveTrigger, { }
      );
    });
  }

  // ===================================
  // Request Templates Whatsapp

  const fetchTemplatesWhatsapp = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || templatesWhatsappListPage };
    // const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setTemplatesWhatsappListPage(newPage);

    AppRequesterConst.Get(`/template-message/whatsapp/${companyId}`, config, 
      () => {},
      (response: I_FetchTemplateMessageWhatsapp) => {
        if (response.status === 200 && response.data.templateMessages.length > 0) {
          if (response.data.templateMessages.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTemplatesWhatsapp(true);
          } else {
            setHasMoreTemplatesWhatsapp(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...templatesWhatsappList, ...response.data.templateMessages];
            setTemplatesWhatsappList(new_array);
          } else {
            setTemplatesWhatsappList(response.data.templateMessages || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTemplatesWhatsappList([]);
          }
        }
      },
      (error: any) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingTemplatesWhatsappSelect, {}
    );
  }

  const fetchTemplatesWhatsappPagination = () => {
    if (hasMoreTemplatesWhatsapp) {
      const newPage = templatesWhatsappListPage + 1;
      setTemplatesWhatsappListPage(newPage);

      fetchTemplatesWhatsapp(true, newPage);
    }
  }

  const fetchSearchTemplatesWhatsapp = (term: string) => {
    if (term) {
      fetchTemplatesWhatsapp(false, 1, term);
    } else {
      fetchTemplatesWhatsapp(false, 1, '');
    }
  }

  // ===================================

  const onSelectDate = (date: Date) => {
    setDateScheduled(date);
  }

  const onSelectHour = (date: Date) => {
    setHourScheduled(date);
  }

  const removeSelectedFile = () => {
    setSelectedFileRecipients(null);
    acceptedFiles.pop();
  }

  const fillTemplateMessage = (message: string) => {
    const blocksFromHTML = convertFromHTML(message);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
    setEditorState(EditorState.createWithContent(contentState));
  }

  const getTriggerTitleInput = () => {
    return {
      title,
      setTitle,
    }
  }

  const getTriggerTypesSelect = () => {
    return {
      triggerTypesList: formatDataSelect(triggerTypesList, 'trigger-type'),
      selectedTriggerType,
      setSelectedTriggerType,
      isLoadingTriggerTypesSelect,
      fetchTriggerTypesPagination,
      fetchSearchTriggerTypes,
      hasMoreTriggerTypes,
    };
  }

  const getChannelSelect = () => {
    return {
      channelsList,
      selectedChannel,
      setSelectedChannel,
    }
  }

  const getDateInput = () => {
    return {
      dateScheduled,
      onSelectDate,
      hourScheduled,
      onSelectHour
    }
  }

  const getSenderEmailsSelect = () => {
    return {
      senderEmailsList: formatDataSelect(senderEmailsList, 'authenticated-emails'),
      selectedSenderEmail,
      setSelectedSenderEmail,
      isLoadingSenderEmailsSelect,
      fetchAuthenticatedEmailsPagination,
      fetchSearchAuthenticatedEmails,
      hasMoreSenderEmails,
    };
  }

  const getTemplatesWhatsappSelect = () => {
    return {
      templatesWhatsappList: formatDataSelect(templatesWhatsappList, 'template-message'),
      selectedTemplateWhatsapp,
      setSelectedTemplateWhatsapp,
      isLoadingTemplatesWhatsappSelect,
      fetchTemplatesWhatsappPagination,
      fetchSearchTemplatesWhatsapp,
      hasMoreTemplatesWhatsapp,
    };
  }

  const getPostAttendanceSelect = () => {
    return {
      postAttendanceTypeList,
      selectedPostAttendanceType,
      setSelectedPostAttendanceType,
      hasPostScheduleAttendance,
    }
  }

  const formatDataSelect = (data: any[], type: 'trigger-type' | 'authenticated-emails' | 'template-message'): { id: string, value: string}[] => {
    const formatted = [];

    if (type === 'authenticated-emails' && defaultCompanyEmail) {
      formatted.push({ 
        id: defaultCompanyEmail.id, 
        value: defaultCompanyEmail.email,
        icon: (
          <OverlayTrigger overlay={<Tooltip>{t('form.sender_support')}</Tooltip>}>
            <IconStar size={20} color="#4a69bd" />
          </OverlayTrigger>
        ),
        iconPosition: 'end'
      });
    }

    data.forEach(item => {
      if (type === 'trigger-type') {
        formatted.push({ id: item.id, value: item.name });
      } else if (type === 'authenticated-emails') {
        formatted.push({ id: item.id, value: item.email, icon: item.icon, iconPosition: 'end' });
      } else if (type === 'template-message') {
        const templateMessage = item as I_TemplateMessageWhatsapp;
        const [title, number] = templateMessage.title_and_number.split(' - ');

        const icons = { botIcon: null, enabledIcon: null, triageIcon: null, attachmentIcon: null };

        const botIcon = (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_has_bot')}</Tooltip>}>
            <IconRobotFace stroke={1.5} size={20} color={'#4a69bd'} />
          </OverlayTrigger>
        );

        const enabledIcon = (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_is_enabled')}</Tooltip>}>
            <IconCircleCheckFilled stroke={1.5} size={20} color={'#4a69bd'} />
          </OverlayTrigger>
        );

        const triageIcon = (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_has_triage')}</Tooltip>}>
            <IconListCheck stroke={1.5} size={20} color={'#4a69bd'} />
          </OverlayTrigger>
        );

        const attachmentIcon = (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_has_attachment')}</Tooltip>}>
            <IconPaperclip stroke={1.5} size={20} color={'#4a69bd'} />
          </OverlayTrigger>
        );

        if (templateMessage.is_bot_nlp) {
          icons.botIcon = botIcon;
        }
        if (templateMessage.bot_enable) {
          icons.enabledIcon = enabledIcon;
        }
        if (templateMessage.has_triage) {
          icons.triageIcon = triageIcon;
        }
        if (templateMessage.is_attachment_allowed) {
          icons.attachmentIcon = attachmentIcon;
        }
        
        const newItem = { id: templateMessage.id, value: `${title} (${number})` };

        if (icons.botIcon || icons.enabledIcon || icons.triageIcon) {
          newItem['icon'] = <>{icons.botIcon}{icons.enabledIcon}{icons.triageIcon}{icons.attachmentIcon}</>;
          newItem['iconPosition'] = 'end';
        }

        formatted.push(newItem);
      }
    });

    return formatted;
  }

  const getWhatsappAttachmentType = (attachmentType: string): { [key: string]: string[] } => {
    switch (attachmentType) {
      case 'icon': {
        return {
          'image/gif': ['.gif'],
          'image/png': ['.png'],
          'image/vnd.microsoft.icon': ['.ico'],
          'image/svg+xml': ['.svg'],
          'video/webm': ['.webm'],
        }
      }
      case 'image': {
        return {
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
        };
      }
      case 'video': {
        return {
          'video/mpeg': ['.mpeg'],
          'video/mp4': ['.mp4'],
        };
      }
      case 'document': {
        return {
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        };
      }
      default: {
        return { 
          'video/mpeg': ['.mpeg'],
          'video/mp4': ['.mp4'],
          'audio/mpeg': ['.mpeg', '.mp3'],
          'audio/aac': ['.aac'],
          'audio/amr': ['.amr'],
          'image/jpeg': ['.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
          'application/vnd.ms-powerpoint': ['.ppt'],
          'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        };
      }
    }
  }

  return (
    <ModalActiveTrigger 
      t={t}
      show={props.show}
      hide={props.hide}
      mode={mode}
      activeTrigger={activeTrigger}
      reloadOnClose={!!reloadOnClose.current}
      isValid={isValid}
      isValidDate={isValidDate}
      handleSubmit={handleSubmit}
      removeSelectedFile={removeSelectedFile}
      loadingActiveTrigger={loadingActiveTrigger}
      errorLoadingActiveTrigger={errorLoadingActiveTrigger}
      loadingRequest={loadingRequest}
      getTriggerTitleInput={getTriggerTitleInput}
      getTriggerTypesSelect={getTriggerTypesSelect}
      getChannelSelect={getChannelSelect}
      getDateInput={getDateInput}
      getSenderEmailsSelect={getSenderEmailsSelect}

      selectedFileRecipients={selectedFileRecipients}
      isLoadingUploadRecipients={isLoadingUploadRecipients}
      getInputProps={getInputProps}
      getRootProps={getRootProps}
      isDragging={isDragging}

      getTemplatesWhatsappSelect={getTemplatesWhatsappSelect}
      getPostAttendanceSelect={getPostAttendanceSelect}
      hasTemplateAttachment={hasTemplateAttachment}
      templateMimetypesAttachment={templateMimetypesAttachment}
      triggerTemplate={triggerTemplate}

      editorState={editorState}
      setEditorState={setEditorState}
      files={files}
      setFiles={setFiles}
      hasFileUploader={hasFileUploader}
      setHasFileUploader={setHasFileUploader}
      audioRecordedFiles={audioRecordedFiles}
      setAudioRecordedFiles={setAudioRecordedFiles}
      currentAudioInfo={currentAudioInfo}
      setCurrentAudioInfo={setCurrentAudioInfo}

      hasTriage={hasTriage}
      makeTriage={makeTriage}
      setMakeTriage={setMakeTriage}
    />
  );
};

export default ModalActiveTriggerController;
