import { IconChevronUp, IconPaperclip, IconPhoto, IconX } from '@tabler/icons-react';
import './styles.scss';
import { FC } from 'react';
import { ChatEditorInterface } from './indexModel';
import { Channels } from '../../../../../../core/enums/channels';

// ChatEditor
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/emoji/lib/plugin.css';
import ToolbarFileUploaderController from './components/toolbarFileUploader/indexController';
import ToolbarAudioRecorderController from './components/toolbarAudioRecorder/indexController';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PopupRecipientsEmailController from './components/popupRecipientsEmail/indexController';
import PopupTemplateMessagesController from './components/popupTemplateMessages/indexController';

const ChatEditorComponent: FC<ChatEditorInterface> = (props) => {
  const configMessageTypeOpenClass = props.showSelectMessageType ? 'chat-editor-config-message-type-opened' : '';
  const blockedSelectMessageTypeClass = props.blockSelectMessageType ? 'chat-editor-config-message-type-blocked' : '';
  const internalMessageClass = props.selectedMessageType.id === 'internal-note' ? 'chat-editor-internal-note' : '';
  const templateMessageClass = props.hasTemplateMessageSelected ? 'chat-editor-template-message' : '';
  const containerTypeClass = props.type === 'create-ticket' ? 'chat-editor-create-ticket' : '';
  const gridWithoutConfigClass = props.type === 'message-trigger' ? 'chat-editor-without-config' : '';
  const replyClass = props.replyMessage ? 'chat-editor-component-reply' : '';

  return (
    <div className={`chat-editor-component ${containerTypeClass} ${gridWithoutConfigClass} ${replyClass}`}>
      { props.replyMessage &&
        <div className="chat-editor-reply-info">
          <div className="chat-editor-reply-message">
            <span className="chat-editor-reply-message-author">
              {props.replyMessage.user_name}
            </span>
            <span className="chat-editor-reply-message-content">
              { props.replyMessage?.attachments?.length > 0 && 
                <IconPaperclip />
              }
              { props.formatReplyMessage(props.replyMessage.content)}
            </span>
          </div>
          <span className="chat-editor-reply-cancel">
            <IconX onClick={props.cancelReplyMessage} />
          </span>
        </div>
      }
      { props.type !== 'message-trigger' &&
        <div className="chat-editor-config">
          <div className="chat-editor-config-left">
            <div className="chat-editor-config-message-type" ref={props.chatEditorConfigRef}>
              {
                props.showSelectMessageType &&
                <div className="chat-editor-config-message-type-popup">
                  <p className="chat-editor-config-message-type-popup-header">
                    {props.t('select_message_type.popup_title')}
                  </p>
                  <div className="chat-editor-config-message-type-options-list">
                    <span className="chat-editor-config-message-type-option" onClick={() => props.selectMessageType(props.internalNote)}>
                      {props.internalNote.icon}
                      {props.internalNote.label}
                    </span>
                    <span className="chat-editor-config-message-type-option" onClick={() => props.selectMessageType(props.publicResponse)}>
                      {props.publicResponse.icon}
                      {props.publicResponse.label}
                    </span>
                  </div>
                </div>
              }
              <div className={`chat-editor-config-message-type-select ${configMessageTypeOpenClass} ${blockedSelectMessageTypeClass}`} onClick={() => props.openSelectMessageType()}>
                {
                  props.selectedMessageType &&
                  <span className="chat-editor-config-message-type-value">
                    {props.selectedMessageType.icon}
                    {props.selectedMessageType.id === 'internal-note' ? props.t('select_message_type.internal_note') : props.t('select_message_type.public_response')}
                  </span>
                }
                {
                  !props.selectedMessageType &&
                  <span className="chat-editor-config-message-type-value">
                    {props.t('select_message_type.empty_selected')}
                  </span>
                }
                <IconChevronUp className='chat-editor-config-arrow' />
              </div>
            </div>
            <div className="chat-editor-config-channel-info">
              {
                props.currentChannel === Channels.Email &&
                <>
                <span className="chat-editor-consumer-info-label">{props.t('editor_info.to_email')}</span>
                <span className="chat-editor-consumer-info-value">
                  { props.currentConsumer?.email && <span className="chat-editor-consumer-info-value-email">{props.currentConsumer?.email}</span> }
                  { !props.currentConsumer?.email && <span className="chat-editor-consumer-info-value-email">{props.t('editor_info.email_not_assigned')}</span> }
                  { props.quantityMoreEmails > 0 &&
                    <OverlayTrigger overlay={<Tooltip>{props.t('editor_info.show_recipients')}</Tooltip>}>
                      <span className="chat-editor-consumer-info-value-more-emails" onClick={props.handleShowMoreEmails}>+{props.quantityMoreEmails}</span>
                    </OverlayTrigger>
                  }
                </span>
                </>
              }
              {
                props.currentChannel === Channels.WhatsApp &&
                <>
                <span className="chat-editor-consumer-info-label">{props.t('editor_info.to_number')}</span>
                { props.currentConsumer?.whatsapp && <span className="chat-editor-consumer-info-value">{props.currentConsumer?.whatsapp}</span> }
                { !props.currentConsumer?.whatsapp && <span className="chat-editor-consumer-info-value">{props.t('editor_info.number_not_assigned')}</span> }
                </>
              }
            </div>
          </div>
          <div className="chat-editor-config-actions">
            { props.currentChannel === Channels.Email &&
              <PopupRecipientsEmailController 
                showPopupRecipientsEmail={props.showPopupRecipientsEmail} 
                setShowPopupRecipientsEmail={props.setShowPopupRecipientsEmail}
                listCC={props.listCC}
                setListCC={props.setListCC}
                listCCO={props.listCCO}
                setListCCO={props.setListCCO}
                type={props.type}
                createSelectedConsumer={props.currentConsumer}
              />
            }
            { props.templateMessageVisibility().popup &&
              <PopupTemplateMessagesController
                type={props.type}
                showPopupTemplateMessages={props.showPopupTemplateMessages} 
                setShowPopupTemplateMessages={props.setShowPopupTemplateMessages} 
                quickMessagesAllowed={props.templateMessageVisibility().quick}
                whatsappMessagesAllowed={props.templateMessageVisibility().whatsapp}
                handleClickTemplateMessage={props.handleClickTemplateMessage}
                selectedTemplateMessage={props.selectedTemplateMessage}
                handleClearTemplateMessage={props.handleClearTemplateMessage}
                consumer={props.currentConsumer}
              />
            }
          </div>
        </div>
      }
      { props.isDraftInitialized &&
        <div className="chat-editor-container">
          <div className="chat-editor-container-column">
          { !props.showFileUploader && !props.showAudioRecorder &&
            <div className={`chat-editor-field ${props.isEditorEmpty ? 'empty-editor': ''} ${internalMessageClass} ${templateMessageClass}`} onClick={props.focusEditor} onPaste={(e) => props.handleOnPaste(e)}>
              <Editor
                editorState={props.editorState}
                onChange={props.handleEditorChange}
                plugins={props.plugins}
                customStyleFn={props.customStyleFn}
                ref={props.chatEditorTextRef}
                handleKeyCommand={props.handleKeyCommand}
                keyBindingFn={props.keyBindingFn}
                placeholder={props.t(props.getChatPlaceholderI18nKey())}
                readOnly={props.hasTemplateMessageSelected || props.statusChatEditor?.blocked}
              />
            </div>
          }
          {
            !props.showFileUploader && props.showAudioRecorder &&
            <div className="show-recorder-field">
              <ToolbarAudioRecorderController 
                showAudioRecorder={props.showAudioRecorder} 
                handleShowAudioRecorder={props.handleShowAudioRecorder} 
                audioRecorderIconRef={props.audioRecorderIconRef}
                audioRecordedFiles={props.audioRecordedFiles}
                setAudioRecordedFiles={props.setAudioRecordedFiles}
                currentAudioInfo={props.currentAudioInfo}
                setCurrentAudioInfo={props.setCurrentAudioInfo}
              />
            </div>
          }
          {
            (props.showFileUploader || props.hasFileUploader || props.hasTemplateMessageAttachment) &&
            <div className="file-uploader-field">
              <ToolbarFileUploaderController 
                files={props.files}
                typeChat={props.type}
                setFiles={props.setFiles}
                showFileUploader={props.showFileUploader}
                setShowFileUploader={props.setShowFileUploader}
                hasFileUploader={props.hasFileUploader}
                setHasFileUploader={props.setHasFileUploader}
                attachmentIconRef={props.attachmentIconRef}
                filesPasted={props.filesPasted}
                setFilesPasted={props.setFilesPasted}
                audioRecordedFiles={props.audioRecordedFiles}
                setAudioRecordedFiles={props.setAudioRecordedFiles}
                hasTemplateMessageAttachment={props.hasTemplateMessageAttachment}
                selectedTemplateMessage={props.selectedTemplateMessage}
                createSelectedChannel={props.currentChannel}
                fileUploadSpecificTypes={props.fileUploadSpecificTypes}
                hasTriggerTemplate={props.hasTriggerTemplate}
                editorDisabled={props.statusChatEditor?.blocked}
              />
            </div>
          }
          </div>
          { (!props.statusChatEditor?.blocked || (props.type === 'view-ticket' && (props.selectedTemplateMessage.quick || props.selectedTemplateMessage.whatsapp))) &&
            <div className="chat-editor-field-actions">
              <div className="chat-editor-field-toolbar">
                {props.getToolbar()}
              </div>
              { props.type === 'view-ticket' &&
                <button
                  id="chat-editor-field-send" 
                  className="chat-editor-field-send" 
                  onClick={props.submit}
                  disabled={(props.isEditorEmpty && !props.hasFileUploader) || props.isLoadingFileUpload() || props.isLoadingRequest} 
                >
                  {props.t('editor_info.button_send')}
                </button>
              }
            </div>
          }
        </div>
      }
    </div>
  );
}

export default ChatEditorComponent;
