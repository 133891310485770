import './styles.scss';
import { FC } from 'react';
import { IconPhoneCheck, IconPhoneX } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReceivingCallInterface } from './indexModel';

const ReceivingCall: FC<ReceivingCallInterface> = (props) => {

  const infoNumberClass = props.context.callStatusEvent === 'in-call' ? 'number in-call-progress' : 'number';

  return (
    <div className="phoneReceivingCall">
      <div className="info">
        { props.context.callStatusDirection === 'outgoing' && <span className={infoNumberClass}>{props.context.callInfoDestiny || props.t('phone_callcenter.receiving_call.unknown_number')}</span>}
        { props.context.callStatusDirection === 'incoming' && <span className={infoNumberClass}>{props.context.callStatusIncomingNumber || props.t('phone_callcenter.receiving_call.unknown_number')}</span>}
        { props.context.callStatusEvent === 'in-call' && <span className="duration">{props.formatDuration(props.context.callStatusDuration)}</span>}
      </div>
      <div className="action">
        { 
          props.context.callStatusEvent === 'ringing' &&
          <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('phone_callcenter.receiving_call.answer')}</Tooltip>}>
            <span className="btnAction answer" onClick={() => props.accept()}><IconPhoneCheck color='#fff' /></span>
          </OverlayTrigger>
        }
        <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('phone_callcenter.receiving_call.hang_up')}</Tooltip>}>
          <span className="btnAction hangup" onClick={() => props.hangup()}><IconPhoneX color='#fff' /></span>
        </OverlayTrigger>
      </div>
    </div>
  );
}

export default ReceivingCall;
