import { useSelector } from "react-redux";
import MainHeader from ".";
import { getAppSidebarPage } from "../../../../store/app_sidebar";
import { useMainAppContext } from "../../../../core/context/main-app-context";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { getToken } from "../../../../store/token";
import { getOwner_id } from "../../../../store/callcenter";
import { getShowAcquirePlatformComponent } from "../../../../store/internal";

const MainHeaderController = () => {

  const currentPage = useSelector(getAppSidebarPage);
  const showAcquirePlatform = useSelector(getShowAcquirePlatformComponent);

  const { setHeaderRef, setHeaderHeight, currentTab, header } = useMainAppContext();
  const headerMainRef = useRef<HTMLDivElement>(null);
  const [showPhone, setShowPhone] = useState(false);

  const sessionToken =  useSelector(getToken);
  const callcenter_owner_id = useSelector(getOwner_id);

  useEffect(() => {
    setHeaderRef(headerMainRef);
    setHeaderHeight(headerMainRef?.current?.offsetHeight);
  }, [headerMainRef?.current?.offsetHeight]);

  useEffect(() => {
    if (sessionToken && callcenter_owner_id) {
      setShowPhone(true);
    } else {
      setShowPhone(false);
    }
  }, [sessionToken, callcenter_owner_id]);

  useLayoutEffect(() => {
    calculateHeight();
    
    window.addEventListener('resize', calculateHeight);

    return () => {
      window.removeEventListener('resize', calculateHeight);
    };

  }, []);

  const calculateHeight = () => {
    setHeaderHeight(headerMainRef?.current?.offsetHeight || 0);
  }

  const showSearch = (): boolean => {
    // const pagesWithSearch = [
    //   'tickets',
    //   'history_tickets',
    //   'history_chatbots',
    //   'consumers', 'consumer-tickets'
    //   // 'agents',
    //   // 'trigger',
    //   // 'configuration_tags',
    //   // 'configuration_account'
    // ];
    // return pagesWithSearch.includes(currentPage);
    return header?.hasSearchComponent || false;
  }

  const showCreateButton = (): boolean => {
    const pagesWithCreateButton = [
      'dashboard_summary',
      'tickets',
      'consumers',
      'agents',
      'trigger',
      'configuration_sectors',
      // 'configuration_account',
      // 'configuration_channels',
      // 'configuration_tickets',
      // 'configuration_chatbots',
      'configuration_tags',
      // 'configuration_custom_fields',
    ];

    const tabsWithCreateButton = [
      'configuration_account_sectors',
      'configuration_account_triage',
      'configuration_account_integration',
      'configuration_channels_email',
      'configuration_tickets_quick_message',
      'configuration_chatbots_whatsapp',
      'configuration_chatbots_webchat',
      'configuration_chatbots_facebook',
      'configuration_chatbots_instagram',
      'configuration_chatbots_voicebot',
      'configuration_tags_ticket',
      'configuration_tags_consumer',
      'configuration_custom_fields_ticket',
      'configuration_custom_fields_consumer',
    ];

    if (currentTab) {
      return pagesWithCreateButton.includes(currentPage) && tabsWithCreateButton.includes(currentTab);
    } else {
      return pagesWithCreateButton.includes(currentPage) && header?.hasCreateButton;
    }
  }

  return (
    <MainHeader 
      headerTitle={header?.headerTitle}
      headerSubtitle={header?.headerSubtitle}
      searchPage={header?.searchPage}
      showSearch={showSearch}
      showCreateButton={showCreateButton}
      headerMainRef={headerMainRef}
      showPhone={showPhone}
      showAcquirePlatform={showAcquirePlatform}
      customBackgroundColor={header?.customBackgroundColor}
    />
  );
}

export default MainHeaderController;