import { FC, useEffect, useState } from 'react';
import ModalDeleteTicket from './index';
import { ModalDeleteTicketControllerInterface } from './indexModel';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setShowAlertFeedback } from '../../../../../store/internal';
import emitter from '../../../../../core/shared/emitter';

const AppRequesterConst = new AppRequesterController();

const ModalDeleteTicketController: FC<ModalDeleteTicketControllerInterface> = (props) => {
  const { t } = useTranslation('ModalDeleteTicket');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ ticketId: string, ticketReferenceId: string }>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-delete-ticket', data => {
      setData(data);
    });

    return () => {
      emitter.off('open-modal-delete-ticket');
    }
  }, []);

  const handleSubmit = () => {
    deleteTicket();
  }

  const deleteTicket = () => {
    if (data) {
      const headers  = UserService.getHeaders();
      const ticket_id = `${data.ticketId}`;
      const ticket_reference_id = `${data.ticketReferenceId}`;

      AppRequesterConst.Delete(
        `/ticket/${ticket_id}`, { headers },
        () => {},
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully', { ticket_reference_id }), visibility: true, signalIcon: true }));
          props.hide(true, ticket_id);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingDelete
      );
    }
  }

  return (
    <ModalDeleteTicket
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingDelete={loadingDelete}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalDeleteTicketController;
