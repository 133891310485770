import './styles.scss';
import { FC } from 'react';
import { ModalDeleteTagInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../../components/loading2';
import { Trans } from 'react-i18next';

const ModalDeleteTag: FC<ModalDeleteTagInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-delete-tag-component"
    >
      <Modal.Header>
        { props.type === 'ticket' &&
          <Modal.Title>
            {props.mode === 'single' ? props.t('title_tag_ticket') : props.t('title_multi_tag_ticket')}
          </Modal.Title>
        }
        { props.type === 'consumer' &&
          <Modal.Title>
            {props.mode === 'single' ? props.t('title_tag_consumer') : props.t('title_multi_tag_consumer')}
          </Modal.Title>
        }
        <div className="modal-delete-tag-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingDelete) && <Loading blur={true}/>}
        { props.data && props.data.length === 1 &&
          <span className="modal-delete-tag-message">
            <Trans 
              i18nKey='ModalDeleteTag:message_single'
              values={{ tagName: props.data[0].name }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data && props.data.length > 1 &&
          <span className="modal-delete-tag-message">
            <Trans 
              i18nKey='ModalDeleteTag:message_multi'
              values={{ deletedCount: props.data.length }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-delete-tag-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-delete-tag-btn-delete' disabled={props.loadingDelete} onClick={() => props.handleSubmit()}>{props.t('btn_delete')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteTag;
