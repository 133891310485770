import './styles.scss';
import { FC } from 'react';
import { DashCounterComponentInterface } from './indexModel';
import { IconFileCheck, IconList, IconUserBolt, IconUserExclamation, IconUserQuestion } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DashCountersComponent: FC<DashCounterComponentInterface> = (props) => {
  const disabledClass = props.isLoading || props.isLoadingClick ? 'dash-counter-item-disabled' : '';
  const activeClass = 'dash-counter-item-active';

  return (
    <div className="dash-counters-component">
      { props.pageType === 'ticket-list' &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('all_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-all' ? activeClass : ''}`} onClick={() => props.handleClick('counter-all')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.allTickets)}</span>
              <IconList />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('all_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }
      
      { props.pageType === 'ticket-list' &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('your_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-user-assigned' ? activeClass : ''}`} onClick={() => props.handleClick('counter-user-assigned')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.yourTickets)}</span>
              <IconUserBolt />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('your_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }

      { props.pageType === 'ticket-list' &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('new_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-unassigned' ? activeClass : ''}`} onClick={() => props.handleClick('counter-unassigned')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.newTickets)}</span>
              <IconUserQuestion />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('new_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }

      { props.pageType === 'ticket-list' &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('unread_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-unread' ? activeClass : ''}`} onClick={() => props.handleClick('counter-unread')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.unreadTickets)}</span>
              <IconUserExclamation />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('unread_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }

      { props.pageType?.includes('history') &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('finished_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-finished' ? activeClass : ''}`} onClick={() => props.handleClick('counter-finished')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.finishedTickets)}</span>
              <IconFileCheck />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('finished_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }

      { props.pageType === 'history-general' &&
        <OverlayTrigger placement='bottom' delay={200} overlay={<Tooltip>{props.t('deleted_tickets_tooltip')}</Tooltip>}>  
          <div className={`dash-counter-item ${disabledClass} ${props.selected === 'counter-deleted' ? activeClass : ''}`} onClick={() => props.handleClick('counter-deleted')}>
            <div className="dash-counter-header">
              <span className="dash-counter-value">{props.isLoading ? '...' : props.formatValue(props.deletedTickets)}</span>
              <IconFileCheck />
            </div>
            <div className="dash-counter-body">
              <span className="dash-counter-label">{props.t('deleted_tickets')}</span>
            </div>
          </div>
        </OverlayTrigger>
      }
    </div>
  );
}

export default DashCountersComponent;