import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { CookieStorage } from 'redux-persist-cookie-storage'
import Cookies from 'cookies-js'
import storage from "redux-persist/lib/storage"
import { encryptTransform } from 'redux-persist-transform-encrypt';

import tokenReducer from './token'
import employeeReducer from './employee'
import companyReducer from './company'
import userReducer from './user'
import internalReducer from './internal'
import callcenterReducer from './callcenter';
import chatbotReducer from './chatbot';
import internalControlPanelReducer from './internal_controlpanel';
import userControlPanelReducer from './user_controlpanel';
import appSidebarReducer from './app_sidebar';
import tableSortOrderReducer from './table_sort_order';

const persistConfig = {
  key: 'root',
  storage,
  // storage: new CookieStorage(Cookies, {
  //   expiration: 86400,
  //   secure: true
  // }),
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_COOKIES_KEY,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
}

const rootReducer = combineReducers({ 
  token: tokenReducer, 
  company: companyReducer, 
  employee: employeeReducer, 
  user: userReducer, 
  internal: internalReducer, 
  callcenter: callcenterReducer, 
  chatbot: chatbotReducer,
  internal_controlpanel: internalControlPanelReducer,
  user_controlpanel: userControlPanelReducer,
  app_sidebar: appSidebarReducer,
  table_sort_order: tableSortOrderReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const returnElement = () => {
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)
  return { store, persistor }
}

export default returnElement;
