import './styles.scss';
import { FC } from 'react';
import { ModalDeleteTaskInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import { Trans } from 'react-i18next';

const ModalDeleteTask: FC<ModalDeleteTaskInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      size="sm"
      centered={true}
      dialogClassName="modal-delete-task-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-delete-task-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingDelete) && <Loading blur={true}/>}
        { props.data &&
          <div className="modal-delete-task-info">
            <span className="modal-delete-task-info-message">
              {props.t('message')}
            </span>
            <div className="modal-delete-task-info-fields">
              <div className="modal-delete-task-info-fields-row">
                <div className="modal-delete-task-info-fields-item">
                  <span className="modal-delete-task-info-fields-item-label">
                    {props.t('labels.date_limit_label')}
                  </span>
                  <span className="modal-delete-task-info-fields-item-value">
                    {props.formatDateLimit(props.data.date_limit)}
                  </span>
                </div>
                <div className="modal-delete-task-info-fields-item">
                  <span className="modal-delete-task-info-fields-item-label">
                    {props.t('labels.status_label')}
                  </span>
                  <span className="modal-delete-task-info-fields-item-value">
                    {props.getStatusName(props.data.status)}
                  </span>
                </div>
              </div>
              <div className="modal-delete-task-info-fields-item">
                <span className="modal-delete-task-info-fields-item-label">
                  {props.t('labels.description_label')}
                </span>
                <span className="modal-delete-task-info-fields-item-value info-fields-item-overflow">
                  {props.data.description}
                </span>
              </div>
              <div className="modal-delete-task-info-fields-row">
                <div className="modal-delete-task-info-fields-item">
                  <span className="modal-delete-task-info-fields-item-label">
                    {props.t('labels.requester_agent_label')}
                  </span>
                  <OverlayTrigger overlay={<Tooltip>{props.data.requesterName}</Tooltip>}>
                    <span className="modal-delete-task-info-fields-item-value">
                      {props.data.requesterName}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="modal-delete-task-info-fields-item">
                  <span className="modal-delete-task-info-fields-item-label">
                    {props.t('labels.assigned_agent_label')}
                  </span>
                  <OverlayTrigger overlay={<Tooltip>{props.data.assignedName}</Tooltip>}>
                    <span className="modal-delete-task-info-fields-item-value">
                      {props.data.assignedName}
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-delete-task-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-delete-task-btn-save' disabled={props.loadingDelete} onClick={() => props.handleSubmit()}>{props.t('btn_delete')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteTask;
