import { FC } from 'react';
import './styles.scss';
import { CustomSelectInterface } from './indexModel';
import { IconChevronDown, IconPlus, IconX } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from '../loading2';
import ReactDOM from 'react-dom';

const CustomSelect: FC<CustomSelectInterface> = (props) => {
  const disabledStyle = props.disabled ? 'select-paginated-disabled' : '';
  const invalidStyleClass = props.required && !props.hasSelected ? 'select-paginated-main-invalid' : '';
  const selectLoadingClass = props.loading && !props.isOpened ? 'select-paginated-main-loading' : '';

  return (
    <div id={`select-paginated-component-${props.selectUniqueId}`} className='select-paginated-component' ref={props.selectRef}>
      <div className={`select-paginated-main ${disabledStyle} ${invalidStyleClass} ${selectLoadingClass}`} onClick={() => !props.disabled && props.setIsOpened(prev => !prev)}>
        <div className="select-paginated-content-selected">
          { !props.hasSelected && props.placeholder && (!props.loading || props.isOpened) && <span className='select-paginated-placeholder'>{props.placeholder}</span> }
          { !props.hasSelected && props.loading && !props.isOpened && <span className='select-paginated-placeholder'>{props.t('loading')}</span> }
          { props.hasSelected && props.showTooltipSelected &&
            <OverlayTrigger placement={props.tooltipSelectedPosition} overlay={<Tooltip id='tooltip'>{props.renderSelected(true)}</Tooltip>}>
              <span>{props.renderSelected()}</span>
            </OverlayTrigger>
          }
          { props.hasSelected && !props.showTooltipSelected &&
            <span>{props.renderSelected()}</span>
          }
        </div>
        {
          !props.disabled &&
          <div className="select-paginated-icon-action">
            { !props.isOpened && props.loading && <span className="circle-loader"></span> }
            { props.hasSelected && !props.isOpened && !props.required &&
              <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.t('clear_selection')}</Tooltip>}>
                <IconX className='select-action-clear' onClick={() => props.clearSelected()} />
              </OverlayTrigger>
            }
            <IconChevronDown className={`select-paginated-icon-chevron-down ${props.isOpened ? 'icon-opened' : ''}`} />
          </div>
        }
      </div>
      { props.dropdownPosition === 'default' &&
        <div className="select-paginated-container" ref={props.popupRef}>
        {
          !props.disabled && props.isOpened &&
          <div className="select-paginated-items-popup" style={props.selectPopupStyle} id={`select-paginated-items-popup-${props.selectUniqueId}`}>
            {
              props.hasSelected && props.isMultiselect &&
              <div className="select-paginated-popup-selected-tags">
                {props.selectedMulti.map((item, index) => {
                  return (
                    <div key={`tag_${item.id}_${index}`} className="selected-paginated-popup-selected-item">
                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{item.value}</Tooltip>}>
                        <span className="selected-paginated-popup-selected-item-value">
                          {item.value}
                        </span>
                      </OverlayTrigger>
                      <IconX className='select-paginated-popup-selected-item-clear' onClick={() => props.selectItem(item)} />
                    </div>
                  );
                })}
              </div>
            }
            { props.hasFilter && props.type === 'pagination' &&
              <div className="select-paginated-popup-filter">
                <input type="text" value={props.searchTerm || ''} placeholder={props.placeholderFilter || ''} onChange={props.handleSearchChange} ref={props.inputSearchRef} onKeyDown={(e) => props.handleEnterSearch(e)} />
                <div className="select-paginated-popup-filter-actions">
                  {
                    props.isFiltered &&
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.t('clear_search')}</Tooltip>}>
                      <IconX className='select-paginated-popup-filter-clear' onClick={() => props.handleClearSearch()} />
                    </OverlayTrigger>
                  }
                  {
                    props.hasAction &&
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.tooltipAction}</Tooltip>}>
                      <IconPlus onClick={() => props.handleActionClick()} />
                    </OverlayTrigger>
                  }
                </div>
              </div>
            }
            {
              props.hasSimpleFilter &&
              <div className="select-paginated-popup-filter">
                <input type="text" value={props.searchTerm || ''} placeholder={props.placeholderFilter || ''} onChange={props.handleSimpleFilter} ref={props.inputSearchRef} />
                <div className="select-paginated-popup-filter-actions">
                  {
                    props.searchTerm?.length > 0 &&
                    <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.t('clear_search')}</Tooltip>}>
                      <IconX className='select-paginated-popup-filter-clear' onClick={() => props.handleClearSearch()} />
                    </OverlayTrigger>
                  }
                </div>
              </div>
            }
            { props.loading && <Loading blur={true} /> }
            {
              props.options?.length > 0 && props.type === 'pagination' &&
              <div className="select-paginated-popup-list" id="select-paginated-popup-list">
                <InfiniteScroll
                  dataLength={props.options.length}
                  next={props.fetchMore}
                  hasMore={props.hasMore}
                  scrollableTarget="select-paginated-popup-list"
                  scrollThreshold="10px"
                  loader={null}
                >
                  {props.options.map((option, index) => {
                    const optionSelectedClass = props.getSelectedOptionClass(option?.id);
                    const optionSystemClass = option.isOptionSystem ? 'selected-paginated-option-system' : '';

                    if (props.showTooltipOptions) {
                      return (
                        <OverlayTrigger key={`select_option_${option.id}_${index}`} placement={props.tooltipOptionsPosition || 'auto'} overlay={<Tooltip>{option?.value}</Tooltip>}>
                          <div className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                            { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                            <span>{option?.value}</span>
                            { !!option?.icon && option.iconPosition === 'end' && option.icon }
                          </div>
                        </OverlayTrigger>
                      );
                    } else {
                      return (
                        <div key={`select_option_${option.id}_${index}`} className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                          { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                          <span>{option?.value}</span>
                          { !!option?.icon && option.iconPosition === 'end' && option.icon }
                        </div>
                      );
                    }
                  })}
                </InfiniteScroll>
              </div>
            }
            {
              props.options?.length > 0 && props.type === 'simple' && 
              <div className="select-paginated-popup-list" id="select-paginated-popup-list">
                {props.options.map((option, index) => {
                  const optionSelectedClass = props.getSelectedOptionClass(option?.id);
                  const optionSystemClass = option.isOptionSystem ? 'selected-paginated-option-system' : '';

                  if (props.showTooltipOptions) {
                    return (
                      <OverlayTrigger key={`select_option_${option.value}_${index}`} placement={props.tooltipOptionsPosition || 'auto'} overlay={<Tooltip>{option?.value}</Tooltip>}>
                        <div className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                          { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                          <span>{option?.value}</span>
                          { !!option?.icon && option.iconPosition === 'end' && option.icon }
                        </div>
                      </OverlayTrigger>
                    );
                  } else {
                    return (
                      <div key={`select_option_${option.value}_${index}`} className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                        { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                        <span>{option?.value}</span>
                        { !!option?.icon && option.iconPosition === 'end' && option.icon }
                      </div>
                    );
                  }
                })}
              </div>
            }
            {
              (props.options?.length === 0 || !props.options) &&
              <span className="select-paginated-popup-empty-list">{props.t('empty_list')}</span>
            }
          </div>
        }
        </div>
      }
      { props.dropdownPosition === 'body' &&
        <div className="select-paginated-container" >
          {
            !props.disabled && props.isOpened &&
            ReactDOM.createPortal(
            <div className="select-paginated-items-popup" style={props.selectPopupStyle} ref={props.popupRef} id={`select-paginated-items-popup-${props.selectUniqueId}`}>
              {
                props.hasSelected && props.isMultiselect &&
                <div className="select-paginated-popup-selected-tags">
                  {props.selectedMulti.map((item, index) => {
                    return (
                      <div key={`tag_${item.id}_${index}`} className="selected-paginated-popup-selected-item">
                        <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{item.value}</Tooltip>}>
                          <span className="selected-paginated-popup-selected-item-value">
                            {item.value}
                          </span>
                        </OverlayTrigger>
                        <IconX className='select-paginated-popup-selected-item-clear' onClick={() => props.selectItem(item)} />
                      </div>
                    );
                  })}
                </div>
              }
              { props.hasFilter && !props.hasSimpleFilter && props.type === 'pagination' &&
                <div className="select-paginated-popup-filter">
                  <input type="text" value={props.searchTerm || ''} placeholder={props.placeholderFilter || ''} onChange={props.handleSearchChange} ref={props.inputSearchRef} onKeyDown={(e) => props.handleEnterSearch(e)} />
                  <div className="select-paginated-popup-filter-actions">
                    {
                      props.isFiltered &&
                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.t('clear_search')}</Tooltip>}>
                        <IconX className='select-paginated-popup-filter-clear' onClick={() => props.handleClearSearch()} />
                      </OverlayTrigger>
                    }
                    {
                      props.hasAction &&
                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.tooltipAction}</Tooltip>}>
                        <IconPlus onClick={() => props.handleActionClick()} />
                      </OverlayTrigger>
                    }
                  </div>
                </div>
              }
              { props.hasSimpleFilter &&
                <div className="select-paginated-popup-filter">
                  <input type="text" value={props.searchTerm || ''} placeholder={props.placeholderFilter || ''} onChange={props.handleSimpleFilter} ref={props.inputSearchRef} />
                  <div className="select-paginated-popup-filter-actions">
                    {
                      props.searchTerm?.length > 0 &&
                      <OverlayTrigger placement='bottom' overlay={<Tooltip id='tooltip'>{props.t('clear_search')}</Tooltip>}>
                        <IconX className='select-paginated-popup-filter-clear' onClick={() => props.handleClearSearch()} />
                      </OverlayTrigger>
                    }
                  </div>
                </div>
              }
              { props.loading && <Loading blur={true} /> }
              {
                props.options?.length > 0 && props.type === 'pagination' &&
                <div className="select-paginated-popup-list" id="select-paginated-popup-list">
                  <InfiniteScroll
                    dataLength={props.options.length}
                    next={props.fetchMore}
                    hasMore={props.hasMore}
                    scrollableTarget="select-paginated-popup-list"
                    scrollThreshold="10px"
                    loader={null}
                  >
                    {props.options.map((option, index) => {
                      const optionSelectedClass = props.getSelectedOptionClass(option?.id);
                      const optionSystemClass = option.isOptionSystem ? 'selected-paginated-option-system' : '';

                      if (props.showTooltipOptions) {
                        return (
                          <OverlayTrigger key={`select_option_${option.id}_${index}`} placement={props.tooltipOptionsPosition || 'auto'} overlay={<Tooltip>{option?.value}</Tooltip>}>
                            <div className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                              { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                              <span>{option?.value}</span>
                              { !!option?.icon && option.iconPosition === 'end' && option.icon }
                            </div>
                          </OverlayTrigger>
                        );
                      } else {
                        return (
                          <div key={`select_option_${option.id}_${index}`} className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                            { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                            <span>{option?.value}</span>
                            { !!option?.icon && option.iconPosition === 'end' && option.icon }
                          </div>
                        );
                      }
                    })}
                  </InfiniteScroll>
                </div>
              }
              {
                props.options?.length > 0 && props.type === 'simple' && 
                <div className="select-paginated-popup-list" id="select-paginated-popup-list">
                  {props.options.map((option, index) => {
                    const optionSelectedClass = props.getSelectedOptionClass(option?.id);
                    const optionSystemClass = option.isOptionSystem ? 'selected-paginated-option-system' : '';

                    if (props.showTooltipOptions) {
                      return (
                        <OverlayTrigger key={`select_option_${option.value}_${index}`} placement={props.tooltipOptionsPosition || 'auto'} overlay={<Tooltip>{option?.value}</Tooltip>}>
                          <div className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                            { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                            <span>{option?.value}</span>
                            { !!option?.icon && option.iconPosition === 'end' && option.icon }
                          </div>
                        </OverlayTrigger>
                      );
                    } else {
                      return (
                        <div key={`select_option_${option.value}_${index}`} className={`select-paginated-option ${optionSelectedClass} ${optionSystemClass}`.trim()} onClick={() => props.selectItem(option)}>
                          { !!option?.icon && option.iconPosition !== 'end' && option.icon }
                          <span>{option?.value}</span>
                          { !!option?.icon && option.iconPosition === 'end' && option.icon }
                        </div>
                      );
                    }
                  })}
                </div>
              }
              {
                (props.options?.length === 0 || !props.options) &&
                <span className="select-paginated-popup-empty-list">{props.t('empty_list')}</span>
              }
            </div>, document.body)
          }
        </div>
      }
    </div>
  );
}

export default CustomSelect;