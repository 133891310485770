import './styles.scss';
import { FC } from 'react';
import { CreateButtonInterface } from './indexModel';
import { IconPlus } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const CreateButton: FC<CreateButtonInterface> = (props) => {

  if (props.tooltip) {
    return (
      <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.tooltip}</Tooltip>}>
        <button className="create-button-component" onClick={props.action} disabled={props.disabled}>
          <IconPlus />
          <span className="create-button-text">{props.text}</span>
        </button>
      </OverlayTrigger>
    );
  } else {
    return (
      <button className="create-button-component" onClick={props.action} disabled={props.disabled}>
        <IconPlus />
        <span className="create-button-text">{props.text}</span>
      </button>
    );
  }
}

export default CreateButton;