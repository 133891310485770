import './styles.scss';
import { FC } from 'react';
import { ModalChangeStatusTriggerTypeInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import { Trans } from 'react-i18next';

const ModalChangeStatusTriggerType: FC<ModalChangeStatusTriggerTypeInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-change-status-trigger-type-component"
    >
      <Modal.Header>
        { props.data &&
          <Modal.Title>
            {props.data?.newStatus ? props.t('title_activate') : props.t('title_inactivate')}
          </Modal.Title>
        }
        <div className="modal-change-status-trigger-type-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingChange) && <Loading blur={true}/>}
        { props.data &&
          <span className="modal-change-status-trigger-type-message">
            <Trans 
              i18nKey={props.data.newStatus ? 'ModalChangeStatusTriggerType:message_activate' : 'ModalChangeStatusTriggerType:message_inactivate'}
              values={{ triggerTypeName: props.data.triggerTypeName }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-change-status-trigger-type-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        { props.data && props.data.newStatus && 
          <button className='modal-change-status-trigger-type-btn-activate' disabled={props.loadingChange} onClick={() => props.handleSubmit()}>{props.t('btn_activate')}</button>
        }
        { props.data && !props.data?.newStatus && 
          <button className='modal-change-status-trigger-type-btn-inactivate' disabled={props.loadingChange} onClick={() => props.handleSubmit()}>{props.t('btn_inactivate')}</button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeStatusTriggerType;
