import './styles.scss';
import { FC } from "react";
import { IconX, IconEye, IconEyeClosed } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CallcenterLoginFormInterface } from './indexModel';

const CallcenterLoginForm: FC<CallcenterLoginFormInterface> = (props) => {

  return (
    <div className="phoneCallcenterLogin" id="callcenter-content-component">
      <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('phone_callcenter.login_form.close')}</Tooltip>}>
        <span className="phoneCallcenterLogin-close" onClick={props.closeLoginForm}>
          <IconX size={18} color="#707070" stroke={2} />
        </span>
      </OverlayTrigger>
      { !props.callcenterCtx.scriptLoading && props.callcenterCtx.scriptLoaded &&
      <>
      <span className="loginInfo">
        {props.t('phone_callcenter.login_form.not_connected')}
      </span>
      <div className="formInfo">
        <div className="item">
          <span>{props.t('phone_callcenter.login_form.terminal')}</span>
          <input className='form-control' disabled={true} value={props.callcenterCtx.sessionCredentials.username} />
        </div>
        <div className="item">
          <span>{props.t('phone_callcenter.login_form.password')}</span>
          <div className="input">
            {
              props.showPassword ? 
              <input readOnly={true} className="form-control" type="text" disabled={true} value={props.callcenterCtx.sessionCredentials.password}></input>
              :
              <input readOnly={true} className="form-control" type="password" disabled={true} value={props.callcenterCtx.sessionCredentials.password}></input>
            }
            {
              props.showPassword ? 
              <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('phone_callcenter.login_form.hide_password')}</Tooltip>}>
                <span className="inputIconEye" onClick={() => props.setShowPassword(false)}><IconEyeClosed size={22} color="#707070" /></span>
              </OverlayTrigger>
              :
              <OverlayTrigger placement="top" overlay={<Tooltip>{props.t('phone_callcenter.login_form.show_password')}</Tooltip>}>
                <span className="inputIconEye" onClick={() => props.setShowPassword(true)}><IconEye size={22} color="#707070" /></span>
              </OverlayTrigger>
            }
          </div>
        </div>
        <button onClick={props.loginExtension} className="buttonBlue btn btn-primary">{props.t('phone_callcenter.login_form.connect')}</button>
      </div>
      </>
      }
      { props.callcenterCtx.scriptLoading &&
        <span className="loginInfo">{props.t('phone_callcenter.login_form.script_loading')}</span>
      }
      { !props.callcenterCtx.scriptLoading && !props.callcenterCtx.scriptLoaded &&
        <span className="loginInfo">{props.t('phone_callcenter.login_form.script_not_loaded')}</span>
      }
    </div>
  );
}

export default CallcenterLoginForm;