import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getLanguageCompany } from "../../../../store/company";
import { getLanguageEmployee } from "../../../../store/employee";
import { setShowAlertFeedback } from "../../../../store/internal";
import ModalSelectLanguage from ".";
import { useTranslation } from "react-i18next";
import UserService from "../../../../services/user-service";
import { ModalSelectLanguageControllerInterface } from "./indexModel";

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { es } from 'date-fns/locale/es';
import { enUS } from 'date-fns/locale/en-US';
import { fr } from 'date-fns/locale/fr';
import { ptBR } from 'date-fns/locale/pt-BR';

registerLocale('es', es);
registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('pt-BR', ptBR);
registerLocale('pt-PT', ptBR);

const ModalSelectLanguageController: FC<ModalSelectLanguageControllerInterface> = ({ 
  isLogged,
  logoutBySetLanguage,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const AppRequesterConst = new AppRequesterController()

  const values: TokenInterface = {
    company: {
      platform_language: useSelector(getLanguageCompany),
    },
    employee: {
      employee_language: useSelector(getLanguageEmployee),
    },
  };

  const languages = [
    { language: 'pt-br', flag: 'br', title: 'Português (BR)' },
    { language: 'pt-pt', flag: 'pt', title: 'Português (PT)' },
    { language: 'es', flag: 'es', title: 'Español' },
    { language: 'en', flag: 'gb', title: 'English' },
    { language: 'fr', flag: 'fr', title: 'Français' },
  ];

  // Quando for adicionado o idioma no arquivo de configuração i18next.tsx, adicionar o language nesse array
  const i18nAvailable = ['pt-br', 'pt-pt', 'es', 'en', 'fr'];

  const currentLngStorage = localStorage.getItem('i18nextLng');
  
  const [validated] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [language, setLanguage] = useState(values.employee?.employee_language || values.company?.platform_language || currentLngStorage  || '');
  const [showModal, setShowModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(values.employee?.employee_language || values.company?.platform_language || currentLngStorage || '');

  useEffect(() => {
    if (language.toLocaleLowerCase() === 'br') {
      i18n.changeLanguage('pt-BR');
      localStorage.setItem("i18nextLng", 'pt-BR');
    } else if (language.toLowerCase() === 'pt') {
      i18n.changeLanguage('pt-PT');
      localStorage.setItem("i18nextLng", 'pt-PT');
    } else if (language.toLowerCase() === 'en') {
      i18n.changeLanguage('en');
      localStorage.setItem("i18nextLng", 'en');
    } else if (language.toLowerCase() === 'fr') {
      i18n.changeLanguage('fr');
      localStorage.setItem("i18nextLng", 'fr');
    } else {
      i18n.changeLanguage(language?.toLowerCase() || currentLngStorage);
      localStorage.setItem('i18nextLng', language?.toLowerCase() || currentLngStorage);
    }
    setDefaultLocale(language || 'pt-BR');
  }, [language]);

  const closeModal = (submited?: boolean) => {
    setShowModal(false);
    setLanguage(submited ? language : currentLanguage);
  }

  const onSubmit = async () => {
    if (isLogged && language !== currentLanguage) {
      const headers = UserService.getHeaders()
      const payload = {
        employee_language: language
      }
      
      await AppRequesterConst.Patch(
        '/agent/update/employee-language', payload, { headers },
        (response: Object) => {
          return response;
        },
        (data: { data: { user_id: string; }; }) => {
          logoutBySetLanguage();
          dispatch(setShowAlertFeedback({ message: t("components.modal_language.sucess_put"), visibility: true, signalIcon: true }));
          closeModal();
        },
        (error) => {
          dispatch(setShowAlertFeedback({ message: t("components.modal_language.put_error"), visibility: true, signalIcon: false }));
        }, navigate, dispatch, setIsLoading
      );
    } else {
      setCurrentLanguage(language);
      closeModal(true);
    }
  }

  const titleLanguage = () => {
    const current_language = isLogged ? values.employee.employee_language : language;
    switch (current_language) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      case 'fr':
        return 'Français';
      case 'pt-pt':
        return 'Português (PT)';
      default:
        return 'Português (BR)';
    }
  }

  const iconLanguage = () => {
    const current_language =  isLogged ? values.employee.employee_language : language;
    switch (current_language) {
      case 'en':
        return 'gb';
      case 'es':
        return 'es';
      case 'fr':
        return 'fr';
      case 'pt-pt':
        return 'pt';
      default:
        return 'br';
    }
  }
    
  const getAvailableLanguages = () => {
    const available = languages.filter(item => i18nAvailable.includes(item.language));

    if (isLogged) {
      const userLanguage = available.find(item => item.language === values.employee.employee_language);
      const ordered = userLanguage ? [userLanguage] : [];
  
      available.sort((a, b) => a.title.localeCompare(b.title)).forEach((item) => {
        if (item.language !== values.employee.employee_language) {
          ordered.push(item);
        }
      });
  
      return ordered;
    } else {
      const ordered = available.sort((a, b) => a.title.localeCompare(b.title));
      return ordered;
    }

  }

  const isSelectedLanguage = (employee_language: string, language: string): boolean => {
    return employee_language?.toLocaleLowerCase() === language?.toLowerCase();
  }

  return <ModalSelectLanguage
    t={t}
    closeModal={closeModal}
    validated={validated}
    language={language}
    setLanguage={setLanguage}
    onSubmit={onSubmit}
    showModal={showModal}
    setShowModal={setShowModal}
    titleLanguage={titleLanguage}
    iconLanguage={iconLanguage}
    getAvailableLanguages={getAvailableLanguages}
    isSelectedLanguage={isSelectedLanguage}
    isLoading={isLoading}
    isLogged={isLogged}
  />
}

export default ModalSelectLanguageController;
