import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, useEffect, useState } from 'react';
import AudioPlayer from './index';
import { AudioPlayerControllerInterface } from './indexModel';

const AudioPlayerController: FC<AudioPlayerControllerInterface> = (props) => {
  const { t } = useTranslation('AudioPlayer');
  const [audio, setAudio] = useState<HTMLAudioElement>(null);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [showVolume, setShowVolume] = useState(false);

  useEffect(() => {
    if (props.currentUrlPlaying !== props.audioUrl) {
      audio?.pause();
      setIsPlaying(false);
    }
  }, [props.currentUrlPlaying, props.audioUrl]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const newAudio = new Audio(props.audioUrl);
      newAudio.volume = volume;
  
      if (newAudio) {
        newAudio.addEventListener('loadedmetadata', () => {
          if (newAudio.duration !== Infinity) {
            setDuration(newAudio.duration);
          } else {
            setDuration(props.duration || 0);
          }
        });
  
        newAudio.addEventListener('timeupdate', () => {
          setCurrentTime(newAudio.currentTime);
        });
  
        newAudio.addEventListener('ended', () => {
          setCurrentTime(0);
          setIsPlaying(false);
        });
  
        newAudio.addEventListener('progress', () => {
        });
      }
  
      setAudio(newAudio);
    }

    return () => {
      isMounted = false;
    }
  }, []);

  const handlePlayPause = () => {
    if (audio) {
      if (isPlaying) {
        audio.pause();
        props.setCurrentAudioPlayingUrl(null);
      } else {
        audio.play();
        props.setCurrentAudioPlayingUrl(props.audioUrl);
      }
      setIsPlaying(!isPlaying);
    }
  }

  const handleProgressChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (audio) {
      const newTime = Number(event.target.value);
      audio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = Number(event.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
    setAudio(audio);
  };

  const handleCloseVolume = (event: React.MouseEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setShowVolume(false);
    }, 300);
  }

  const getFileInfo = (filename: string): { name: string, extension: string } => {
    const splitted = filename.split('.');
    const extension = `.${splitted.pop()}`;
    return {
      name: splitted.join('.'),
      extension
    };
  }

  const handleDownloadFile = () => {
    if (props.audioUrl) {
      const link = document.createElement('a');
      link.href = props.audioUrl;
      link.setAttribute('download', props.filename);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  return (
    <AudioPlayer 
      t={t}
      filename={props.filename}
      duration={duration}
      currentTime={currentTime}
      isPlaying={isPlaying}
      volume={volume}
      showVolume={showVolume}
      setShowVolume={setShowVolume}
      handlePlayPause={handlePlayPause}
      handleProgressChange={handleProgressChange}
      handleVolumeChange={handleVolumeChange}
      getFileInfo={getFileInfo}
      handleDownloadFile={handleDownloadFile}
      handleCloseVolume={handleCloseVolume}
    />
  );
};

export default AudioPlayerController;
