import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PhoneCallcenterController from '../../indexController';
import './styles.scss';
import { PhoneButtonInterface } from './indexModel';
import { IconPhone } from '@tabler/icons-react';

const PhoneButton: FC<PhoneButtonInterface> = (props) => {
  
  return (
    <div className="phone-button-component">
      <OverlayTrigger placement="left" overlay={<Tooltip>{props.getTooltip()}</Tooltip>}>
        <span className={`phone-button-component-trigger ${props.getPhoneClass()}`} onClick={() => props.show()}>
          <IconPhone />
        </span>
      </OverlayTrigger>
      <PhoneCallcenterController showPopup={props.showPopup} show={props.show} />
    </div>
  );
}

export default PhoneButton;