import { useNavigate } from "react-router-dom";
import HomeSummary from ".";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import UserService from "../../../../services/user-service";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { DataCompanyInterface, DataTagIdInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
/* VALORES */
import { getIdCompany, getEnableSummary } from '../../../../store/company';
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user';
import { getToken } from '../../../../store/token';
import { getShowRegisterSummary, setShowRegisterSummary } from "../../../../store/internal";
import constsRouters from "../../../../routes/constsRouter";
import { getLanguageEmployee } from "../../../../store/employee";
import BackendConstants from "../../../../core/constants/backend-constants";
import { useMainAppContext } from "../../../../core/context/main-app-context";
import { useTranslation } from "react-i18next";

const AppRequesterConst = new AppRequesterController();

const HomeSummaryController = () => {

    const values: TokenInterface = {
        
        company: {
            id: useSelector(getIdCompany),
            configs: {
                enable_summary: useSelector(getEnableSummary)
            }
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowRegisterSummary: useSelector(getShowRegisterSummary)
        },
        employee: {
            employee_language: useSelector(getLanguageEmployee),
        },

    };

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { setMainHeader } = useMainAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [currentParams, setCurrentParams] = useState({});
    const [currentState, setCurrentState] = useState("");
    const [summaries, setSummaries] = useState([]);
    const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true);
    const [currentLimit, /*SetCurrentLimit*/] = useState(BackendConstants.limitMin);


    const [selectedSummary, setSelectedSummary] = useState([]);
    const [modalShow, setModalShow] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [aux, setAux] = useState([]);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);

    const [showSummaryFail, setShowSummaryFail] = useState(false);

    useEffect(() => {
        setMainHeader({
            pageTitle: t('summary_dashboard.title_summary'),
            pageSubtitle: t('summary_dashboard.subtitle_summary'),
            hasSearchComponent: false,
            hasSearchInput: false,
            hasSearchFilter: false,
            searchPage: null,
            searchPlaceholder: null,
            searchFunction: null,
            clearSearchFunction: null,
            hasCreateButton: true,
            createButtonDisabled: !values.company.configs.enable_summary,
            createButtonText: t('summary_dashboard.btn_create'),
            createButtonTooltip: values.company.configs.enable_summary ? t('summary_dashboard.register_summary_tooltip') : t('summary_dashboard.register_summary_modal.warning_no_register_summary'),
            createButtonAction: values.company.configs.enable_summary ? showRegisterSummaryModal : () => {},
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController();

        if (values.token.value === null) {
        } else {
            // let summaryParams
            // summaryParams = { limit: currentLimit, page: currentPage };
            setCurrentParams((params) => {
                
                setCurrentState((state) => {
                    getAllSummariesToPagination(Object.assign(currentParams), controller);
                    return state;
                });
                return params;
            });
        }
        return () => {
            controller.abort();
        }
    }, [currentState, currentPage]);


    useEffect(() => {
        setModalShow(false);

        if(values.internal.getShowRegisterSummary) {
            dispatch(setShowRegisterSummary(false))
        }
    }, [])

    const showRegisterSummaryModal = () => {
        dispatch(setShowRegisterSummary(true))
    }

    const getAllSummariesToPagination = (params: any, controller: AbortController) => {
        getAllSummaries(params, controller)
    }

    const getAllSummaries = async (params: any, controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller.signal;
        
        await AppRequesterConst.Get(
            '/summary', { headers, signal, params: params },
            (response: Object) => {  },
            (data) => {                
                if (data.status === 200 && data) {
                    setCurrentHasMoreInformation(true);
                    setSummaries(data.data.summaries);
                        
                    } else {
                        setSummaries([]);
                    }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }
    
    /* FILTRO */

    const search = (e: any, auxObj: Object, action: string) => {
        setCurrentPage(1);
        setAux([]);

        let name = "";
        if (e.currentTarget) {
            name = e.currentTarget.name;
        } else if (e.target) {
            name = e.target.name;
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("");
            auxObj = {};
            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }
        } else if (name === "searchButtonName" || name === "searchInputName") {
            auxObj = {};

            setCurrentState("searching");
            if (currentState === "searching") {
                setCurrentState("");
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch;
            }
        } else {
            setCurrentState("filtering");

            if (currentState === "filtering") {
                setCurrentState("");
            }
        }

        setCurrentParams(auxObj);
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    /* ordenar por data inicial */

    const callbackOrderBy = (column: any, order: any) => {
        setCurrentPage(1);
        setAux([]);
        const auxObj = {};


        if (column != null) {
            auxObj["sort_by"] = column;
        }

        if (order != null) {
            auxObj["order_by"] = order;
        }
        
        setCurrentState(column + order);
        setCurrentParams(auxObj);

        if(document.getElementById("ListOfSummary")){
            document.getElementById("ListOfSummary").scrollTop = 0;
        }
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    };
  

    return <HomeSummary
        t={t}
        summaries={summaries}
        currentState={currentState}
        setCurrentState={setCurrentState}
        cleanList={cleanList}
        search={search}
        showRegisterSummaryModal={showRegisterSummaryModal}
        callbackOrderBy={callbackOrderBy}
        values={values}
        updateListfunction={updateListfunction}
        currentHasMoreInformation={currentHasMoreInformation}
        isLoading={isLoading}
        showSummaryFail={showSummaryFail}
        setShowSummaryFail={setShowSummaryFail}
        enableSummary={values.company.configs.enable_summary}
    />

}

export default HomeSummaryController;