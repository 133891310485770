import { FC } from 'react';
import AppLoading from './index';
import { AppLoadingControllerInterface } from './indexModel';

const AppLoadingController: FC<AppLoadingControllerInterface> = (props) => {

  return (
    <AppLoading fadingOut={props.fadingOut} />
  );
};

export default AppLoadingController;
