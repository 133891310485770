import { FC, useEffect, useState } from 'react';
import ModalTicketLogs from './index';
import { FetchTicketLogsInterface, ModalTicketLogsControllerInterface, TicketLogsInterface } from './indexModel';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UserService from '../../../../../../services/user-service';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useViewTicketContext } from '../../../../../../core/context/view-ticket-context';
import { TicketLogChange } from '../../../../../../core/enums/ticket-log-change';
import i18n from '../../../../../../i18next';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 10;

const ModalTicketLogsController: FC<ModalTicketLogsControllerInterface> = (props) => {
  
  const { t } = useTranslation('ModalTicketLogs');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    currentTicket,
  } = useViewTicketContext();

  const [loading, setLoading] = useState(false);
  const [ticketLogs, setTicketLogs] = useState<TicketLogsInterface[]>([]);
  const [currentTicketLogsPage, setCurrentTicketLogsPage] = useState(1);
  const [hasMoreTicketLogs, setHasMoreTicketLogs] = useState(false);
  const [isLoadingTicketLogsPagination, setIsLoadingTicketLogsPagination] = useState(false);

  useEffect(() => {
    if (props.show) {
      fetchTicketLogs(currentTicket?.id, 1, false);
    }
  }, [props.show]);

  const fetchTicketLogs = async (ticket_id: string, newPage: number, isPagination?: boolean, refresh?: boolean) => {
    if (ticket_id) {
      const headers = UserService.getHeaders();
      const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTicketLogsPage };
      const config = { headers, params };
  
      setCurrentTicketLogsPage(newPage);
      
      await AppRequesterConst.Get(
        `/ticket/${ticket_id}/log`,
        config,
        (response: any) => {},
        (response: FetchTicketLogsInterface) => {
          if (response.status === 200 && response.data.logTicket.length > 0) {
            if (response.data.logTicket.length === LIMIT_ITEMS_PAGINATION) {
              setHasMoreTicketLogs(true);
            } else {
              setHasMoreTicketLogs(false);
            }
  
            if (isPagination) {
              const new_array = [...ticketLogs, ...response.data.logTicket];
              setTicketLogs(new_array);
            } else {
              setTicketLogs(response.data.logTicket || []);
            }
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          if (error?.message === "canceled") {
            // requisição abortada
            return;
          } else {
            if (error?.response?.status === 401) {
              if (error?.response?.data?.code_cxpress === 1011) {
                  return;
              }
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            } else if (error?.response?.data) {
              // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            }
          }
        }, navigate, dispatch, isPagination || refresh ? setIsLoadingTicketLogsPagination : setLoading, { }
      );
    }
  }

  const fetchTicketLogsPagination = async () => {
    if (hasMoreTicketLogs) {
      const newPage = currentTicketLogsPage + 1;
      setCurrentTicketLogsPage(newPage);

      await fetchTicketLogs(currentTicket?.id, newPage, true);
    }
  }

  const getTicketLogs = () => {
    return {
      ticketLogs,
      fetchTicketLogsPagination,
      hasMoreTicketLogs,
      isLoadingTicketLogsPagination,
    };
  }

  const someLoading = (): boolean => {
    return loading || isLoadingTicketLogsPagination;
  }

  const getLogMessage = (log: TicketLogsInterface): { message: string, element: JSX.Element } => {
    const formatted_log = { message: '', element: <></> };

    if (log.action === TicketLogChange.Replace) {
      formatted_log.message = `${log.field || 'N/A'} alterado de ${log.old_value} para ${log.new_value}`;

      if (log.old_value && log.new_value) {
        formatted_log.message = `${log.field || 'N/A'} alterado de ${log.old_value} para ${log.new_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{log.field || 'N/A'}</span> {t('changed_from')}
            <span className="info-history-message-value-old">{log.old_value}</span> {t('to')}
            <span className="info-history-message-value-new">{log.new_value}</span>
          </span>
        );
      } else if (!log.old_value && log.new_value) {
        formatted_log.message = `${log.field || 'N/A'} alterado para ${log.new_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{log.field || 'N/A'}</span> {t('changed_to')}
            <span className="info-history-message-value-new">{log.new_value}</span>
          </span>
        );
      }
    } else if (log.action === TicketLogChange.Add) {
      if (log.field.toLowerCase() === 'seguidores') {
        formatted_log.message = `Seguidor adicionado: ${log.new_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{t('follower')}</span> {t('added')}:
            <span className="info-history-message-value-new">{log.new_value}</span>
          </span>
        );
      } else if (log.field.toLowerCase() === 'tags') {
        formatted_log.message = `Tag adicionada: ${log.new_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{t('tag')}</span> {t('added_feminine')}:
            <span className="info-history-message-value-new">{log.new_value}</span>
          </span>
        );
      } else {
        formatted_log.message = `${log.field} adicionado: ${log.new_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{log.field}</span> {t('added')}:
            <span className="info-history-message-value-new">{log.new_value}</span>
          </span>
        );
      }
    } else if (log.action === TicketLogChange.Remove) {
      if (log.field.toLowerCase() === 'seguidores') {
        formatted_log.message = `Seguidor removido: ${log.old_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{t('follower')}</span> {t('removed')}:
            <span className="info-history-message-value-old">{log.old_value}</span>
          </span>
        );
      } else if (log.field.toLowerCase() === 'tags') {
        formatted_log.message = `Tag removida: ${log.old_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{t('tag')}</span> {t('removed_feminine')}:
            <span className="info-history-message-value-old">{log.old_value}</span>
          </span>
        );
      } else {
        formatted_log.message = `${log.field} removido: ${log.old_value}`;
        formatted_log.element = (
          <span className="modal-ticket-logs-list-item-message">
            <span className="info-history-message-field">{log.field}</span> {t('removed')}:
            <span className="info-history-message-value-old">{log.old_value}</span>
          </span>
        );
      }
    }

    return formatted_log;
  }

  const getFormattedDate = (created_at: string, seconds?: boolean) => {
    const date = new Date(created_at);
    const formatted = date.toLocaleDateString(i18n.language) + ` ${t('date_at_label')} ` + date.toLocaleTimeString(i18n.language).slice(0, seconds ? 8 : 5);
    return formatted;
  }

  return (
    <ModalTicketLogs 
      t={t}
      show={props.show}
      hide={props.hide}
      ticketLogs={ticketLogs}
      loading={someLoading()}
      getTicketLogs={getTicketLogs}
      getLogMessage={getLogMessage}
      getFormattedDate={getFormattedDate}
      ticketReferenceId={currentTicket?.ticket_reference_id}
    />
  );
};

export default ModalTicketLogsController;
