import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import ListOfSectors from './index';
import { I_FetchSectorsList, I_SectorList, ListOfSectorsControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useMainAppContext } from '../../../../../core/context/main-app-context';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../../../services/user-service';
import { CustomTableListHeaders, PaginationDetailsType } from '../../../../../components/customTableList/indexModel';
import { AppliedFiltersBarType, SearchComponentFilterType } from '../../../components-fixed/searchComponent/indexModel';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { getSectorsListNameOrderBy, getSectorsListSortBy, getSectorsListStatusOrderBy, setSectorsListNameOrderBy, setSectorsListSortBy, setSectorsListStatusOrderBy } from '../../../../../store/table_sort_order';
import { OrderByType, SortByType } from '../../../../../core/enums/order-sort-by';
import emitter from '../../../../../core/shared/emitter';
import constsRouters from '../../../../../routes/constsRouter';
import { Popover } from 'react-bootstrap';
import { IconCancel, IconCircleCheck, IconEdit, IconTicket } from '@tabler/icons-react';

const AppRequesterConst = new AppRequesterController();

const LIMIT_PAGINATION = 25;
const NAME_SIZE = '250px';
const STATUS_SIZE = '120px';
const KEYWORDS_SIZE = '300px';
const PARTICIPANTS_SIZE = '400px';

const ListOfSectorsController: FC<ListOfSectorsControllerInterface> = (props) => {
  const { t } = useTranslation('ListOfSectors');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const {
    setMainHeader,
    clearFilterTermFunction, 
    filterApplied,
    setFilterApplied,
    setFilteredMode,
    performSearch,
    formatFilter,
    searchTerm,
    setIsSearched,
  } = useMainAppContext();

  const sectorsListSortBy = useSelector(getSectorsListSortBy);
  const sectorsListNameOrderBy = useSelector(getSectorsListNameOrderBy);
  const sectorsListStatusOrderBy = useSelector(getSectorsListStatusOrderBy);

  const [apiHeader, setApiHeader] = useState(UserService.getHeaders());
  const [listHeaders, setListHeaders] = useState<CustomTableListHeaders[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [paginationDetails, setPaginationDetails] = useState<PaginationDetailsType>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef<number>(1);

  const [showModalSector, setShowModalSector] = useState(false);
  const [showModalChangeStatusSector, setShowModalChangeStatusSector] = useState(false);

  const queryFilterRef = useRef<{ [key: string]: string }>({});
  const [filterMode, setFilterMode] = useState<'main' | 'custom'>(null);
  const [filters, setFilters] = useState<AppliedFiltersBarType[]>([]);

  useEffect(() => {
    const userHeader = UserService.getHeaders();
    if (!apiHeader && userHeader) {
      setApiHeader(userHeader);
    }
  }, [UserService.getHeaders()]);

  useEffect(() => {
    if (filterApplied) {
      setFilters(getFiltersApplied());
    }
  }, [filterApplied, searchTerm]);

  useEffect(() => {
    setListHeaders([
      {
        title: t('list_headers.sector_name'),
        field: 'sector_name',
        width: NAME_SIZE,
        hasOrderBy: true,
        orderByFn: getSectorsOrdered,
        orderBy: sectorsListNameOrderBy || 'asc',
        orderType: 'text',
        hasTooltip: true
      },
      {
        title: t('list_headers.status'),
        field: 'active',
        width: STATUS_SIZE,
        hasOrderBy: true,
        orderByFn: getSectorsOrdered,
        orderBy: sectorsListStatusOrderBy || 'asc',
        orderType: 'default',
        hasTooltip: true
      },
      {
        title: t('list_headers.participants'),
        field: 'agents',
        width: PARTICIPANTS_SIZE,
        hasOrderBy: false,
        hasTooltip: true
      },
      {
        title: t('list_headers.keywords'),
        field: 'keyword',
        width: KEYWORDS_SIZE,
        hasOrderBy: false,
        hasTooltip: true
      },
      { title: t("list_headers.actions"), field: 'action', width: 'auto' },
    ]);
  }, []);

  useEffect(() => {
    if (apiHeader) {
      configMainHeader();
      setCurrentPage(1);

      const sortBy = sectorsListSortBy || 'name';
      let orderBy = 'asc';
      
      if (sortBy === 'active') {
        orderBy = sectorsListStatusOrderBy || 'asc';  
      } else {
        orderBy = sectorsListNameOrderBy || 'asc';
      }

      const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };

      getSectors(params);
    }
  }, [apiHeader]);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const configMainHeader = () => {
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    setMainHeader({
      pageTitle: t('header.welcome_page'),
      pageSubtitle: t('header.sub_info_ticket'),
      hasSearchComponent: true,
      hasSearchInput: true,
      hasSearchFilter: false,
      searchPage: 'tags-ticket',
      searchPlaceholder: t('header.search_ticket_placeholder'),
      searchFunction: searchSectors,
      clearSearchFunction: clearSearchSectors,
      hasCreateButton: true,
      createButtonText: t('header.button_create'),
      createButtonTooltip: t('header.button_create_ticket_tooltip'),
      createButtonAction: openModalNewSector,
    });
  }

  const getListHeaders = (): CustomTableListHeaders[] => {
    return listHeaders.filter(item => !item.hidden);
  }

  const getSectors = (params?: any, isScrollPagination?: boolean): void => {
    setErrorLoading(false);
    const config = { headers: apiHeader, params };

    AppRequesterConst.Get(
      '/sector', config,
      () => {},
      (response: I_FetchSectorsList) => {
        if (response.status === 200 && response.data.sectors.length > 0) {
          if (response.data.sectors.length === LIMIT_PAGINATION) {
            setHasMoreData(true);
          } else {
            setHasMoreData(false);
          }
          if (isScrollPagination) {
            const new_array = [...data, ...response.data.sectors];
            setData(new_array);
          } else {
            setData(response.data.sectors || []);
          }
          processPaginationDetails(response.data.pagination);
        } else {
          setData([]);
        }
      },
      (error: { response: { status: number; data?: { message: any []; code_cxpress: number } }, message?: string }) => {
        setErrorLoading(true);
        dispatch(setShowAlertFeedback({ message: t('errors.defaultErrorMessage'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setIsLoading, { }
    );
  }

  const getSectorsPagination = async (page?: number, type?: 'pagination' | 'infinite') => {
    const sortBy = sectorsListSortBy || 'name';
    let orderBy = 'asc';
    
    if (sortBy === 'active') {
      orderBy = sectorsListStatusOrderBy || 'asc';  
    } else {
      orderBy = sectorsListNameOrderBy || 'asc';
    }
    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};
    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...currentFilter };
    if (page) {
      params.page = page;
      setCurrentPage(page);
      getSectors(params, false);
    } else {
      if (hasMoreData) {
        params.page += 1;
        setCurrentPage(params.page);
  
        getSectors(params, true);
      }
    }
  }

  const reloadSectors = (preserveFilter?: boolean) => {
    const sortBy = sectorsListSortBy || 'name';
    let orderBy = 'asc';
    
    if (sortBy === 'active') {
      orderBy = sectorsListStatusOrderBy || 'asc';  
    } else {
      orderBy = sectorsListNameOrderBy || 'asc';
    }

    let params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    if (preserveFilter && queryFilterRef.current) {
      params = { ...params, ...queryFilterRef.current };
    }
    getSectors(params, false);
  }

  const searchSectors = (query: { [key: string]: string; }, filterApplied: SearchComponentFilterType) => {
    const sortBy = sectorsListSortBy || 'name';
    let orderBy = 'asc';
    
    if (sortBy === 'active') {
      orderBy = sectorsListStatusOrderBy || 'asc';  
    } else {
      orderBy = sectorsListNameOrderBy || 'asc';
    }

    const params = { limit: LIMIT_PAGINATION, page: currentPage, sort_by: sortBy, order_by: orderBy, ...query };
    
    queryFilterRef.current = query;
    setFilterApplied(filterApplied);
    getSectors(params);
  }

  const clearSearchSectors = () => {
    const sortBy = sectorsListSortBy || 'name';
    let orderBy = 'asc';
    
    if (sortBy === 'active') {
      orderBy = sectorsListStatusOrderBy || 'asc';  
    } else {
      orderBy = sectorsListNameOrderBy || 'asc';
    }

    const params = { limit: LIMIT_PAGINATION, page: 1, sort_by: sortBy, order_by: orderBy };
    setIsSearched(false);
    queryFilterRef.current = {};
    setFilterApplied({});
    setFilteredMode('simple');
    if (clearFilterTermFunction) {
      clearFilterTermFunction();
    }
    getSectors(params);
  }

  const getSectorsOrdered = async (field: string, order: OrderByType) => {
    let formattedField = field as SortByType;

    const currentFilter = queryFilterRef.current ? queryFilterRef.current : {};

    const params = { limit: LIMIT_PAGINATION, page: currentPageRef.current, sort_by: formattedField, order_by: order, ...currentFilter };
    setHeaderOrderBy(formattedField, order);
    getSectors(params);
  }

  const setHeaderOrderBy = (field: SortByType, order: 'asc' | 'desc'): void => {
    let clearedField: string = field;

    setListHeaders(prevState => {
      prevState.forEach(item => {
        if (item.field === clearedField) {
          item.orderBy = order;
        } else {
          if (item.field === 'sector_name') {
            item.orderBy = 'asc';
          } else if (item.field === 'active') {
            item.orderBy = 'asc';
          }
        }
      });

      if (clearedField === 'sector_name') {
        dispatch(setSectorsListNameOrderBy(order));
        dispatch(setSectorsListStatusOrderBy('asc')); // resetando valor default
      } else if (field === 'active') {
        dispatch(setSectorsListStatusOrderBy(order));
        dispatch(setSectorsListNameOrderBy('asc')); // resetando valor default
      }

      dispatch(setSectorsListSortBy(field));

      return prevState;
    });
  }

  // ############################################################################################
  // ############################################################################################

  const processPaginationDetails = (pagination: PaginationDetailsType) => {
    if (pagination) {
      setPaginationDetails({
        currentPage: pagination.currentPage,
        prevPage: pagination.prevPage,
        nextPage: pagination.nextPage,
        lastPage: pagination.lastPage,
        hasPrev: pagination.prevPage !== null,
        hasNext: pagination.nextPage !== null,
        from: pagination.from + 1,
        to: pagination.to,
        perPage: pagination.perPage,
        total: pagination.total,
        pages: Array.from(Array(pagination.lastPage || 1), (x, i) => i + 1)
      });
    }
  }

  const clearSpecificFilter = (key: string) => {
    const tempFilterApplied: SearchComponentFilterType = { ...filterApplied };
    if (filterMode) {
      const mode = filterMode === 'main' ? 'simple' : 'advanced';
      let currentSearchTerm = searchTerm;

      if (key === 'search') {
        currentSearchTerm = '';
        clearFilterTermFunction();
      }

      setFilterApplied(tempFilterApplied);
      performSearch(formatFilter(tempFilterApplied, currentSearchTerm, mode, 'sectors'), tempFilterApplied);
    }
  }

  const getFiltersApplied = (): AppliedFiltersBarType[] => {
    const filters: AppliedFiltersBarType[] = [];
    // let hasDate = false;
    setFilterMode('main');

    Object.keys(queryFilterRef.current || {}).forEach(key => {
      const item = { key, tooltip_key: `filter_applied.${key}`, value: '' };
      
      if (key === 'search') {
        item.value = queryFilterRef.current[key];
      }

      // if (!hasDate && key.includes('date')) {
      //   hasDate = true;
      //   if (filterApplied.selectedFilterDate?.id === 'custom') {
      //     item.value = `${filterApplied.selectedDate.start.toDateString()} - ${filterApplied.selectedDate.end.toDateString()}`
      //   } else {
      //     item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
      //   }
      // }

      if (!key.includes('date')) {
        filters.push(item);
      }
    });

    // if (queryFilterRef.current?.start_date && queryFilterRef.current?.end_date) {
    //   const item = { key: 'date', tooltip_key: 'filter_applied.period', value: '' };

    //   if (filterApplied.selectedFilterDate.id === 'custom') {
    //     item.value = `${filterApplied.selectedDate.start.toLocaleDateString()} - ${filterApplied.selectedDate.end.toLocaleDateString()}`
    //   } else {
    //     item.value = t(`filter_applied.period_${filterApplied.selectedFilterDate.id}`);
    //   }

    //   filters.push(item);
    // }
    return filters;
  }

  // =================================================
  // =================================================

  const openModalSector = (item: I_SectorList, event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setShowModalSector(true);
    setTimeout(() => {
      emitter.emit('open-modal-sector', { id: item.sector_id, name: item.sector_name, active: item.active });
    }, 50);
  }

  const openModalNewSector = () => {
    setShowModalSector(true);
    setTimeout(() => {
      emitter.emit('open-modal-sector', null);
    }, 50);
  }

  const closeModalSector = (reload: boolean) => {
    if (reload) {
      reloadSectors(true);
    }
    setShowModalSector(false);
  }

  // =====

  const openModalChangeStatusSector = (data: { id: string; name?: string }, newStatus: boolean) => {
    setShowModalChangeStatusSector(true);
    setTimeout(() => {
      emitter.emit('open-modal-change-status-sector', { sectorId: data.id, sectorName: data.name, newStatus });
    }, 50);
  }

  const closeModalChangeStatusSector = (reload: boolean) => {
    if (reload) {
      reloadSectors(true);
    }
    setShowModalChangeStatusSector(false);
  }

  const handlePopoverListItemAction = (event: any, data: I_SectorList, type: 'open-sector-tickets' | 'edit-sector' | 'activate-sector' | 'inactivate-sector' ) => {
    event.stopPropagation();
    emitter.emit('close-item-action-popover-list-sectors');
    
    setTimeout(() => {
      if (type === 'open-sector-tickets') {
        navigate(
          `${constsRouters.routers.configurationSectorsTickets.path}`, 
          { 
            state: { 
              sectorId: data.sector_id,
              sectorValue: data.sector_name,
              page: 'sectors', 
              prevPath: constsRouters.routers.configurationSectors.path 
            } 
          }
        );
      } else if (type === 'edit-sector') {
        openModalSector(data, event);
      } else if (type === 'activate-sector') {
        openModalChangeStatusSector({ id: data.sector_id, name: data.sector_name }, true);
      } else if (type === 'inactivate-sector') {
        openModalChangeStatusSector({ id: data.sector_id, name: data.sector_name }, false);
      } 
    }, 50);
  }

  const popoverItemAction = (data: I_SectorList) => {
    const showOpenSectorTickets = true;
    const showEditSector = true;
    const showActivateSector = !data.active;
    const showInactivateSector = data.active;
    
    return (
      <Popover bsPrefix='popover-custom-action-menu'>
        <Popover.Body>
          { showOpenSectorTickets &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'open-sector-tickets')}>
              <IconTicket />{t('list_popover_actions.open_sector_tickets')}
            </span>
          }
          { showEditSector &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'edit-sector')}>
              <IconEdit />{t('list_popover_actions.edit_sector')}
            </span>
          }
          { showActivateSector &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'activate-sector')}>
              <IconCircleCheck />{t('list_popover_actions.activate_sector')}
            </span>
          }
          { showInactivateSector &&
            <span className='popover-item' onClick={(e) => handlePopoverListItemAction(e, data, 'inactivate-sector')}>
              <IconCancel />{t('list_popover_actions.inactivate_sector')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <ListOfSectors 
      t={t}
      listHeaders={getListHeaders}
      listData={data}
      paginationDetails={paginationDetails}
      getSectorsPagination={getSectorsPagination}
      reloadSectors={reloadSectors}
      isLoading={isLoading}
      errorLoading={errorLoading}

      openModalSector={openModalSector}
      showModalSector={showModalSector}
      closeModalSector={closeModalSector}
      showModalChangeStatusSector={showModalChangeStatusSector}
      closeModalChangeStatusSector={closeModalChangeStatusSector}

      filters={filters}
      clearSearchSectors={clearSearchSectors}
      clearSpecificFilter={clearSpecificFilter}
      popoverItemAction={popoverItemAction}
    />
  );
};

export default ListOfSectorsController;
