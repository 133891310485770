import { FC, useEffect, useState } from 'react';
import ModalSendTicketCopy from './index';
import { ModalSendTicketCopyControllerInterface, SendTicketBody } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalSendTicketCopyController: FC<ModalSendTicketCopyControllerInterface> = (props) => {
  const { t } = useTranslation('ModalSendTicketCopy');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ ticketId: string, ticketReferenceId: string, subject: string }>(null);
  const [loadingSend, setLoadingSend] = useState(false);
  const [listEmails, setListEmails] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');

  const tagifySettings = {
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  }

  useEffect(() => {
    emitter.on('open-modal-send-ticket-copy', data => {
      setData(data);
    });

    return () => {
      emitter.off('open-modal-send-ticket-copy');
    }
  }, []);

  const handleSubmit = () => {
    if (isValid()) {
      const body: SendTicketBody = {
        ticket_id: data.ticketId,
        subject: `${t('subject_value')} "${data.subject}" (#${data.ticketReferenceId})`,
        email_list: listEmails
      }
  
      if (message?.length > 0) {
        body['content'] = message
      }

      const headers = UserService.getHeaders();
      const ticket_reference_id = `${data.ticketReferenceId}`;
  
      AppRequesterConst.Post(
        '/ticket/send-copy', body, { headers },
        () => {},
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.send_successfully', { ticket_reference_id }), visibility: true, signalIcon: true }));
          props.hide();
        },
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingSend
      );
    }
  }

  const isValid = () => {
    return data?.ticketId && listEmails?.length > 0;
  }

  return (
    <ModalSendTicketCopy
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingSend={loadingSend}
      handleSubmit={handleSubmit}
      listEmails={listEmails}
      setListEmails={setListEmails}
      message={message}
      setMessage={setMessage}
      tagifySettings={tagifySettings}
      isValid={isValid}
    />
  );
};

export default ModalSendTicketCopyController;
