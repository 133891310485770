import { FC } from 'react';
import TicketInfo from '.';
import { TicketInfoControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { IconMail, IconPhone, IconBrandWhatsapp, IconBrandFacebook, IconBrandInstagram, IconMessages, IconHelpCircle } from '@tabler/icons-react';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';

const TicketInfoController: FC<TicketInfoControllerInterface> = (props) => {
  const { t } = useTranslation('TicketInfo');
  const { currentTicket } = useViewTicketContext();
  
  const formatDate = (date: string): string => {
    return new Date(date).toLocaleString();
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    const size = 20;
    const color = '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("channels_names.email"), icon: <IconMail stroke={1.5} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("channels_names.telephony"), icon: <IconPhone stroke={1.5} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("channels_names.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("channels_names.facebook"), icon: <IconBrandFacebook stroke={1.5} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("channels_names.instagram"), icon: <IconBrandInstagram stroke={1.5} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("channels_names.webchat"), icon: <IconMessages stroke={1.5} size={size} color={color} />};
      default:
        return { title: 'N/A', icon: <IconHelpCircle stroke={1.5} size={size} color={color} /> }
    }
  }

  return (
    <TicketInfo 
      t={t}
      ticket={currentTicket}
      formatDate={formatDate}
      getChannel={getChannel}
    />
  );
}

export default TicketInfoController;