import { FC, useEffect, useRef, useState } from 'react';
import DetailsComponent from '.';
import { 
  DetailsComponentControllerInterface, 
  FetchTicketCustomFieldsInterface, 
  FetchEmployeesInterface, 
  FetchSectorsInterface, 
  FetchTagsInterface, 
  FetchUserConsumerInterface, 
  FormatDataSelectType, 
  FetchCustomFieldsInterface, 
  CustomFieldInterface,
  TicketCustomFieldInterface,
  FormattedCustomFieldInterface
} from './indexModel';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../../services/user-service';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useViewTicketContext } from '../../../../../../core/context/view-ticket-context';
import { CustomSelectOptionType } from '../../../../../../components/customSelect/indexModel';
import { TicketStatus } from '../../../../../../core/enums/ticket-status';
import { CustomFieldEntity, CustomFieldsType } from '../../../../../../core/enums/custom-field-type'; 
import { FetchListTicketsInterface, ListTicketsInterface } from '../../../listOfTickets/indexModel';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { verifyCode } from '../../../../../../services/codeCxpressInterface';
import { useMainAppContext } from '../../../../../../core/context/main-app-context';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 10;

const DetailsComponentController: FC<DetailsComponentControllerInterface> = (props) => {
  const { t } = useTranslation('DetailsComponent');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { 
    setIsLoadingSelectedConsumer,
    // setIsLoadingSectorsList,
    // setIsLoadingEmployeesList,
    // setIsLoadingFollowersList,
    // setIsLoadingTagsList,
    setIsLoadingTicketCustomFields,
    setIsLoadingCustomFieldsList,
    setIsLoadingConsumerTickets,
    currentTicket, 
    setCurrentConsumer,
    showConsumerModal,
    setShowConsumerModal,
    setIsLoadingTicketEdit,
  } = useViewTicketContext();

  const { actionEvent, emitActionEvent } = useMainAppContext();

  const [tab, setTab] = useState<'edit' | 'info' | 'consumer' | 'ticket_history'>('edit');

  // UseRefs
  const consumerTicketsObjRef = useRef<{ [key: string]: string}>({}); // Armazenar os ids dos tickets carregados, para verificar se ele está visível na listagem - listOfTickets

  // Data and requests states
  const [apiHeader] = useState(UserService.getHeaders());
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);

  // Consumers
  const [consumersList, setConsumersList] = useState<CustomSelectOptionType[]>([]);
  const [selectedConsumer, setSelectedConsumer] = useState<CustomSelectOptionType>(null);
  const [consumerTickets, setConsumerTickets] = useState<ListTicketsInterface[]>([]);
  const [currentConsumerTicketsPage, setCurrentConsumerTicketsPage] = useState(1);
  const [hasMoreConsumerTickets, setHasMoreConsumerTickets] = useState(false);
  const [isLoadingConsumerTicketsPagination, setIsLoadingConsumerTicketsPagination] = useState(false);

  // Sectors
  const [sectorsList, setSectorsList] = useState<CustomSelectOptionType[]>([]);
  const [currentSectorsListPage, setCurrentSectorsListPage] = useState(1);
  const [hasMoreSectorsList, setHasMoreSectorsList] = useState(false);
  const [selectedSector, setSelectedSector] = useState<CustomSelectOptionType>(null);
  const [isLoadingSectorsSelect, setIsLoadingSectorsSelect] = useState(false);
  const currentSectorId = useRef(null);

  // Employees
  const [employeesList, setEmployeesList] = useState<CustomSelectOptionType[]>([]);
  const [currentEmployeesListPage, setCurrentEmployeesListPage] = useState(1);
  const [hasMoreEmployeesList, setHasMoreEmployeesList] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<CustomSelectOptionType>(null);
  const [isLoadingEmployeesSelect, setIsLoadingEmployeesSelect] = useState(false);

  // Followers
  const [followersList, setFollowersList] = useState<CustomSelectOptionType[]>([]);
  const [currentFollowersListPage, setCurrentFollowersListPage] = useState(1);
  const [hasMoreFollowersList, setHasMoreFollowersList] = useState(false);
  const [selectedFollowers, setSelectedFollowers] = useState<CustomSelectOptionType[]>(null);
  const [isLoadingFollowersSelect, setIsLoadingFollowersSelect] = useState(false);

  // Tags
  const [tagsList, setTagsList] = useState<CustomSelectOptionType[]>([]);
  const [currentTagsListPage, setCurrentTagsListPage] = useState(1);
  const [hasMoreTagsList, setHasMoreTagsList] = useState(false);
  const [selectedTags, setSelectedTags] = useState<CustomSelectOptionType[]>(null);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);

  // Status
  const [selectedStatus, setSelectedStatus] = useState<CustomSelectOptionType>(null);
  const statusList = [
    { id:  TicketStatus.New, value: t("ticket_status.new") },
    { id:  TicketStatus.Open, value: t("ticket_status.open") },
    { id:  TicketStatus.Pending, value: t("ticket_status.pending") },
    { id:  TicketStatus.OnHold, value: t("ticket_status.on_hold") },
    { id:  TicketStatus.Resolved, value: t("ticket_status.resolved") }
  ];
  const statusListComplete = [
    { id: TicketStatus.New, value: t('ticket_status.new') },
    { id: TicketStatus.Open, value: t('ticket_status.open') },
    { id: TicketStatus.Pending, value: t('ticket_status.pending') },
    { id: TicketStatus.OnHold, value: t('ticket_status.on_hold') },
    { id: TicketStatus.Resolved, value: t('ticket_status.resolved') },
    { id: TicketStatus.Abandoned, value: t('ticket_status.abandoned') },
  ];

  // Subject
  const [subject, setSubject] = useState<string>('');

  // CustomFields
  const [formattedCustomFields, setFormattedCustomFields] = useState<FormattedCustomFieldInterface[]>([]);
  const [ticketCustomFields, setTicketCustomFields] = useState<TicketCustomFieldInterface[]>([]);
  const [customFieldsList, setCustomFieldsList] = useState<CustomFieldInterface[]>([]);
  // const [currentCustomFieldsListPage, setCurrentCustomFieldsListPage] = useState(1);
  const [customFieldsValuesObject, setCustomFieldsValuesObject] = useState<any>({});

  useEffect(() => {
    if (currentTicket) {
      setSelectedStatus({ 
        id: currentTicket.status_ticket.id, 
        value: statusListComplete.filter(item => item.id === currentTicket.status_ticket.id)[0]?.value 
      });
      setSubject(currentTicket.subject);

      if (customFieldsList) {
        fetchTicketCustomFields();
      }
    }
  }, [currentTicket?.id, customFieldsList]);

  useEffect(() => {
    if (currentTicket?.requester_user?.user_id) {
      fetchConsumerSelected(currentTicket.requester_user.user_id);
      fetchConsumerTickets(currentTicket.requester_user.user_id, 1, false);
    } else {
      setSelectedConsumer(null);
    }
  }, [currentTicket?.requester_user?.user_id]);

  useEffect(() => {
    if (currentTicket?.sector?.id) {
      setSelectedSector({ id: currentTicket.sector.id, value: currentTicket.sector.name });
      currentSectorId.current = currentTicket.sector.id;
    } else {
      setSelectedSector(null);
    }
  }, [currentTicket?.sector?.id]);

  useEffect(() => {
    if (currentTicket?.assigned_employee?.id) {
      setSelectedEmployee({ id: currentTicket.assigned_employee.user_id, value: currentTicket.assigned_employee.name });
    } else {
      setSelectedEmployee(null);
    }
  }, [currentTicket?.assigned_employee?.user_id]);

  useEffect(() => {
    if (currentTicket?.followers?.length > 0) {
      setSelectedFollowers(formatDataSelect(currentTicket.followers, 'followers'));
    } else {
      setSelectedFollowers([]);
    }
  }, [currentTicket?.followers]);

  useEffect(() => {
    if (currentTicket?.tags?.length > 0) {
      setSelectedTags(formatDataSelect(currentTicket.tags, 'tags'));
    } else {
      setSelectedTags([]);
    }
  }, [currentTicket?.tags]);

  useEffect(() => {
    if (currentTicket?.status_id) {
      setSelectedStatus({ 
        id: currentTicket.status_ticket.id,
        value: statusListComplete.filter(item => item.id === currentTicket.status_ticket.id)[0]?.value 
      });
    }
  }, [currentTicket?.status_id]);

  useEffect(() => {
    if (props.pageType === 'ticket-list' && currentTicket?.company_id) {
      const fetchData = async () => {
        setIsLoadingData(true);
        await Promise.all([
          fetchCustomFields(false, 1),
          fetchSectors(false, 1),
          fetchEmployees(false, 1, null, currentTicket?.sector?.id),
          fetchFollowers(false, 1),
          fetchTags(false, 1),
        ]).then(() => {
          setIsLoadingData(false);
        }).catch((error: any) => {
          console.log(error);
        }).finally(() => {
        });
      }
  
      fetchData();
    }
  }, [currentTicket?.company_id, props.pageType]);

  useEffect(() => {
    if (selectedSector?.id) {
      if (selectedSector?.id !== currentSectorId.current) {
        currentSectorId.current = selectedSector.id;
        fetchEmployees(false, 1, null, selectedSector.id);
        setSelectedEmployee(null);
      }
    } else {
      if (currentSectorId.current) {
        currentSectorId.current = null;
        fetchEmployees(false, 1);
        setSelectedEmployee(null);
      }
    }
  }, [selectedSector?.id]);

  useEffect(() => {
    if (customFieldsList?.length > 0) {
      const newArray: FormattedCustomFieldInterface[] = [];
      const newValues = {};

      customFieldsList.forEach(item => {
        const customField = {
          id: item.id,
          name: item.name,
          options: formatCustomFieldOptions(item.custom_field_type_id, item.options),
          placeholder: item.placeholder,
          required: item.required,
          custom_field_type_id: item.custom_field_type_id,
          value: [],
          setValue: setCustomFieldValue
        }

        newArray.push(customField);
      });

      setFormattedCustomFields(newArray);
      setCustomFieldsValuesObject(newValues);
    }
  }, [customFieldsList, ticketCustomFields]);

  useEffect(() => {
    if (ticketCustomFields?.length > 0 && customFieldsList.length > 0) {
      ticketCustomFields.forEach(item => {
        const formattedValue = formatCustomFieldValue(item.custom_field_id, item.value);
        setCustomFieldValue(item.custom_field_id, formattedValue);
      });
    }
  }, [ticketCustomFields]);

  // useEffect(() => { // Quando o back implementar filtro por setor
  //   fetchEmployees(false, 1);
  //   fetchFollowers(false, 1);
  // }, [currentTicket?.sector_id]);

  useEffect(() => {
    // Verificar necessidade de atualizar os tickets do consumidor a cada ticket-update. Pode atualizar só se o ticket tiver visível na listagem.
    if (actionEvent === 'update-ticket-request' && currentTicket) {
      // fetchConsumerTickets(currentTicket?.requester_user?.user_id, 1, false);
    }
  }, [actionEvent, currentTicket]);

  const fetchConsumerSelected = async (user_id: string) => {
    const config = { headers: apiHeader };
    await AppRequesterConst.Get(
      `/user/${user_id}`,
      config,
      (_response: any) => {},
      (response: FetchUserConsumerInterface) => {
        if (response.status === 200 && response.data?.users.length > 0) {
          const consumer = response.data.users[0];
          setCurrentConsumer(consumer);
          setSelectedConsumer({ id: consumer.user_id, value: consumer.name });
          setConsumersList([{ id: consumer.user_id, value: consumer.name }]);
        } else {
          setCurrentConsumer(null);
          setSelectedConsumer({ id: undefined, value: undefined });
          setConsumersList([{ id: undefined, value: undefined }]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingSelectedConsumer, { }
    );
  }

  // Sectors Requests

  const fetchSectors = (isPagination?: boolean, newPage?: number, termSearch?: string) => {
    const params = { companyid: currentTicket?.company_id, limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentSectorsListPage, search: termSearch };

    if (!params.search) {
      delete params.search;
    }

    const config = { headers: apiHeader, params };

    setCurrentSectorsListPage(newPage);

    AppRequesterConst.Get(
      `/sector/actives`,
      config,
      (_response: any) => {},
      (response: FetchSectorsInterface) => {
        if (response.status === 200 && response.data?.sectors.length > 0) {
          if (response.data.sectors.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreSectorsList(true);
          } else {
            setHasMoreSectorsList(false);
          }

          // if (response.data.pagination.nextPage) { } // Tá bugado

          if (isPagination) {
            const new_array = [...sectorsList, ...formatDataSelect(response.data.sectors, 'sector')];
            setSectorsList(new_array);
          } else {
            setSectorsList(formatDataSelect(response.data.sectors, 'sector') || []);
          }
        } else {
          if (!isPagination) {
            setSectorsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingSectorsSelect, { }
    );
  }

  const fetchSectorsPagination = async (page?: number) => {
    if (hasMoreSectorsList || page?.toString().length > 0) {
      const newPage = page?.toString().length > 0 ? page : (currentSectorsListPage + 1);
      setCurrentSectorsListPage(newPage);

      fetchSectors(page?.toString().length > 0 ? false : true, newPage);
    }
  }

  const fetchSearchSectors = (term: string) => {
    if (term) {
      fetchSectors(false, 1, term);
    } else {
      fetchSectors(false, 1, '');
    }
  }
  // ##################################################################

  // Assigned Employees Requests

  const fetchEmployees = (isPagination: boolean, newPage: number, termSearch?: string, sectorId?: string) => {
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentEmployeesListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    if (sectorId) {
      params['sector'] = sectorId;
    }

    const config = { headers: apiHeader, params };

    setCurrentEmployeesListPage(newPage);

    AppRequesterConst.Get(
      `/agent`,
      config,
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data?.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreEmployeesList(true);
          } else {
            setHasMoreEmployeesList(false);
          }

          if (isPagination) {
            const new_array = [...employeesList, ...formatDataSelect(response.data.employees, 'employee')];
            setEmployeesList(new_array);
          } else {
            setEmployeesList(formatDataSelect(response.data.employees, 'employee') || []);
          }
        } else {
          if (!isPagination) {
            setEmployeesList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingEmployeesSelect, { }
    );
  }

  const fetchEmployeesPagination = async () => {
    if (hasMoreEmployeesList) {
      const newPage = currentEmployeesListPage + 1;
      setCurrentEmployeesListPage(newPage);

      fetchEmployees(true, newPage);
    }
  }

  const fetchSearchEmployees = (term: string) => {
    if (term) {
      fetchEmployees(false, 1, term);
    } else {
      fetchEmployees(false, 1, '');
    }
  }

  // ##################################################################

  // Followers Requests

  const fetchFollowers = (isPagination: boolean, newPage: number, termSearch?: string) => {
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentEmployeesListPage, search: termSearch };

    if (!params.search) {
      delete params.search;
    }

    // if (currentTicket.sector_id) {
    //   params['sector'] = currentTicket.sector_id;
    // }

    const config = { headers: apiHeader, params };

    setCurrentFollowersListPage(newPage);

    AppRequesterConst.Get(
      `/agent`,
      config,
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data?.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreFollowersList(true);
          } else {
            setHasMoreFollowersList(false);
          }

          if (isPagination) {
            const new_array = [...employeesList, ...formatDataSelect(response.data.employees, 'employee')];
            setFollowersList(new_array);
          } else {
            setFollowersList(formatDataSelect(response.data.employees, 'employee') || []);
          }
        } else {
          if (!isPagination) {
            setFollowersList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingFollowersSelect, { }
    );

  }

  const fetchFollowersPagination = async () => {
    if (hasMoreFollowersList) {
      const newPage = currentFollowersListPage + 1;
      setCurrentFollowersListPage(newPage);

      fetchFollowers(true, newPage);
    }
  }

  const fetchSearchFollowers = (term: string) => {
    if (term) {
      fetchFollowers(false, 1, term);
    } else {
      fetchFollowers(false, 1, '');
    }
  }

  // ##################################################################

  // Tags Requests

  const fetchTags = (isPagination: boolean, newPage: number, termSearch?: string) => {
    const params = { active: true, limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage, search: termSearch };

    if (!params.search) {
      delete params.search;
    }

    const config = { headers: apiHeader, params };

    setCurrentTagsListPage(newPage);

    AppRequesterConst.Get(
      `/tag`,
      config,
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data?.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTagsList(true);
          } else {
            setHasMoreTagsList(false);
          }

          if (isPagination) {
            const new_array = [...tagsList, ...formatDataSelect(response.data.tags, 'tags')];
            setTagsList(new_array);
          } else {
            setTagsList(formatDataSelect(response.data.tags, 'tags') || []);
          }
        } else {
          if (!isPagination) {
            setTagsList([]);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingTagsSelect, { }
    );
  }

  const fetchTagsPagination = async (page?: number) => {
    if (hasMoreTagsList || page?.toString().length > 0) {
      const newPage = page?.toString().length > 0 ? page : (currentTagsListPage + 1);
      setCurrentTagsListPage(newPage);

      fetchTags(page?.toString().length > 0 ? false : true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  // ##################################################################

  // Ticket Custom Fields Requests

  const fetchTicketCustomFields = () => {
    const config = { headers: apiHeader };

    AppRequesterConst.Get(
      `/ticket/${currentTicket.id}/custom-fields`,
      config,
      (_response: any) => {},
      (response: FetchTicketCustomFieldsInterface) => {
        if (response.status === 200 && response.data?.custom_fields.length > 0) {
          setTicketCustomFields(response.data.custom_fields);
        } else {
          setTicketCustomFields([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingTicketCustomFields, { }
    );

  }

  const fetchCustomFields = (isPagination: boolean, newPage: number) => {
    // const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentTagsListPage };

    const config = { headers: apiHeader };

    // setCurrentCustomFieldsListPage(newPage);
    AppRequesterConst.Get(
      `/custom-fields/entity-type/${CustomFieldEntity.Ticket}`,
      config,
      (_response: any) => {},
      (response: FetchCustomFieldsInterface) => {
        if (response.status === 200 && response.data?.custom_fields.length > 0) {
          setCustomFieldsList(response.data.custom_fields);
        } else {
          setCustomFieldsList([]);
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
              return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, setIsLoadingCustomFieldsList, { }
    );
  }

  // ##################################################################

  // History consumer tickets

  const fetchConsumerTickets = (user_id: string, newPage: number, isPagination?: boolean, refresh?: boolean) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || currentConsumerTicketsPage };
    const config = { headers, params };

    setCurrentConsumerTicketsPage(newPage);
    
    AppRequesterConst.Get(
      `/user/${user_id}/tickets`,
      config,
      (response: Object) => {},
      (response: FetchListTicketsInterface) => {
        if (response.status === 200 && response.data.tickets.length > 0) {
          if (response.data.tickets.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreConsumerTickets(true);
          } else {
            setHasMoreConsumerTickets(false);
          }

          if (isPagination) {
            const new_array = [...consumerTickets, ...response.data.tickets];
            setConsumerTickets(new_array);
          } else {
            setConsumerTickets(response.data.tickets || []);
          }
        }
      },
      (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
        if (error?.message === "canceled") {
          // requisição abortada
          return;
        } else {
          if (error?.response?.status === 401) {
            if (error?.response?.data?.code_cxpress === 1011) {
                return;
            }
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else if (error?.response?.data) {
            // dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          }
        }
      }, navigate, dispatch, isPagination || refresh ? setIsLoadingConsumerTicketsPagination : setIsLoadingConsumerTickets, { }
    );
  }

  const fetchConsumerTicketsPagination = () => {
    if (hasMoreConsumerTickets) {
      const newPage = currentConsumerTicketsPage + 1;
      setCurrentConsumerTicketsPage(newPage);

      fetchConsumerTickets(currentTicket?.requester_user?.user_id, newPage, true);
    }
  }

  const refreshConsumerTickets = () => {
    fetchConsumerTickets(currentTicket?.requester_user.user_id, 1, false, true);
  }

  // ##################################################################

  const handleSubmit = () => {
    const ticketForm = {
      id: currentTicket?.id,
      subject,
      requester_user_id: selectedConsumer.id,
      status_id: selectedStatus?.id
    }

    if (selectedEmployee?.id) {
      ticketForm['assigned_employee_user_id'] = selectedEmployee.id;
    }

    if (selectedSector?.id) {
      ticketForm['sector_id'] = selectedSector.id;
    }

    if (selectedSector?.id) {
      ticketForm['sector_id'] = selectedSector.id;
    }

    if (selectedFollowers?.length > 0) {
      ticketForm['followers_id'] = selectedFollowers.map(item => item.id);
    }

    if (selectedTags?.length > 0) {
      ticketForm['tags'] = selectedTags.map(item => item.id);
    }

    const customFields = Object.keys(customFieldsValuesObject || {});

    if (customFields.length > 0) {
      const fieldsValues = [];
      customFields.forEach(key => {
        const item = { custom_field_id: key, value: [] };
        const value = customFieldsValuesObject[key];

        if (value) {
          item.value = Array.isArray(value) ? value.map(item => item.value) : [value];
        }
        fieldsValues.push(item);
      });

      ticketForm['custom_fields'] = fieldsValues;
    }

    setIsLoadingTicketEdit(true);
    const headers = UserService.getHeaders();
    AppRequesterConst.Put('/ticket', ticketForm, { headers },
      (response: any) => {},
      (response: { data: { code_cxpress: number, message: string } }) => {
        setIsLoadingTicketEdit(false);
        dispatch(setShowAlertFeedback({ 
          message:  t("ticket_edit.success", { ticket_reference_id: currentTicket.ticket_reference_id }), 
          signalIcon: true, 
          visibility: true 
        }));

        setTimeout(() => {
          // Verificar se o tipo de alteração inclui resolver o ticket
          // Se sim, atualizar a listagem e alterar o ticket atual para ser o próximo da lista
          // const resolveStatus = [TicketStatus.Abandoned.toString(), TicketStatus.Resolved.toString()];

          emitActionEvent('update-ticket-request');

          // if (resolveStatus.includes(ticketForm.status_id)) {
          //   emitActionEvent('resolve-ticket');
          //   // Mecher aqui
          // } else {
          //   emitActionEvent('update-ticket-request');
          // }
        }, 10);
      },
      (error: any) => {
        setIsLoadingTicketEdit(false);
        if (error?.response?.data?.code_cxpress) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
        } else {
          console.log(error?.response?.data || error);
        }
      },
      navigate, dispatch, setIsLoadingEdit, () => { },
    );
  }

  const formatDataSelect = (data: any[], type: FormatDataSelectType): { id: string, value: string}[] => {
    const formatted = [];

    data.forEach(item => {
      if (item) {
        if (type === 'sector' || type === 'tags') {
          formatted.push({ id: item.id, value: item.name });
        }
        if (type === 'employee' || type === 'followers') {
          formatted.push({ id: item.user_id, value: item.name });
        }
        if (type === 'custom-field') {
          formatted.push({ id: item, value: item });
        }
      }
    });

    return formatted;
  }

  const formatCustomFieldOptions = (custom_field_type_id: string, options: any[]) => {
    const selectType = [CustomFieldsType.Dropdown.toString(), CustomFieldsType.DropdownMultiselect.toString()];
    if (selectType.includes(custom_field_type_id) && options) {
      return formatDataSelect(options, 'custom-field');
    } else {
      return null;
    }
  }

  const formatCustomFieldValue = (custom_field_id: string, value: any[]) => {
    const selectType = [CustomFieldsType.Dropdown.toString(), CustomFieldsType.DropdownMultiselect.toString()];

    const customField = formattedCustomFields.find(item => item.id === custom_field_id);

    if (customField) {
      if (selectType.includes(customField.custom_field_type_id) && value) {
        if (value[0]) {
          return formatDataSelect(value, 'custom-field');
        } else {
          return null;
        }
      } else {
        return value[0];
      }
    }
  }

  const setCustomFieldValue = (custom_field_id: string, value: any) => {
    setCustomFieldsValuesObject((customFieldsValuesObject: any) => ({
      ...customFieldsValuesObject,
      [custom_field_id]: value,
    }));
  }

  const isValidSubject = () => {
    return subject?.length > 0;
  }

  const isValidCustomFields = () => {
    let valid = true;

    if (formattedCustomFields?.length > 0 && Object.keys(customFieldsValuesObject || {}).length > 0) {
      formattedCustomFields.forEach(customField => {
        if (customField.required && !customFieldsValuesObject[customField.id]) {
          valid = false;
        }
      });
    }

    return valid;
  }

  const openSectorModal = () => {
    console.log('open sector modal');
  }

  const getConsumerSelect = () => {
    return {
      consumersList,
      selectedConsumer,
      setSelectedConsumer,
    }
  }

  const getSectorSelect = () => {
    return {
      sectorsList,
      selectedSector,
      setSelectedSector,
      isLoadingSectorsSelect,
      fetchSectorsPagination,
      fetchSearchSectors,
      hasMoreSectorsList,
      openSectorModal,
    }
  }

  const getEmployeeSelect = () => {
    return {
      employeesList,
      selectedEmployee,
      setSelectedEmployee,
      isLoadingEmployeesSelect,
      fetchEmployeesPagination,
      fetchSearchEmployees,
      hasMoreEmployeesList,
    };
  }

  const getStatusSelect = () => {
    return {
      statusList,
      selectedStatus,
      setSelectedStatus,
    }
  }

  const getFolowersSelect = () => {
    return {
      followersList,
      selectedFollowers,
      setSelectedFollowers,
      isLoadingFollowersSelect,
      fetchFollowersPagination,
      fetchSearchFollowers,
      hasMoreFollowersList,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList,
      selectedTags,
      setSelectedTags,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTagsList,
    };
  }

  const getSubjectInput = () => {
    return {
      subject,
      setSubject,
      isValidSubject,
    }
  }

  const getCustomFieldsData = () => {
    return {
      ticketCustomFields,
      customFieldsList,
      formattedCustomFields,
      setCustomFieldValue,
      customFieldsValuesObject,
      isValidCustomFields,
    }
  }

  const closeConsumerModal = () => {
    setShowConsumerModal(false);
  }

  const getConsumerTickets = () => {
    return {
      consumerTickets,
      fetchConsumerTicketsPagination,
      hasMoreConsumerTickets,
      isLoadingConsumerTicketsPagination,
      refreshConsumerTickets,
    };
  }

  return (
    <DetailsComponent 
      t={t}
      pageType={props.pageType}
      tab={tab}
      setTab={setTab}
      showTicketDetails={props.showTicketDetails}
      setShowTicketDetails={props.setShowTicketDetails}

      showModalConsumer={showConsumerModal}
      setShowModalConsumer={setShowConsumerModal}
      closeConsumerModal={closeConsumerModal}

      handleSubmit={handleSubmit}
      isLoadingEdit={isLoadingEdit}

      getConsumerSelect={getConsumerSelect}
      getSectorSelect={getSectorSelect}
      getEmployeeSelect={getEmployeeSelect}
      getStatusSelect={getStatusSelect}
      getFolowersSelect={getFolowersSelect}
      getTagsSelect={getTagsSelect}
      getSubjectInput={getSubjectInput}
      getCustomFieldsData={getCustomFieldsData}
      getConsumerTickets={getConsumerTickets}
    />
  );
}

export default DetailsComponentController;