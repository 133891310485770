import './styles.scss';
import { IconCircleFilled, IconUserCircle } from '@tabler/icons-react';
import FadeInOut from '../../../../components/fadeInOut';
import { StatusAgent } from '../../../../core/enums/status-agent';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FC } from 'react';
import { StatusAgentComponentInterface } from './indexModel';
import { UserProfile } from '../../../../core/enums/user-profile';

const StatusAgentComponent: FC<StatusAgentComponentInterface> = (props) => {
  const showPopupClass = props.showPopup ? 'status-agent-component-popup-opened' : '';
  const isActiveOnlineClass = props.currentStatus === StatusAgent.Online ? 'status-agent-option-active' : '';
  const isActiveAwayClass = props.currentStatus === StatusAgent.Ausente ? 'status-agent-option-active' : '';
  const isActiveOfflineClass = props.currentStatus === StatusAgent.Offline ? 'status-agent-option-active' : '';
  const agentMasterClass = props.values.profile_id === UserProfile.Master ? 'status-agent-component-master' : '';

  return (
    <div className="status-agent-component" ref={props.popupStatusRef}>
      <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('button_tooltip')}</Tooltip>}>
        <span className={`status-agent-component-trigger ${showPopupClass} ${agentMasterClass}`} onClick={() => props.show()}>
          <IconCircleFilled className={`status-agent-${props.getStatusClass()}`} />
          <span className="status-agent-component-value">
            {props.getShortName() || '...'}
          </span>
          {/* { props.currentStatus === StatusAgent.Online && <span className="status-agent-component-value">{props.t('status.online')}</span> }
          { props.currentStatus === StatusAgent.Ausente && <span className="status-agent-component-value">{props.t('status.away')}</span> }
          { props.currentStatus === StatusAgent.Offline && <span className="status-agent-component-value">{props.t('status.offline')}</span> } */}
        </span>
      </OverlayTrigger>
      <FadeInOut duration={200} show={props.showPopup} style={{ zIndex: 20 }}>
        <div className="status-agent-component-popup">
          <div className="status-agent-component-popup-header">
            <IconUserCircle />
            <div className="status-agent-component-popup-header-info">
              <span className="status-agent-component-popup-header-info-name" title={props.getFullName() || '...'}>
                {props.values.user_name || '...'}
              </span>
              <span className="status-agent-component-popup-header-info-email" title={props.values.user_email || '...'}>
                {props.values.user_email || '...'}
              </span>
            </div>
          </div>
          <div className="status-agent-component-popup-action">
            <span className={`status-agent-component-popup-option status-agent-option-online ${isActiveOnlineClass}`} onClick={() => props.handleChangeStatus(StatusAgent.Online)}>
              <IconCircleFilled className='status-agent-online' />
              <span className="status-agent-component-popup-action-value">{props.t('status.online')}</span>
            </span>
            <span className={`status-agent-component-popup-option status-agent-option-away  ${isActiveAwayClass}`} onClick={() => props.handleChangeStatus(StatusAgent.Ausente)}>
              <IconCircleFilled className='status-agent-away' />
              <span className="status-agent-component-popup-action-value">{props.t('status.away')}</span>
            </span>
            <span className={`status-agent-component-popup-option status-agent-option-offline  ${isActiveOfflineClass}`} onClick={() => props.handleChangeStatus(StatusAgent.Offline)}>
              <IconCircleFilled className='status-agent-offline' />
              <span className="status-agent-component-popup-action-value">{props.t('status.offline')}</span>
            </span>
          </div>
        </div>
      </FadeInOut>
    </div>
  );
}

export default StatusAgentComponent;
