import { FC, useEffect, useState } from "react";
import { useSocketV2Context } from "../core/context/socket-context-v2";
import { TokenInterface } from "../services/requestsInterfacesModel";
import { useSelector } from "react-redux";
import { getExtension, getPassword, getOwner_id } from "../store/callcenter";
import { getToken } from "../store/token";
import { getIdUser, getNameUser, getProfileId } from "../store/user";
import UserService from "../services/user-service";
import { getShowAlertFeedback } from "../store/internal";
import AlertPositiveNegativeController from "../components/alertPositiveNegative/indexController";
import AlertDisconnectSocket from '../components/alertDisconnectSocket/indexController';

const SocketWrapper: FC = ({ children }) => {
  const { initSocket, disconnectSocket, socketConnectError } = useSocketV2Context();

  const values: TokenInterface = {
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    callcenter: {
      extension: useSelector(getExtension),
      password: useSelector(getPassword),
      owner_id: useSelector(getOwner_id),
    }
  };

  const [url_iframe] = useState(window.location.href?.includes('chat-bot-iframe'));
  const [control_panel] = useState(window.location.href?.includes('controlpanel'));

  useEffect(() => {
    if (!url_iframe && !control_panel) {
      UserService.token = values.token.value;
  
      let token = values.token.value;
      if (token) {
        initSocket(token, 'app');
      } else {
        disconnectSocket();
      }
    }
  }, [values.token.value]);

  const alertFeedback = useSelector(getShowAlertFeedback);

  return (
    <>
      { alertFeedback.visibility && 
        <AlertPositiveNegativeController 
          timestamp={Date.now()}
          visibility={alertFeedback.visibility} 
          message={alertFeedback.message} 
          signalIcon={alertFeedback.signalIcon}
          timeOut={alertFeedback.timeOut} 
        />
      }
      { socketConnectError && !url_iframe && !control_panel &&
        <AlertDisconnectSocket
          visibility={socketConnectError}
          signalIcon={alertFeedback.signalIcon} 
        />
      }
      <div id='verify-iframe-parent' style={{display: 'none'}}></div>
      {children}
    </>
  );
}

export default SocketWrapper;