import './styles.scss';
import { FC } from 'react';
import { TicketInfoInterface } from './indexModel';

const TicketInfo: FC<TicketInfoInterface> = (props) => {

  return (
    <div className="details-ticket-info-component">
      <div className="details-ticket-info-list">
        <div className="details-ticket-info-group">
          <div className="details-ticket-info-item details-ticket-info-item-small">
            <span className="ticket-info-item-label">
              {props.t('id')}
            </span>
            <span className="ticket-info-item-value">
              #{props.ticket.ticket_reference_id}
            </span>
          </div>
          <div className="details-ticket-info-item details-ticket-info-item-small">
            <span className="ticket-info-item-label">
              {props.t('bot')}
            </span>
            <span className="ticket-info-item-value">
              {props.ticket.is_bot && props.t('yes')}
              {!props.ticket.is_bot && props.t('no')}
            </span>
          </div>
        </div>
        <div className="details-ticket-info-group">
          <div className="details-ticket-info-item">
            <span className="ticket-info-item-label">
              {props.t('channel')}
            </span>
            <span className="ticket-info-item-value">
              {props.getChannel(props.ticket.channel_id).icon}
              {props.getChannel(props.ticket.channel_id).title}
            </span>
          </div>
          <div className="details-ticket-info-item">
            <span className="ticket-info-item-label">
              {props.t('ticket_creator')}
            </span>
            <span className="ticket-info-item-value">
              {!!props.ticket.ticket_created_by_employee && props.t('agent')}
              {!props.ticket.ticket_created_by_employee && props.t('consumer')}
            </span>
          </div>
        </div>
        <div className="details-ticket-info-item">
          <span className="ticket-info-item-label">
          {props.t('subject')}
          </span>
          <span className="ticket-info-item-value" title={props.ticket.subject}>
            {props.ticket.subject}
          </span>
        </div>
        <div className="details-ticket-info-item">
          <span className="ticket-info-item-label">
          {props.t('overflow_date')}
          </span>
          <span className="ticket-info-item-value" title={props.formatDate(props.ticket.updated_level2_at)}>
            {props.formatDate(props.ticket.updated_level2_at)}
          </span>
        </div>
        <div className="details-ticket-info-item">
          <span className="ticket-info-item-label">
          {props.t('last_update')}
          </span>
          <span className="ticket-info-item-value" title={props.formatDate(props.ticket.updated_at)}>
            {props.formatDate(props.ticket.updated_at)}
          </span>
        </div>
      </div>
    </div>
  );
}

export default TicketInfo;