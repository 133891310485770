import './styles.scss';
import { FC } from 'react';
import { ToolbarLinkifyInterface } from './indexModel';
import { IconLink, IconX } from '@tabler/icons-react';
import FadeInOut from '../../../../../../../../components/fadeInOut';

const ToolbarLinkify: FC<ToolbarLinkifyInterface> = (props) => {
  return (
    <div className="toolbar-linkify-component" ref={props.linkifyPopupRef}>
      <div className="toolbar-linkify-swatch" onClick={props.handleToggle}>
        <IconLink />
      </div>
      <FadeInOut show={props.showLinkifyPopup} duration={100}>
        <div className="toolbar-linkify-popover">
          <div className="toolbar-linkify-popover-header">
            <span className="toolbar-linkify-popover-title">{props.t('insert_link')}</span>
            <IconX onClick={props.handleClose} />
          </div>
          <div className="toolbar-linkify-popover-body">
            <div className="toolbar-linkify-popover-body-item">
              <span className="toolbar-linkify-popover-body-item-text-label">{props.t('text')}</span>
              <input 
                type="text" 
                value={props.selectedText} 
                onChange={props.handleSelectChange}
                placeholder={props.t('text_placeholder')}
                required={true}
                className={props.selectedText?.length === 0 ? 'toolbar-linkify-popover-url-invalid' : ''}
              />
            </div>
            <div className="toolbar-linkify-popover-body-item">
              <span className="toolbar-linkify-popover-body-item-text-label">{props.t('link')}</span>
              <input 
                className={!props.isValidUrl ? 'toolbar-linkify-popover-url-invalid' : ''}
                id='toolbar-linkify-item-url'
                type="text" 
                value={props.url} 
                onChange={props.handleUrlChange} 
                placeholder={props.t('link_placeholder')}
                required={true}
              />
            </div>
          </div>
          <div className="toolbar-linkify-popover-footer">
            <button className="toolbar-linkify-popover-action-cancel" onClick={props.handleClose}>Cancelar</button>
            { props.hasLink && 
              <button className="toolbar-linkify-popover-action-cancel" onClick={props.handleRemoveLink}>{props.t('remove_link')}</button>
            }
            <button 
              className="toolbar-linkify-popover-action-submit" 
              onClick={props.handleSubmit} 
              disabled={props.selectedText?.length === 0 || props.url?.length === 0 || !props.isValidUrl}
              >
              {props.t('save')}
            </button>
          </div>
        </div>
      </FadeInOut>
    </div>
  );
};

export default ToolbarLinkify;
