import './styles.scss';
import { FC } from 'react';
import { ModalTriggerTypeInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomInputController from '../../../../../components/customInput/indexController';

const ModalTriggerType: FC<ModalTriggerTypeInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-trigger-type-component"
    >
      <Modal.Header>
        <Modal.Title>
          { props.data?.id ? props.t('title_edit') : props.t('title_new') }
        </Modal.Title>
        <div className="modal-trigger-type-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingEdit) && <Loading blur={true}/>}
        { props.data &&
          <>
          <div className="modal-trigger-type-section">
            <div className="modal-trigger-type-item">
              <div className="modal-trigger-type-item-header">
                <span className="modal-trigger-type-item-label">{props.t('form.name_label')}</span>
                <span className="modal-trigger-type-item-label-required">*</span>
              </div>
              <CustomInputController 
                type='input'
                value={props.data?.name || ''}
                setCustomValue={props.setDataValue}
                placeholder={props.t('form.name_placeholder')}
                required={true}
                id={'name'}
              />
            </div>
          </div>
          <div className="modal-trigger-type-section">
            <div className="modal-trigger-type-section-status">
              <div className="modal-trigger-type-section-status-info">
                <span className="modal-trigger-type-section-status-title">{props.t('form.status_label')}</span>
                <span className="modal-trigger-type-section-status-message">
                  {props.t(props.data.active ? 'form.status_message_active' : 'form.status_message_inactive')}
                </span>
              </div>
              <div className="modal-trigger-type-section-status-switch">
                <div className="switch-input">
                  <div className="switch-input">
                    <OverlayTrigger overlay={<Tooltip>{props.data.active ? props.t('form.status_active') : props.t('form.status_inactive')}</Tooltip>}>
                      <label className="switch">
                        <input type="checkbox" checked={props.data.active} onChange={() => props.setDataValue('active', !props.data.active)} />
                        <span className="slider round"></span>
                      </label>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-trigger-type-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-trigger-type-btn-save' disabled={!props.isValid() || props.loadingEdit} onClick={() => props.handleSubmit()}>
          { props.data?.id && props.t('btn_save')}
          { !props.data?.id && props.t('btn_create')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTriggerType;
