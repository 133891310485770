import React, { FC } from 'react';
import { Row, Col, ListGroup, OverlayTrigger, Tooltip, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Bar, Pie } from 'react-chartjs-2';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';
import { t } from 'i18next';
import ButtonListNotificationController from '../../../components/buttonListNotification/indexController';
import ButtonStatusAgent from '../components/buttonStatusAgent';
import Loading from '../../../components/loading';
import CallNotificationBar from '../components/callNotificationBar';
import AcquirePlatformNotificationBar from '../components/acquirePlatformNotificationBar';
import { useSelector } from 'react-redux';
import { getShowAcquirePlatformComponent, getShowCallNotificationBar } from '../../../store/internal';
import Utils from '../../../core/shared/utils';
import ChartDataLabels from "chartjs-plugin-datalabels";
import DataDashboardController from './dataDashboard/indexController';
import ListRankingController from './listRanking/indexController';
import { IHomeDashboard } from './indexModel';
import { Chart, ArcElement } from 'chart.js';
Chart.register(ArcElement, ChartDataLabels);

const HomeDashboard: FC<IHomeDashboard> = (props) => {
    return (
        <>
            <Container fluid className="nopadding" >
                <Loading visibility={props.isLoading}></Loading>
                <div className="wrapper" >
                    {/* <SidebarController currentPage={SidebarButtonPage.Dashboard} subPage={SidebarButtonPage.DashboardSubGeral} className='nopadding'></SidebarController> */}
                    <div className="content pt-0 ps-0" style={{ backgroundColor: "#F9FBff", overflowY: "scroll", maxHeight: 'calc(100vh - 134px)' }}>
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        {props.printPDF ?
                            <></>
                            :
                            // <Row className="nopadding mt-3 ps-3" >
                            //     <Col md={12} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                            //         <Row>
                            //             <Col className="d-flex justify-content-start align-self-start">
                            //                 <div style={{ font: 'var(--px20_24_Bold-font)' }}>{`${t('dashboard.bem_vindo_dashboard')}`}</div>
                            //             </Col>
                            //             <Col className="d-flex justify-content-end">
                            //                 <ButtonListNotificationController/>
                            //                 <ButtonStatusAgent/>
                            //             </Col>
                            //         </Row>
                            //         <Row className="mt-2">
                            //             <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{`${t('dashboard.descricao')}`}</div>
                            //         </Row>
                            //     </Col>
                            // </Row>
                            <></>
                        }
                        <div ref={props.componentRef} style={{ backgroundColor: "#F9FBff" }}>
                            <style type='text/css' media="print">
                                {"\
                                        @page {\ size: landscape;\ }\
                                    "}
                            </style>
                            {props.printPDF ?
                                <div style={{ height: '190px', backgroundColor: '#E5ECFF' }}>
                                    <Row className=" justify-content-center" >
                                        <Col md={10}>
                                            <Row style={{ marginTop: '4vh', marginLeft: '2vw' }}>
                                                <Col className="d-flex">
                                                    <div style={{ font: 'normal normal bold 25px/30px Montserrat' }}>{t('dashboard.title')}</div>
                                                </Col>
                                            </Row>
                                            <Row style={{ font: 'normal normal normal 16px/21px Roboto', color: '#1E1E1E', marginTop: '4vh', marginLeft: '2vw' }}>
                                                <Col md={3} style={{ width: '25vw' }}>
                                                    {`${t('dashboard.periodo')}`}
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {`${t('dashboard.canal')}`}
                                                </Col>
                                                <Col md={1} style={{ width: '8.3vw' }}>
                                                    {`${t('dashboard.tags')}`}
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {`${t('dashboard.atribuicao')}`}
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {`${t('dashboard.setor')}`}
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {`${t('dashboard.consumidor')}`}
                                                </Col>
                                                {/* <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>Aqui você pode visualizar os indicadores da sua empresa</div> */}
                                            </Row>
                                            <Row style={{ font: 'normal normal bold 16px/21px Roboto', color: '#1E1E1E', marginLeft: '2vw' }}>
                                                <Col md={3} style={{ width: '25vw' }}>
                                                    {props.currentParamsNames["start_date"] && props.currentParamsNames["end_date"] ?
                                                        Utils.formatedDate(props.currentParamsNames["start_date"]) + " à " + Utils.formatedDate(props.currentParamsNames["end_date"])
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {props.currentParamsNames["channel"] ?
                                                        props.currentParamsNames["channel"]
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col md={1} style={{ width: '8.3vw' }}>
                                                    {props.currentParamsNames["tag"] ?
                                                        props.currentParamsNames["tag"]
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {props.currentParamsNames["employee"] ?
                                                        props.currentParamsNames["employee"]
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {props.currentParamsNames["sector"] ?
                                                        props.currentParamsNames["sector"]
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                                <Col md={2} style={{ width: '16vw' }}>
                                                    {props.currentParamsNames["consumer"] ?
                                                        props.currentParamsNames["consumer"]
                                                        :
                                                        "-"
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                :
                                <div className='FilterTicketsRow ps-3' style={{ margin: '2vh 0.5vw 0px', padding: '0', marginTop: '0' }}>
                                    <ListGroup variant="flush" >
                                        <ListGroup.Item style={{ backgroundColor: '#CEE2FF', padding: '0.6%' }}>
                                            <FilterOptionComponentController
                                                optionNames={["Data", "Canal", "Tag", "Atribuido", "Setor", "Consumer"]}
                                                currentState={props.currentState}
                                                setCurrentState={props.setCurrentState}
                                                values_user={props.user}
                                                values_internal_getShowEditTicketsModal={false}
                                                values_internal_getShowCreateTicketsModal={false}
                                                filteringTickets={(e, auxObj, dataObj, action) => props.filteringTickets(e, auxObj, dataObj, action)}
                                                cleanList={() => props.cleanList()}
                                                values_token_value={props.token}
                                                values_company_id={props.company}
                                                resetingFilters={props.resetingFilters}
                                                setResetingFilters={props.setResetingFilters}
                                                yoursTickets={props.yoursTickets}
                                                setYoursTickets={props.setYoursTickets}
                                                dashboardMetrics={true}
                                                firstAndCurrentDay={[props.getDate(), props.currentDayDate]}
                                                setFilterDates={props.setCurrentFilterDates}
                                                setTag={props.setTag}
                                                setChannel={props.setChannel}
                                                setDepartmentId={props.setDepartmentId}
                                                setEmployee={props.setEmployee}
                                                setConsumer={props.setConsumer}
                                                screen={'home_dashboard'}
                                            />
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            }
                            <Row style={{ margin: '2vh 2vw 0px', padding: '0' }}>
                                {
                                    props.dataChart && 
                                    <DataDashboardController
                                        totalTickets={props.dataChart.result['tickets-total']}
                                        inProgressTickets={props.dataChart.result['tickets-running']}
                                        newTickets={props.dataChart.result['tickets-new']}
                                        totalConsumers={props.dataChart.result['consumers-total']}
                                        // totalExchangeMessages={dataChart['messages-count']}
                                        // totalSentMessages={dataChart['messages-sent-count']}
                                        averageTicketsTime={props.dataChart.result['average-attendance-time']}
                                        averageWaitingTime={props.dataChart.result['average-waiting-time']}
                                        dropoutRate={props.dataChart.result['dropout-rate']}//taxa de desistência
                                        averageTicketsSolved={props.dataChart.result['tickets-solved']}
                                        printPDF={props.printPDF}
                                        currentParams={props.currentParams} 
                                        setCurrentParams={props.setCurrentParams}
                                    />
                                }
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={4} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.tickets_hora_periodo')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.tickets_hora_periodo_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                        {props.printPDF ? <></> :
                                            <Col className='d-flex justify-content-end'>
                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={props.popover}>
                                                    <div style={{ cursor: 'pointer' }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                            <path d="M12 17v-6" />
                                                            <path d="M9.5 14.5l2.5 2.5l2.5 -2.5" />
                                                        </svg>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        }
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        <Col >
                                            <Bar plugins={[ChartDataLabels]} options={props.options} data={props.data} style={{ height: '30vh', width: props.printPDF ? '105vh' : '67.4vw' }} />
                                        </Col>
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '10vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={3} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.tickets_per_tags')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.tickets_per_tags_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        {props.dataTagPieChart ? 
                                            <Col>
                                                <Bar 
                                                    plugins={[ChartDataLabels]} 
                                                    data={props.dataTagPieChart} 
                                                    options={props.optionsBarCharTag} 
                                                    style={
                                                            props.dataTagPieChart.datasets[0].data.length > 8 ? { height: '80vh', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataTagPieChart.datasets[0].data.length > 5 ? { height: '400px', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataTagPieChart.datasets[0].data.length > 3 ? { height: '300px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : props.dataTagPieChart.datasets[0].data.length === 1 ? { height: '100px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : { height: '180px', width: props.printPDF ? '120vh' : '67.4vw' }
                                                    } 
                                                />
                                            </Col>
                                            :
                                            <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '10vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={3} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.tickets_per_channel')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.tickets_per_channel_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        {props.dataChannelPieChart ? 
                                            <Col>
                                                <Bar 
                                                    plugins={[ChartDataLabels]} 
                                                    data={props.dataChannelPieChart} 
                                                    options={props.optionsBarCharChannel} 
                                                    style={
                                                            props.dataChannelPieChart.datasets[0].data.length > 8 ? { height: '80vh', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataChannelPieChart.datasets[0].data.length > 5 ? { height: '400px', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataChannelPieChart.datasets[0].data.length > 3 ? { height: '300px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : props.dataChannelPieChart.datasets[0].data.length === 1 ? { height: '100px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : { height: '180px', width: props.printPDF ? '120vh' : '67.4vw' }
                                                    } 
                                                />
                                            </Col>
                                            :
                                            <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row style={{ margin: props.printPDF ? '100vh 1.5vw 0px': '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }}>
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFF", borderRadius: "10px", paddingBottom: "65px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={4} className='d-flex justify-content-start'>
                                            <p className='nopadding' style={{ color: '#1e1e1e', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.amount_active_desactive')}`}
                                            </p>
                                            <OverlayTrigger
                                                key='top'
                                                placement='top'
                                                overlay={
                                                    <Tooltip>
                                                        {`${t('dashboard.tickets_active_receptive')}`}
                                                    </Tooltip>
                                                }
                                            >
                                                <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                    <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                </svg>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    {props.dataActivePieReceptive?.datasets[0].data.length > 0 ?
                                        <Row style={{ height: '35vh' }} className='d-flex justify-content-center'>
                                            <Col md='5'>
                                                <Pie
                                                    data={props.dataActivePieReceptive}
                                                    options={props.optionsPieChart}
                                                    style={{ height: props.printPDF ? '30vh' : '', width: props.printPDF ? '36vw' : '', marginLeft: props.printPDF ? '30vw' : '', fontSize: '11px' }}
                                                />
                                            </Col>
                                        </Row>
                                        :
                                        <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '10vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={3} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.tickets_per_sector')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.tickets_per_sector_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        {props.dataSectorPieChart ? 
                                            <Col>
                                                <Bar 
                                                    plugins={[ChartDataLabels]} 
                                                    data={props.dataSectorPieChart} 
                                                    options={props.optionsBarCharSector} 
                                                    style={
                                                            props.dataSectorPieChart.datasets[0].data.length > 8 ? { height: '80vh', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataSectorPieChart.datasets[0].data.length > 5 ? { height: '400px', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataSectorPieChart.datasets[0].data.length > 3 ? { height: '300px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : props.dataSectorPieChart.datasets[0].data.length === 1 ? { height: '100px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : { height: '180px', width: props.printPDF ? '120vh' : '67.4vw' }
                                                    } 
                                                />
                                            </Col>
                                            :
                                            <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '100vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={3} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.tickets_per_employee')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.tickets_per_employee_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        {props.dataEmployeeChart ? 
                                            <Col>
                                                <Bar 
                                                    plugins={[ChartDataLabels]} 
                                                    data={props.dataEmployeeChart} 
                                                    options={props.optionsBarCharEmployee} 
                                                    style={
                                                        props.dataEmployeeChart.datasets[0].data.length > 15 ? { height: '120vh', width: props.printPDF ? '105vh' : '67.4vw' }
                                                        : props.dataEmployeeChart.datasets[0].data.length > 8 ? { height: '80vh', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                        : props.dataEmployeeChart.datasets[0].data.length > 5 ? { height: '400px', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                        : props.dataEmployeeChart.datasets[0].data.length > 3 ? { height: '300px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                        : props.dataEmployeeChart.datasets[0].data.length === 1 ? { height: '100px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                        : { height: '180px', width: props.printPDF ? '120vh' : '67.4vw' }
                                                    } 
                                                />
                                            </Col>
                                            :
                                            <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '10vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <Row style={{ margin: '2vh 0vw 0px' }}>
                                        <Col md={3} className="d-flex justify-content-start" >
                                            <p className="nopadding" style={{ color: '#1E1E1E', font: 'normal normal bold min(max(10px, calc(0.2em + 0.75vw)), 15px) Montserrat' }}>
                                                {`${t('dashboard.nps_Score_metrics')}`}
                                            </p>
                                            {props.printPDF ?
                                                <></>
                                                :
                                                <OverlayTrigger
                                                    key='top'
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip id='tooltip'>
                                                            {`${t('dashboard.nps_Score_metrics_tooltip')}`}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                                        <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                        <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                    </svg>
                                                </OverlayTrigger>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className={'mb-3 justify-content-center'} style={{ margin: '1vh 0vw 0px' }}>
                                        {props.dataNpsPieChart ? 
                                            <Col>
                                                <Bar 
                                                    plugins={[ChartDataLabels]} 
                                                    data={props.dataNpsPieChart} 
                                                    options={props.optionsBarCharNps} 
                                                    style={
                                                            props.dataNpsPieChart.datasets[0].data.length > 8 ? { height: '80vh', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataNpsPieChart.datasets[0].data.length > 5 ? { height: '400px', width: props.printPDF ? '105vh' : '67.4vw' } 
                                                            : props.dataNpsPieChart.datasets[0].data.length > 3 ? { height: '300px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : props.dataNpsPieChart.datasets[0].data.length === 1 ? { height: '100px', width: props.printPDF ? '105vh' : '67.4vw' }
                                                            : { height: '180px', width: props.printPDF ? '120vh' : '67.4vw' }
                                                    } 
                                                />
                                            </Col>
                                            :
                                            <Row className='d-flex justify-content-center mb-3'>
                                            <Col className='d-flex justify-content-center align-items-center'>
                                                <Row className='d-flex justify-content-center align-content-center' style={{ /* backgroundColor: '#F9F9F9'  */}}>
                                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('dashboard.no_results')}</p>
                                                </Row>
                                            </Col>
                                        </Row>
                                        } 
                                    </Row>
                                </Col>
                                {/* <Col md={1}></Col> */}
                            </Row>
                            {/* <Row className="justify-content-center" style={{ margin: '2vh 1.5vw 0px', padding: '0' }} >
                                    {printPDF ?
                                        <></>
                                        :
                                        <>
                                            <Col md={5}>
                                                <ReactToPrint
                                                    content={() => componentRef.current}
                                                    onBeforeGetContent={() => HomeDashboardControllerConst.printIsValid()}
                                                    onAfterPrint={() => HomeDashboardControllerConst.printIsInvalid()}
                                                >
                                                    <PrintContextConsumer>
                                                        {({ handlePrint }) => (
                                                            <Button onClick={handlePrint} size='lg' type='submit' className="buttonBlue" style={{ width: '90%' }}>{`${t('dashboard.exportar_pdf_metricas')}`}</Button>
                                                        )}
                                                    </PrintContextConsumer>
                                                </ReactToPrint>
                                            </Col>
                                            <Col md={5}>
                                                <Button onClick={exportTickets} size='lg' type='submit' className="buttonBlue" style={{ width: '100%' }}>{`${t('dashboard.exportar_tickets')}`}</Button>
                                            </Col>
                                        </>
                                    }
                                </Row> */}
                            {/* <Row className={'mb-3 justify-content-center'}>
                                    <Col md={1}></Col>
                                    <Col>
                                        <ListRanking onClick={listRankingAgents} dataList={dataChart['ranking-of-ratings-nps-agent']} data={dataChart['overall-service-average-nps-agent']} updateListfunction={updateListfunction} HasMoreInformation={currentHasMoreInformation}/>
                                    </Col>
                                    <Col md={1}></Col>
                                </Row> */}
                            <Row className={props.printPDF ? '' : 'justify-content-center'} style={{ margin: props.printPDF ? '20vh 1.5vw 0px' : '2vh 1.5vw 0px', padding: '0', display: "flex", justifyContent: "center", marginBottom: "10px" }} >
                                {/* <Col md={1}></Col> */}
                                <Col md={10} style={{ padding: '0', background: "#FFFFFF", borderRadius: "10px", width: "76.5vw" }}>
                                    <ListRankingController
                                        onClick={props.listRankingAgents}
                                        currentFilterDatesInitial={new Date(props.currentFilterDates[0]).toISOString().split("T")[0]}
                                        currentFilterDateFinal={new Date(props.currentFilterDates[1]).toISOString().split("T")[0]}
                                        currentTag={props.tag}
                                        currentChannel={props.channel}
                                        currentDepartmentId={props.departmentId}
                                        currentEmployee={props.employee}
                                        currentConsumer={props.consumer}
                                        dataList={props.dataChart? props.dataChart.result['ranking-of-ratings-nps-agent']: []}
                                        data={props.dataChart? props.dataChart.result['overall-service-average-nps-agent']: 0}
                                        updateListfunction={props.updateListfunction}
                                        HasMoreInformation={props.currentHasMoreInformation}
                                        printPDF={props.printPDF}
                                    />
                                </Col>
                                {/* <Col md={1}></Col> */}
                                {/* <Button type='submit' className='buttonBlue' style={{ width: "95%" }}>Exportar PDF das métricas</Button> */}
                            </Row>
                            {/* <Row className='justify-content-center'>
                                    <Button onClick={listRankingAgents} size='lg' type='submit' className='buttonBlue' style={{ width: '85%' }}>{`${t('dashboard.exportar_tickets')}`}</Button>
                                </Row> */}
                            {/* <Row className="justify-content-center" style={{ margin: '2vh 1.5vw 10vh', padding: '0' }} >
                                    {printPDF ?
                                        <></>
                                        :
                                        <Col md={10}>
                                            <Button onClick={exportTickets} size='lg' type='submit' className="buttonBlue" style={{ width: '100%' }}>Exportar Tickets</Button>
                                        </Col>
                                    }
                                </Row> */}
                        </div>
                    </div>
                </div>
            </Container >
        </>
    );
}

export default HomeDashboard;