import './styles.scss';
import { FC } from 'react';
import { ListOfTagsInterface } from './indexModel';
import ModalTagController from '../components/modalTag/indexController';
import ModalDeleteTagController from '../components/modalDeleteTag/indexController';
import ModalChangeStatusTagController from '../components/modalChangeStatusTag/indexController';
import FilteredViewBarController from '../../../components-fixed/searchComponent/filteredViewBar/indexController';
import CustomTableListController from '../../../../../components/customTableList/indexController';
import { IconTags } from '@tabler/icons-react';

const ListOfTags: FC<ListOfTagsInterface> = (props) => {
  const tabTicketActive = props.tab === 'ticket' ? 'list-tags-tab-item-active' : '';
  const tabConsumerActive = props.tab === 'consumer' ? 'list-tags-tab-item-active' : '';

  return (
    <div className="list-tags-component">
      { props.showModalTag && <ModalTagController show={props.showModalTag} hide={props.closeModalTag} type={props.tab} />}
      { props.showModalDeleteTag && <ModalDeleteTagController show={props.showModalDeleteTag} hide={props.closeModalDeleteTag} type={props.tab} />}
      { props.showModalChangeStatusTag && <ModalChangeStatusTagController show={props.showModalChangeStatusTag} hide={props.closeModalChangeStatusTag} type={props.tab} /> }

      <div className="list-tags-tabs">
        <span className={`list-tags-tab-item ${tabTicketActive}`} onClick={() => props.changeTab('ticket')}>{props.t('tab_ticket')}</span>
        <span className={`list-tags-tab-item ${tabConsumerActive}`} onClick={() => props.changeTab('consumer')}>{props.t('tab_consumer')}</span>
      </div>
      <div className="list-tags-page-info">
        { props.filters?.length > 0 && props.tab === 'ticket' &&
          <FilteredViewBarController 
            pageType='tags-ticket'
            filters={props.filters}
            filterMode="simple"
            clearSearch={props.clearSearchTagsTicket}
            clearSpecificFilter={props.clearSpecificFilter}
          />
        }
        { props.filters?.length > 0 && props.tab === 'consumer' &&
          <FilteredViewBarController 
            pageType='tags-consumer'
            filters={props.filters}
            filterMode="simple"
            clearSearch={props.clearSearchTagsConsumer}
            clearSpecificFilter={props.clearSpecificFilter}
          />
        }
      </div>
      <div className="list-tags-page-content">
        { props.tab === 'ticket' &&
          <CustomTableListController 
            component='tags-ticket'
            text_empty_list={props.t('no_tag_ticket')}
            text_loading_list={props.t('loading_tag_ticket')}
            text_error_list={props.t('error_loading_tag_ticket')}
            icon_entity_list={<IconTags className='tabler-icon-entity' />}
            headers={props.listHeadersTagsTicket}
            data={props.listDataTicket}
            paginationDetails={props.paginationDetails}
            fetchMore={props.getTagsTicketPagination}
            isLoading={props.isLoading}
            errorLoading={props.errorLoading}
            popoverItemAction={props.popoverItemAction}
            reloadData={props.reloadTagsTicket}
            handleItemClick={props.openModalTag}
            hasItemCheckSelection={true}
            selectItem={props.selectItem}
            selectAllItems={props.selectAllItems}
            isSelectedItem={props.isSelectedItem} 
            selectedItemsLength={props.selectedItemsLength}
            hasSomeSelected={props.hasSomeSelected}
            hasSomeSelectedCheck={props.hasSomeSelectedCheck}
            isAllSelectedItems={props.isAllSelectedItems}
            hasDeleteSelected={true}
            deleteSelected={props.deleteSelected}
            hasActivateSelected={true}
            activateSelected={props.activateSelected}
            hasInactivateSelected={true}
            inactivateSelected={props.inactivateSelected}
            clearSelected={props.clearSelected}
            clearPageSelected={props.clearPageSelected}
          />
        }
        { props.tab === 'consumer' &&
          <CustomTableListController 
            component='tags-consumer'
            text_empty_list={props.t('no_tag_consumer')}
            text_loading_list={props.t('loading_tag_consumer')}
            text_error_list={props.t('error_loading_tag_consumer')}
            icon_entity_list={<IconTags className='tabler-icon-entity' />}
            headers={props.listHeadersTagsConsumer}
            data={props.listDataConsumer}
            paginationDetails={props.paginationDetails}
            fetchMore={props.getTagsConsumerPagination}
            isLoading={props.isLoading}
            errorLoading={props.errorLoading}
            popoverItemAction={props.popoverItemAction}
            reloadData={props.reloadTagsConsumer}
            handleItemClick={props.openModalTag}
            hasItemCheckSelection={true}
            selectItem={props.selectItem}
            selectAllItems={props.selectAllItems}
            isSelectedItem={props.isSelectedItem} 
            selectedItemsLength={props.selectedItemsLength}
            hasSomeSelected={props.hasSomeSelected}
            hasSomeSelectedCheck={props.hasSomeSelectedCheck}
            isAllSelectedItems={props.isAllSelectedItems}
            hasDeleteSelected={true}
            deleteSelected={props.deleteSelected}
            hasActivateSelected={true}
            activateSelected={props.activateSelected}
            hasInactivateSelected={true}
            inactivateSelected={props.inactivateSelected}
            clearSelected={props.clearSelected}
            clearPageSelected={props.clearPageSelected}
          />
        }
      </div>
    </div>
  );
};

export default ListOfTags;
