import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null as string | null,
    name: null as string | null,
    email: null as string | null,
    profile_id: null as string | null,
    lastname:  null as string | null,
  },
  reducers: {
    setIdUser: (state, action) => {
      state.id = action.payload
    },
    setNameUser: (state, action) => {
      state.name = action.payload
    },
    setEmailUser: (state, action) => {
      state.email = action.payload
    },
    setProfileId: (state, action) => {
      state.profile_id = action.payload
    },
    setUserLastName: (state, action) => {
      state.lastname = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setIdUser, setNameUser, setEmailUser, setProfileId, setUserLastName } = userSlice.actions;

export const getIdUser = (state: { user: { id: string } }) => (state.user?.id);
export const getNameUser = (state: { user: { name: string } }) => (state.user?.name);
export const getEmailUser = (state: { user: { email: string } }) => (state.user?.email);
export const getProfileId = (state: { user: { profile_id: string } }) => (state.user?.profile_id);
export const getUserLastName = (state: { user: { lastname: string } }) => (state.user?.lastname);

export default userSlice.reducer

