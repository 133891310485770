import './styles.scss';
import { FC } from 'react';
import { ModalSectorInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconSitemap, IconX } from '@tabler/icons-react';
import Loading from '../../../../../../components/loading2';
import CustomInputController from '../../../../../../components/customInput/indexController';
import CustomSelectController from '../../../../../../components/customSelect/indexController';

const ModalSector: FC<ModalSectorInterface> = (props) => {
  const invalidEmailItemClass = props.getEmailsSelect().selectedInUse ? 'modal-sector-item-invalid' : '';
  const invalidWhatsappItemClass = props.getWhatsappKeysSelect().selectedInUse ? 'modal-sector-item-invalid' : '';
  const invalidInstagramItemClass = props.getInstagramKeysSelect().selectedInUse ? 'modal-sector-item-invalid' : '';
  const invalidFacebookItemClass = props.getFacebookKeysSelect().selectedInUse ? 'modal-sector-item-invalid' : '';

  const inUse = {
    email: props.getEmailsSelect().selectedInUse,
    whatsapp: props.getWhatsappKeysSelect().selectedInUse,
    instagram: props.getInstagramKeysSelect().selectedInUse,
    facebook: props.getFacebookKeysSelect().selectedInUse
  }

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      size="lg"
      fullscreen='sm-down'
      centered={true}
      dialogClassName="modal-sector-component"
    >
      { props.mode &&
        <Modal.Header>
          { props.mode === 'edit' && <Modal.Title>{props.t('title_edit')}</Modal.Title>}
          { props.mode === 'create' && <Modal.Title>{props.t('title_new')}</Modal.Title>}
          <div className="modal-sector-header-actions">
            <IconX className="modal-sector-header-actions-close" onClick={() => props.hide(false)} />
          </div>
        </Modal.Header>
      }
      { props.loadingSector &&
        <Modal.Body className='modal-body-loading'>
          <Loading blur />
          <div className="modal-body-loading-info">
            <IconSitemap />
            <span className="modal-body-loading-info-message">
              {props.t('loading_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingSector && props.errorLoadingSector &&
        <Modal.Body className='modal-body-loading'>
          <div className="modal-body-loading-info modal-body-loading-error">
            <IconSitemap />
            <span className="modal-body-loading-info-message">
              {props.t('loading_error_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingSector && !props.errorLoadingSector &&
        <Modal.Body>
          { ((!props.sector && props.mode === 'edit') || props.loadingRequest) && <Loading blur={true}/>}
          <div className="modal-sector-section">
            <div className="modal-sector-item">
              <div className="modal-sector-item-header">
                <span className="modal-sector-item-label">{props.t('form.name_label')}</span>
                <span className="modal-sector-item-label-required">*</span>
              </div>
              <CustomInputController 
                type='input'
                value={props.name || ''}
                setValue={props.setName}
                placeholder={props.t('form.name_placeholder')}
                required={true}
                id={'name'}
              />
            </div>
            <div className="modal-sector-item">
              <div className="modal-sector-item-header">
                <span className="modal-sector-item-label">{props.t('form.agent_label')}</span>
              </div>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                showTooltipOptions={true}
                isMultiselect={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('form.agent_placeholder')}
                placeholderFilter={props.t('form.agent_search')}
                options={props.getAgentsSelect().agentsList || []}
                selectedMulti={props.getAgentsSelect().selectedAgents}
                setSelectedMulti={props.getAgentsSelect().setSelectedAgents}
                fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                hasMore={props.getAgentsSelect().hasMoreAgents}
                loading={props.getAgentsSelect().isLoadingAgentsSelect}
                search={props.getAgentsSelect().fetchSearchAgents}
              />
            </div>
            <div className="modal-sector-items-group">
              <div className={`modal-sector-item ${invalidEmailItemClass}`}>
                <div className="modal-sector-item-header">
                  <span className="modal-sector-item-label">{props.t('form.email_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={false}
                  hasSimpleFilter={true}
                  showTooltipOptions={true}
                  isMultiselect={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.email_placeholder')}
                  placeholderFilter={props.t('form.email_search')}
                  options={props.getEmailsSelect().emailsList || []}
                  selectedMulti={props.getEmailsSelect().selectedEmails}
                  setSelectedMulti={props.getEmailsSelect().setSelectedEmails}
                  fetchMore={props.getEmailsSelect().fetchEmailsPagination}
                  hasMore={props.getEmailsSelect().hasMoreEmails}
                  loading={props.getEmailsSelect().isLoadingEmailsSelect}
                  disabled={!props.status}
                />
                { props.getEmailsSelect().selectedInUse &&
                  <span className="modal-sector-item-invalid-message">
                    {props.t('form.email_in_use_alert')}
                  </span>
                }
              </div>
              <div className={`modal-sector-item ${invalidWhatsappItemClass}`}>
                <div className="modal-sector-item-header">
                  <span className="modal-sector-item-label">{props.t('form.automation_whatsapp_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={false}
                  hasSimpleFilter={true}
                  showTooltipOptions={true}
                  isMultiselect={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.automation_whatsapp_placeholder')}
                  placeholderFilter={props.t('form.automation_whatsapp_search')}
                  options={props.getWhatsappKeysSelect().whatsappKeysList || []}
                  selectedMulti={props.getWhatsappKeysSelect().selectedWhatsappKeys}
                  setSelectedMulti={props.getWhatsappKeysSelect().setSelectedWhatsappKeys}
                  fetchMore={props.getWhatsappKeysSelect().fetchWhatsappKeysPagination}
                  hasMore={props.getWhatsappKeysSelect().hasMoreWhatsappKeys}
                  loading={props.getWhatsappKeysSelect().isLoadingWhatsappKeysSelect}
                  disabled={!props.status}
                />
                { props.getWhatsappKeysSelect().selectedInUse &&
                  <span className="modal-sector-item-invalid-message">
                    {props.t('form.automation_in_use_alert')}
                  </span>
                }
              </div>
            </div>
            <div className="modal-sector-items-group">
              <div className={`modal-sector-item ${invalidInstagramItemClass}`}>
                <div className="modal-sector-item-header">
                  <span className="modal-sector-item-label">{props.t('form.automation_instagram_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={false}
                  hasSimpleFilter={true}
                  showTooltipOptions={true}
                  isMultiselect={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.automation_instagram_placeholder')}
                  placeholderFilter={props.t('form.automation_instagram_search')}
                  options={props.getInstagramKeysSelect().instagramKeysList || []}
                  selectedMulti={props.getInstagramKeysSelect().selectedInstagramKeys}
                  setSelectedMulti={props.getInstagramKeysSelect().setSelectedInstagramKeys}
                  fetchMore={props.getInstagramKeysSelect().fetchInstagramKeysPagination}
                  hasMore={props.getInstagramKeysSelect().hasMoreInstagramKeys}
                  loading={props.getInstagramKeysSelect().isLoadingInstagramKeysSelect}
                  disabled={!props.status}
                />
                { props.getInstagramKeysSelect().selectedInUse &&
                  <span className="modal-sector-item-invalid-message">
                    {props.t('form.email_in_use_alert')}
                  </span>
                }
              </div>
              <div className={`modal-sector-item ${invalidFacebookItemClass}`}>
                <div className="modal-sector-item-header">
                  <span className="modal-sector-item-label">{props.t('form.automation_facebook_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={false}
                  hasSimpleFilter={true}
                  showTooltipOptions={true}
                  isMultiselect={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.automation_facebook_placeholder')}
                  placeholderFilter={props.t('form.automation_facebook_search')}
                  options={props.getFacebookKeysSelect().facebookKeysList || []}
                  selectedMulti={props.getFacebookKeysSelect().selectedFacebookKeys}
                  setSelectedMulti={props.getFacebookKeysSelect().setSelectedFacebookKeys}
                  fetchMore={props.getFacebookKeysSelect().fetchFacebookKeysPagination}
                  hasMore={props.getFacebookKeysSelect().hasMoreFacebookKeys}
                  loading={props.getFacebookKeysSelect().isLoadingFacebookKeysSelect}
                  disabled={!props.status}
                />
                { props.getFacebookKeysSelect().selectedInUse &&
                  <span className="modal-sector-item-invalid-message">
                    {props.t('form.email_in_use_alert')}
                  </span>
                }
              </div>
            </div>
            <div className="modal-sector-item">
              <div className="modal-sector-item-header">
                <span className="modal-sector-item-label">{props.t('form.keywords_label')}</span>
              </div>
              <CustomInputController 
                type='tags'
                tagsValue={props.keywords}
                placeholder={props.t('form.keywords_placeholder')}
                setTagsValue={props.setKeywords}
                required={true}
              />
            </div>
          </div>
          { (inUse.email && (inUse.whatsapp || inUse.instagram || inUse.facebook)) &&
            <div className="modal-sector-section section-margin-top">
              <div className="modal-sector-section-alert">
                <span className="modal-sector-section-alert-message">
                  {props.t(props.mode === 'edit' ? 'section_alert.in_use_edit_email_automation' : 'section_alert.in_use_create_email_automation')}
                </span>
              </div>
            </div>
          }
          { (!inUse.email && (inUse.whatsapp || inUse.instagram || inUse.facebook)) &&
            <div className="modal-sector-section section-margin-top">
              <div className="modal-sector-section-alert">
                <span className="modal-sector-section-alert-message">
                  {props.t(props.mode === 'edit' ? 'section_alert.in_use_edit_automation' : 'section_alert.in_use_create_automation')}
                </span>
              </div>
            </div>
          }
          { (inUse.email && !inUse.whatsapp && !inUse.instagram && !inUse.facebook) &&
            <div className="modal-sector-section section-margin-top">
              <div className="modal-sector-section-alert">
                <span className="modal-sector-section-alert-message">
                  {props.t(props.mode === 'edit' ? 'section_alert.in_use_edit_email' : 'section_alert.in_use_create_email')}
                </span>
              </div>
            </div>
          }
          <div className="modal-sector-section section-margin-top">
            <div className="modal-sector-section-check">
              <div className="modal-sector-section-check-info">
                <span className="modal-sector-section-check-title">{props.t('form.status_label')}</span>
                <span className="modal-sector-section-check-message">{props.status ? props.t('form.status_message_active') : props.t('form.status_message_inactive')}</span>
              </div>
              <div className="modal-sector-section-check-switch">
                <div className="switch-input">
                  <div className="switch-input">
                    <OverlayTrigger overlay={<Tooltip>{props.status ? props.t('form.status_active') : props.t('form.status_inactive')}</Tooltip>}>
                      <label className="switch">
                        <input type="checkbox" checked={props.status} onChange={() => props.setStatus(!props.status)} />
                        <span className="slider round"></span>
                      </label>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        <button className='modal-sector-btn-cancel' onClick={() => props.hide(false)}>
          {props.t('btn_cancel')}
        </button>
        <button className='modal-sector-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
          {props.t(props.mode === 'edit' ? 'btn_save' : 'btn_create')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSector;
