import './styles.scss';
import { FC } from 'react';
import { ToolbarColorPickerInterface } from './indexModel';
import { SketchPicker } from 'react-color';
import { IconPalette, IconTextColor } from '@tabler/icons-react';
import FadeInOut from '../../../../../../../../components/fadeInOut';


const ToolbarColorPicker: FC<ToolbarColorPickerInterface> = (props) => {
  return (
    <div className="toolbar-color-picker-component" ref={props.colorPickerRef}>
      <div className="toolbar-color-picker-swatch">
        { props.type === 'text' && <IconTextColor className='toolbar-color-picker-text' />}
        { props.type === 'text' && <div className="toolbar-color-picker-color-text" style={{ background: `${props.color}`}} />}
        { props.type === 'background' && <IconPalette />}
        { props.type === 'background' && <div className="toolbar-color-picker-color-background" style={{ background: `${props.color}`}} />}
      </div>
      <FadeInOut duration={100} show={props.showColorPicker}>
        <div className="toolbar-color-picker-popover">
          <SketchPicker color={props.color} onChange={props.handleChange} disableAlpha={true} />
          <button onClick={props.applyColor}>
            <span className="selected-color" style={{ backgroundColor: props.color.toString()}}></span>
            {props.t('use_current_color')}
          </button>
          <button onClick={props.removeColor}>
            { props.type === 'text' && <>{props.t('remove_text_color')}</> }
            { props.type === 'background' && <>{props.t('remove_background_color')}</> }
          </button>
        </div>
      </FadeInOut>
    </div>
  );
};

export default ToolbarColorPicker;
