import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { TicketInterface, TicketMessageInterface } from '../../pages/homeApp/homeTickets2/viewTicket/indexModel';
import { UserConsumerInterface } from '../../pages/homeApp/homeConsumers2/listOfConsumers/indexModel';

export const ViewTicketContext = createContext<ViewTicketContextType>(null);

export const ViewTicketProvider = ({ children }) => {

  const [currentTicket, setCurrentTicket] = useState<TicketInterface>(null);
  const [currentConsumer, setCurrentConsumer] = useState<UserConsumerInterface>(null);
  const [ticketMessages, setTicketMessages] = useState<TicketMessageInterface[]>([]);
  const [ticketIsBlocked, setTicketIsBlocked] = useState(false);
  const [ticketUsers, setTicketUsers] = useState<{ [key: string]: string }>();
  const [showConsumerModal, setShowConsumerModal] = useState(false);
  const [replyMessage, setReplyMessage] = useState<TicketMessageInterface>(null);
  
  // Loadings
  const [loadingFetchData, setLoadingFetchData] = useState(false);
  const [isLoadingConsumersList, setIsLoadingConsumersList] = useState(false);
  const [isLoadingSelectedConsumer, setIsLoadingSelectedConsumer] = useState(false);
  const [isLoadingSectorsList, setIsLoadingSectorsList] = useState(false);
  const [isLoadingEmployeesList, setIsLoadingEmployeesList] = useState(false);
  const [isLoadingFollowersList, setIsLoadingFollowersList] = useState(false);
  const [isLoadingTagsList, setIsLoadingTagsList] = useState(false);
  const [isLoadingTicketCustomFields, setIsLoadingTicketCustomFields] = useState(false);
  const [isLoadingCustomFieldsList, setIsLoadingCustomFieldsList] = useState(false);
  const [isLoadingConsumerTickets, setIsLoadingConsumerTickets] = useState(false);
  const [isLoadingSendMessage, setIsLoadingSendMessage] = useState(false);
  const [isLoadingTicketEdit, setIsLoadingTicketEdit] = useState(false);

  const isLoadingRequest = () => {
    return isLoadingSendMessage || isLoadingTicketEdit;
  }

  const ProviderValue: ViewTicketContextType = {
    loadingFetchData, setLoadingFetchData,
    isLoadingConsumersList, setIsLoadingConsumersList,
    isLoadingSelectedConsumer, setIsLoadingSelectedConsumer,
    isLoadingSectorsList, setIsLoadingSectorsList,
    isLoadingEmployeesList, setIsLoadingEmployeesList,
    isLoadingFollowersList, setIsLoadingFollowersList,
    isLoadingTagsList, setIsLoadingTagsList,
    isLoadingTicketCustomFields, setIsLoadingTicketCustomFields,
    isLoadingCustomFieldsList, setIsLoadingCustomFieldsList,
    isLoadingConsumerTickets, setIsLoadingConsumerTickets,

    setIsLoadingSendMessage,
    setIsLoadingTicketEdit,
    isLoadingRequest,

    currentTicket, setCurrentTicket,
    currentConsumer, setCurrentConsumer,
    ticketMessages, setTicketMessages,
    ticketIsBlocked, setTicketIsBlocked,
    ticketUsers, setTicketUsers,
    showConsumerModal, setShowConsumerModal,
    replyMessage, setReplyMessage,
  };

  return (
    <ViewTicketContext.Provider value={ProviderValue}>
      {children}
    </ViewTicketContext.Provider>
  );
};

export const useViewTicketContext = () => useContext(ViewTicketContext);

type ViewTicketContextType = {
  loadingFetchData: boolean;
  setLoadingFetchData: Dispatch<SetStateAction<boolean>>;

  isLoadingConsumersList: boolean;
  setIsLoadingConsumersList: Dispatch<SetStateAction<boolean>>;

  isLoadingSelectedConsumer: boolean;
  setIsLoadingSelectedConsumer: Dispatch<SetStateAction<boolean>>;

  isLoadingSectorsList: boolean;
  setIsLoadingSectorsList: Dispatch<SetStateAction<boolean>>;

  isLoadingEmployeesList: boolean;
  setIsLoadingEmployeesList: Dispatch<SetStateAction<boolean>>;

  isLoadingFollowersList: boolean;
  setIsLoadingFollowersList: Dispatch<SetStateAction<boolean>>;

  isLoadingTagsList: boolean;
  setIsLoadingTagsList: Dispatch<SetStateAction<boolean>>;

  isLoadingTicketCustomFields: boolean;
  setIsLoadingTicketCustomFields: Dispatch<SetStateAction<boolean>>;

  isLoadingCustomFieldsList: boolean;
  setIsLoadingCustomFieldsList: Dispatch<SetStateAction<boolean>>;

  isLoadingConsumerTickets: boolean;
  setIsLoadingConsumerTickets: Dispatch<SetStateAction<boolean>>;

  setIsLoadingSendMessage: Dispatch<SetStateAction<boolean>>;
  setIsLoadingTicketEdit: Dispatch<SetStateAction<boolean>>;
  isLoadingRequest: () => boolean;

  // ###################################### //

  currentTicket: TicketInterface;
  setCurrentTicket: Dispatch<SetStateAction<TicketInterface>>;

  currentConsumer: UserConsumerInterface;
  setCurrentConsumer: Dispatch<SetStateAction<UserConsumerInterface>>;

  ticketMessages: TicketMessageInterface[];
  setTicketMessages: Dispatch<SetStateAction<TicketMessageInterface[]>>;

  ticketIsBlocked: boolean;
  setTicketIsBlocked: Dispatch<SetStateAction<boolean>>;

  ticketUsers: { [key: string]: string }; 
  setTicketUsers: Dispatch<SetStateAction<{[key: string]: string;}>>;

  showConsumerModal: boolean;
  setShowConsumerModal: Dispatch<SetStateAction<boolean>>;

  replyMessage: TicketMessageInterface;
  setReplyMessage: Dispatch<SetStateAction<TicketMessageInterface>>;
}