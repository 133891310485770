import './styles.scss';
import { FC } from 'react';
import { FileViewerInterface } from './indexModel';
import { getIconFileType } from '../../../chatEditorComponent/components/toolbarFileUploader/indexModel';
import { IconChevronLeft, IconChevronRight, IconDownload, IconEye, IconWindowMaximize, IconX } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AudioPlayerController from '../audioPlayer/indexController';

const FileViewer: FC<FileViewerInterface> = (props) => {
  const noContentClass = props.hasMessageContent ? '' : 'file-viewer-component__list-attachments-no-content';

  return (
    <div className="file-viewer-component">
      { props.type === 'messages' &&
        <div className={`file-viewer-component__list-attachments ${noContentClass}`}>
          {props.attachments?.map((item) => {
            const audioItemClass = item.type?.includes('audio') ? 'file-viewer-component__attachment-item__audio_file' : '';
            return (
              <span key={item._id} className={`file-viewer-component__attachment-item ${audioItemClass}`}>
                { !item.type?.includes('audio') &&
                  <>
                  <span className="file-viewer-component_attachment-item-overlay">
                    <span className="file-viewer-component_attachment-item-name" title={item.filename} onClick={() => props.handleSelectAttachment(item)}>
                      {item.filename}
                    </span>
                    <span className="file-viewer-component_attachment-actions">
                      <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('download_file')}</Tooltip>}>
                        <span className="file-viewer-component_attachment-actions-item" onClick={() => props.handleDownloadFile(item, null)}>
                          <IconDownload />
                        </span>
                      </OverlayTrigger>
                      { item.type?.includes('image') &&
                        <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('view')}</Tooltip>}>
                          <span className="file-viewer-component_attachment-actions-item" onClick={() => props.handleSelectAttachment(item)}>
                            <IconEye />
                          </span>
                        </OverlayTrigger>
                      }
                    </span>
                  </span>
                  { item.type?.includes('image') &&
                    <img key={item._id} src={item.url} alt=""  />
                  }
                  { !item.type?.includes('image') &&
                    getIconFileType(item.type)
                  }
                  </>
                }
                { item.type?.includes('audio') &&
                  <AudioPlayerController 
                    audioUrl={item.url} 
                    filename={item.filename} 
                    currentUrlPlaying={props.currentAudioPlayingUrl} 
                    setCurrentAudioPlayingUrl={props.setCurrentAudioPlayingUrl} 
                  />
                }
              </span>
            );
          })}
        </div>
      }
      { (props.type === 'preview-upload' || props.type === 'template-message-attachment') && props.parentComponent }
      { props.showFileViewer &&
        <div className="file-viewer-component-wrapper">
          <div className="file-viewer-component-wrapper__overlay" />
          <span className="file-viewer-component-wrapper__actions" ref={props.prevewActionsRef}>
            <OverlayTrigger placement='left' overlay={<Tooltip>{props.t('open_image_in_new_tab')}</Tooltip>}>
              <>
              { props.type === 'messages' &&  <IconWindowMaximize className='file-viewer-component-wrapper__open' onClick={() => props.handleDownloadFile(props.selectedAttachment, null)} />}
              { props.type === 'preview-upload' && <IconDownload className='file-viewer-component-wrapper__open' onClick={() => props.handleDownloadFile(null, props.selectedFileUpload)} /> }
              { props.type === 'template-message-attachment' && <IconDownload className='file-viewer-component-wrapper__open' onClick={() => props.handleDownloadFile(null, null)} /> }
              </>
            </OverlayTrigger>
            <IconX className='file-viewer-component-wrapper__close' onClick={props.handleClose} />
          </span>
          { (props.type === 'messages' || props.type === 'preview-upload' || props.type === 'template-message-attachment') &&
            <div className="file-viewer-component-wrapper__content">
              <div className="file-viewer-component-wrapper__selected-wrapper">
                { (props.selectedAttachment || props.selectedFileUpload || props.selectedTemplateMessageAttachment) && 
                  <span className="file-viewer-component-wrapper__selected-wrapper-container">
                    <img 
                      src={props.selectedAttachment?.url || props.selectedFileUpload?.preview || props.selectedTemplateMessageAttachment?.url} 
                      alt="" 
                      className="file-viewer-component-wrapper__selected" 
                      ref={props.selectedRef} 
                    /> 
                    <span className="file-viewer-component-page-info" ref={props.paginationInfoRef}>
                      {props.selectedIndex + 1}/{props.filterImages().length || 1}
                    </span>
                  </span>
                }
                { (props.selectedAttachment || props.selectedFileUpload || props.selectedTemplateMessageAttachment) && 
                  <span className='file-viewer-component-wrapper__selected-filename' ref={props.selectedFilenameRef}>
                    {props.selectedAttachment?.filename || props.selectedFileUpload?.name || props.selectedTemplateMessageAttachment?.fileName}
                  </span> 
                }
                {
                  props.selectedIndex > 0 &&
                  <span 
                    className="file-viewer-component-selected-navigator selected-navigator-left" 
                    onClick={() => props.handleNavigation(props.selectedIndex - 1)}
                    ref={props.navigationLeftRef}>
                    <IconChevronLeft />
                  </span>
                }
                {
                  props.selectedIndex < (props.filterImages().length - 1) &&
                  <span 
                    className="file-viewer-component-selected-navigator selected-navigator-right" 
                    onClick={() => props.handleNavigation(props.selectedIndex + 1)}
                    ref={props.navigationRightRef}>
                    <IconChevronRight />
                  </span>
                }
              </div>
              <div className="file-viewer-component-wrapper__thumbnails-container">
                <div className="file-viewer-component-wrapper__thumbnails" ref={props.thumbnailsRef} style={{ left: `calc((100vw / 2) - ${props.selectedIndex * 100}px - 50px - ${props.selectedIndex * 10}px)`}}>
                  {props.filterImages()?.map((item: any) => {
                    let selectedClass = '';

                    if (props.type === 'messages') {
                      selectedClass = props.selectedAttachment?.url === item.url ? 'attachment-selected' : '';
                    } else if (props.type === 'preview-upload') {
                      selectedClass = props.selectedFileUpload?.preview === item.preview ? 'attachment-selected' : '';
                    }

                    return (
                      <span 
                        key={item.url || item.preview} 
                        className={`file-viewer-component-wrapper__attachment-item ${selectedClass}`}
                        onClick={() => props.type === 'messages' ? props.handleSelectAttachment(item) : props.handleSelectFileUpload(item)}  
                        >
                        { item.type?.includes('image') &&
                          <img key={item._id} src={item.url || item.preview} alt="" />
                        }
                        { !item.type?.includes('image') &&
                          getIconFileType(item.type)
                        }
                      </span>
                    );
                  })}
                  { props.selectedTemplateMessageAttachment &&
                    <span 
                      key={props.selectedTemplateMessageAttachment.url} 
                      className="file-viewer-component-wrapper__attachment-item attachment-selected" 
                      >
                      { props.selectedTemplateMessageAttachment?.mimeType.includes('image') &&
                        <img key={props.selectedTemplateMessageAttachment.url} src={props.selectedTemplateMessageAttachment.url} alt="" />
                      }
                      { !props.selectedTemplateMessageAttachment?.mimeType.includes('image') &&
                        getIconFileType(props.selectedTemplateMessageAttachment.mimeType)
                      }
                    </span>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default FileViewer;
