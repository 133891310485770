import { FC, useEffect, useRef, useState, MouseEvent } from "react";
import { TableListControllerInterface } from "./indexModel";
import TableListComponent from ".";
import { useTranslation } from "react-i18next";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMail, IconMessages, IconPhone } from "@tabler/icons-react";
import { TicketStatus } from "../../../../../core/enums/ticket-status";
import { useNavigate, useParams } from "react-router-dom";
import constsRouters from "../../../../../routes/constsRouter";
import { useMainAppContext } from "../../../../../core/context/main-app-context";
import emitter from "../../../../../core/shared/emitter";
import i18n from "../../../../../i18next";
import { PagesType } from "../../../components-fixed/sidebarAppComponent/indexModel";

const TableListComponentController: FC<TableListControllerInterface> = (props) => {
  const { t } = useTranslation('TableListComponent');
  const navigate = useNavigate();
  // const [headers, setHeaders] = useState([]);

  // useEffect(() => {
  //   setHeaders(props.headers);
  //   console.log(props.headers);
  // }, [props.headers]);

  const { id } = useParams();

  const tableListRef = useRef<HTMLDivElement>(null);
  const [hasVerticalScrollbar, setHasVerticalScrollbar] = useState(false);
  const [isCompactList, setIsCompactList] = useState(false);
  const [showPopupPages, setShowPopupPages] = useState(false);
  const [openedPopoverId, setOpenedPopoverId] = useState<string>(null);
  const [showColumnSelector, setShowColumnSelector] = useState(false);
  const [hoverColumnSelector, setHoverColumnSelector] = useState(false);

  const tableCompactHeaderRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const columnBtnSelectRef = useRef<HTMLSpanElement>(null);
  const columnSelectorRef = useRef<HTMLDivElement>(null);

  const { headerHeight, isSearched } = useMainAppContext();

  useEffect(() => {
    const divList = document.getElementById('table-list-items');

    if (divList) {
      setHasVerticalScrollbar(divList.scrollHeight > divList.clientHeight);
    } else {
      setHasVerticalScrollbar(false);
    }
  }, [props.data]);

  useEffect(() => {
    setIsCompactList(!!id);
    setOpenedPopoverId(null);
  }, [id]);

  useEffect(() => {
    if (showPopupPages) {
      const handleClickOutside = (event: any) => {
        if (paginationRef?.current && !paginationRef?.current?.contains(event.target)) {
          setShowPopupPages(false);
        }
      }
  
      if (paginationRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showPopupPages]);

  useEffect(() => {
    if (showColumnSelector) {
      const handleClickOutside = (event: any) => {
        if ((columnSelectorRef?.current && !columnSelectorRef?.current?.contains(event.target)) &&
          (columnBtnSelectRef?.current && !columnBtnSelectRef?.current?.contains(event.target))) {
          setShowColumnSelector(false);
        }
      }
  
      if (columnSelectorRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showColumnSelector, columnSelectorRef?.current]);

  useEffect(() => {
    emitter.on('close-item-action-popover-list-tickets', () => {
      setOpenedPopoverId(null);
    });

    return () => {
      emitter.off('close-item-action-popover-list-tickets');
    }
  }, []);

  const formatValue = (value: any, field: string, type?: string): { value: any, icon?: any } => {
    const result = { value };
    if (value) {
      if (['created_at', 'updated_level2_at', 'home_ticket_update_at', 'updated_at', 'finished_at', 'deleted_at'].includes(field)) {
        result.value = formatDate(value, type);
      } else if (field === 'ticket_reference_id') {
        result.value = `#${value}`;
      } else if (field === 'requester_user') {
        if (props.pageType === 'consumer-tickets') {
          result.value = props.itemView?.value || 'N/A';
        } else {
          result.value = value.name || 'N/A';
        }
      } else if (field === 'assigned_employee') {
        if (props.pageType === 'agent-tickets') {
          result.value = props.itemView?.value || 'N/A';
        } else {
          result.value = value.name || 'N/A';
        }
      } else if (field === 'sector') {
        result.value = value.name || 'N/A';
      } else if (field === 'channel_id') {
        result.value = getChannel(value).title;
        result['icon'] = getChannel(value).icon;
      } else if (field === 'last_message') {
        result.value = formatLastMessage(value?.slice(0, 190));
        
        if (value?.length > 190) {
          result.value += '...';
        }
      } else {
        result.value = value;
      }
    } else {
      if (field === 'requester_user' && props.pageType === 'consumer-tickets') {
        result.value = props.itemView?.value || 'N/A';
      } else if (field === 'assigned_employee' && props.pageType === 'agent-tickets') {
        result.value = props.itemView?.value || 'N/A';
      } else {
        result.value = 'N/A';
      }
    }

    return result;
  }

  const formatDate = (date: string, type?: string): string => {
    if (type === 'short') {
      return `${new Date(date).toLocaleDateString()}`;
    } else {
      return `${new Date(date).toLocaleDateString()} - ${new Date(date).toLocaleTimeString()}`;
    }
  }

  const formatLastMessage = (message: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = message;
    const decodedMessage = tempDiv.innerText;
  
    const cleanedMessage = decodedMessage
      .replace(/\n/g, " ")       // Remove quebras de linha
      .replace(/\s+/g, " ")     // Substitui múltiplos espaços por um espaço único
      .trim();                  // Remove espaços no início e no final
  
    if (cleanedMessage.length === 0) {
      return t('attachment_message');
    } else {
      return cleanedMessage;
    }
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    const size = props.compactList ? 26 : 18;
    const color = props.compactList ? '#909090' : '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("channels_names.email"), icon: <IconMail stroke={1} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("channels_names.telephony"), icon: <IconPhone stroke={1} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("channels_names.whatsapp"), icon: <IconBrandWhatsapp stroke={1} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("channels_names.facebook"), icon: <IconBrandFacebook stroke={1} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("channels_names.instagram"), icon: <IconBrandInstagram stroke={1} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("channels_names.webchat"), icon: <IconMessages stroke={1} size={size} color={color} />};
      default:
        return { title: 'N/A' }
    }
  }

  const getStatusIcon = (status_id: string): { tooltip: string, symbol: string, color: string } => {
    switch (status_id) {
      case TicketStatus.Open: 
        return { tooltip: t("ticket_status.open"), symbol: t('ticket_status_symbol.open'), color: '#4A69BD' };
      case TicketStatus.Resolved: 
        return { tooltip: t("ticket_status.resolved"), symbol: t('ticket_status_symbol.resolved'), color: '#707070' };
      case TicketStatus.Pending: 
        return { tooltip: t("ticket_status.pending"), symbol: t('ticket_status_symbol.pending'), color: '#DE2450' };
      case TicketStatus.OnHold: 
        return { tooltip: t("ticket_status.on_hold"), symbol: t('ticket_status_symbol.on_hold'), color: '#FBBD4E' };
      case TicketStatus.New: 
        return { tooltip: t("ticket_status.new"), symbol: t('ticket_status_symbol.new'), color: '#17a589' };
      case TicketStatus.Abandoned: 
        return { tooltip: t("ticket_status.abandoned"), symbol: t('ticket_status_symbol.abandoned'), color: '#2D3233' };
      case TicketStatus.OverdueTicketTask: 
        return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
      default:
        return { tooltip: 'N/A', symbol: 'N/A', color: '#707070' };
    }
  }

  const backToList = () => {
    if (props.pageType === 'history-general') {
      navigate(constsRouters.routers.history.path);
    } else if (props.pageType === 'history-chatbot') {
      navigate(constsRouters.routers.historyChatbot.path);
    } else if (props.pageType === 'history-telephony') {
      navigate(constsRouters.routers.historyTelephony.path);
    } else if (props.pageType === 'consumer-tickets' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.consumerTickets.path}`, 
        { 
          state: { 
            consumerUserId: props.itemView?.id, 
            consumerName: props.itemView?.value
          } 
        }
      );
    } else if (props.pageType === 'agent-tickets' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.agentTickets.path}`, 
        { 
          state: { 
            agentUserId: props.itemView?.id, 
            agentName: props.itemView?.value
          } 
        }
      );
    } else if (props.pageType === 'task-ticket' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.taskTicket.path}`, 
        { 
          state: { 
            taskTicketId: props.itemView?.id,
          } 
        }
      );
    } else if (props.pageType === 'messages-trigger-tickets' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.activeTriggerTickets.path}`, 
        { 
          state: { 
            activeTriggerId: props.itemView?.id, 
            activeTriggerValue: props.itemView?.value
          } 
        }
      );
    } else if (props.pageType === 'sector-tickets' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.configurationSectorsTickets.path}`, 
        { 
          state: { 
            sectorId: props.itemView?.id, 
            sectorValue: props.itemView?.value
          } 
        }
      );
    } else if (props.pageType === 'tag-tickets' && props.itemView?.id) {
      navigate(
        `${constsRouters.routers.configurationTagsTickets.path}`, 
        { 
          state: { 
            tagId: props.itemView?.id, 
            tagValue: props.itemView?.value
          } 
        }
      );
    } else {
      navigate(constsRouters.routers.tickets.path);
    }
  }

  const getCompactHeaderOffsetHeight = () => {
    const mainHeaderHeight = props.getOffsetHeight();
    const tableCompactHeaderHeight = tableCompactHeaderRef?.current?.offsetHeight || 0;
    // const bottomMargin = 10; // 10px a menos definido na list-tickets-page para não ficar colado em baixo

    return mainHeaderHeight + tableCompactHeaderHeight;
  }

  const fetchMore = (page?: number) => {
    props.fetchMore(page);
    scrollTop();
  }

  const scrollTop = () => {
    const listElement = document.getElementById('table-list-component-content');
    const compactListElement = document.getElementById('table-compact-list-items');

    if (listElement) {
      listElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (compactListElement) {
      compactListElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const getDateSystemMsg = (date: string): string => {

    const texts2 = {
      today: 'system_date.today',
      yesterday: 'system_date.yesterday',
      0: 'system_date.sunday',
      1: 'system_date.monday',
      2: 'system_date.tuesday',
      3: 'system_date.wednesday',
      4: 'system_date.thursday',
      5: 'system_date.friday',
      6: 'system_date.saturday',
    };

    const today = new Date();
    const target = new Date(date);
    today.setHours(0, 0, 0, 0);
    target.setHours(0, 0, 0, 0);

    const totalDays = Math.abs(Math.ceil((target.getTime() - today.getTime()) / (1000 * 3600 * 24)));
    const lang = localStorage.getItem('i18nextLng')?.split('-')[0] || 'pt';

    if (today.toLocaleDateString(lang) === target.toLocaleDateString(lang)) {
      return t(texts2.today);
    } else if (totalDays === 1) {
      return t(texts2.yesterday);
    } else {
      if (totalDays < 7) {
        const new_today = new Date();
        new_today.setHours(0, 0, 0, 0);
        new_today.setDate(new_today.getDate() - totalDays);
        const day = new_today.getDay();
        return t(texts2[day]);
      } else {
        return target.toLocaleDateString(lang);
      }
    }
  }

  const handlePopoverMenuAction = (event: MouseEvent<HTMLDivElement, MouseEvent>, itemId: string) => {
    event.stopPropagation();
    const popoverElement = document.querySelector('.popover-custom-action-menu') as HTMLDivElement;

    if (popoverElement) {
      if (!popoverElement.classList.contains('show')) {
        popoverElement.classList.add('show');
        popoverElement.style.visibility = '';
        setOpenedPopoverId(itemId);
      } else {
        setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
      }
    } else {
      setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
    }
  }

  const handleClickPage = (page: number) => {
    setShowPopupPages(false);
    if (props.paginationDetails.currentPage !== page) {
      props.fetchMore(page);
    }
  }

  const formatNumberValue = (value: number) => {
    return new Intl.NumberFormat(i18n.language?.toLocaleLowerCase() || 'pt').format(value);
  }

  const getComponentHeighDiff = () => {
    const customPagesFilter: PagesType[] = ['consumer-tickets', 'agent-tickets', 'task-ticket', 'messages-trigger-tickets', 'sector-tickets', 'tag-tickets'];
    if (isSearched || customPagesFilter.includes(props.pageType)) {
      return 300;
    } else {
      return 230;
    }
  }

  return (
    <TableListComponent
      t={t}
      pageType={props.pageType}
      tableListRef={tableListRef}
      headers={props.headers}
      data={props.data}
      paginationDetails={props.paginationDetails}
      fetchMore={fetchMore}
      handleClickPage={handleClickPage}
      isLoading={props.isLoading}
      errorLoading={props.errorLoading}
      reloadTickets={props.reloadTickets ? props.reloadTickets : () => {}}
      isCompactList={isCompactList}
      backToList={backToList}
      util_formatValue={formatValue}
      util_getChannel={getChannel}
      util_getStatusIcon={getStatusIcon}
      util_hasVerticalScrollbar={hasVerticalScrollbar}
      util_formatNumberValue={formatNumberValue}
      selectItem={props.selectItem}
      selectAllItems={props.selectAllItems}
      isSelectedItem={props.isSelectedItem}
      hasSomeSelected={props.hasSomeSelected}
      hasSomeSelectedCheck={props.hasSomeSelectedCheck}
      isAllSelectedItems={props.isAllSelectedItems}
      selectedItemsLength={props.selectedItemsLength}
      hasExportAll={props.hasExportAll}
      hasExportSelected={props.hasExportSelected}
      hasManagerSelected={props.hasManagerSelected}
      exportAll={props.exportAll}
      exportSelected={props.exportSelected}
      manageSelected={props.manageSelected}
      clearSelected={props.clearSelected}
      clearPageSelected={props.clearPageSelected}
      hasOptionalAction={props.hasOptionalAction}
      optionalActionField={props.optionalActionField}
      openTicket={props.openTicket}
      selectedItemId={props.selectedItemId}
      compactList={props.compactList}
      showPopupPages={showPopupPages}
      setShowPopupPages={setShowPopupPages}
      showList={props.showList}
      setShowList={props.setShowList}
      getOffsetHeight={props.getOffsetHeight}
      tableCompactHeaderRef={tableCompactHeaderRef}
      offsetHeight={props.offsetHeight}
      getCompactHeaderOffsetHeight={getCompactHeaderOffsetHeight}
      getMainHeaderHeight={props.getMainHeaderHeight}
      paginationRef={paginationRef}
      headerHeight={headerHeight}
      isSearched={isSearched}
      getDateSystemMsg={getDateSystemMsg}
      openedPopoverId={openedPopoverId}
      setOpenedPopoverId={setOpenedPopoverId}
      handlePopoverMenuAction={handlePopoverMenuAction}
      popoverItemAction={props.popoverItemAction}
      showColumnSelector={showColumnSelector}
      setShowColumnSelector={setShowColumnSelector}
      hoverColumnSelector={hoverColumnSelector}
      setHoverColumnSelector={setHoverColumnSelector}
      columnBtnSelectRef={columnBtnSelectRef}
      columnSelectorRef={columnSelectorRef}
      getComponentHeighDiff={getComponentHeighDiff}
      getIsItemDisabled={props.getIsItemDisabled}
    />
  );
}

export default TableListComponentController;