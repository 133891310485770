import { useState, useEffect, FC } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ConstsRouters from "../../../../routes/constsRouter"
import ListClients from "."
import { ListClientsInterface } from "./indexModel"
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController"
import BackendConstants from "../../../../core/constants/backend-constants"
import { TokenInterfaceControlPanel } from "../../../../services/requestsInterfacesModel"
import { getToken } from "../../../../store/control_panel"
import { ListName } from "../../../../core/enums/order-sort-by"
import Utils from "../../../../core/shared/utils"

const AppRequesterConst = new AppRequesterController()

const ListOfClientsController: FC<ListClientsInterface> = (props) => {

    let navigate = useNavigate()
    let dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [currentCompanies, setCurrentCompanies] = useState(undefined)
    const [currentLimit, setCurrentLimit] = useState(BackendConstants.limit)
    const [currentHasMoreInformation, setCurrentHasMoreInformation] = useState(true)
    const [currentCompanyId, setCurrentCompanyId] = useState('')

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        props.setCurrentParams((params) => {
            props.setCurrentState((state) => {
                getAllCompaniesToPagination(Object.assign(props.currentParams, { limit: currentLimit, page: props.currentPage }))
                return state
            })
            return params
        })
    }, [props.currentPage, props.currentState])

    useEffect(() => {
        Utils.changeActiveScrollBar("ListOfAgents");
    }, [props.aux]);

    const getAllCompaniesToPagination = async (params: { limit: number; page: number }) => {
        getCompany(params)
    }

    const getCompany = async (params: { limit: number; page: number }) => {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + values.token.value
        };
        await AppRequesterConst.Get(
            '/panel/company', { headers, params },
            (response: Object) => {
            },
            (data: any) => {
                if(data.status === 200) {
                    props.setAux((state) => {
                        let aux = state.concat(data.data.companies)
                        props.setAux(aux)
                        setCurrentCompanies(aux)
                        return state
                    })
                } else if (props.aux.length === 0) {
                    setCurrentCompanies(undefined)
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, {  }
        )
    }

    const formatDate = (date: string, timeZone: string) => {
        const result = new Date(date).toLocaleDateString(timeZone)
        return result
    }

    const updateListFunction = () => {
        props.setCurrentPage(props.currentPage + 1)
    }

    const formatCNPJ = (cnpj: string) => {
        const result = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        return result
    }

    const viewAgentPage = (e: { currentTarget: { id: string } }) => {
        setCurrentCompanyId(e.currentTarget.id)
        let selectedCompanyId = e.currentTarget.id.split("*ID*")[1];
        navigate(ConstsRouters.routers.controlPanelclientsView.path, { state: currentCompanies?.filter(item => item.id === selectedCompanyId)[0] })
    }

    const callbackOrderByCompanies = (column: number, order: any) => {
        props.setCurrentPage(1)
        props.setAux([])
        const auxObj = {}

        if (column != null) {
            auxObj["sort_by"] = column
        }

        if (order != null) {
            auxObj["order_by"] = order
        }

        props.setCurrentState(column + order)
        props.setCurrentParams(auxObj)
        // document.getElementById("listOfTickets").scrollTop = 0
    }


    return <ListClients
                isLoading={isLoading}
                datas={props.aux}
                formatDate={formatDate}
                formatCNPJ={formatCNPJ}
                viewAgentPage={viewAgentPage}
                updateListfunction={updateListFunction}
                HasMoreInformation={currentHasMoreInformation}
                callbackOrderBy={callbackOrderByCompanies}
            />

}

export default ListOfClientsController
