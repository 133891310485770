import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import CallNotificationBar from "../../components/callNotificationBar";
import { getShowAcquirePlatformComponent, getShowCallNotificationBar } from "../../../../store/internal";
import AcquirePlatformNotificationBar from "../../components/acquirePlatformNotificationBar";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import ButtonStatusAgent from "../../components/buttonStatusAgent";
import MasterAccessController from "./masterAccess/indexController";
import MultifactorTabController from "./multifactorTab/indexController";
import AppAuthTabController from "./appAuthTab/indexController";
import ButtonListNotificationController from "../../../../components/buttonListNotification/indexController";

const HomeSecurityConfig = ({
  isLoading,
  phoneNumber,
  openModalPhone,
  setOpenModalPhone,
  getAvailableAuthentications,
  setAuthentication,
  changeMasterAccess,
  infoAuth,
  companyAuth,
  employeeHasAuthApp,
  generateAppCode,
  verifyAppCode,
  openRemoveConfirmation,
  userProfileId,
  getCurrentAuthentications,
  isAgent,
  companyHasAuthApp,
  t,
}) => {

  return (

    <Container fluid className="nopadding" style={{ overflow: "hidden" }}>
      {isLoading && <Loading visibility={isLoading}></Loading>}
      <div className="wrapper">
          {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubSecurity}/> */}
          <div className="content ps-0 pt-0">
            <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
            <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
            {/* <Row className="nopadding mt-3 ps-3">
              <Col md={7} style={{ marginBottom: '2vh' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>
                      {t('configurations.security.welcome')}
                    </div>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>
                    {t('configurations.security.welcome_sub')}
                  </div>
                </Row>
              </Col>
              <Col className="d-flex justify-content-end">
                <ButtonListNotificationController />
                <ButtonStatusAgent />
              </Col>
            </Row> */}
              <Row className="mx-4">
                {
                  isAgent() && companyHasAuthApp ?
                  <Tabs activeKey={'securityAppMFA'} id="agentSecurityTabs" style={{ padding: 0 }}>
                    { // Aba de configuração só deve ser exibida se a empresa tiver o método via app ativado
                      <Tab eventKey={"securityAppMFA"} title={t('configurations.security.tab_app.title')}>
                      <AppAuthTabController
                          isLoading={isLoading}
                          employeeHasAuthApp={employeeHasAuthApp}
                          generateAppCode={generateAppCode}
                          verifyAppCode={verifyAppCode}
                          openRemoveConfirmation={openRemoveConfirmation}
                        />
                      </Tab>
                    }
                  </Tabs>
                  :
                  <Tabs defaultActiveKey={'securityMFA'} id="adminSecurityTabs" style={{ padding: 0 }}>
                    <Tab eventKey={"securityMFA"} title={t('configurations.security.tab_mfa.title')}>
                    <MultifactorTabController
                        getAvailableAuthentications={getAvailableAuthentications}
                        isLoading={isLoading}
                        setAuthentication={setAuthentication}
                        companyAuth={companyAuth}
                      />
                    </Tab>
                    { // Aba de configuração só deve ser exibida se a empresa tiver o método via app ativado
                      companyHasAuthApp &&
                      <Tab eventKey={"securityAppMFA"} title={t('configurations.security.tab_app.title')}>
                        <AppAuthTabController
                          isLoading={isLoading}
                          employeeHasAuthApp={employeeHasAuthApp}
                          generateAppCode={generateAppCode}
                          verifyAppCode={verifyAppCode}
                          openRemoveConfirmation={openRemoveConfirmation}
                        />
                      </Tab>
                    }
                    <Tab eventKey={"masterAccess"} title={t('configurations.security.tab_master.title')}>
                      <MasterAccessController
                        isLoading={isLoading}
                        getCurrentAuthentications={getCurrentAuthentications}
                        changeMasterAccess={changeMasterAccess}
                        infoGetAuth={infoAuth}
                        t={t}
                      />
                    </Tab>
                  </Tabs>
                }
              </Row>
          </div >
      </div >
      </Container >
  );
}

export default HomeSecurityConfig;