import { FC, useContext } from 'react';
import { ReceivingCallControllerInterface } from './indexModel';
import ReceivingCall from '.';
import { CallcenterContext } from '../../../../../../core/context/callcenter-context';
import { useTranslation } from 'react-i18next';

const ReceivingCallController: FC<ReceivingCallControllerInterface> = (props) => {
  const { t } = useTranslation();
  const context = useContext(CallcenterContext);

  const accept = () => {
    context.instance.acceptCall();
    props.show(true);
  }

  const hangup = () => {
    context.instance.hangup();
  }

  const formatDuration = (time = 0) => {
    const sec_num = parseInt(String(time), 10)
    const hours   = Math.floor(sec_num / 3600)
    const minutes = Math.floor(sec_num / 60) % 60
    const seconds = sec_num % 60

    return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":");
  }
  
  return (
    <ReceivingCall 
      t={t}
      context={context}
      accept={accept}
      hangup={hangup}
      formatDuration={formatDuration}
    />
  );
}

export default ReceivingCallController;