import { useState, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomeSubdomain from '../pages/home/index';
import HomeRegister from '../pages/register/registerDesktop/index';
import HomeValidateEmail from '../pages/validateEmail/index';
import HomeForgotPassword from '../pages/forgotPassword/indexController';
import HomeNewPassword from '../pages/newPassword/index';
import HomeForgotURLController from '../pages/forgotURL/indexControler';
import HomeDashboardChatbot from '../pages/homeApp/homeDashboard/homeDashboardChatbot/indexController';
import HomeConfigurationsTicketsController from '../pages/homeApp/configurations/homeTickets/indexController';
import HomeConfigurationsChannelsController from '../pages/homeApp/configurations/homeChannels/indexController';
import HomeAgentsController from '../pages/homeApp/homeAgents/indexController';
import ViewAgentsController from '../pages/homeApp/homeAgents/viewAgents/indexController';
import HomeConsumersController from '../pages/homeApp/homeConsumers/indexController';
import ViewConsumersController from '../pages/homeApp/homeConsumers/viewConsumers/indexController';
import HomeChannelsController from '../pages/homeApp/configurations/homeTickets/indexController';
import HomeDepartments from '../pages/homeApp/configurations/homeDepartments/indexController';
import ViewDepartmentsController from '../pages/homeApp/configurations/homeDepartments/viewDepartments/indexController';
import constsRouters from './constsRouter';
import { ContractStatus } from '../core/enums/contract-status';
import { useSelector } from 'react-redux';
import { getShowAcquirePlatformComponent } from '../store/internal';
import HomeMessageTriggerController from '../pages/homeApp/homeMessageTrigger/indexController';
import { getProfileId } from '../store/user';
import constsApi from '../services/constsApi';
import HomeRegisterMobile from '../pages/register/registerMobile';
import HomeControlPanel from '../pages/homeControlPanel';
import HomeClientsController from '../pages/homeControlPanel/homeClients/indexController';
import { TokenInterface } from '../services/requestsInterfacesModel';
import HomeBotxpress from '../pages/homeApp/configurations/homeBotxpress/indexController';
import ViewBot from '../pages/homeApp/configurations/homeBotxpress/viewBot/indexController';
import LoginPanelController from '../pages/homeControlPanel/loginControlPanel/indexController';
import ChatBotIFrame from '../pages/homeApp/configurations/homeBotxpress/chatBotIFrame/indexController';
import HomeTasksController from '../pages/homeApp/homeTasks/indexController';
import HomeHistoryChatbotController from '../pages/homeApp/homeHistory/homeHistoryChatbot/indexController';
import HomeCustomFieldsConfigController from '../pages/homeApp/configurations/homeCustomFields/indexController';
import HomeSecurityConfigController from '../pages/homeApp/configurations/homeSecurity/indexController';
import ViewAnalysisSimilarConsumersController from '../pages/homeApp/homeConsumers/unificationConsumers/viewAnalysisSimilarConsumers/indexController';
import { getCompanyHasApp2FA } from '../store/company';
import HomeHistoryController from '../pages/homeApp/homeHistory/homeHistoryTicket/indexController';
import HomeDashboardController from '../pages/homeApp/homeDashboard/indexController';
import ViewMessageTriggersController from '../pages/homeApp/homeMessageTrigger/viewMessageTriggers/indexController';
import ValidateControlPanelEmailController from '../pages/homeControlPanel/validateControlPanel/indexController';
import ControlPanelAccountsController from '../pages/homeControlPanel/homeAccounts/indexController';
import ControlPanelSecurityController from '../pages/homeControlPanel/homeSecurity/indexController';
import { getUserControlPanelPermissions } from '../store/user_controlpanel';
import NewPasswordControlPanelController from '../pages/homeControlPanel/newPasswordControlPanel/indexController';
import ForgotPasswordControlPanelController from '../pages/homeControlPanel/forgotPasswordControlPanel/indexController';
import HomeTicketsController from '../pages/homeApp/homeTickets/indexController';
import HomeTagsControllerOld from '../pages/homeApp/configurations/homeTags/indexController';
import ViewTagsControllerOld from '../pages/homeApp/configurations/homeTags/viewTags/indexController';
import HomeCreateTicketController from '../pages/homeApp/homeTickets/homeCreateTicket/indexController';
import HomeDashboardSummary from '../pages/homeApp/homeDashboard/homeDashboardSummary/indexController';
import { getHasPhoneIntegrationEmployee } from '../store/employee';
import EndTrialController from '../pages/endTrial/indexController';
import LoginHomeController from '../pages/login/indexController';
import ListOfTicketsController from '../pages/homeApp/homeTickets2/listOfTickets/indexController';
import HomeAppController from '../pages/homeApp/indexController';
import Page404Controller from '../pages/page404/indexController';
import HomeViewClientController from '../pages/homeControlPanel/homeViewClient/indexController';
import ListOfConsumersController from '../pages/homeApp/homeConsumers2/listOfConsumers/indexController';
import ListOfTasksController from '../pages/homeApp/homeTasks2/listOfTasks/indexController';
import ListOfAgentsController from '../pages/homeApp/homeAgents2/listOfAgents/indexController';
import ListOfMessageTriggersController from '../pages/homeApp/homeMessageTrigger2/listOfMessageTriggers/indexController';
import ListOfTagsController from '../pages/homeApp/homeConfigurations/homeTags/listOfTags/indexController';
import ListOfSectorsController from '../pages/homeApp/homeConfigurations/homeSectors/listOfSectors/indexController';
import HomeViewSummaryController from '../pages/homeApp/homeDashboard/homeDashboardSummary/viewSummary/indexController';
import HomeControlPanelConfigController from '../pages/homeControlPanel/homeConfig/indexController';

const RoutesFunction = () => {

  const [subdomain, setSubDomain] = useState<string>(null);

  const contractStatusId: string = useSelector(getShowAcquirePlatformComponent).currentContractStatusId;
  const companyHasApp2FA = useSelector(getCompanyHasApp2FA);
  const hasPhoneIntegrationEmployee = useSelector(getHasPhoneIntegrationEmployee);
  
  const values: TokenInterface = {
    user: {
      profile_id: useSelector(getProfileId)
    },
    internal_controlpanel: {
      permissions: new Array(useSelector(getUserControlPanelPermissions))
    }
  }

  useEffect(() => {
    const host = window.location.host;
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) {
      setSubDomain(arr[0]);
      
    }
  }, [subdomain]);

  const isAdmin = () => values.user.profile_id === constsApi.profile[0].id;
  const isAgent = () => values.user.profile_id === constsApi.profile[1].id;
  const isOwner = () => values.user.profile_id === constsApi.profile[2].id;
  const isMaster = () => values.user.profile_id === constsApi.profile[3].id;

  const staticSubdomains = ['app', 'staging', 'staging2', 'homolog1', 'homolog2', 'homolog3', 'homolog4'];

  return (
    <BrowserRouter>
      <Routes>
        { /* Rotas do painel de controle */
          subdomain === 'controlpanel' &&
          <>  
            <Route path='/' element={<LoginPanelController />} />
            <Route path={constsRouters.routers.controlPanelHome.path} element={<HomeControlPanel />} >
              <Route path={constsRouters.routers.controlPanelclients.path} element={<HomeClientsController/>}/>
              <Route path={constsRouters.routers.controlPanelclientsView.path} element={<HomeViewClientController />} />
              <Route path={constsRouters.routers.controlPanelAccounts.path} element={<ControlPanelAccountsController />} />
              <Route path={constsRouters.routers.controlPanelSecurity.path} element={<ControlPanelSecurityController />} />
              <Route path={constsRouters.routers.controlPanelConfig.path} element={<HomeControlPanelConfigController />} />
            </Route>
            <Route path='*' element={<Navigate to={constsRouters.routers.controlPanelclients.path} replace />} />
          </>
        }

        {
          /* Rotas externas do painel */
          <>
            <Route path={constsRouters.routers.controlPanelValidateEmail.path} element={<ValidateControlPanelEmailController />} />
            <Route path={constsRouters.routers.controlPanelNewPassword.path} element={<NewPasswordControlPanelController />} />
            <Route path={constsRouters.routers.controlPanelForgotPassword.path} element={<ForgotPasswordControlPanelController />} />
          </>
        }

        {
          subdomain !== 'controlpanel' &&
          <>
            <Route path={constsRouters.routers.forgotpassword.path} element={<HomeForgotPassword />} />
            <Route path={constsRouters.routers.validateemail.path} element={<HomeValidateEmail />}></Route>
            <Route path={constsRouters.routers.newpassword.path} element={<HomeNewPassword />}></Route>
            <Route path={constsRouters.routers.forgoturl.path} element={<HomeForgotURLController />} />
            <Route path={constsRouters.routers.chatBotIFrame.path} element={<ChatBotIFrame />} />
            { subdomain === null || subdomain === process.env.REACT_APP_SUBDOMAIN ?
              <Route path='/' element={<HomeSubdomain />} />
              :  
              <Route path='/' element={<LoginHomeController />} />
            }
            
            { subdomain !== null && subdomain !== process.env.REACT_APP_SUBDOMAIN ?
              <Route path={constsRouters.routers.register.path} element={<LoginHomeController />} />
              :
              <>
                { window.innerWidth > window.innerHeight ?
                  <Route path={constsRouters.routers.register.path} element={<HomeRegister />} /> :
                  <Route path={constsRouters.routers.register.path} element={<HomeRegisterMobile />} />
                }
              </>
            }

            { contractStatusId === ContractStatus.EndTrial || contractStatusId === ContractStatus.Inactive ?
              <>
                <Route path={constsRouters.routers.endTrial.path} element={<EndTrialController />} />
                <Route path="*" element={<EndTrialController />}></Route>
              </> 
              :
              <>
                <Route path={constsRouters.routers.home.path} element={<HomeAppController />} >
                  {/* Rotas novas */}
                  <Route path={constsRouters.routers.tickets.path} element={<ListOfTicketsController pageType='ticket-list' />} />
                  <Route path={constsRouters.routers.ticketsView.path} element={<ListOfTicketsController pageType='ticket-list' />} />

                  <Route path={constsRouters.routers.history.path} element={<ListOfTicketsController pageType='history-general' />} />
                  <Route path={constsRouters.routers.historyView.path} element={<ListOfTicketsController pageType='history-general' />} />

                  <Route path={constsRouters.routers.historyChatbot.path} element={<ListOfTicketsController pageType='history-chatbot' />} />
                  <Route path={constsRouters.routers.historyChatbotView.path} element={<ListOfTicketsController pageType='history-chatbot' />} />

                  <Route path={constsRouters.routers.historyTelephony.path} element={<ListOfTicketsController pageType='history-telephony' />} />
                  <Route path={constsRouters.routers.historyTelephonyView.path} element={<ListOfTicketsController pageType='history-telephony' />} />

                  <Route path={constsRouters.routers.consumerTickets.path} element={<ListOfTicketsController pageType='consumer-tickets' />} />
                  <Route path={constsRouters.routers.consumerTicketsView.path} element={<ListOfTicketsController pageType='consumer-tickets' />} />

                  <Route path={constsRouters.routers.agentTickets.path} element={<ListOfTicketsController pageType='agent-tickets' />} />
                  <Route path={constsRouters.routers.agentTicketsView.path} element={<ListOfTicketsController pageType='agent-tickets' />} />

                  <Route path={constsRouters.routers.taskTicket.path} element={<ListOfTicketsController pageType='task-ticket' />} />
                  <Route path={constsRouters.routers.taskTicketView.path} element={<ListOfTicketsController pageType='task-ticket' />} />

                  <Route path={constsRouters.routers.activeTriggerTickets.path} element={<ListOfTicketsController pageType='messages-trigger-tickets' />} />
                  <Route path={constsRouters.routers.activeTriggerTicketsView.path} element={<ListOfTicketsController pageType='messages-trigger-tickets' />} />

                  { (isAdmin() || isOwner() || isMaster()) &&
                  <>
                    <Route path={constsRouters.routers.configurationSectorsTickets.path} element={<ListOfTicketsController pageType='sector-tickets' />} />
                    <Route path={constsRouters.routers.configurationSectorsTicketsView.path} element={<ListOfTicketsController pageType='sector-tickets' />} />

                    <Route path={constsRouters.routers.configurationTagsTickets.path} element={<ListOfTicketsController pageType='tag-tickets' />} />
                    <Route path={constsRouters.routers.configurationTagsTicketsView.path} element={<ListOfTicketsController pageType='tag-tickets' />} />

                    <Route path={constsRouters.routers.configurationTagsConsumers.path} element={<ListOfConsumersController pageType='tag-consumers' />} />
                  </>
                  }

                  <Route path={constsRouters.routers.consumers.path} element={<ListOfConsumersController />} />
                  <Route path={constsRouters.routers.agents.path} element={<ListOfAgentsController />} />
                  <Route path={constsRouters.routers.tasks.path} element={<ListOfTasksController />} />
                  <Route path={constsRouters.routers.activeTrigger.path} element={<ListOfMessageTriggersController />} />

                  {/* Rotas antigas */}

                  <Route path={constsRouters.routers.ticketsOld.path} element={<HomeTicketsController />} />
                  { !isMaster() && <Route path={constsRouters.routers.createTicket.path} element={<HomeCreateTicketController />} /> }
                  
                  <Route path={constsRouters.routers.tasksOld.path} element={<HomeTasksController />} />

                  <Route path={constsRouters.routers.historyOld.path} element={<HomeHistoryController />} />
                  <Route path={constsRouters.routers.historyChatbotOld.path} element={<HomeHistoryChatbotController />} />
                  
                  <Route path={constsRouters.routers.consumersView.path} element={<ViewConsumersController />} />
                  <Route path={constsRouters.routers.consumersOld.path} element={<HomeConsumersController />} />
                  <Route path={constsRouters.routers.consumersViewAnalysisSimilar.path} element={<ViewAnalysisSimilarConsumersController />} />

                  <Route path={constsRouters.routers.agentsOld.path} element={<HomeAgentsController />} />
                  <Route path={constsRouters.routers.agentsView.path} element={<ViewAgentsController />} />

                  { subdomain !== null && <Route path="*" element={<Page404Controller />}></Route> }

                  { (isAdmin() || isOwner() || isMaster()) &&
                    <>
                      <Route path={constsRouters.routers.dashboard.path} element={<HomeDashboardController />} />
                      <Route path={constsRouters.routers.dashboardChatbot.path} element={<HomeDashboardChatbot />} />
                      <Route path={constsRouters.routers.dashboardSummary.path} element={<HomeDashboardSummary />} />
                      <Route path={constsRouters.routers.summaryView.path} element={<HomeViewSummaryController />} />

                      <Route path={constsRouters.routers.ticketsMessageTrigger.path} element={<HomeMessageTriggerController />} />
                      <Route path={constsRouters.routers.ticketsMessageTriggerView.path} element={<ViewMessageTriggersController />} />

                      <Route path={constsRouters.routers.configurationSectors.path} element={<ListOfSectorsController />} />

                      <Route path={constsRouters.routers.configurationTags.path} element={<ListOfTagsController />} />
                      <Route path={constsRouters.routers.configurationTagsOld.path} element={<HomeTagsControllerOld />} />
                      <Route path={constsRouters.routers.configurationTagsViewOld.path} element={<ViewTagsControllerOld />} />

                      <Route path={constsRouters.routers.configurationTickets.path} element={<HomeChannelsController />} />
                      <Route path={constsRouters.routers.configurationTickets.path} element={<HomeConfigurationsTicketsController />} />
                      <Route path={constsRouters.routers.configurationDepartments.path} element={<HomeDepartments />} />
                      <Route path={constsRouters.routers.configurationDepartmentsView.path} element={<ViewDepartmentsController />} />
                      <Route path={constsRouters.routers.configurationChatbot.path} element={<HomeBotxpress />} />
                      <Route path={constsRouters.routers.configurationChatbotView.path} element={<ViewBot />} />
                      <Route path={constsRouters.routers.configurationCustomFields.path} element={<HomeCustomFieldsConfigController />} />
                      <Route path={constsRouters.routers.configurationTriage.path} element={<HomeSecurityConfigController />} />
                    </>
                  }
                  { (isAdmin() || isOwner() || isMaster() || (isAgent() && hasPhoneIntegrationEmployee === true)) && 
                    <>
                      <Route path={constsRouters.routers.configurationChannels.path} element={<HomeConfigurationsChannelsController />} />
                    </>
                  }
                  { (isAdmin() || isOwner() || isMaster() || (isAgent() && companyHasApp2FA)) && 
                    <>
                      <Route path={constsRouters.routers.configurationSecurity.path} element={<HomeSecurityConfigController />} />
                    </>
                  }
                </Route>
              </>
            }
          </>
        }
      </Routes>
    </BrowserRouter>
  );
}

export default RoutesFunction;
