import { FC, useEffect, useState } from "react";
import IModalConsumersSimilarData from "./indexModel";
import { useDispatch } from "react-redux";
import { setShowModalConsumersSimilarData } from "../../../../../store/internal";
import { useNavigate } from "react-router-dom";
import constsRouters from "../../../../../routes/constsRouter";
import ModalConsumersSimilarData from ".";
import { useTranslation } from "react-i18next";
import emitter from "../../../../../core/shared/emitter";

// interface TokenInterface {
//     getShowModalConsumersSimilarData: boolean
// }

const ModalConsumersSimilarDataController: FC<IModalConsumersSimilarData> = (props) => {

    const dispatch = useDispatch();
    // const location = useLocation();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(props.visibility);

    const [similarConsumers, setSimilarConsumers] = useState(undefined);
    const [currentConsumer, setCurrentConsumer] = useState(null);

    const { t } = useTranslation()

    // const values: TokenInterface = {
    //     getShowModalConsumersSimilarData: useSelector(getShowModalConsumersSimilarData)
    // }

    /* useEffect(() => {
        if (location?.state) {
            setSimilarConsumers(location.state.similarConsumers);
            setCurrentConsumer(location.state.currentConsumer);
        }
    }, [location]); */

    useEffect(() => {
        emitter.on('open-modal-consumer-similar-data', data => {
            setCurrentConsumer(data.current);
            setSimilarConsumers(data.duplicated);
        });

        return () => {
            emitter.off('open-modal-consumer-similar-data');
        }
    }, []);

    // useEffect(() => {
    //     setSimilarConsumers(props.similarConsumers);
    //     setCurrentConsumer(props.currentConsumer);
    // }, [props.currentConsumer, props.similarConsumers]);

    useEffect(() => {
        if (props.visibility && similarConsumers && currentConsumer)
            setShowModal(props.visibility);
        else {
            setShowModal(false);
        }
    }, [props.visibility, similarConsumers, currentConsumer]); 

    // const openUnifyModal = () => {
    //     hide();

    //     // abrindo modal de unificação
    //     dispatch(setShowModalUnifyConsumers(true));
    // }

    const hide = () => {
        dispatch(setShowModalConsumersSimilarData(false));
        setShowModal(false);
    }

    const gotoUnifyPage = (originRedirectPage) => {
        hide();
        navigate(constsRouters.routers.consumersViewAnalysisSimilar.path, { state: { similarConsumers: similarConsumers, currentConsumer: currentConsumer, originRedirectPage: originRedirectPage } });
    }

    return <ModalConsumersSimilarData
        showModal={showModal}
        hide={hide}
        currentConsumer={currentConsumer}
        similarConsumers={similarConsumers}
        gotoUnifyPage={gotoUnifyPage}
        props={props}
        t={t}
    />

}

export default ModalConsumersSimilarDataController;