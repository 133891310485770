// import { useContext } from "react"
// import { SocketContext } from "../core/context/socket-context"
import { useSocketV2Context } from "../core/context/socket-context-v2";

const useUpdateStatusEmployee = () => {

    // const socket = useContext(SocketContext);
    const { socketInitialized, emitSocketAppEvent } = useSocketV2Context();

    // const requestAgentStatus = (user_id: string, status_employee: string, company_id: string) => {
    //     socket?.socket?.emit("update-status-employee", { user_id, status_employee, company_id })
    // }

    const requestAgentStatus = (user_id: string, status_employee: string, company_id: string) => {
        if (socketInitialized) {
            emitSocketAppEvent('update-status-employee', { user_id, status_employee, company_id });
        }
    }

    return { requestAgentStatus }
}

export default useUpdateStatusEmployee;