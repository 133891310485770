import './styles.scss';
import { FC } from 'react';
import { ModalChangeStatusSectorInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import { Trans } from 'react-i18next';
import Loading from '../../../../../../components/loading2';

const ModalChangeStatusSector: FC<ModalChangeStatusSectorInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-change-status-sector-component"
    >
      <Modal.Header>
        <Modal.Title>
          { props.t(props.newStatus ? 'title_sector_activate' : 'title_sector_inactivate') }
        </Modal.Title>
        <div className="modal-change-status-sector-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingChange || props.loadingSector) && <Loading blur={true}/>}
        { props.data && !props.loadingSector &&
          <span className="modal-change-status-sector-message">
            <Trans 
              i18nKey={props.newStatus ? 'ModalChangeStatusSector:message_activate' : 'ModalChangeStatusSector:message_inactivate'}
              values={{ sectorName: props.data.name }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-change-status-sector-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        { props.data && props.newStatus && 
          <button className='modal-change-status-sector-btn-activate' disabled={props.loadingChange || props.loadingSector} onClick={() => props.handleSubmit()}>{props.t('btn_activate')}</button>
        }
        { props.data && !props.newStatus && 
          <button className='modal-change-status-sector-btn-inactivate' disabled={props.loadingChange || props.loadingSector} onClick={() => props.handleSubmit()}>{props.t('btn_inactivate')}</button>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalChangeStatusSector;
