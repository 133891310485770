import { FC, useEffect, useState } from 'react';
import ModalCancelActiveTrigger from './index';
import { ModalCancelActiveTriggerControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import emitter, { T_EmitterOpenModalCancelActiveTrigger } from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { MessageTriggerStatus } from '../../../../../core/enums/message-trigger-status';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { IconMail, IconPhone, IconBrandWhatsapp, IconBrandFacebook, IconBrandInstagram, IconMessages, IconHelp } from '@tabler/icons-react';
import { I_ActiveTriggerDetails, I_FetchActiveTrigger } from '../modalActiveTrigger/indexModel';
import { WhatsappMessageInterface } from '../../../homeTickets2/viewTicket/components/chatEditorComponent/components/popupTemplateMessages/indexModel';

const AppRequesterConst = new AppRequesterController();

const ModalCancelActiveTriggerController: FC<ModalCancelActiveTriggerControllerInterface> = (props) => {
  const { t } = useTranslation('ModalCancelActiveTrigger');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<T_EmitterOpenModalCancelActiveTrigger>(null);
  const [, setLoadingActiveTrigger] = useState(false);
  const [, setErrorLoadingActiveTrigger] = useState(false);
  const [activeTrigger, setActiveTrigger] = useState<I_ActiveTriggerDetails>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-cancel-active-trigger', data => {
      if (data?.id) {
        fetchActiveTrigger(data.id).then(response => {
          setData(data);
          setActiveTrigger(response.message_sending);
        });
      }
    });

    return () => {
      emitter.off('open-modal-cancel-active-trigger');
    }
  }, []);

  // ==================================
  // Request Message Sending
  const fetchActiveTrigger = async (id: string): Promise<{ message_sending: I_ActiveTriggerDetails; template_message: WhatsappMessageInterface }> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingActiveTrigger(true);
    setErrorLoadingActiveTrigger(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/message-sending/${id}`, config,
        () => {},
        (response: I_FetchActiveTrigger) => {
          setErrorLoadingActiveTrigger(false);
          if (response.status === 200 && response.data?.message_sending) {
            resolve({ message_sending: response.data.message_sending, template_message: response.data?.template_message }); // colocar o template_message aqui tbm
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingActiveTrigger(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingActiveTrigger, { }
      );
    });
  }

  const handleSubmit = () => {
    cancelActiveTrigger();
  }

  const cancelActiveTrigger = () => {
    if (data && activeTrigger) {
      const headers  = UserService.getHeaders();
      const config = { headers };

      const payload = {
        id: data.id,
        message_sending_status_id: MessageTriggerStatus.Canceled
      };

      if (activeTrigger.is_active_triage) {
        payload['has_triage'] = activeTrigger.has_triage;
      }

      if (activeTrigger.response_message_level) {
        payload['response_message_level'] = activeTrigger.response_message_level;
      }

      AppRequesterConst.Put(
        '/message-sending', payload, config,
        () => {},
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.canceled_successfully'), visibility: true, signalIcon: true }));
          props.hide(true);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingDelete
      );
    }
  }

  const formatDateScheduled = (date_scheduled: string) => {
    const date = new Date(date_scheduled);
    return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
  }

  const getStatusName = (status_id: string) => {
    switch (status_id) {
      case MessageTriggerStatus.Scheduled:
        return t('labels.status_name.scheduled');
      case MessageTriggerStatus.Triggered:
        return t('labels.status_name.triggered');
      case MessageTriggerStatus.Canceled:
        return t('labels.status_name.canceled');
    }
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    const size = 20;
    const color = '#707070';
    switch (channel_id) {
      case '3f3fa530-fd3e-4018-ab98-4d9c3cd5406a': // E-mail
        return { title: t("labels.channels_name.email"), icon: <IconMail stroke={1} size={size} color={color} /> };
      case '6977996d-11d1-44cc-a863-2e1d9c24e046': // Telefonia
        return { title: t("labels.channels_name.telephony"), icon: <IconPhone stroke={1} size={size} color={color} />};
      case '33be81d8-88ac-4e08-bb4d-cf69fd23267f': // Whatsapp
        return { title: t("labels.channels_name.whatsapp"), icon: <IconBrandWhatsapp stroke={1} size={size} color={color} />};
      case 'aa1cea93-23de-46aa-af14-d6f766acf5c8': // Facebook
        return { title: t("labels.channels_name.facebook"), icon: <IconBrandFacebook stroke={1} size={size} color={color} />};
      case 'f28cb4a4-e42b-425a-8011-59d5f9c1d0f2': // Instagram
        return { title: t("labels.channels_name.instagram"), icon: <IconBrandInstagram stroke={1} size={size} color={color} />};
      case '4a7b71bb-6432-4794-ad11-f978b067871d': // Webchat
        return { title: t("labels.channels_name.webchat"), icon: <IconMessages stroke={1} size={size} color={color} />};
      default:
        return { title: 'N/A', icon: <IconHelp stroke={1} size={size} color={color} />}
    }
  }

  return (
    <ModalCancelActiveTrigger 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingDelete={loadingDelete}
      handleSubmit={handleSubmit}
      formatDateScheduled={formatDateScheduled}
      getStatusName={getStatusName}
      getChannel={getChannel}
    />
  );
};

export default ModalCancelActiveTriggerController;
