import { FC, useEffect, useState } from 'react';
import ModalChangeStatusTriggerType from './index';
import { ModalChangeStatusTriggerTypeControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalChangeStatusTriggerTypeController: FC<ModalChangeStatusTriggerTypeControllerInterface> = (props) => {
  const { t } = useTranslation('ModalChangeStatusTriggerType');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ triggerTypeId: string, triggerTypeName: string, newStatus: boolean }>(null);
  const [loadingChange, setLoadingChange] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-change-status-trigger-type', data => {
      setData(data);
    });

    return () => {
      emitter.off('open-modal-change-status-trigger-type');
    }
  }, []);

  const handleSubmit = () => {
    editTriggerType();
  }

  const editTriggerType = () => {
    if (data) {
      const headers  = UserService.getHeaders();

      const payload = {
        id: data.triggerTypeId,
        active: data.newStatus
      };

      const config = {
        headers
      };

      AppRequesterConst.Put(
        '/message-sending-type', payload, config,
        () => {},
        () => {
          if (data.newStatus) {
            dispatch(setShowAlertFeedback({ message: t('alert.changed_successfully_activate'), visibility: true, signalIcon: true }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.changed_successfully_inactivate'), visibility: true, signalIcon: true }));
          }
          props.hide(true);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingChange
      );
    }
  }

  return (
    <ModalChangeStatusTriggerType 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingChange={loadingChange}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalChangeStatusTriggerTypeController;
