import './styles.scss';
import { FC } from 'react';
import { ModalAgentInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconUserBolt, IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomInputController from '../../../../../components/customInput/indexController';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import { UserProfile } from '../../../../../core/enums/user-profile';

const ModalAgent: FC<ModalAgentInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="lg"
      centered={true}
      fullscreen='sm-down'
      dialogClassName='modal-agent-component'
    >
      <Modal.Header>
        { props.mode === 'edit' && <Modal.Title>{props.t('title_edit')}</Modal.Title>}
        { props.mode === 'create' && <Modal.Title>{props.t('title_new')}</Modal.Title>}
        <div className="modal-agent-header-actions">
          <IconX className='modal-agent-header-actions-close' onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      { props.loadingAgent &&
        <Modal.Body className='modal-body-loading'>
          <Loading blur />
          <div className="modal-body-loading-info">
            <IconUserBolt />
            <span className="modal-body-loading-info-message">
              {props.t('loading_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingAgent && props.errorLoadingAgent &&
        <Modal.Body className='modal-body-loading'>
          <div className="modal-body-loading-info modal-body-loading-error">
            <IconUserBolt />
            <span className="modal-body-loading-info-message">
              {props.t('loading_error_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { !props.loadingAgent && !props.errorLoadingAgent && 
        <Modal.Body>
          { props.loadingRequest && <Loading blur /> }
          <div className="modal-agent-section">
            <div className="modal-agent-items-group">
              <div className="modal-agent-item">
                <div className="modal-agent-item-header">
                  <span className="modal-agent-item-label">{props.t('form.name_label')}</span>
                  <span className="modal-agent-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.agentValuesObject?.name || ''}
                  setCustomValue={props.setAgentValue}
                  placeholder={props.t('form.name_placeholder')}
                  required={true}
                  id={'name'}
                />
              </div>
              <div className="modal-agent-item">
                <div className="modal-agent-item-header">
                  <span className="modal-agent-item-label">{props.t('form.email_label')}</span>
                  <span className="modal-agent-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='email'
                  value={props.agentValuesObject?.email || ''}
                  setCustomValue={props.setAgentValue}
                  placeholder={props.t('form.email_placeholder')}
                  required={true}
                  id={'email'}
                  isValidInputRef={props.isValidEmailRef}
                />
                { props.agentValuesObject?.email?.length > 0 && !props.isValidEmailRef.current && 
                  <span className="modal-agent-item-invalid-message">{props.t('form.email_invalid')}</span>
                }
              </div>
            </div>
            <div className="modal-agent-item">
              <div className="modal-agent-item-header">
                <span className="modal-agent-item-label">{props.t('form.country_label')}</span>
                <span className="modal-agent-item-label-required">*</span>
              </div>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                placeholder={props.t('form.country_placeholder')}
                options={props.getCountrySelect().countryList || []}
                selected={props.getCountrySelect().selectedCountry}
                setSelected={props.getCountrySelect().setSelectedCountry}
                required={true}
              />
            </div>
            { props.getCountrySelect().selectedCountry?.id &&
              <div className="modal-agent-items-group">
                <div className="modal-agent-item">
                  <div className="modal-agent-item-header">
                    <span className="modal-agent-item-label">{props.t(`form.document_${props.getCountrySelect().selectedCountry.id}_label`)}</span>
                  </div>
                  <CustomInputController 
                    type='document'
                    value={props.agentValuesObject?.document_number || ''}
                    setCustomValue={props.setAgentValue}
                    placeholder={props.t(`form.document_${props.getCountrySelect().selectedCountry.id}_placeholder`)}
                    id={'document_number'}
                    documentInputSettings={{ country: props.getCountrySelect().selectedCountry.id, documentType: 'personal' }}
                    isValidInputRef={props.isValidDocumentRef}
                  />
                  { props.agentValuesObject?.document_number?.length > 0 && !props.isValidDocumentRef.current && 
                    <span className="modal-agent-item-invalid-message">{props.t('form.document_invalid')}</span>
                  }
                </div>
                <div className="modal-agent-item">
                  <div className="modal-agent-item-header">
                    <span className="modal-agent-item-label">{props.t('form.phone_label')}</span>
                  </div>
                  <CustomInputController 
                    type='phone'
                    value={props.agentValuesObject?.phone || ''}
                    setCustomValue={props.setAgentValue}
                    placeholder={props.t('form.phone_placeholder')}
                    id={'phone'}
                    isValidInputRef={props.isValidPhoneRef}
                    phoneInputSettings={{
                      enableSearch: true,
                      searchPlaceholder: props.t('form.phone_country_search'),
                      searchNotFound: props.t('form.phone_country_not_found'),
                      disableDropdown: true,
                      countryDefault: props.getCountrySelect().selectedCountry?.id || 'br',
                      onlyCountries: props.getCountrySelect().allowedCountries,
                      countryCodeEditable: false
                    }}
                  />
                  { props.agentValuesObject?.phone?.length > 0 && !props.isValidPhoneRef.current && 
                    <span className="modal-agent-item-invalid-message">{props.t('form.phone_invalid')}</span>
                  }
                </div>
              </div>
            }
            <div className="modal-agent-item">
              <div className="modal-agent-item-header">
                <span className="modal-agent-item-label">{props.t('form.role_label')}</span>
              </div>
              <CustomInputController 
                type='input'
                value={props.agentValuesObject?.role || ''}
                setCustomValue={props.setAgentValue}
                placeholder={props.t('form.role_placeholder')}
                id={'role'}
              />
            </div>
            <div className="modal-agent-item">
              <div className="modal-agent-item-header">
                <span className="modal-agent-item-label">{props.t('form.observations_label')}</span>
              </div>
              <CustomInputController 
                type='textarea'
                value={props.agentValuesObject?.observations || ''}
                setCustomValue={props.setAgentValue}
                placeholder={props.t('form.observations_placeholder')}
                id={'observations'}
              />
            </div>
          </div>
          { (props.mode === 'create' || (props.agentValuesObject && props.agentValuesObject?.profile_id !== UserProfile.Owner)) &&
            <div className="modal-agent-section">
              <div className="modal-agent-section-profile">
                <div className="modal-agent-section-profile-info">
                  <span className="modal-agent-section-profile-title">{props.t('form.profile_label')}</span>
                  <span className="modal-agent-section-profile-message">{props.t('form.profile_message')}</span>
                </div>
                <div className="modal-agent-section-profile-switch">
                  <div className="switch-input">
                    <div className="switch-input">
                      <OverlayTrigger overlay={<Tooltip>{props.isAdmin ? props.t('form.profile_active') : props.t('form.profile_inactive')}</Tooltip>}>
                        <label className="switch">
                          <input type="checkbox" checked={props.isAdmin} onChange={() => props.setIsAdmin(!props.isAdmin)} />
                          <span className="slider round"></span>
                        </label>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </Modal.Body>
      }
      <Modal.Footer>
        <button className='modal-agent-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-agent-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
          {props.t(props.mode === 'edit' ? 'btn_save' : 'btn_create')}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAgent;
