import { IconAdjustmentsHorizontal, IconFilter, IconSearch, IconSearchOff, IconX } from '@tabler/icons-react';
import { SearchComponentInterface } from './indexModel';
import './styles.scss';
import { FC } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FadeInOut from '../../../../components/fadeInOut';
import PopupSearchFilterController from './popupSearchFilter/indexController';

const SearchComponent: FC<SearchComponentInterface> = (props) => {

  const searchedClass = props.isSearched ? 'search-component-filtered' : '';
  const showPopupClass = props.showFilter ? 'search-component-icon-popup-active' : '';
  const inputSearchClass = props.hasSearchFilter ? 'search-component-input-item-with-filter' : '';
  const clearSearchIconClass = props.hasSearchFilter ? 'search-component-icon-clear-with-filter' : '';

  return (
    <div className="search-component-2" ref={props.popupRef}>
      { props.hasSearchInput &&
        <div className="search-component-input">
          <IconSearch className='search-component-icon-label' />
          <input 
            className={`search-component-input-item ${inputSearchClass}`}
            value={props.termSearch || ''}
            type="text"
            placeholder={props.placeholder || props.t('fallback_placeholder')}
            onChange={props.onChangeSearchQuery}
            onKeyDown={(e) => props.handleSearchFunction(e)}
          />
          { props.isSearched && props.termSearch?.length > 0 &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('clear')}</Tooltip>}>
              <IconX className={`search-component-icon-clear ${clearSearchIconClass}`} onClick={() => props.clearFilterTerm(true)} />
            </OverlayTrigger>
          }
          { props.hasSearchFilter &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.showFilter ? props.t('hide_filter') : props.t('show_filter')}</Tooltip>}>
              <IconAdjustmentsHorizontal className={`search-component-icon-popup ${showPopupClass} ${searchedClass}`} onClick={() => props.handleShowFilter()} />
            </OverlayTrigger>
          }
          { props.hasSearchFilter && props.showFilter &&
            <PopupSearchFilterController
              page={props.page} 
              showFilter={props.showFilter} 
              setShowFilter={props.setShowFilter} 
              applyFilter={props.applyFilter}
              filterApplied={props.filterApplied}
              isSearched={props.isSearched}
              filteredMode={props.filteredMode}
            />
          }
        </div>
      }
      { !props.hasSearchInput && props.hasSearchFilter &&
        <div className="search-component-input search-component-input-fake">
          <IconFilter className='search-component-icon-label' />
          <div className={`search-component-input-fake-div ${showPopupClass}`} onClick={() => props.handleShowFilter()}>
            <span className="search-component-input-fake-placeholder">{props.placeholder}</span>
          </div>
          { props.isSearched &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('clear')}</Tooltip>}>
              <IconX className="search-component-icon-clear" onClick={() => props.onClickClearFunction()} />
            </OverlayTrigger>
          }
          <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.showFilter ? props.t('hide_filter') : props.t('show_filter')}</Tooltip>}>
            <IconAdjustmentsHorizontal className={`search-component-icon-popup ${showPopupClass} ${searchedClass}`} onClick={() => props.handleShowFilter()} />
          </OverlayTrigger>
          { props.hasSearchFilter && props.showFilter &&
            <PopupSearchFilterController
              page={props.page} 
              showFilter={props.showFilter} 
              setShowFilter={props.setShowFilter} 
              applyFilter={props.applyFilter}
              filterApplied={props.filterApplied}
              isSearched={props.isSearched}
              filteredMode={props.filteredMode}
            />
          }
        </div>
      }
    </div>
  );
}

export default SearchComponent;