import { FC } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ModalConsumerIntertface } from './indexModel';
import { IconAlertCircle, IconChevronDown, IconFileDownload, IconFileTypeCsv, IconUser, IconX } from '@tabler/icons-react';
import CustomInputController from '../../../../../components/customInput/indexController';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import { CustomField } from '../../../../../core/enums/custom-field-type';
import Loading from '../../../../../components/loading2';

const ModalConsumer: FC<ModalConsumerIntertface> = (props) => {
  const showCustomFieldsClass = props.showCustomFields ? 'modal-consumer-show-custom-fields' : '';
  const modalTabSingleSelectedClass = props.modalTab === 'single' ? 'modal-consumer-tab-item-selected' : '';
  const modalTabMultiSelectedClass = props.modalTab === 'multiple' ? 'modal-consumer-tab-item-selected' : '';
  const fileUploaderSelectedClass = props.selectedFileConsumers?.name ? 'modal-consumer-import-selected' : '';

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="lg"
      centered={true}
      fullscreen='sm-down'
      dialogClassName='modal-consumer-component'
    >
      <Modal.Header>
        { props.mode === 'edit' && !props.isCreatedByEmployee && <Modal.Title>{props.t('title_edit')}</Modal.Title>}
        { props.mode === 'edit' && props.isCreatedByEmployee && <Modal.Title>{props.t('title_info')}</Modal.Title>}
        { props.mode === 'create' && props.modalTab === 'single' && <Modal.Title>{props.t('title_new')}</Modal.Title>}
        { props.mode === 'create' && props.modalTab === 'multiple' && <Modal.Title>{props.t('title_new_multi')}</Modal.Title>}
        <div className="modal-consumer-header-actions">
          { props.mode === 'create' &&
            <div className="modal-consumer-header-actions-tabs-select">
              <span className={`modal-consumer-tab-item ${modalTabSingleSelectedClass}`} onClick={() => props.changeModalTab('single')}>
                {props.t('tab_single')}
              </span>
              <span className={`modal-consumer-tab-item ${modalTabMultiSelectedClass}`}  onClick={() => props.changeModalTab('multiple')}>
                {props.t('tab_multi')}
              </span>
            </div>
          }
          <IconX className='modal-consumer-header-actions-close' onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      { props.loading &&
        <Modal.Body className='modal-body-loading'>
          <Loading blur />
          <div className="modal-body-loading-info">
            <IconUser />
            <span className="modal-body-loading-info-message">
              {props.t('loading_message')}
            </span>
          </div>
        </Modal.Body>
      }
      { props.loadingRequest && <Loading blur /> }
      { !props.loading && props.modalTab === 'single' &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.name_label')}</span>
                  <span className="modal-consumer-item-label-required">*</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.name || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.name_placeholder')}
                  required={true}
                  id={'name'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.email_label')}</span>
                </div>
                <CustomInputController 
                  type='email'
                  value={props.consumerValuesObject?.email || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.email_placeholder')}
                  id={'email'}
                  isValidInputRef={props.isValidEmailRef}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.email?.length > 0 && !props.isValidEmailRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.email_invalid')}</span>
                }
              </div>
            </div>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.document_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.document_number || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.document_placeholder')}
                  id={'document_number'}
                  disabled={props.isCreatedByEmployee}
                />
                {/* { props.consumerValuesObject?.phone?.length > 0 && !props.isValidDocumentRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.document_invalid')}</span>
                } */}
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.phone_label')}</span>
                </div>
                <CustomInputController 
                  type='phone'
                  value={props.consumerValuesObject?.phone || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.phone_placeholder')}
                  id={'phone'}
                  isValidInputRef={props.isValidPhoneRef}
                  phoneInputSettings={{
                    enableSearch: true,
                    searchPlaceholder: props.t('form.phone_country_search'),
                    searchNotFound: props.t('form.phone_country_not_found'),
                    preferredCountries: ['br', 'pt', 'es', 'fr', 'gb']
                  }}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.phone?.length > 0 && !props.isValidPhoneRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.phone_invalid')}</span>
                }
              </div>
            </div>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.address_label')}</span>
              </div>
              <CustomInputController 
                type='input'
                value={props.consumerValuesObject?.address || ''}
                setCustomValue={props.setConsumerValue}
                placeholder={props.t('form.address_placeholder')}
                id={'address'}
                inputLengthMax={100}
                disabled={props.isCreatedByEmployee}
              />
            </div>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.note_label')}</span>
              </div>
              <CustomInputController 
                type='textarea'
                value={props.consumerValuesObject?.observations || ''}
                setCustomValue={props.setConsumerValue}
                placeholder={props.t('form.note_placeholder')}
                id={'observations'}
                inputLengthMax={200}
                disabled={props.isCreatedByEmployee}
              />
            </div>
          </div>
          <div className="modal-consumer-section">
            <span className="modal-consumer-section-title">{props.t('form.section_addictional')}</span>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.assigned_agent_label')}</span>
              </div>
              <CustomSelectController 
                type='pagination'
                hasFilter={true}
                placeholder={props.t('form.assigned_agent_placeholder')}
                placeholderFilter={props.t('form.assigned_agent_search')}
                options={props.getEmployeeSelect().employeesList || []}
                selected={props.getEmployeeSelect().selectedEmployee}
                setSelected={props.getEmployeeSelect().setSelectedEmployee}
                fetchMore={props.getEmployeeSelect().fetchEmployeesPagination}
                hasMore={props.getEmployeeSelect().hasMoreEmployeesList}
                loading={props.getEmployeeSelect().isLoadingEmployeesSelect}
                search={props.getEmployeeSelect().fetchSearchEmployees}
                dropdownPosition='body'
                disabled={props.isCreatedByEmployee}
              />
            </div>
            <div className="modal-consumer-item">
              <div className="modal-consumer-item-header">
                <span className="modal-consumer-item-label">{props.t('form.tags_label')}</span>
              </div>
              <CustomSelectController 
                type='pagination'
                hasFilter={true}
                isMultiselect={true}
                placeholder={props.t('form.tags_placeholder')}
                placeholderFilter={props.t('form.tags_search')}
                options={props.getTagsSelect().tagsList || []}
                selectedMulti={props.getTagsSelect().selectedTags}
                setSelectedMulti={props.getTagsSelect().setSelectedTags}
                fetchMore={props.getTagsSelect().fetchTagsPagination}
                hasMore={props.getTagsSelect().hasMoreTagsList}
                loading={props.getTagsSelect().isLoadingTagsSelect}
                search={props.getTagsSelect().fetchSearchTags}
                dropdownPosition='body'
                disabled={props.isCreatedByEmployee}
              />
            </div>
          </div>
          <div className="modal-consumer-section">
            <span className="modal-consumer-section-title">{props.t('form.section_social')}</span>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.whatsapp_label')}</span>
                </div>
                <CustomInputController 
                  type='phone'
                  value={props.consumerValuesObject?.whatsapp || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.whatsapp_label')}
                  id={'whatsapp'}
                  isValidInputRef={props.isValidWhatsappRef}
                  phoneInputSettings={{
                    enableSearch: true,
                    searchPlaceholder: props.t('form.phone_country_search'),
                    searchNotFound: props.t('form.phone_country_not_found'),
                    preferredCountries: ['br', 'pt', 'es', 'fr', 'gb']
                  }}
                  disabled={props.isCreatedByEmployee}
                />
                { props.consumerValuesObject?.whatsapp?.length > 0 && !props.isValidWhatsappRef.current && 
                  <span className="modal-consumer-item-invalid-message">{props.t('form.phone_invalid')}</span>
                }
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.instagram_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.instagram_user || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.instagram_placeholder')}
                  id={'instagram_user'}
                  inputLengthMax={30}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-items-group">
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.twitter_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.twitter || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.twitter_placeholder')}
                  id={'twitter'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
              <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.facebook_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.facebook_user || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.facebook_placeholder')}
                  id={'facebook_user'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
            </div>
            <div className="modal-consumer-item">
                <div className="modal-consumer-item-header">
                  <span className="modal-consumer-item-label">{props.t('form.youtube_label')}</span>
                </div>
                <CustomInputController 
                  type='input'
                  value={props.consumerValuesObject?.youtube || ''}
                  setCustomValue={props.setConsumerValue}
                  placeholder={props.t('form.youtube_placeholder')}
                  id={'youtube'}
                  inputLengthMax={50}
                  disabled={props.isCreatedByEmployee}
                />
              </div>
          </div>
          <div className="modal-consumer-section modal-consumer-section-custom-fields">
            <span className={`modal-consumer-section-title ${showCustomFieldsClass}`} onClick={() => props.setShowCustomFields(!showCustomFieldsClass)}>
              {props.t('form.section_custom_fields')}
              <IconChevronDown />
            </span>
            { props.showCustomFields && props.getCustomFieldsData().formattedCustomFields.length > 0 && 
              <div className="modal-consumer-custom-fields">
                {props.getCustomFieldsData().formattedCustomFields.map(item => {
                  return (
                    <div className="modal-consumer-item" key={item.id}>
                      <div className="modal-consumer-item-header">
                        <span className="modal-consumer-item-label" title={item.name}>{item.name}</span>
                        { item.required && <span className="modal-consumer-item-label-required">*</span>}
                      </div>
                      {
                        item.custom_field_type_id === CustomField.Text &&
                        <CustomInputController 
                          type='input'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Textarea &&
                        <CustomInputController 
                          type='textarea'
                          value={props.getCustomFieldsData().customFieldsValuesObject[item.id]?.toString() || ''}
                          setCustomValue={item.setValue}
                          placeholder={item.placeholder}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Dropdown &&
                        <CustomSelectController 
                          type='simple'
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selected={props.getCustomFieldsData().customFieldsValuesObject[item.id]}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                      {
                        item.custom_field_type_id === CustomField.Multiselect &&
                        <CustomSelectController 
                          type='simple'
                          isMultiselect={true}
                          placeholder={item.placeholder}
                          options={item.options || []}
                          selectedMulti={props.getCustomFieldsData().customFieldsValuesObject[item.id] || []}
                          setCustomSelected={item.setValue}
                          loading={false}
                          required={item.required}
                          id={item.id}
                          disabled={props.isCreatedByEmployee}
                        />
                      }
                    </div>
                  );
                })}
              </div>
            }
            { props.showCustomFields && props.getCustomFieldsData().formattedCustomFields.length === 0 && 
              <div className="modal-consumer-custom-fields">
                <span className="modal-consumer-custom-fields-empty">
                  <IconAlertCircle />
                  {props.t('form.custom_fields_empty')}
                </span>
              </div>
            }
          </div>
        </Modal.Body>
      }
      { !props.loading && props.modalTab === 'multiple' &&
        <Modal.Body>
          <div className="modal-consumer-section">
            <span className="modal-consumer-section-title">
              {props.t('import.label')}
              <OverlayTrigger 
                trigger={['click']} 
                placement='right' 
                overlay={props.popoverFileModel()}
                show={props.showMenuDownload}
                rootClose={true}
              >
                <span className='ticket-chat-header-action' onClick={() => props.handleOpenPopover()}>
                  <OverlayTrigger placement='top' overlay={<Tooltip>{props.t('import.download_tooltip')}</Tooltip>}>
                    <IconFileDownload className='modal-consumer-import-download-icon' />
                  </OverlayTrigger>
                </span>
              </OverlayTrigger>
            </span>
            <div {...props.getRootProps({ className: `modal-consumer-import-area ${fileUploaderSelectedClass}` })}>
                <input {...props.getInputProps({})} />
                { !props.selectedFileConsumers &&
                  <span className="modal-consumer-import-area-message">
                    { <>{props.t(props.isDragging ? 'import.message_dragging' : 'import.message')}</> }
                  </span>
                }
                { props.selectedFileConsumers?.name &&
                  <span className="modal-consumer-import-area-selected-file">
                    <IconFileTypeCsv />
                    { props.selectedFileConsumers.name }
                    { !props.isLoadingUploadConsumersFile &&
                      <OverlayTrigger overlay={<Tooltip>{props.t('import.remove_file')}</Tooltip>}>
                        <IconX className='modal-consumer-import-delete-file' onClick={props.removeSelectedFile} />
                      </OverlayTrigger>
                    }
                    { props.isLoadingUploadConsumersFile &&
                      <span className="circle-loader"></span>
                    }
                  </span>
                }
              </div>
          </div>
        </Modal.Body>
      }
      <Modal.Footer>
        <button className='modal-consumer-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        { props.modalTab === 'single' && !props.isCreatedByEmployee &&
          <button className='modal-consumer-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
            {props.t(props.mode === 'edit' ? 'btn_save' : 'btn_create')}
          </button>
        }
        { props.modalTab === 'multiple' && 
          <button className='modal-consumer-btn-save' onClick={() => props.handleImport()} disabled={!props.isValid() || props.isLoadingUploadConsumersFile}>
            {props.t('btn_import')}
          </button>
        }
      </Modal.Footer>
    </Modal>
  );
}

export default ModalConsumer;