import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackendConstants from "../../../../core/constants/backend-constants";
import UserService from "../../../../services/user-service";
import { useNavigate } from "react-router";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowConfirmationModal, getShowMeetButton } from "../../../../store/internal";
import { getOwner_id } from "../../../../store/callcenter";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import HomeConsumersConfig from "./index";
import { useTranslation } from "react-i18next";
import { useMainAppContext } from "../../../../core/context/main-app-context";


const AppRequesterConst = new AppRequesterController();

const HomeConsumersConfigController = (props: any) => {

    const dispatch = useDispatch();
    const { setMainHeader } = useMainAppContext();

    const [isLoading, setIsLoading] = useState(false);
    const [/*currentFilter*/, setCurrentFilter] = useState(undefined);
    const [custom, setCustom] = useState([]);
    const [currentPage, setCurrentPage] = useState(1)
    const [currentParams, setCurrentParams] = useState({})
    const [currentState, setCurrentState] = useState("");
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit)
    const [showCustomFieldConsumerModalRegister, setShowCustomFieldConsumerModalRegister] = useState(false)
    const [showCustomFieldTicketModalRegister, setShowCustomFieldTicketModalRegister] = useState(false)

    const [aux, setAux] = useState([])

    const { t } = useTranslation()

    const handleCloseCustomFieldConsumerModalRegister = () => setShowCustomFieldConsumerModalRegister(false)
    const handleShowCustomFieldConsumerModalRegister = () => setShowCustomFieldConsumerModalRegister(true)

    const handleCloseCustomFieldTicketModalRegister = () => setShowCustomFieldTicketModalRegister(false)
    const handleShowCustomFieldTicketModalRegister = () => setShowCustomFieldTicketModalRegister(true)

    const [customDataTicket, setCustomDataTicket] = useState([]);
    const [customDataConsumer, setCustomDataConsumer] = useState([]);

    useEffect(() => {
        setMainHeader({
            pageTitle: t('configurations_consumers.general.welcome_main_custom_fields'),
            pageSubtitle: t('configurations_consumers.general.welcome_sub'),
            hasSearchComponent: false,
            hasSearchInput: false,
            hasSearchFilter: false,
            searchPage: null,
            searchPlaceholder: null,
            searchFunction: null,
            clearSearchFunction: null,
            hasCreateButton: false,
            createButtonText: null,
            createButtonTooltip: null,
            createButtonAction: null,
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController()

        if (props.values === null) {

        } else {
            setCurrentParams((params) => {
                setCurrentState((state) => {
                    getAllInformationsToPagination({ limit: currentLimit, page: currentPage }, controller)
                    return state
                })
                return params
            })
        }

        return () => {
            controller.abort()
        }

    }, [currentPage, currentState])

    useEffect(() => {
        if (custom) {
            let arrayTicket = [];
            let arrayConsumer = [];
            for (let index = 0; index < custom?.length; index++) {
                if (custom[index]?.entity_type_id === '250204ce-44eb-4cdc-865a-f54eed9c6496') {
                    arrayTicket.push({ name: custom[index]?.name, type: custom[index]?.custom_field_type_id, id: custom[index]?.id });
                }
                else if(custom[index]?.entity_type_id === 'b93b0740-a77d-4474-aad9-7aa0044d5654'){
                    arrayConsumer.push({ name: custom[index]?.name, type: custom[index]?.custom_field_type_id, id: custom[index]?.id });
                }
            }
            setCustomDataTicket(arrayTicket);
            setCustomDataConsumer(arrayConsumer);
        }
    }, [custom])

    const getAllCustomFields = async (params: { limit: number; page: number }, controller: AbortController) => {
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // }
        const headers = UserService.getHeaders()
        const signal = controller.signal
        
        await AppRequesterConst.Get(
            `/custom-fields`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data?.status === 200 && data) {
                    setAux((state) => {
                        let aux = state.concat(data?.data?.custom_fields)
                        setAux(aux)
                        setCustom(aux)
                        return state
                    })
                } else if (aux.length === 0) {
                    setCustom(undefined)
                } else {
                    setCustom([])
                }
            },
            (error: { response: { status: number } }) => {
                if (error.response?.status === 400) {

                }
                if (error.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    useEffect(() => {

        let array = [];
        for (let index = 0; index < custom?.length; index++) {
            array.push({ name: custom[index]?.name, type: custom[index]?.custom_field_type_id, id: custom[index]?.id });
        }
        setCurrentFilter(array);
    }, [setCustom])


    const navigate = useNavigate();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    const updateListFunction = () => {
        setCurrentPage(currentPage + 1)
    }

    const getAllInformationsToPagination = (params: { limit: number; page: number }, controller: AbortController) => {
        getAllCustomFields(params, controller)
    }

    const callBackModalCustomField = () => {
        setCurrentPage(1)
        setAux([])
        const controller = new AbortController()
        getAllInformationsToPagination({ limit: currentLimit, page: currentPage }, controller)
    }

    return <HomeConsumersConfig
        isLoading={isLoading}
        showCustomFieldConsumerModalRegister={showCustomFieldConsumerModalRegister}
        handleCloseCustomFieldConsumerModalRegister={handleCloseCustomFieldConsumerModalRegister}
        handleShowCustomFieldConsumerModalRegister={handleShowCustomFieldConsumerModalRegister}
        showCustomFieldTicketModalRegister={showCustomFieldTicketModalRegister}
        handleCloseCustomFieldTicketModalRegister={handleCloseCustomFieldTicketModalRegister}
        handleShowCustomFieldTicketModalRegister={handleShowCustomFieldTicketModalRegister}
        callBackModalCustomField={callBackModalCustomField}
        updateListFunction={updateListFunction}
        //custom={custom}
        customDataTicket={customDataTicket}
        customDataConsumer={customDataConsumer}
        t={t}
    />
}

export default HomeConsumersConfigController;