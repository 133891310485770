import './styles.scss';
import { FC } from 'react';
import { ModalReopenTicketInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';

const ModalReopenTicket: FC<ModalReopenTicketInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-reopen-ticket-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-reopen-ticket-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingReopen) && <Loading blur={true}/>}
        { props.data &&
          <span className="modal-reopen-ticket-message">
            {props.t('message_1')} <span>#{props.data.ticketReferenceId}</span>. {props.t('message_2')}
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-reopen-ticket-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-reopen-ticket-btn-save' disabled={props.loadingReopen} onClick={() => props.handleSubmit()}>{props.t('btn_reopen')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReopenTicket;
