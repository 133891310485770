import { CustomInputInterface, TagifyEvent } from './indexModel';
import './styles.scss';
import { FC } from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import "@yaireo/tagify/dist/tagify.css";
import DatePicker from 'react-datepicker';
import { IconCalendarEvent, IconClock } from '@tabler/icons-react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import InputMask from 'react-input-mask';

const CustomInput: FC<CustomInputInterface> = (props) => {
  const disabledClass = props.disabled ? 'custom-input-component-disabled' : '';
  const invalidClass = props.isValidInput ? '' : 'custom-input-component-invalid';
  const datePickerCustomSizeClass = props.inputWidth < 460 ? 'custom-input-datepicker-size-custom' : '';
  const timePickerCustomSizeClass = props.datePickerSettings?.timeContainerWidth === 'large' ? 'custom-input-timepicker' : '';

  return (
    <div className={`custom-input-component ${disabledClass}`} ref={props.customInputRef}>
      {
        ['input', 'email'].includes(props.type) &&
        <input
          className={invalidClass}
          type="text" 
          value={props.value || ''} 
          required={!!props.required} 
          placeholder={props.placeholder || ''}
          onChange={props.handleChangeInput}
          onPaste={props.handlePasteInput}
          disabled={!!props.disabled}
          minLength={props.inputLengthMin}
          maxLength={props.inputLengthMax}
          ref={props.inputRef}
        />
      }
      {
        props.type === 'input-number' &&
        <input
          className={invalidClass}
          type="number" 
          min={props.valueNumberMin || 0}
          max={props.valueNumberMax || 999}
          value={props.valueNumber} 
          required={!!props.required} 
          placeholder={props.placeholder || ''}
          onChange={props.handleChangeInput}
          disabled={!!props.disabled}
          ref={props.inputRef}
        />
      }
      {
        props.type === 'textarea' &&
        <textarea
          className={invalidClass}
          value={props.value || ''} 
          onChange={props.handleChangeTextarea}
          onPaste={props.handlePasteTextarea}
          placeholder={props.placeholder || ''}
          required={!!props.required}
          disabled={!!props.disabled}
          minLength={props.inputLengthMin}
          maxLength={props.inputLengthMax}
          ref={props.textareaRef}
        />
      }
      {
        props.type === 'document' &&
        <InputMask 
          className={`custom-input-document ${invalidClass}`}
          mask={props.getDocumentMask()}
          maskChar={null}
          value={props.value}
          onChange={props.handleChangeInput}
          required={!!props.required} 
          placeholder={props.placeholder || ''}
          disabled={!!props.disabled}
        />
      }
      {
        props.type === 'tags' &&
        <Tags
          value={props.tagsValue || []}
          className="custom-input-tags"
          placeholder={props.placeholder || ''}
          onChange={(e: TagifyEvent) => props.handleChangeTags(e)}
          settings={props.tagifySettings || {}}
          required={!!props.required}
          disabled={!!props.disabled}
        />
      }
      {
        props.type === 'datepicker' &&
        <DatePicker 
          className={`custom-input-datepicker ${invalidClass}`}
          calendarClassName={`${datePickerCustomSizeClass}`}
          id="custom-input-datepicker"
          clearButtonClassName="custom-input-datepicker-clear"
          locale={props.getLocale().language}
          dateFormat={props.getLocale().dateFormat}
          showIcon
          icon={<IconCalendarEvent onClick={() => props.onCalendarIconClick()} />}
          placeholderText={props.placeholder}
          onChange={(dates: Date | [Date, Date]) => props.handleChangeDatepicker(dates)}
          selectsRange={props.datePickerSettings.selectsRange || undefined}
          selected={props.datePickerSettings.startDate}
          startDate={props.datePickerSettings.startDate}
          endDate={props.datePickerSettings.endDate || undefined}
          minDate={props.datePickerSettings.minDate || undefined}
          maxDate={props.datePickerSettings.maxDate || undefined}
          onCalendarClose={props.datePickerSettings.onCalendarClose || undefined}
          isClearable={props.datePickerSettings.isClearable || undefined}
          monthsShown={props.datePickerSettings.showTwoMonths ? (props.inputWidth < 460 ? 1 : 2) : 1}
          popperPlacement={props.datePickerSettings.popperPlacement || 'auto'}
          disabled={!!props.disabled}
          required={!!props.required}
        />
      }
      {
        props.type === 'timepicker' &&
        <DatePicker 
          className={`custom-input-datepicker ${invalidClass}`}
          calendarClassName={timePickerCustomSizeClass}
          id="custom-input-timepicker"
          clearButtonClassName="custom-input-datepicker-clear"
          locale={props.getLocale().language}
          showIcon
          icon={<IconClock onClick={() => props.onTimeIconClick()} />}
          placeholderText={props.placeholder}
          timeCaption="Horário"
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={props.datePickerSettings.timeIntervals || 10}
          dateFormat="HH:mm"
          onChange={(dates: Date | [Date, Date]) => props.handleChangeDatepicker(dates)}
          selectsRange={props.datePickerSettings.selectsRange || undefined}
          selected={props.datePickerSettings.startDate}
          startDate={props.datePickerSettings.startDate}
          endDate={props.datePickerSettings.endDate || undefined}
          minDate={props.datePickerSettings.minDate || undefined}
          maxDate={props.datePickerSettings.maxDate || undefined}
          onCalendarClose={props.datePickerSettings.onCalendarClose || undefined}
          isClearable={props.datePickerSettings.isClearable || undefined}
          popperPlacement='bottom'
          disabled={!!props.disabled}
          required={!!props.required}
        />
      }
      {
        props.type === 'phone' &&
        <PhoneInput 
          placeholder={props.placeholder}
          containerClass={props.phoneInputSettings?.disableDropdown ? 'custom-input-phone custom-input-phone-dropdown-disabled' : 'custom-input-phone'}
          dropdownClass='custom-input-phone-dropdown'
          localization={props.getPhoneLocalization()}
          value={props.value}
          onChange={props.handleChangePhone}
          disabled={!!props.disabled}
          enableSearch={props.phoneInputSettings?.enableSearch}
          searchPlaceholder={props.phoneInputSettings?.searchPlaceholder}
          searchNotFound={props.phoneInputSettings?.searchNotFound}
          disableSearchIcon={true}
          country={props.phoneInputSettings?.countryDefault || props.getPhoneDefaultCountry()}
          disableDropdown={props.phoneInputSettings?.disableDropdown ?? undefined}
          onlyCountries={props.phoneInputSettings?.onlyCountries ?? undefined}
          countryCodeEditable={props.phoneInputSettings?.countryCodeEditable ?? undefined}
          isValid={(value: string, country: CountryData) => props.validatePhoneNumber(value, country.countryCode)}
          preferredCountries={props.phoneInputSettings?.preferredCountries || undefined}
        />
      }
    </div>
  );
}

export default CustomInput;