import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalRegisterCustomFieldTickets from '.';
import { useTranslation } from 'react-i18next';
import { CustomField } from '../../../../core/enums/custom-field-type';

const ModalRegisterCustomFieldTicketsController = ({ show, handleClose, callback }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [type, setType] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.simple_text_label'), 'value': CustomField.Text },
        { 'label': t('configurations_ticket.modal_register_custom_field.multiline_text_label'), 'value': CustomField.Textarea },
        { 'label': t('configurations_consumers.modal_edit_custom_field.simple_select_label'), 'value': CustomField.Dropdown },
        { 'label': t('configurations_consumers.modal_edit_custom_field.multiple_select_label'), 'value': CustomField.Multiselect }]);
    const [selectedType, setSelectedType] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [showFeedbackOptionList, /*setShowFeedbackOptionList*/] = useState(false)
    const [/*defaultValue*/, setDefaultValue] = useState('')
    const [labelledOptions, setLabelledOptions] = useState([])
    const [placeholder, setPlaceholder] = useState('')
    const [showFeedbackPlaceholder, setShowFeedbackPlaceholder] = useState(false)
    const [customTicketIsRequired, setCustomTicketIsRequired] = useState(false)
    const entityId = '250204ce-44eb-4cdc-865a-f54eed9c6496'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const clearFields = () => {
        setName('')
        setShowFeedbackName(false)
        setSelectedType([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setPlaceholder('')
        setShowFeedbackPlaceholder(false)
        handleClose()
        setCustomTicketIsRequired(false)
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }
    }, [selectedOptions])

    return <ModalRegisterCustomFieldTickets
        show={show}
        clearFields={clearFields}
        type={type}
        setName={setName}
        setType={setType}
        setSelectedOptions={setSelectedOptions}
        setDefaultValue={setDefaultValue}
        setPlaceholder={setPlaceholder}
        setShowFeedbackName={setShowFeedbackName}
        setShowFeedbackPlaceholder={setShowFeedbackPlaceholder}
        placeholder={placeholder}
        entityId={entityId}
        selectedType={selectedType}
        labelledOptions={labelledOptions}
        selectedsOptions={selectedsOptions}
        dispatch={dispatch}
        callback={callback}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        validated={validated}
        showFeedbackName={showFeedbackName}
        setSelectedType={setSelectedType}
        onChangeTags={onChangeTags}
        selectedOptions={selectedOptions}
        showFeedbackOptionList={showFeedbackOptionList}
        setLabelledOptions={setLabelledOptions}
        setSelectedsOptions={setSelectedsOptions}
        showFeedbackPlaceholder={showFeedbackPlaceholder}
        name={name}
        t={t}
        customTicketIsRequired={customTicketIsRequired}
        setCustomTicketIsRequired={setCustomTicketIsRequired}
    />
}

export default ModalRegisterCustomFieldTicketsController