import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import SearchComponent from '.';
import { useMainAppContext } from '../../../../core/context/main-app-context';
import { getAppSidebarPage } from '../../../../store/app_sidebar';
import { useSelector } from 'react-redux';
import { SearchComponentControllerInterface, SearchComponentFilterType } from './indexModel';
import { useTranslation } from 'react-i18next';

const SearchComponentController: FC<SearchComponentControllerInterface> = (props) => {
  const { t } = useTranslation('SearchComponent');

  const currentPage = useSelector(getAppSidebarPage);
  const { 
    performSearch, 
    performClearSearch, 
    setClearFilterTermFunction,
    filterApplied,
    setFilterApplied,
    filteredMode,
    setFilteredMode,
    setSearchTerm,
    formatFilter,
    isSearched,
    setIsSearched,
    header
  } = useMainAppContext();

  const popupRef = useRef<HTMLDivElement>(null);

  const [inputTermSearch, setInputTermSearch] = useState<string>(null);
  const [showFilter, setShowFilter] = useState(false);
  
  useEffect(() => {
    if (currentPage) {
      setInputTermSearch('');
      setFilterApplied({});
      setFilteredMode('simple');
      setClearFilterTermFunction(() => clearFilterTerm);
      setIsSearched(false);
    }
  }, [currentPage]);

  useEffect(() => {
    if (showFilter) {
      const handleClickOutside = (event: any) => {
        const selectPaginatedItemsPopupElement = document.querySelector('[id^="select-paginated-items-popup"]');

        if (popupRef?.current) {
          if (!popupRef?.current?.contains(event.target) && !selectPaginatedItemsPopupElement) { // Clique fora popup do select aberto
            setShowFilter(false);
          }
          if (!popupRef?.current?.contains(event.target) && selectPaginatedItemsPopupElement && !selectPaginatedItemsPopupElement?.contains(event.target)) {
            setShowFilter(false);
          }
        }
      }
  
      if (popupRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    }
  }, [showFilter]);


  const onChangeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    setInputTermSearch(event.target.value);
  }

  const handleSearchFunction = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputTermSearch || (!inputTermSearch && isSearched)) {
        setSearchTerm(inputTermSearch);
        performSearch(formatFilter(filterApplied, inputTermSearch, filteredMode, props.page), filterApplied);
      }
    }
  }

  const applyFilter = (filter: SearchComponentFilterType, mode?: 'simple' | 'advanced') => {
    setFilteredMode(mode);
    setShowFilter(false);
    setFilterApplied(filter);
    performSearch(formatFilter(filter, inputTermSearch, mode, props.page), filter);
    setSearchTerm(inputTermSearch);
    if (!mode) {
      setFilterApplied({});
      onClickClearFunction();
    }
  }

  const onClickClearFunction = () => {
    setInputTermSearch('');
    performClearSearch();
  }

  const handleShowFilter = (value?: boolean) => {
    if (value !== undefined) {
      setShowFilter(value);
    } else {
      setShowFilter(!showFilter);
    }
  }

  const clearFilterTerm = (onlyTerm?: boolean) => {
    setInputTermSearch('');
    setSearchTerm('');

    if (onlyTerm) {
      performSearch(formatFilter(filterApplied, '', filteredMode, props.page), filterApplied);
    }
  }

  return (
    <SearchComponent
      t={t}
      page={props.page}
      popupRef={popupRef}
      termSearch={inputTermSearch}
      isSearched={isSearched}
      placeholder={header?.searchPlaceholder}
      onChangeSearchQuery={onChangeSearchQuery}
      handleSearchFunction={handleSearchFunction}
      onClickClearFunction={onClickClearFunction}
      showFilter={showFilter}
      setShowFilter={setShowFilter}
      handleShowFilter={handleShowFilter}
      applyFilter={applyFilter}
      filterApplied={filterApplied}
      filteredMode={filteredMode}
      hasSearchInput={header.hasSearchInput}
      hasSearchFilter={header.hasSearchFilter}
      clearFilterTerm={clearFilterTerm}
    />
  );
}

export default SearchComponentController;