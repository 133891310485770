import { TFunction } from "i18next";
import { DropzoneInputProps, DropzoneRootProps, FileWithPath } from "react-dropzone";
import { Channels } from "../../../../../../../../core/enums/channels";
import { Dispatch, MutableRefObject, SetStateAction } from "react";
import { IconFileTypeTxt, IconFileTypePdf, IconFileTypeJpg, IconFileTypePng, IconFileTypeDoc, IconFileUnknown, IconFileTypeXls, IconFileTypeDocx, IconFileTypeZip, IconFileTypeCsv, IconFileMusic, IconMovie, IconFileSmile } from "@tabler/icons-react";
import { IconFileTypePpt } from "@tabler/icons-react";
import { SelectedTemplateMessageType, TemplateMessageAttachmentInfoType } from "../popupTemplateMessages/indexModel";

export interface ToolbarFileUploaderInterface {
  t: TFunction<"translation", undefined, "translation">;
  showFileUploader: boolean;
  hasFileUploader: boolean;
  files: FileWithPath[];
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  currentAttachmentSize: number;
  bytesToSize: (bytes: number) => string;
  getFileInfo: (filename: string) => { name: string; extension: string; };
  formatDate: (date: string | number, type: 'date' | 'time' | 'datetime') => string;
  getFilePreview: (file: FileWithPath & CustomFileAttributes, previewTemplateMessageAttachment?: TemplateMessageAttachmentInfoType) => JSX.Element;
  fileUploaderRef: MutableRefObject<HTMLDivElement>;
  removeUploadRef: MutableRefObject<HTMLDivElement>;
  removeFile: (file: FileWithPath & CustomFileAttributes, event: any) => void;
  previewFile: (file: FileWithPath & CustomFileAttributes, event: any, templateMessageAttachment?: TemplateMessageAttachmentInfoType) => void;
  getIconFileType: (type: string) => JSX.Element;
  isDragging: boolean;
  showFilePreview: boolean;
  setShowFilePreview: Dispatch<SetStateAction<boolean>>;
  previewFirstFileUpload: FileWithPath & CustomFileAttributes;
  hasTemplateMessageAttachment: boolean;
  selectedTemplateMessage: SelectedTemplateMessageType;
  getSelectedTemplateMessageInfo: () => { fileName: string; extension: string; mimeType: string | null; url: string};
  previewTemplateMessageAttachment: TemplateMessageAttachmentInfoType;
  hasTriggerTemplate: boolean;
  editorDisabled: boolean;
}

export interface ToolbarFileUploaderControllerInterface {
  showFileUploader: boolean;
  setShowFileUploader: Dispatch<SetStateAction<boolean>>;
  hasFileUploader: boolean;
  typeChat: 'view-ticket' | 'create-ticket' | 'message-trigger';
  setHasFileUploader: Dispatch<SetStateAction<boolean>>;
  files: FileWithPath[];
  setFiles: Dispatch<SetStateAction<FileWithPath[]>>;
  attachmentIconRef: MutableRefObject<HTMLSpanElement>;
  filesPasted: File[];
  setFilesPasted: Dispatch<SetStateAction<File[]>>;
  audioRecorded?: any[];
  audioRecordedFiles: File[];
  setAudioRecordedFiles: Dispatch<SetStateAction<File[]>>;
  hasTemplateMessageAttachment: boolean;
  selectedTemplateMessage: SelectedTemplateMessageType;
  createSelectedChannel?: string;
  fileUploadSpecificTypes?: { [key: string]: string[]; };
  hasTriggerTemplate?: boolean;
  editorDisabled: boolean;
}

export interface CustomFileAttributes {
  preview?: string;
  isLoading?: boolean;
  dateUploaded?: string;
  bucketId?: string;
  errorUpload?: boolean;
}

export type CustomFile = FileWithPath & CustomFileAttributes;

export interface FetchAttachmentData {
  status: number,
  data: AttachmentData;
}

interface AttachmentData {
  code_cxpress: number,
  files: [{
    id: string,
    name: string
  }],
  message: string;
}

// = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * =
// = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * =
// = * = * = * = * = * = * = * = * = * UTIL FUNCTIONS * = * = * = * = * = * = * = * = * =
// = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * =
// = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * = * =

export const getDropzoneAccept = (channel: string): { [key: string]: string[] } => {
  switch (channel) {
    case 'message-trigger': {
      return {
        'video/mpeg': ['.mpeg'],
        'video/mp4': ['.mp4'],
        'audio/mpeg': ['.mpeg', '.mp3', '.aac'],
        'audio/aac': ['.aac'],
        'audio/amr': ['.amr'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      };
    }
    case Channels.Instagram: {
      return {
        'image/gif': ['.gif'],
        'image/jpeg': ['.jpeg'],
        'image/png': ['.png'],
        'video/mp4': ['.mp4'],
      };
    }
    case Channels.Email: {
      return {
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'application/vnd.ms-xpsdocument': ['.xps'],
        'image/vnd.microsoft.icon': ['.ico'],
        'application/oxps': ['.oxps'],
        'application/pdf': ['.pdf', '.ai'],
        'image/vnd.adobe.photoshop': ['.psd'],
        'application/postscript': ['.eps', '.ai', '.ps'],
        'application/eps': ['.eps', '.ai', '.ps'],
        'application/x-eps': ['.eps', '.ps'],
        'image/eps': ['.eps', '.ps'],
        'image/x-eps': ['.eps', '.ps'],
        'image/svg+xml': ['.svg'],
        'font/ttf': ['.ttf'],
        'application/zip': ['.zip'],
        'application/vnd.rar': ['.rar'],
        'application/x-tar': ['.tar'],
        'application/gzip': ['.gzip', '.gz'],
        'audio/mpeg': ['.mpeg', '.mp3', '.aac'],
        'audio/wav': ['.wav'],
        'audio/webm': ['.webm'],
        'audio/aac': ['.aac'],
        'application/ogg': ['.ogg'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/gif': ['.gif'],
        'image/bmp': ['.bmp'],
        'image/tiff': ['.tif', '.tiff'],
        'video/webm': ['.webm'],
        'video/mp4': ['.mp4'],
        'video/3gpp': ['.3gpp', '.3gp'],
        'video/quicktime': ['.mov'],
        'video/x-msvideo': ['.avi'],
        'video/x-ms-wmv': ['.wmv'],
        'text/plain': ['.txt'],
        'text/csv': ['.csv'],
        'text/calendar': ['.ics'],
        'audio/midi': ['.mid', '.midi'],
        'audio/x-midi': ['.mid', '.midi'],
        'video/mpeg': ['.mpeg'],
        'application/vnd.oasis.opendocument.presentation': ['.odp'],
        'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
        'application/vnd.oasis.opendocument.text': ['.odt'],
        'font/otf': ['.otf'],
        'application/rtf': ['.rtf'],
        'application/x-7z-compressed': ['.7z']
      };
    }
    case Channels.WhatsApp: {
      return {
        'video/mp4': ['.mp4'],
        'video/mpeg': ['.mpeg'],
        'audio/mpeg': ['.mpeg', '.mp3', '.aac'],
        'audio/aac': ['.aac'],
        'audio/amr': ['.amr'],
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      }
    }
    case Channels.Facebook: {
      return {
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'image/tiff': ['.tif', '.tiff'],
        'image/gif': ['.gif'],
        'audio/x-matroska': [".mka"],
        'audio/x-mod': ['.mod'],
        'audio/mpeg': ['.mpeg', '.mp3', '.aac'],
        'audio/wav': ['.wav'],
        'audio/x-flac': ['.flac'],
        'audio/ogg': ['.ogg'],
        'audio/aac': ['.aac'],
        'audio/x-ms-wma': ['.wma'],
        'audio/3gpp2': ['.3g2'],
        'audio/3gpp': ['.3gp'],
        'video/3gpp2': ['.3g2'],
        'video/3gpp': ['.3gp', '.3gpp'],
        'video/x-ms-asf': ['.asf'],
        'video/x-msvideo': ['.avi'],
        'video/divx': ['.divx'],
        'video/x-dv': ['.dv'],
        'video/x-f4v': ['f4v'],
        'video/x-flv': [".flv"],
        'video/mp2t': ['.m2ts', '.mts', '.ts'],
        'video/x-m4v': ['.m4v'],
        'video/x-matroska': [".mkv"],
        'video/quicktime': ['.mov', '.qt'],
        'video/mp4': ['.mp4'],
        'video/mpeg': ['.mpe', '.mpeg', '.mpg', '.tod'],
        'video/x-nsv': ['.nsv', '.NSV'],
        'video/nsv': ['.nsv', '.NSV'],
        'video/ogg': ['.ogv'],
        'video/MP2T': ['MP2T'],
        'video/x-ms-vob': ['.vob'],
        'video/x-ms-wmv': ['.wmv'],
        'application/x-pds': ['.dat'],
        'application/vnd.ms-asf': ['.asf'],
        'application/ogg': ['.ogg', '.ogm'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        'application/vnd.ms-powerpoint': ['.ppt'],
        'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
        'application/rtf': ['.rtf'],
        'text/plain': ['.txt'],
      };
    }
    case Channels.Webchat: {
      return {
        'application/pdf': ['.pdf'],
        'text/plain': ['.txt'],
      };
    }
    default: {
      return {};
    }
  }
}

export const getIconFileType = (type: string): JSX.Element => {
  if (type?.includes('text/plain')) {
    return <IconFileTypeTxt className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('pdf')) {
    return <IconFileTypePdf className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('jpg') || type?.includes('jpeg')) {
    return <IconFileTypeJpg className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('png')) {
    return <IconFileTypePng className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('gif')) {
    return <IconFileSmile className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('msword')) {
    return <IconFileTypeDoc className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('officedocument.wordprocessingml')) {
    return <IconFileTypeDocx className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('powerpoint') || type?.includes('officedocument.presentationml')) {
    return <IconFileTypePpt className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('excel') || type?.includes('officedocument.spreadsheetml')) {
    return <IconFileTypeXls className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('zip')) {
    return <IconFileTypeZip className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('csv')) {
    return <IconFileTypeCsv className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('audio')) {
    return <IconFileMusic className='toolbar-file-uploader-icon-type' />;
  } else if (type?.includes('video')) {
    return <IconMovie className='toolbar-file-uploader-icon-type' />;
  } else {
    return <IconFileUnknown className='toolbar-file-uploader-icon-type' />;
  }
}

export const CustomIconUpload = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cloud-upload toolbar-file-uploader-icon-upload" width="128" height="128" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
      <path className="customIconUploadArrow" d="M9 15l3 -3l3 3" />
      <path className="customIconUploadArrow" d="M12 12l0 9" />
    </svg>
  );
}

export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (!bytes) {
    return '0';
  } else {
    const i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))), 10);
    if (i === 0) return `${bytes.toLocaleString('pt-BR', { maximumFractionDigits: 1 })} ${sizes[i]}`;
    return `${(bytes / (1024 ** i)).toLocaleString('pt-BR', { maximumFractionDigits: 1 })} ${sizes[i]}`;
  }
}

const EXTENSION_TO_MIME_TYPE: { [key: string]: string } = {
  '.3g2': 'audio/3gpp2',
  '.3gp': 'video/3gpp',
  '.aac': 'audio/aac',
  '.ai': 'application/pdf',
  '.amr': 'audio/amr',
  '.asf': 'video/x-ms-asf',
  '.avi': 'video/x-msvideo',
  '.bmp': 'image/bmp',
  '.csv': 'text/csv',
  '.dat': 'application/x-pds',
  '.divx': 'video/divx',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.dv': 'video/x-dv',
  '.eps': 'application/postscript',
  '.f4v': 'video/x-f4v',
  '.flac': 'audio/x-flac',
  '.flv': 'video/x-flv',
  '.gif': 'image/gif',
  '.gz': 'application/gzip',
  '.gzip': 'application/gzip',
  '.ico': 'image/vnd.microsoft.icon',
  '.ics': 'text/calendar',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.m2ts': 'video/mp2t',
  '.m4v': 'video/x-m4v',
  '.mka': 'audio/x-matroska',
  '.mkv': 'video/x-matroska',
  '.mid': 'audio/midi',
  '.midi': 'audio/midi',
  '.mod': 'audio/x-mod',
  '.mov': 'video/quicktime',
  '.mp3': 'audio/mpeg',
  '.mp4': 'video/mp4',
  '.mpeg': 'video/mpeg',
  '.mts': 'video/mp2t',
  '.nsv': 'video/x-nsv',
  '.ogm': 'application/ogg',
  '.ogv': 'video/ogg',
  '.ogg': 'application/ogg',
  '.otf': 'font/otf',
  '.oxps': 'application/oxps',
  '.pdf': 'application/pdf',
  '.png': 'image/png',
  '.ps': 'application/postscript',
  '.psd': 'image/vnd.adobe.photoshop',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.rar': 'application/vnd.rar',
  '.rtf': 'application/rtf',
  '.svg': 'image/svg+xml',
  '.tar': 'application/x-tar',
  '.tif': 'image/tiff',
  '.tiff': 'image/tiff',
  '.ts': 'video/mp2t',
  '.ttf': 'font/ttf',
  '.txt': 'text/plain',
  '.vob': 'video/x-ms-vob',
  '.wav': 'audio/wav',
  '.webm': 'audio/webm',
  '.wma': 'audio/x-ms-wma',
  '.wmv': 'video/x-ms-wmv',
  '.xps': 'application/vnd.ms-xpsdocument',
  '.zip': 'application/zip',
  '.7z': 'application/x-7z-compressed'
};

export const getFileDetailsFromURL = (url: string): { fileName: string, extension: string, mimeType: string | null, url: string } => {
  if (url) {
    const urlObject = new URL(url);
    const path = urlObject.pathname;
    const fileName = path.substring(path.lastIndexOf('/') + 1);
    const extension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    const mimeType = EXTENSION_TO_MIME_TYPE[extension] || null;
  
    return {
      fileName,
      extension: extension.substring(1),
      mimeType,
      url
    };
  } else {
    return {
      fileName: null,
      extension: null,
      mimeType: null,
      url: null
    }
  }
};