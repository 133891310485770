import './styles.scss';
import { FC } from 'react';
import { ModalAgentConsumersInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconUsers, IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomTableListController from '../../../../../components/customTableList/indexController';
import { Trans } from 'react-i18next';

const ModalAgentConsumers: FC<ModalAgentConsumersInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      size="lg"
      centered={true}
      dialogClassName='modal-agent-consumers-component'
    >
      <Modal.Header>
        <Modal.Title>{props.t('title')}</Modal.Title>
        <div className="modal-agent-consumers-header-actions">
          <IconX className="modal-agent-consumers-header-actions-close" onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { props.showLoading() && <Loading blur={true}/>}
        <div className="modal-agent-consumers-content">
          <CustomTableListController
            component='modal-agent-consumers'
            text_empty_list={props.t('no_consumers')}
            text_loading_list={props.t('loading_consumers')}
            text_error_list={props.t('error_loading_consumers')}
            icon_entity_list={<IconUsers className='tabler-icon-entity' />}
            headers={props.listHeaders} 
            data={props.data}
            paginationDetails={props.paginationDetails}
            fetchMore={props.getAgentConsumersPagination}
            isLoading={props.isLoading}
            errorLoading={props.errorLoading}
            popoverItemAction={props.popoverItemAction}
            reloadData={props.reloadAgentConsumers}
            itemClickDisabled={true}
          />

          { props.itemUnlinkConfirmation &&
            <div className="modal-agent-consumers-unlink-confirmation">
              <span className="modal-agent-consumers-unlink-title">{props.t('unlink_view.title')}</span>
              <div className="modal-agent-consumers-unlink-info">
                <span className="modal-agent-consumers-unlink-info-message">
                  <Trans 
                    i18nKey='ModalAgentConsumers:unlink_view.message'
                    values={{ agentName: props.agentInfo.agentName }}
                    components={[ <span /> ]}
                  />
                </span>
                <div className="modal-agent-consumers-unlink-info-fields">
                  <div className="modal-agent-consumers-unlink-info-fields-row">
                    <div className="modal-agent-consumers-unlink-info-fields-item">
                      <span className="modal-agent-consumers-unlink-info-fields-item-label">
                        {props.t('unlink_view.consumer_name')}
                      </span>
                      <OverlayTrigger overlay={<Tooltip>{props.itemUnlinkConfirmation?.name}</Tooltip>}>
                        <span className="modal-agent-consumers-unlink-info-fields-item-value">
                          {props.itemUnlinkConfirmation?.name}
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div className="modal-agent-consumers-unlink-info-fields-item">
                      <span className="modal-agent-consumers-unlink-info-fields-item-label">
                        {props.t('unlink_view.consumer_email')}
                      </span>
                      <OverlayTrigger overlay={<Tooltip>{props.itemUnlinkConfirmation?.email}</Tooltip>}>
                        <span className="modal-agent-consumers-unlink-info-fields-item-value">
                          {props.itemUnlinkConfirmation?.email}
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="modal-agent-consumers-unlink-info-fields-row">
                    <div className="modal-agent-consumers-unlink-info-fields-item">
                      <span className="modal-agent-consumers-unlink-info-fields-item-label">
                        {props.t('unlink_view.consumer_document')}
                      </span>
                      <span className="modal-agent-consumers-unlink-info-fields-item-value">
                        {props.formatValue('document_number', props.itemUnlinkConfirmation?.document_number)}
                      </span>
                    </div>
                    <div className="modal-agent-consumers-unlink-info-fields-item">
                      <span className="modal-agent-consumers-unlink-info-fields-item-label">
                        {props.t('unlink_view.consumer_phone')}
                      </span>
                      <span className="modal-agent-consumers-unlink-info-fields-item-value">
                        {props.formatValue('phone', props.itemUnlinkConfirmation?.phone)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-list-task-unlink-footer">
                <span className="modal-agent-consumers-unlink-action tasks-unlink-action-cancel" onClick={() => props.showUnlinkConfirmation(null, false)}>
                  {props.t('unlink_view.btn_cancel')}
                </span>
                <span className="modal-agent-consumers-unlink-action tasks-unlink-action-confirm" onClick={() => props.handleUnlinkConsumer()}>
                  {props.t('unlink_view.btn_unlink')}
                </span>
              </div>
            </div>
          }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAgentConsumers;
