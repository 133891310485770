import './styles.scss';
import { FC } from 'react';
import { ModalExportAllInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconDownload, IconInfoCircle, IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import CustomInputController from '../../../../../components/customInput/indexController';

const ModalExportAll: FC<ModalExportAllInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      size="lg"
      fullscreen='sm-down'
      centered={true}
      dialogClassName="modal-export-all-component"
    >
      <Modal.Header>
        <Modal.Title>{props.t('title')}</Modal.Title>
        <div className="modal-export-all-header-actions">
          <IconX className="modal-export-all-header-actions-close" onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { props.loadingRequest && <Loading blur={true}/>}
        { props.step === 'export' &&
          <>
          <div className="modal-export-all-section">
            <div className="modal-export-all-items-group">
              <div className="modal-export-all-item">
                <div className="modal-export-all-item-header">
                  <span className="modal-export-all-item-label">{props.t('form.channel_label')}</span>
                </div>
                <CustomSelectController 
                  type='simple'
                  dropdownPosition='body'
                  isMultiselect={true}
                  placeholder={props.t('form.channel_placeholder')}
                  options={props.getChannelSelect().channelsList || []}
                  selectedMulti={props.getChannelSelect().selectedChannels}
                  setSelectedMulti={props.getChannelSelect().setSelectedChannels}
                />
              </div>
              <div className="modal-export-all-item">
                <div className="modal-export-all-item-header">
                  <span className="modal-export-all-item-label">{props.t('form.agent_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  isMultiselect={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  showIconSelected={true}
                  placeholder={props.t('form.agent_placeholder')}
                  placeholderFilter={props.t('form.agent_search')}
                  options={props.getAgentsSelect().agentsList || []}
                  selectedMulti={props.getAgentsSelect().selectedAgents}
                  setSelectedMulti={props.getAgentsSelect().setSelectedAgents}
                  fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                  hasMore={props.getAgentsSelect().hasMoreAgents}
                  loading={props.getAgentsSelect().isLoadingAgentsSelect}
                  search={props.getAgentsSelect().fetchSearchAgents}
                />
              </div>
            </div>
            <div className="modal-export-all-items-group">
              <div className="modal-export-all-item">
                <div className="modal-export-all-item-header">
                  <span className="modal-export-all-item-label">{props.t('form.sector_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  isMultiselect={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.sector_placeholder')}
                  placeholderFilter={props.t('form.sector_search')}
                  options={props.getSectorsSelect().sectorsList || []}
                  selectedMulti={props.getSectorsSelect().selectedSectors}
                  setSelectedMulti={props.getSectorsSelect().setSelectedSectors}
                  fetchMore={props.getSectorsSelect().fetchSectorsPagination}
                  hasMore={props.getSectorsSelect().hasMoreSectors}
                  loading={props.getSectorsSelect().isLoadingSectorsSelect}
                  search={props.getSectorsSelect().fetchSearchSectors}
                />
              </div>
              <div className="modal-export-all-item">
                <div className="modal-export-all-item-header">
                  <span className="modal-export-all-item-label">{props.t('form.tag_label')}</span>
                </div>
                <CustomSelectController 
                  type='pagination'
                  dropdownPosition='body'
                  hasFilter={true}
                  isMultiselect={true}
                  showTooltipOptions={true}
                  tooltipOptionsPosition='right'
                  placeholder={props.t('form.tag_placeholder')}
                  placeholderFilter={props.t('form.tag_search')}
                  options={props.getTagsSelect().tagsList || []}
                  selectedMulti={props.getTagsSelect().selectedTags}
                  setSelectedMulti={props.getTagsSelect().setSelectedTags}
                  fetchMore={props.getTagsSelect().fetchTagsPagination}
                  hasMore={props.getTagsSelect().hasMoreTags}
                  loading={props.getTagsSelect().isLoadingTagsSelect}
                  search={props.getTagsSelect().fetchSearchTags}
                />
              </div>
            </div>
            <div className="modal-export-all-item">
              <div className="modal-export-all-item-header">
                <span className="modal-export-all-item-label">{props.t('form.period_label')}</span>
                <span className="modal-export-all-item-label-required">*</span>
              </div>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                placeholder={props.t('form.period_placeholder')}
                options={props.getFilterDateSelect().filterDateList || []}
                selected={props.getFilterDateSelect().selectedFilterDate}
                setSelected={props.getFilterDateSelect().setSelectedFilterDate}
                required={true}
              />
            </div>
            { props.getFilterDateSelect().selectedFilterDate?.id === 'custom' &&
              <div className="modal-export-all-item">
                <div className="modal-export-all-item-header">
                  <span className="modal-export-all-item-label">{props.t('form.custom_period_label')}</span>
                  <span className="modal-export-all-item-label-required">*</span>
                  <OverlayTrigger overlay={<Tooltip>{props.t('form.custom_period_tooltip')}</Tooltip>}>
                    <IconInfoCircle className='modal-export-all-item-info' />
                  </OverlayTrigger>
                </div>
                <CustomInputController 
                  type='datepicker'
                  placeholder={props.t('form.custom_period_placeholder')}
                  datePickerSettings={{ 
                    maxDate: new Date(), 
                    onChange: props.getDateInput().onSelectDate,
                    selectsRange: true,
                    startDate: props.getDateInput().selectedDate.start,
                    endDate: props.getDateInput().selectedDate.end,
                    showTwoMonths: true,
                    isClearable: true,
                    popperPlacement: 'top',
                    valid: !!props.getDateInput().selectedDate?.start && !!props.getDateInput().selectedDate?.end
                  }}
                  required={true}
                />
                { !props.isValid() &&
                  <span className="modal-export-all-item-invalid-message">{props.t('form.custom_period_invalid')}</span>
                }
              </div>
            }
          </div>
          <div className="modal-export-all-section section-margin-top">
            <div className="modal-export-all-section-check">
              <div className="modal-export-all-section-check-info">
                <span className="modal-export-all-section-check-title">{props.t('form.content_label')}</span>
                <span className="modal-export-all-section-check-message">{props.hasContent ? props.t('form.content_message_active') : props.t('form.content_message_inactive')}</span>
              </div>
              <div className="modal-export-all-section-check-switch">
                <div className="switch-input">
                  <div className="switch-input">
                    <OverlayTrigger overlay={<Tooltip>{props.hasContent ? props.t('form.content_active') : props.t('form.content_inactive')}</Tooltip>}>
                      <label className="switch">
                        <input type="checkbox" checked={props.hasContent} onChange={() => props.setHasContent(!props.hasContent)} />
                        <span className="slider round"></span>
                      </label>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
        }
        { props.step === 'links' &&
          <>
            <div className="modal-export-all-section">
              <div className="modal-export-all-links-container">
                { props.links.length === 0 &&
                  <span className="modal-export-all-links-message-info">{props.t('step_links.empty_message')}</span>
                }
                {
                  props.links.length > 0 &&
                  <span className="modal-export-all-links-message-info">
                    {props.t(props.links.length === 1 ? 'step_links.success_message_single' : 'step_links.success_message_multiple')}
                  </span>
                }
                { props.links.length > 0 &&
                  <div className="modal-export-all-links-list">
                    {props.links.map((link, index) => {
                      const filename = link.split('/').slice(-1)[0];

                      return (
                        <div key={`${index}_${link}`} className="modal-export-all-links-item">
                          <OverlayTrigger overlay={<Tooltip>{filename}</Tooltip>}>
                            <span className="modal-export-all-links-item-name">{filename}</span>
                          </OverlayTrigger>
                          <OverlayTrigger overlay={<Tooltip>{props.t('step_links.btn_download_tooltip')}</Tooltip>}>
                            <button className="modal-export-all-links-item-download" onClick={() => window.open(link)}>
                              <IconDownload />
                              {props.t('step_links.btn_download')}
                            </button>
                          </OverlayTrigger>
                        </div>
                      );
                    })}
                  </div>
                }
              </div>
            </div>
          </>
        }
      </Modal.Body>
      <Modal.Footer>
        { props.step === 'export' &&
          <>
          <button className='modal-export-all-btn-cancel' onClick={() => props.hide(false)}>
            {props.t('btn_cancel')}
          </button>
          <button className='modal-export-all-btn-save' onClick={() => props.handleSubmit()} disabled={!props.isValid() || props.loadingRequest}>
            {props.t('btn_export')}
          </button>
          </>
        }
        { props.step === 'links' &&
          <>
          <button className='modal-export-all-btn-cancel' onClick={() => props.back()}>
            {props.t('btn_back')}
          </button>
          <button className='modal-export-all-btn-save' onClick={() => props.hide(false)}>
            {props.t('btn_close')}
          </button>
          </>
        }
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExportAll;
