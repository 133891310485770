import { useTranslation } from "react-i18next";
import { FC, useState, useCallback, useRef, useEffect } from 'react';
import ToolbarColorPicker from './index';
import { ToolbarColorPickerControllerInterface } from './indexModel';
import { EditorState, Modifier } from 'draft-js';
import { Color, ColorResult } from 'react-color';

const ToolbarColorPickerController: FC<ToolbarColorPickerControllerInterface> = (props) => {
  const { t } = useTranslation('ToolbarColorPicker');
  const [color, setColor] = useState<Color>('#000000');
  const colorPickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (colorPickerRef?.current && !colorPickerRef?.current?.contains(event.target)) {
        props.setShowColorPicker(false);
      }
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    }

    if (props.showColorPicker) {
      if (colorPickerRef?.current) {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyDown);
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [props.showColorPicker]);

  const handleClose = useCallback(() => {
    props.setShowColorPicker(false);
  }, []);

  const handleChange = useCallback((color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => {
    setColor(color.hex);
    const selection = props.editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = props.editorState.getCurrentContent();
      const contentStateWithColor = Modifier.applyInlineStyle(
        contentState,
        selection,
        props.type === 'text' ? `color-${color.hex}` : `bgcolor-${color.hex}`
      );

      const newEditorState = EditorState.push(
        props.editorState,
        contentStateWithColor,
        'change-inline-style'
      );
      props.setEditorState(newEditorState);
    }
  }, [props.editorState, props.setEditorState]);

  const applyColor = useCallback(() => {
    const selection = props.editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = props.editorState.getCurrentContent();
      const contentStateWithColor = Modifier.applyInlineStyle(
        contentState,
        selection,
        props.type === 'text' ? `color-${color}` : `bgcolor-${color}`
      );
      const newEditorState = EditorState.push(
        props.editorState,
        contentStateWithColor,
        'change-inline-style'
      );
      props.setEditorState(newEditorState);
    }
    handleClose();
  }, [props.editorState, color, props.setEditorState, handleClose]);

  const removeColor = () => {
    const selection = props.editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = props.editorState.getCurrentContent();
      let nextContentState = contentState;
      const styles = props.editorState.getCurrentInlineStyle();

      styles.forEach((style: string) => {
        if (props.type === 'text') {
          if (style.startsWith('color-')) {
            nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style);
          }
        } else {
          if (style.startsWith('bgcolor-')) {
            nextContentState = Modifier.removeInlineStyle(nextContentState, selection, style);
          }
        }
      });

      const nextEditorState = EditorState.push(props.editorState, nextContentState, 'change-inline-style');
      props.setEditorState(nextEditorState);
      handleClose();
    }
  };

  return (
    <ToolbarColorPicker 
      t={t}
      type={props.type}
      showColorPicker={props.showColorPicker}
      color={color}
      handleClose={handleClose}
      handleChange={handleChange}
      applyColor={applyColor}
      colorPickerRef={colorPickerRef}
      removeColor={removeColor}
    />
  );
};

export default ToolbarColorPickerController;
