import './styles.scss';
import { FC } from 'react';
import { ListOfMessageTriggersInterface } from './indexModel';
import CustomTableListController from '../../../../components/customTableList/indexController';
import { IconSend } from '@tabler/icons-react';
import FilteredViewBarController from '../../components-fixed/searchComponent/filteredViewBar/indexController';
import ModalChangeStatusTriggerTypeController from '../components/modalChangeStatusTriggerType/indexController';
import ModalTriggerTypeController from '../components/modalTriggerType/indexController';
import ModalCancelActiveTriggerController from '../components/modalCancelActiveTrigger/indexController';
import ModalActiveTriggerReportController from '../components/modalActiveTriggerReport/indexController';
import ModalActiveTriggerController from '../components/modalActiveTrigger/indexController';

const ListOfMessageTriggers: FC<ListOfMessageTriggersInterface> = (props) => {
  const tabScheduleActive = props.tab === 'schedules' ? 'list-message-triggers-tab-item-active' : '';
  const tabTypesActive = props.tab === 'types' ? 'list-message-triggers-tab-item-active' : '';

  return (
    <div className="list-message-triggers-component">
      { props.showModalActiveTrigger && <ModalActiveTriggerController show={props.showModalActiveTrigger} hide={props.closeModalActiveTrigger} />}
      { props.showModalReportActiveTrigger && <ModalActiveTriggerReportController show={props.showModalReportActiveTrigger} hide={props.closeModalReportActiveTrigger} />}
      { props.showModalCancelActiveTrigger && <ModalCancelActiveTriggerController show={props.showModalCancelActiveTrigger} hide={props.closeModalCancelActiveTrigger} /> }
      { props.showModalTriggerType && <ModalTriggerTypeController show={props.showModalTriggerType} hide={props.closeModalTriggerType} /> }
      { props.showModalChangeStatusTriggerType && <ModalChangeStatusTriggerTypeController show={props.showModalChangeStatusTriggerType} hide={props.closeModalChangeStatusTriggerType} /> }

      <div className="list-message-triggers-tabs">
        <span className={`list-message-triggers-tab-item ${tabScheduleActive}`} onClick={() => props.changeTab('schedules')}>{props.t('tab_schedules')}</span>
        <span className={`list-message-triggers-tab-item ${tabTypesActive}`} onClick={() => props.changeTab('types')}>{props.t('tab_types')}</span>
      </div>
      <div className="list-message-triggers-page-info">
        { props.filters?.length > 0 && props.tab === 'schedules' &&
          <FilteredViewBarController 
            pageType='messages-trigger-schedules'
            filters={props.filters}
            filterMode="simple"
            clearSearch={props.clearSearchActiveTriggersSchedules}
            clearSpecificFilter={props.clearSpecificFilter}
          />
        }
        { props.filters?.length > 0 && props.tab === 'types' &&
          <FilteredViewBarController 
            pageType='messages-trigger-types'
            filters={props.filters}
            filterMode="simple"
            clearSearch={props.clearSearchActiveTriggersTypes}
            clearSpecificFilter={props.clearSpecificFilter}
          />
        }
      </div>
      <div className="list-message-triggers-page-content">
        { props.tab === 'schedules' &&
          <CustomTableListController 
            component='messages-trigger-schedules'
            text_empty_list={props.t('no_active_trigger_schedules')}
            text_loading_list={props.t('loading_active_trigger_schedules')}
            text_error_list={props.t('error_loading_active_trigger_schedules')}
            icon_entity_list={<IconSend className='tabler-icon-entity' />}
            headers={props.listHeadersSchedules}
            data={props.listDataSchedule}
            paginationDetails={props.paginationDetails}
            fetchMore={props.getActiveTriggersSchedulesPagination}
            isLoading={props.isLoading}
            errorLoading={props.errorLoading}
            popoverItemAction={props.popoverItemActionSchedules}
            reloadData={props.reloadActiveTriggersSchedules}
            handleItemClick={props.openModalActiveTrigger}
          />
        }
        { props.tab === 'types' &&
          <CustomTableListController 
            component='messages-trigger-types'
            text_empty_list={props.t('no_active_trigger_types')}
            text_loading_list={props.t('loading_active_trigger_types')}
            text_error_list={props.t('error_loading_active_trigger_types')}
            icon_entity_list={<IconSend className='tabler-icon-entity' />}
            headers={props.listHeadersTypes}
            data={props.listDataType}
            paginationDetails={props.paginationDetails}
            fetchMore={props.getActiveTriggersTypesPagination}
            isLoading={props.isLoading}
            errorLoading={props.errorLoading}
            popoverItemAction={props.popoverItemActionTypes}
            reloadData={props.reloadActiveTriggersTypes}
            handleItemClick={props.openModalTriggerType}
          />
        }
      </div>
    </div>
  );
};

export default ListOfMessageTriggers;
