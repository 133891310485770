import { FC, useContext } from "react";
// import { SocketContext } from "./socket-context";
import { EmailContext } from "./cc-cco-context";
import { TicketContext } from "./ticket-context";
import { MainAppProvider } from "./main-app-context";
import { CallcenterProvider } from "./callcenter-context";
import { ViewTicketProvider } from "./view-ticket-context";
import { BlockTicketProvider } from "./block-ticket-context";
import { StatusAgentProvider } from "./status-agent-context";
import { TicketNotifyContext } from "./ticket-notification-context";
import { SocketV2Provider } from "./socket-context-v2";

const AppContextProviders: FC = ({ children }) => {
  
  // const socket = useContext(SocketContext);
  const email = useContext(EmailContext);
  const ticket = useContext(TicketContext);
  const ticketNot = useContext(TicketNotifyContext);

  return (
    <MainAppProvider>
      <SocketV2Provider>
        <BlockTicketProvider>
          <StatusAgentProvider>
            <TicketContext.Provider value={ticket}>
              <TicketNotifyContext.Provider value={ticketNot}>
                <EmailContext.Provider value={email}>
                  {/* <SocketContext.Provider value={socket}> */}
                    <CallcenterProvider>
                      <ViewTicketProvider>
                        {children}
                      </ViewTicketProvider>
                    </CallcenterProvider>
                  {/* </SocketContext.Provider> */}
                </EmailContext.Provider>
              </TicketNotifyContext.Provider>
            </TicketContext.Provider>
          </StatusAgentProvider>
        </BlockTicketProvider>
      </SocketV2Provider>
    </MainAppProvider>
  );
}

export default AppContextProviders;