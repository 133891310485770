import { FC } from 'react';
import SingleTabDetection from './index';
import { SingleTabDetectionControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import useSingleTabDetection from '../../hooks/useSingleTabDetection';

const SingleTabDetectionController: FC<SingleTabDetectionControllerInterface> = () => {
  const { t } = useTranslation('SingleTabDetection');
  const { handleClear } = useSingleTabDetection();

  return (
    <SingleTabDetection
      t={t}
      handleClear={handleClear}
    />
  );
};

export default SingleTabDetectionController;
