import { FC, useEffect, useRef, useState } from 'react';
import ConsumerInfo from '.';
import { ConsumerInfoControllerInterface, TicketRequesterUserInfo } from './indexModel';
import { useViewTicketContext } from '../../../../../../../../core/context/view-ticket-context';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TicketStatus } from '../../../../../../../../core/enums/ticket-status';
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp, IconMail, IconMessages, IconPhone } from '@tabler/icons-react';
import { Channels } from '../../../../../../../../core/enums/channels';
import constsRouters from '../../../../../../../../routes/constsRouter';
import i18n from '../../../../../../../../i18next';
import { ListTicketsInterface } from '../../../../../listOfTickets/indexModel';
import emitter from '../../../../../../../../core/shared/emitter';
import { useMainAppContext } from '../../../../../../../../core/context/main-app-context';
import { UserConsumerInterface } from '../../indexModel';
import { PagesType } from '../../../../../../components-fixed/sidebarAppComponent/indexModel';

const ConsumerInfoController: FC<ConsumerInfoControllerInterface> = (props) => {

  const { t } = useTranslation("ConsumerInfo");
  const navigate = useNavigate();

  const { currentConsumer, currentTicket, setShowConsumerModal } = useViewTicketContext();
  const { isSearched } = useMainAppContext();

  const [selectedTicket, setSelectedTicket] = useState<string>();
  const [channels, setChannels] = useState<{ channel_id: string, contains: boolean }[]>([]);
  const [showHistory, setShowHistory] = useState(true);
  const [ticketRequesterUserInfo, setTicketRequesterUserInfo] = useState<TicketRequesterUserInfo>(null);
  const [isDeletedConsumer, setIsDeletedConsumer] = useState(false);

  const historyListRef = useRef<HTMLDivElement>(null);

  const default_channels = [
    Channels.Email, Channels.WhatsApp, Channels.Facebook, Channels.Instagram, Channels.Telefonia
  ];

  useEffect(() => {
    if (currentConsumer) {
      const obj_channels = {};

      default_channels.forEach(channel_id => {
        obj_channels[channel_id] = { channel_id, contains: false };
      });

      currentConsumer.channels?.forEach(item => {
        if (obj_channels[item.id]) {
          obj_channels[item.id].contains = true;
        }
      });

      setChannels(Object.values(obj_channels));
    }
  }, [currentConsumer?.user_id]);

  useEffect(() => {
    if (currentTicket) {
      setSelectedTicket(currentTicket.id);
      setTicketRequesterUserInfo(currentTicket.requester_user);
      
      if (currentTicket?.requester_user) {
        setIsDeletedConsumer(!!currentTicket.requester_user.deleted_at);
      } else {
        setIsDeletedConsumer(true);
      }
    }
  }, [currentTicket?.id]);

  const openConsumerModal = () => {
    if (!isDeletedConsumer) {
      setShowConsumerModal(true);
      setTimeout(() => {
        emitter.emit('open-modal-consumer', { userId: currentConsumer.user_id });
      }, 50);
    }
  }

  const openTicket = (item: ListTicketsInterface): void => {
    if (item) {
      let routePath = `${constsRouters.routers.tickets.path}`;
      
      if (item.finished_at && item.updated_level2_at) {
        routePath = `${constsRouters.routers.history.path}`;
      } else if (!item.updated_level2_at) {
        routePath = `${constsRouters.routers.historyChatbot.path}`;
      }
  
      navigate(`${routePath}/${item.id}`);
      setSelectedTicket(item.id);
    }
  }

  const getStatusIcon = (status_id: string): { tooltip: string, symbol: string, color: string } => {
    switch (status_id) {
      case TicketStatus.Open: 
        return { tooltip: t("ticket_status.open"), symbol: t('ticket_status_symbol.open'), color: '#4A69BD' };
      case TicketStatus.Resolved: 
        return { tooltip: t("ticket_status.resolved"), symbol: t('ticket_status_symbol.resolved'), color: '#707070' };
      case TicketStatus.Pending: 
        return { tooltip: t("ticket_status.pending"), symbol: t('ticket_status_symbol.pending'), color: '#DE2450' };
      case TicketStatus.OnHold: 
        return { tooltip: t("ticket_status.on_hold"), symbol: t('ticket_status_symbol.on_hold'), color: '#FBBD4E' };
      case TicketStatus.New: 
        return { tooltip: t("ticket_status.new"), symbol: t('ticket_status_symbol.new'), color: '#17a589' };
      case TicketStatus.Abandoned: 
        return { tooltip: t("ticket_status.abandoned"), symbol: t('ticket_status_symbol.abandoned'), color: '#2D3233' };
      case TicketStatus.OverdueTicketTask: 
        return { tooltip: t("ticket_status.delayed"), symbol: t('ticket_status_symbol.delayed'), color: '#FFA500' };
      default:
        return { tooltip: 'N/A', symbol: 'N/A', color: '#707070' };
    }
  }

  const getChannel = (channel_id: string): { title: string, icon?: any } => {
    switch (channel_id) {
      case Channels.Email:
        return { title: t("channels_names.email"), icon: <IconMail /> };
      case Channels.Telefonia:
        return { title: t("channels_names.telephony"), icon: <IconPhone />};
      case Channels.WhatsApp:
        return { title: t("channels_names.whatsapp"), icon: <IconBrandWhatsapp />};
      case Channels.Facebook:
        return { title: t("channels_names.facebook"), icon: <IconBrandFacebook />};
      case Channels.Instagram:
        return { title: t("channels_names.instagram"), icon: <IconBrandInstagram />};
      case Channels.Webchat:
        return { title: t("channels_names.webchat"), icon: <IconMessages />};
      default:
        return { title: 'N/A' }
    }
  }

  const getFormattedDate = (created_at: string) => {
    const date = new Date(created_at);
    const formatted = date.toLocaleDateString(i18n.language) + ` ${t('date_at_label')} ` + date.toLocaleTimeString(i18n.language).slice(0,5);
    return formatted;
  }

  const getConsumerInfo = (): UserConsumerInterface | TicketRequesterUserInfo => {
    if (currentConsumer) {
      return currentConsumer;
    } else {
      return ticketRequesterUserInfo;
    }
  }

  const getComponentHeighDiff = () => {
    const customPagesFilter: PagesType[] = [
      'consumer-tickets', 
      'agent-tickets', 
      'task-ticket', 
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];
    
    if (isSearched || customPagesFilter.includes(props.pageType)) {
      return 490;
    } else {
      return 420;
    }
  }

  return (
    <ConsumerInfo 
      t={t}
      consumer={getConsumerInfo()}
      requesterInfo={ticketRequesterUserInfo}
      isDeletedConsumer={isDeletedConsumer}
      openConsumerModal={openConsumerModal}
      tickets={props.getConsumerTickets().consumerTickets}
      fetchTickets={props.getConsumerTickets().fetchConsumerTicketsPagination}
      hasMoreTickets={props.getConsumerTickets().hasMoreConsumerTickets}
      loadingHistory={props.getConsumerTickets().isLoadingConsumerTicketsPagination}
      selectedTicket={selectedTicket}
      getStatusIcon={getStatusIcon}
      getChannel={getChannel}
      getFormattedDate={getFormattedDate}
      historyListRef={historyListRef}
      channels={channels}
      showHistory={showHistory}
      setShowHistory={setShowHistory}
      openTicket={openTicket}
      refreshConsumerTickets={props.getConsumerTickets().refreshConsumerTickets}
      isSearched={isSearched}
      getComponentHeighDiff={getComponentHeighDiff}
    />
  );
}

export default ConsumerInfoController;