import { useSelector } from 'react-redux'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Row, Col, Form, Button, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    getShowEditAgentsModal
} from '../../../../store/internal'
import InfiniteScroll from 'react-infinite-scroll-component';
import constsApi from '../../../../services/constsApi';
import Loading from '../../../../components/loading'
import ModalEditAgentsController from '../../components/modalEditAgents/indexController';
import Utils from '../../../../core/shared/utils';
import ButtonOrderByList from '../../components/buttonOrderByList';
import { ListName } from '../../../../core/enums/order-sort-by';
import { StatusAgent } from '../../../../core/enums/status-agent';
import ModalViewConsumerController from '../modalViewConsumer/indexController';

const ListOfAgents = ({
    t,
    isLoading,
    values,
    props,
    currentAgentData,
    AllCheckboxesClick,
    CheckboxesClick,
    viewAgentPage,
    popover,
    setCurrentAgentInfo,
    deleteAllSelected,
    isAdmin,
    isAgent,
    isOwner,
    isMaster,
    documentNumberFormat,
    show,
    handleClose,
    handleShow,
    currentAgentId,
    setCurrentAgentId,
    agentId,
    agentStatusId,
    isNotification
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalViewConsumerController show={show} handleClose={handleClose} handleShow={handleShow} currentAgentId={currentAgentId} setCurrentAgentId={setCurrentAgentId}/>
            <ModalEditAgentsController token={values.token.value} visibility={useSelector(getShowEditAgentsModal)} callback={props.callbackModalAgents} data={currentAgentData} />
            <div style={{ marginTop: '2vh' }} className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="ListOfAgentsHead">
                            <Col md={1} style={{ width: '5%' }} >
                                {
                                    (isAdmin() || isOwner() || isMaster()) &&
                                    <Form.Check
                                        type='checkbox'
                                        id='Allcheckboxes'
                                        onClick={(e) => AllCheckboxesClick(e)}
                                    />
                                }
                            </Col>
                            {!isNotification ?
                                (isAdmin() || isOwner() || isMaster()) &&
                                <Col md={1} className='nopadding' style={{ width: '5.9%' }}>
                                        <p>{t('home_agents.list_agents.status')}</p>
                                    </Col>
                                :
                                <></>
                            }
                            <Col md={2} className="nopadding">
                                <ButtonOrderByList title={t("home_agents.list_agents.name")} columnIdName={'name'} callbackOrderBy={props.callbackOrderByEmployees} />
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>{t('home_agents.list_agents.email')}</p>
                            </Col>
                            {!isNotification ?
                                <>
                            <Col md={2} className="nopadding">
                                <p>{t("home_agents.list_agents.document")}</p>
                            </Col>
                            <Col md={2} className="nopadding">
                                <p>{t('home_agents.list_agents.phone')}</p>
                            </Col>
                                </>
                                :
                                <></>
                            }   
                            <Col md className="nopadding">
                                <ButtonOrderByList title={t("home_agents.list_agents.type")} columnIdName='profile_id' callbackOrderBy={props.callbackOrderByEmployees} />
                            </Col>
                            {!isNotification ?
                                (isAdmin() || isOwner() || isMaster()) &&
                                <Col className='d-flex justify-content-end '>
                                        <p>{t('home_agents.list_agents.actions')}</p>
                                </Col>
                                :
                                <></>
                            }
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                {
                    props.datas !== undefined ?
                        <div id="ListOfAgents" style={isNotification ? { overflowY: 'auto', height: '19vh' } : { overflowY: 'auto', height: '60vh' }}>
                            <InfiniteScroll
                                dataLength={props.datas.length}
                                next={props.updateListfunction}
                                hasMore={props.HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfAgents"}
                                onScroll={ () => { Utils.hidePopoverOnScroll(ListName.ListOfAgents) } }
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {props.datas.map((item, index) => {
                                            
                                            let status = `${t('home_agents.list_agents.na')}`

                                            // switch (props.getStatusEmployeeId(item.id)) {
                                            //     case StatusAgent.Online:
                                            //         status = `${t('home_agents.list_agents.online')}`
                                            //         break;
                                            //     case StatusAgent.Offline:
                                            //         status = `${t('home_agents.list_agents.offline')}`
                                            //         break;
                                            //     case StatusAgent.Ausente:
                                            //         status = `${t('home_agents.list_agents.away')}`
                                            //     default:
                                            //         break;
                                            // }

                                            return (
                                                <ListGroup.Item style={{ color: '#707070' }} id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} key={"keyAll" + index.toString()} >
                                                    <Row className='TicketsRowHeight'>

                                                        <Col md={1} className='d-flex align-self-center' style={{ width: '5%' }}>
                                                            {
                                                                (isAdmin() || isOwner() || isMaster()) &&
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id={"checkboxesAll" + index}
                                                                    onChange={(e) => CheckboxesClick(item.user_id, e.target.checked)}
                                                                    className="form-check-input-2"
                                                                />
                                                            }
                                                        </Col>
                                                        {!isNotification ?
                                                            (isAdmin() || isOwner() || isMaster()) &&
                                                            <Col className='d-flex align-self-center' style={{ width: '5%' }}>
                                                                {status === `${t('home_agents.list_agents.online')}` ? 
                                                                    <span style={{ padding: "0px", margin: "0px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                            <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" stroke-width="0" fill="#1e8e3e" />
                                                                        </svg>
                                                                    </span>
                                                                :
                                                                    status === `${t('home_agents.list_agents.offline')}` ? 
                                                                    <span style={{ padding: "0px", margin: "0px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                            <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" stroke-width="0" fill="#ed1c24" />
                                                                        </svg>
                                                                    </span>
                                                                :
                                                                        status === `${t('home_agents.list_agents.away')}` ?
                                                                    <span style={{ padding: "0px", margin: "0px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-point-filled" width="1.5rem" height="1.5rem" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                                            <path d="M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z" stroke-width="0" fill="#fff200" />
                                                                        </svg>
                                                                    </span>
                                                                :
                                                                null
                                                            }
                                                            </Col>
                                                            :
                                                            <></>
                                                        }

                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => isAgent() === false && viewAgentPage(e)}>
                                                            <div className='oneLineClampEllipsis'>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item?.name ?
                                                                                <>{item?.name} {item?.name && item?.lastname ? item.lastname : ''}</>
                                                                                :
                                                                                <>{t('home_agents.list_agents.na')}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.name ? item.name : <>{t('home_agents.list_agents.na')}</>} {item?.name && item?.lastname ? item.lastname : ''}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => isAgent() === false && viewAgentPage(e)}>
                                                            <div className="oneLineClampEllipsis me-3">
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.email ?
                                                                                <>{item.email}</>
                                                                                :
                                                                                <>{t('home_agents.list_agents.na')}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {item?.email ? item.email : <>{t('home_agents.list_agents.na')}</>}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </Col>
                                                        {!isNotification ?
                                                            <>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => isAgent() === false && viewAgentPage(e)}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item?.document_number ?
                                                                            <>{documentNumberFormat(item)}</>
                                                                            :
                                                                            <>{t('home_agents.list_agents.na')}</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item?.document_number ?
                                                                        <>{documentNumberFormat(item)}</>
                                                                        :
                                                                        <>{t('home_agents.list_agents.na')}</>
                                                                    }
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item?.name + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => isAgent() === false && viewAgentPage(e)}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.phone ?
                                                                            <>{formatPhoneNumberIntl("+"+item.phone)}</>
                                                                            :
                                                                            <>{t('home_agents.list_agents.na')}</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.phone ?
                                                                        <>{formatPhoneNumberIntl("+"+item.phone)}</>
                                                                        :
                                                                        <>{t('home_agents.list_agents.na')}</>
                                                                    }
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                        <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item + " " + (item?.name && item?.lastname ? item.lastname : "") + "*ID*" + item?.user_id} onClick={(e) => isAgent() === false && viewAgentPage(e)}>
                                                            <OverlayTrigger
                                                                key='top'
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip id='tooltip'>
                                                                        {item.profile_id === constsApi.profile[1].id ?
                                                                            <>{t('home_agents.list_agents.agent')}</>
                                                                            :
                                                                            item.profile_id === constsApi.profile[0].id ?
                                                                                <>{t('home_agents.list_agents.admin')}</>
                                                                                :
                                                                                item.profile_id === constsApi.profile[2].id ?
                                                                                    <>{t('home_agents.list_agents.owner')}</>
                                                                                    :
                                                                                    <>{t('home_agents.list_agents.na')}</>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>
                                                                    {item.profile_id === constsApi.profile[1].id ?
                                                                        <>{t('home_agents.list_agents.agent')}</>
                                                                        :
                                                                        item.profile_id === constsApi.profile[0].id ?
                                                                            <>{t('home_agents.list_agents.admin')}</>
                                                                            :
                                                                            item.profile_id === constsApi.profile[2].id ?
                                                                                <>{t('home_agents.list_agents.owner')}</>
                                                                                :
                                                                                <>{t('home_agents.list_agents.na')}</>
                                                                    }
                                                                </span>
                                                            </OverlayTrigger>
                                                        </Col>
                                                        {
                                                            !isNotification ?
                                                            (isAdmin() || isOwner() || isMaster()) &&
                                                            <Col className='d-flex justify-content-end' >
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                        <Button id={item?.name + "*ID*" + item?.user_id + "*ID*" + item?.profile_id} onClick={(e) => setCurrentAgentInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </Col>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </Row>
                                                </ListGroup.Item>);
                                        })}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '57vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1} className="d-flex justify-content-center">
                                    <svg id="Grupo_11213" data-name="Grupo 11213" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9567" data-name="Caminho 9567" d="M0,0H24V24H0Z" fill="none" />
                                        <circle id="Elipse_158" data-name="Elipse 158" cx="4" cy="4" r="4" transform="translate(5 3)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9568" data-name="Caminho 9568" d="M3,21V19a4,4,0,0,1,4-4h4a4,4,0,0,1,4,4v2" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9569" data-name="Caminho 9569" d="M16,11h6M19,8v6" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_agents.list_agents.no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={props.showCreateAgent}>{t('home_agents.list_agents.add_agent')}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }
                {!isNotification ?
                <Row style={{ marginTop: '3vh',width: '100%'  }} >
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonDeleteAllSelected" className="buttonBlue" onClick={deleteAllSelected} >{t('home_agents.list_agents.delete_selecteds')}</Button>
                    </Col>
                </Row>
                    :
                    <></>
                }
            </div>
        </>
    );

}

export default ListOfAgents;