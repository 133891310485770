import { FC, useEffect, useState } from 'react';
import ModalExportConsumer from './index';
import { FetchExportConsumer, ModalExportConsumerControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';
import saveAs from 'file-saver';

const AppRequesterConst = new AppRequesterController();

const ModalExportConsumerController: FC<ModalExportConsumerControllerInterface> = (props) => {
  const { t } = useTranslation('ModalExportConsumer');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ userId: string, consumerName?: string }[]>(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [mode, setMode] = useState<'all' | 'single' | 'multiple'>('single');

  useEffect(() => {
    emitter.on('open-modal-export-consumer', data => {
      if (data) {
        setData(data);
        if (data.length === 0) {
          setMode('all');
        } else if (data.length > 1) {
          setMode('multiple');
        } else {
          setMode('single');
        }
      } else {
        setMode('all');
        setData([]);
      }
    });

    return () => {
      emitter.off('open-modal-export-consumer');
    }
  }, []);

  const handleSubmit = () => {
    console.log('handleSubmit')
    console.log(mode);
    if (mode === 'all') {
      exportAllConsumers();
    } else {
      exportSelectedConsumers();
    }
  }

  const exportSelectedConsumers = () => {
    if (data && data.length > 0) {
      const headers  = UserService.getHeaders();

      const users = data.map(item => item.userId);
      const config = { headers };

      const formData = { users };

      AppRequesterConst.Post(
        `/consumer/csv/export-consumers`, formData, config,
        () => {},
        (response: FetchExportConsumer) => {
          if (data.length === 1) {
            dispatch(setShowAlertFeedback({ message: t('alert.export_selected_single_successfully'), visibility: true, signalIcon: true }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.export_selected_multi_successfully', { selectedCount: users.length }), visibility: true, signalIcon: true }));
          }

          const arrayBuffer = new Uint8Array(response.data.content.data).buffer;
          const blob = new Blob([arrayBuffer], {
            type: response.data.mimetype
          });
          saveAs(blob, response.data.fileName);
          
          props.hide();
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingExport
      );
    }
  }

  const exportAllConsumers = () => {
    const headers  = UserService.getHeaders();

    const config = { headers };

    AppRequesterConst.Post(
      `/consumer/csv/export-consumers-by-company`, {},  config,
      () => {},
      (response: FetchExportConsumer) => {
        dispatch(setShowAlertFeedback({ message: t('alert.export_all_successfully'), visibility: true, signalIcon: true }));

        const arrayBuffer = new Uint8Array(response.data.content.data).buffer;
        const blob = new Blob([arrayBuffer], {
          type: response.data.mimetype
        });
        saveAs(blob, response.data.fileName);

        props.hide();
      },
      (error: any) => {
        console.log(error);
        dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
      },
      navigate, dispatch, setLoadingExport
    );
  }

  return (
    <ModalExportConsumer 
      t={t}
      mode={mode}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingExport={loadingExport}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalExportConsumerController;
