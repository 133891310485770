import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';

import { getToken } from '../../../../store/token'
import { getContractStatusId, getIdCompany } from '../../../../store/company'
import { getIdEmployee } from '../../../../store/employee'
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user'
import { getChatbot } from "../../../../store/chatbot";
import { setShowAlertFeedback, setShowConfirmationModal, getShowUpdatedBot, setShowUpdatedBot, setPromptUpdatedNlp, getPromptUpdatedNlp } from "../../../../store/internal";
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import UserService from '../../../../services/user-service';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import HomeBotxpress from "./index"
import { useTranslation } from "react-i18next";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import useBotCxpressUpdatedFiles from "../../../../hooks/useBotCxpressUpdatedFilesSocket";
import { useMainAppContext } from "../../../../core/context/main-app-context";

const AppRequesterConst = new AppRequesterController();

const HomeBotxpressController = () => {
  const { t } = useTranslation();
  const dataBot = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setMainHeader } = useMainAppContext();

  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany),
      contractStatusId: useSelector(getContractStatusId)
    },
    employee: {
      id: useSelector(getIdEmployee)
    },
    user: {
      id: useSelector(getIdUser),
      name: useSelector(getNameUser),
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
    chatbot: {
      hasBot: useSelector(getChatbot)
    },
    updatedBot: useSelector(getShowUpdatedBot),
    promptUpdatedNlp: useSelector(getPromptUpdatedNlp),
    internal: {
      getShowUpdatedBot: useSelector(getShowUpdatedBot),
  }};
  
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([])
  const [dataCompany, setDataCompany] = useState([])
  const [activeTab, setActiveTab] = useState(dataBot?.state?.channel); // Estado para controlar a guia ativa

  const { updatedFiles } = useBotCxpressUpdatedFiles()

  useEffect(() => {
    setMainHeader({
        pageTitle: t('configurations.chatbots.title_welcome'),
        pageSubtitle: t('configurations.chatbots.title_here'),
        hasSearchComponent: false,
        hasSearchInput: false,
        hasSearchFilter: false,
        searchPage: null,
        searchPlaceholder: null,
        searchFunction: null,
        clearSearchFunction: null,
        hasCreateButton: false,
        createButtonText: null,
        createButtonTooltip: null,
        createButtonAction: null,
    });
  }, []);

  useEffect(() => {
    getBotCxpress();
    getCompanyId();
  }, [values.promptUpdatedNlp]);

  useEffect(() => {
    if (values.internal.getShowUpdatedBot) {
      dispatch(setShowUpdatedBot(false));
    } 
  }, []);

  useEffect(() => {
    if (updatedFiles != undefined) {
      onSocketUpdateBot(updatedFiles)
    }
  }, [updatedFiles])

  const getBotCxpress = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/bot-cxpress",
      { headers: headers, params: {} },
      (response: Object) => { },
      (data) => {
        setData(data.data.bot_cxpress)
      },
      (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => { 
        if (error.response?.data?.message && error.response.data.message[0]) {
          dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
        } else {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }))
        }
      },
      navigate, dispatch, setIsLoading, { values: values }
    );
  }

  const getCompanyId = async () => {

    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      `/company/id/${values.company.id}`,
      { headers: headers, params: {} },
      (response: Object) => { },
      (data) => {
        setDataCompany(data?.data?.companies[0])
      },
      (error: { response: { status: number; data: { message: any[]; code_cxpress: number } } }) => {
        // if (error.response?.data?.message && error.response.data.message[0]) {
        //   dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
        // } else {defaultMessage
        //   dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
        // }
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }))
      },
      navigate, dispatch, setIsLoading, { values: values }
      );
  }

  const filterDataBot = (channel?: string, voice?: boolean) => {
    return data.filter((item) => {
      if (voice) {
        return item && (item?.flag_control_voice_bot === true)
      }
      else {
        return item && (item.channel_id === channel)
      }
    });
  }

  const requestChatbot = async () => {
    const JsonSend = {}

    const headers = UserService.getHeaders();

    await AppRequesterConst.Post(
      '/bot-cxpress/send-email', JsonSend, { headers },
      (response: Object) => { },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.requestChatbot"), visibility: true, signalIcon: true }));
      },
      (error: { response: { status: number; }; }) => {
        if (error.response !== undefined) {
          dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.defaultMessage"), visibility: true, signalIcon: false }));
          if (error.response.status === 400) {
          }
          if (error.response.status === 401) {
          }
        }
      }, navigate, dispatch, setIsLoading
    );
  }

  const sendConfirmation = () => {
    dispatch(setShowConfirmationModal({ 
        visibility: true, 
        text: { 
            title: t("configurations.chatbots.request_chatbot_confirm"), 
            body: t("configurations.chatbots.request_chatbot_body") + ' ', 
            id: t("configurations.chatbots.waiting_contact"), 
            buttonReturnText: t("configurations.chatbots.cancel"), 
            buttonConfirmationText: t("configurations.chatbots.request_contact") 
        }, 
        functionConfirmation: () => { requestChatbot() } 
    }));
  }

  const onSocketUpdateBot = async (data: {bot_name: string, prompt_updated_nlp: boolean, message_error: string, id: string}) => {
    if (data.message_error) {
      dispatch(setShowAlertFeedback({ message: data.message_error, visibility: true, signalIcon: false }));
      dispatch(setPromptUpdatedNlp(true))
    } else if (data.prompt_updated_nlp === true && data.bot_name !== "") {
      dispatch(setShowAlertFeedback({ message: t("configurations.chatbots.chatbot_trained", { bot_name: data.bot_name }), visibility: true, signalIcon: true }));
      dispatch(setPromptUpdatedNlp(true))
    }
  }

  const handleClick = (mute: boolean) => {
    dispatch(setShowUpdatedBot(!mute));
  }

  return (
    <HomeBotxpress
      t={t}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      values={values}
      data={data}
      dataBot={dataBot}
      filterDataBot={filterDataBot}
      getBotCxpress={getBotCxpress}
      sendConfirmation={sendConfirmation}
      handleClick={handleClick}
      dataCompany={dataCompany}
      getCompanyId={getCompanyId}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
}

export default HomeBotxpressController;