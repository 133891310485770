import './styles.scss';
import { FC } from 'react';
import { PopupSearchFilterInterface } from './indexModel';
import CustomSelectController from '../../../../../components/customSelect/indexController';
import CustomInputController from '../../../../../components/customInput/indexController';
import { IconMinus, IconPlus, IconTicket, IconUsers } from '@tabler/icons-react';
import { CustomFieldEntity, CustomFieldsType } from '../../../../../core/enums/custom-field-type';
import Loading from '../../../../../components/loading2';

const PopupSearchFilter: FC<PopupSearchFilterInterface> = (props) => {
  const mainTabActiveClass = props.tab === 'main' ? 'filter-tabs-active' : '';
  const customTabActiveClass = props.tab === 'custom' ? 'filter-tabs-active' : '';

  const hasCustomFieldSelected = props.getCustomFieldsData().selectedCustomFieldTypeTemp && props.getCustomFieldsData().selectedCustomFieldItemTemp;
  const disabledAddCustomFieldClass = hasCustomFieldSelected ? '' : 'popup-search-filter-form-button-add-disabled';
  const disabledTabCustomField = props.getCustomFieldsData().hasCustomField ? '' : 'filter-tab-disabled';

  return (
    <div className="popup-search-filter-component">
      { ['ticket-list', 'history-general'].includes(props.page) &&
        <div className="popup-search-filter-tabs">
          <span className={`popup-search-filter-tabs-item ${mainTabActiveClass}`} onClick={() => props.setTab('main')}>{props.t('main_fields_tab')}</span>
          <span className={`popup-search-filter-tabs-item ${customTabActiveClass} ${disabledTabCustomField}`} onClick={() => props.setTab('custom')}>{props.t('custom_fields_tab')}</span> 
        </div>
      }
      { props.loading &&
        <div className="popup-search-filter-form-loading">
          <p>{props.t('loading_filters')}</p>
          <Loading relative={true} />
        </div>
      }
      { props.tab === 'main' && !props.loading &&
        <div className="popup-search-filter-form">
          { props.filterFields.includes('channel-ticket') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('channel_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                isMultiselect={true}
                placeholder={props.t('channel_placeholder')}
                options={props.getChannelSelect().channelsList || []}
                selectedMulti={props.getChannelSelect().selectedChannel}
                setSelectedMulti={props.getChannelSelect().setSelectedChannel}
              />
            </div>
          }
          { props.filterFields.includes('trigger-channel') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('channel_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                isMultiselect={true}
                placeholder={props.t('channel_placeholder')}
                options={props.getChannelSelect().channelsList || []}
                selectedMulti={props.getChannelSelect().selectedChannel}
                setSelectedMulti={props.getChannelSelect().setSelectedChannel}
              />
            </div>
          }
          { props.filterFields.includes('trigger-type') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('trigger_type_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('trigger_type_placeholder')}
                placeholderFilter={props.t('trigger_type_search')}
                options={props.getTriggerTypesSelect().triggerTypesList || []}
                selectedMulti={props.getTriggerTypesSelect().selectedTriggerType}
                setSelectedMulti={props.getTriggerTypesSelect().setSelectedTriggerType}
                fetchMore={props.getTriggerTypesSelect().fetchTriggerTypesPagination}
                hasMore={props.getTriggerTypesSelect().hasMoreTriggerTypes}
                loading={props.getTriggerTypesSelect().isLoadingTriggerTypesSelect}
                search={props.getTriggerTypesSelect().fetchSearchTriggerTypes}
              />
            </div>
          }
          { props.filterFields.includes('automation') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('automation_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                hasSimpleFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('automation_placeholder')}
                placeholderFilter={props.t('automation_search')}
                options={props.getAutomationsSelect().automationsList || []}
                selectedMulti={props.getAutomationsSelect().selectedAutomation}
                setSelectedMulti={props.getAutomationsSelect().setSelectedAutomation}
                loading={false}
              />
            </div>
          }
          { props.filterFields.includes('chatbot') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('chatbot_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                hasSimpleFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('chatbot_placeholder')}
                placeholderFilter={props.t('chatbot_search')}
                options={props.getChatbotsSelect().chatbotsList || []}
                selectedMulti={props.getChatbotsSelect().selectedChatbot}
                setSelectedMulti={props.getChatbotsSelect().setSelectedChatbot}
                loading={false}
              />
            </div>
          }
          { props.filterFields.includes('agent-ticket') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('agent_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                showIconSelected={true}
                placeholder={props.t('agent_placeholder')}
                placeholderFilter={props.t('agent_search')}
                options={props.getAgentsSelect().agentsList || []}
                selectedMulti={props.getAgentsSelect().selectedAgent}
                setSelectedMulti={props.getAgentsSelect().setSelectedAgent}
                fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                hasMore={props.getAgentsSelect().hasMoreAgents}
                loading={props.getAgentsSelect().isLoadingAgentsSelect}
                search={props.getAgentsSelect().fetchSearchAgents}
              />
            </div>
          }
          { props.filterFields.includes('agent-consumer-assigned') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('agent_assigned_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                showIconSelected={true}
                placeholder={props.t('agent_placeholder')}
                placeholderFilter={props.t('agent_search')}
                options={props.getAgentsSelect().agentsList || []}
                selectedMulti={props.getAgentsSelect().selectedAgent}
                setSelectedMulti={props.getAgentsSelect().setSelectedAgent}
                fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                hasMore={props.getAgentsSelect().hasMoreAgents}
                loading={props.getAgentsSelect().isLoadingAgentsSelect}
                search={props.getAgentsSelect().fetchSearchAgents}
              />
            </div>
          }
          { props.filterFields.includes('agent-task-requester') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('agent_task_requester_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                showIconSelected={true}
                placeholder={props.t('agent_placeholder')}
                placeholderFilter={props.t('agent_search')}
                options={props.getAgentsSelect().agentsList || []}
                selectedMulti={props.getAgentsSelect().selectedAgent}
                setSelectedMulti={props.getAgentsSelect().setSelectedAgent}
                fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                hasMore={props.getAgentsSelect().hasMoreAgents}
                loading={props.getAgentsSelect().isLoadingAgentsSelect}
                search={props.getAgentsSelect().fetchSearchAgents}
              />
            </div>
          }
          { props.filterFields.includes('agent-task-assigned') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('agent_task_assigned_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                showIconSelected={true}
                placeholder={props.t('agent_placeholder')}
                placeholderFilter={props.t('agent_search')}
                options={props.getAgentsTaskAssignedSelect().agentsTaskAssignedList || []}
                selectedMulti={props.getAgentsTaskAssignedSelect().selectedAgentTaskAssigned}
                setSelectedMulti={props.getAgentsTaskAssignedSelect().setSelectedAgentTaskAssigned}
                fetchMore={props.getAgentsTaskAssignedSelect().fetchAgentsTaskAssignedPagination}
                hasMore={props.getAgentsTaskAssignedSelect().hasMoreAgentsTaskAssigned}
                loading={props.getAgentsTaskAssignedSelect().isLoadingAgentsTaskAssignedSelect}
                search={props.getAgentsTaskAssignedSelect().fetchSearchAgentsTaskAssigned}
              />
            </div>
          }
          { props.filterFields.includes('trigger-requester') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('agent_task_requester_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                showIconSelected={true}
                placeholder={props.t('agent_placeholder')}
                placeholderFilter={props.t('agent_search')}
                options={props.getAgentsSelect().agentsList || []}
                selectedMulti={props.getAgentsSelect().selectedAgent}
                setSelectedMulti={props.getAgentsSelect().setSelectedAgent}
                fetchMore={props.getAgentsSelect().fetchAgentsPagination}
                hasMore={props.getAgentsSelect().hasMoreAgents}
                loading={props.getAgentsSelect().isLoadingAgentsSelect}
                search={props.getAgentsSelect().fetchSearchAgents}
              />
            </div>
          }
          { props.filterFields.includes('status-ticket') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('status_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                isMultiselect={true}
                placeholder={props.t('status_placeholder')}
                options={props.getStatusSelect().statusList || []}
                selectedMulti={props.getStatusSelect().selectedStatus}
                setSelectedMulti={props.getStatusSelect().setSelectedStatus}
              />
            </div>
          }
          { props.filterFields.includes('status-task') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('status_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                isMultiselect={true}
                placeholder={props.t('status_placeholder')}
                options={props.getStatusSelect().statusList || []}
                selectedMulti={props.getStatusSelect().selectedStatus}
                setSelectedMulti={props.getStatusSelect().setSelectedStatus}
              />
            </div>
          }
          { props.filterFields.includes('trigger-status') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('status_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                isMultiselect={true}
                placeholder={props.t('status_placeholder')}
                options={props.getStatusSelect().statusList || []}
                selectedMulti={props.getStatusSelect().selectedStatus}
                setSelectedMulti={props.getStatusSelect().setSelectedStatus}
              />
            </div>
          }
          { props.filterFields.includes('sector') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('sector_label')}</span>
              <CustomSelectController 
                type={props.isSimpleEmployee ? 'simple' : 'pagination'}
                dropdownPosition='body'
                hasFilter={!props.isSimpleEmployee}
                hasSimpleFilter={props.isSimpleEmployee}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('sector_placeholder')}
                placeholderFilter={props.t('sector_search')}
                options={props.getSectorsSelect().sectorsList || []}
                selectedMulti={props.getSectorsSelect().selectedSector}
                setSelectedMulti={props.getSectorsSelect().setSelectedSector}
                fetchMore={props.getSectorsSelect().fetchSectorsPagination}
                hasMore={props.getSectorsSelect().hasMoreSectors}
                loading={props.getSectorsSelect().isLoadingSectorsSelect}
                search={props.getSectorsSelect().fetchSearchSectors}
              />
            </div>
          }
          { props.filterFields.includes('tag') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('tag_label')}</span>
              <CustomSelectController 
                type='pagination'
                dropdownPosition='body'
                hasFilter={true}
                isMultiselect={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('tag_placeholder')}
                placeholderFilter={props.t('tag_search')}
                options={props.getTagsSelect().tagsList || []}
                selectedMulti={props.getTagsSelect().selectedTag}
                setSelectedMulti={props.getTagsSelect().setSelectedTag}
                fetchMore={props.getTagsSelect().fetchTagsPagination}
                hasMore={props.getTagsSelect().hasMoreTags}
                loading={props.getTagsSelect().isLoadingTagsSelect}
                search={props.getTagsSelect().fetchSearchTags}
              />
            </div>
          }
          { props.filterFields.includes('date') &&
            <div className="popup-search-filter-form-item filter-form-item-responsive">
              <span className="popup-search-filter-form-item-label">{props.t('filter_date_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                placeholder={props.t('filter_date_placeholder')}
                options={props.getFilterDateSelect().filterDateList || []}
                selected={props.getFilterDateSelect().selectedFilterDate}
                setSelected={props.getFilterDateSelect().setSelectedFilterDate}
              />
            </div>
          }
          { props.filterFields.includes('date') && props.getFilterDateSelect().selectedFilterDate?.id === 'custom' &&
            <div className="popup-search-filter-form-item">
              <span className="popup-search-filter-form-item-label">{props.t('date_label')}</span>
              <CustomInputController 
                type='datepicker'
                placeholder={props.t('date_placeholder')}
                datePickerSettings={{ 
                  maxDate: new Date(), 
                  onChange: props.getDateInput().onSelectDate,
                  selectsRange: true,
                  startDate: props.getDateInput().selectedDate.start,
                  endDate: props.getDateInput().selectedDate.end,
                  showTwoMonths: true,
                  isClearable: true,
                  popperPlacement: 'bottom',
                  valid: !!props.getDateInput().selectedDate?.start && !!props.getDateInput().selectedDate?.end
                }}
              />
            </div>
          }
        </div>
      }
      {
        props.tab === 'custom' && props.getCustomFieldsData().hasCustomField && !props.loading && ['ticket-list', 'history-general'].includes(props.page) &&
        <div className="popup-search-filter-form">
          <div className="popup-search-filter-form-custom-field-header">
            <div className="popup-search-filter-form-item">
              <span className="popup-search-filter-form-item-label">{props.t('custom_field_type')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                showIconSelected={true}
                iconSelectedPosition='start'
                placeholder={props.t('custom_field_type_placeholder')}
                options={props.getCustomFieldsData().customFieldListTypes || []}
                selected={props.getCustomFieldsData().selectedCustomFieldTypeTemp}
                setSelected={props.getCustomFieldsData().setSelectedCustomFieldTypeTemp}
                disabled={true}
              />
            </div>
            <div className="popup-search-filter-form-item">
              <span className="popup-search-filter-form-item-label">{props.t('custom_field_label')}</span>
              <CustomSelectController 
                type='simple'
                dropdownPosition='body'
                hasSimpleFilter={true}
                showTooltipOptions={true}
                tooltipOptionsPosition='right'
                placeholder={props.t('custom_field_placeholder')}
                placeholderFilter={props.t('custom_field_search')}
                options={props.getCustomFieldsData().customFieldsListAll || []}
                selected={props.getCustomFieldsData().selectedCustomFieldItemTemp}
                setSelected={props.getCustomFieldsData().setSelectedCustomFieldItemTemp}
                loading={false}
                disabled={!props.getCustomFieldsData().selectedCustomFieldTypeTemp}
              />
            </div>
            <div className={`popup-search-filter-form-button-add ${disabledAddCustomFieldClass}`} onClick={props.getCustomFieldsData().handleAddCustomField}>
              <IconPlus />
            </div>
          </div>
          <div className="popup-search-filter-form-custom-field-list">
            {(props.getCustomFieldsData().selectedCustomFieldListFilter?.length === 0) &&
              <span className='popup-search-filter-form-no-items'>{props.t('custom_field_no_items')}</span>
            }
            {props.getCustomFieldsData().selectedCustomFieldListFilter?.map(item => {
              return (
                <div className="popup-search-filter-form-custom-field-item" key={item.id}>
                  <div className="popup-search-filter-form-custom-field-item-header">
                    <span className="popup-search-filter-form-custom-field-item-label" title={item.name}>{item.name}</span>
                    { item.entity_type_id === CustomFieldEntity.Ticket && <IconTicket /> }
                    { item.entity_type_id === CustomFieldEntity.Consumer && <IconUsers /> }
                  </div>
                  <div className="popup-search-filter-form-custom-field-item-input">
                  { [CustomFieldsType.Text.toString(), CustomFieldsType.Textarea.toString()].includes(item.custom_field_type_id) &&
                    <CustomInputController 
                      type='input'
                      value={props.getCustomFieldsData().getCustomFieldValue(item.id) || ''}
                      setCustomValue={item.setValue}
                      placeholder={item.placeholder}
                      id={item.id}
                    />
                  }
                  {
                    [CustomFieldsType.Dropdown.toString(), CustomFieldsType.DropdownMultiselect.toString()].includes(item.custom_field_type_id) &&
                    <CustomSelectController 
                      type='simple'
                      isMultiselect={true}
                      placeholder={item.placeholder}
                      options={item.options || []}
                      selectedMulti={props.getCustomFieldsData().getCustomFieldValue(item.id) || []}
                      setCustomSelected={item.setValue}
                      loading={false}
                      id={item.id}
                      dropdownPosition='body'
                    />
                  }
                  <span className="popup-search-filter-form-custom-field-item-delete" onClick={() => props.getCustomFieldsData().handleRemoveCustomField(item.id)}>
                    <IconMinus />
                  </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      }
      {
        !props.loading &&
        <div className="popup-search-filter-footer">
          {
            props.isSearched && props.filteredMode === 'simple' && props.tab === 'main' &&
            <button className="popup-search-filter-button-clear" onClick={() => props.handleClearFilter()}>
              {props.t('btn_clear_filter')}
            </button>
          }
          {
            props.isSearched && props.filteredMode === 'advanced' && props.tab === 'custom' &&
            <button className="popup-search-filter-button-clear" onClick={() => props.handleClearFilter()}>
              {props.t('btn_clear_filter')}
            </button>
          }
          { props.tab === 'main' &&
            <button id="popup-search-filter-button" onClick={() => props.handleFilter('simple')}>
              {props.t('btn_filter')}
            </button>
          }
          { props.tab === 'custom' &&
            <button 
              id="popup-search-filter-button" 
              onClick={() => props.handleFilter('advanced')} 
              disabled={props.getCustomFieldsData().selectedCustomFieldListFilter?.length === 0 || !props.getCustomFieldsData().isValidCustomFieldsFilter()}>
              {props.t('btn_filter_custom')}
            </button>
          }
        </div>
      }
    </div>
  );
};

export default PopupSearchFilter;
