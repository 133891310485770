import { useTranslation } from "react-i18next";
import { FC, useEffect, useState, MouseEvent } from "react";
import { MessagesComponentControllerInterface } from "./indexModel";
import MessagesComponent from ".";
import { TicketMessageInterface } from "../../indexModel";
// import sanitizeHTML from 'sanitize-html';
import { IconRobotFace, IconUser, IconUserBolt, IconWriting } from "@tabler/icons-react";
import { useViewTicketContext } from "../../../../../../core/context/view-ticket-context";
import { AttachmentFile } from "./components/fileViewer/indexModel";
import { useSelector } from "react-redux";
import { getIdUser } from "../../../../../../store/user";
import { Popover } from "react-bootstrap";


const MessagesComponentController: FC<MessagesComponentControllerInterface> = (props) => {
  const { t } = useTranslation('MessagesComponent');

  const { ticketMessages, loadingFetchData, ticketUsers, setReplyMessage, ticketIsBlocked } = useViewTicketContext();

  const currentUserId = useSelector(getIdUser);
  
  const [userNames, setUserNames] = useState<{[key: string]: string}>({});
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [previewFirstAttachment, setPreviewFirstAttachment] = useState<AttachmentFile>(null);
  const [currentAudioPlayingUrl, setCurrentAudioPlayingUrl] = useState<string>(null);
  const [openedPopoverId, setOpenedPopoverId] = useState<string>(null);

  useEffect(() => {
    if (ticketUsers) {
      if (Object.keys(ticketUsers).length > 0) {
        setUserNames(ticketUsers);
      } else {
        setUserNames({});
      }
    }
  }, [ticketUsers]);

  const getMessageStyleClass = (message: TicketMessageInterface) => {
    let itemClass = 'ticket-chat-message';

    if (message.is_agent) {
      itemClass += ' chat-message-agent';
    }

    if (message.is_bot) {
      itemClass += ' chat-message-bot';
    }

    if (message.is_internal_response) {
      itemClass += ' chat-message-internal';
    }

    if (message.date) {
      itemClass += ' chat-message-system';
    }

    if (!message.date && !message.is_agent && !message.is_bot && !message.is_internal_response) {
      itemClass += ' chat-message-requester';
    }

    if (message.status === 'not sent') {
      itemClass += ' chat-message-not-sent';
    }

    return itemClass;
  }

  const sanitizeContent = (message: TicketMessageInterface) => {
    if (message) {
      let format = false;

      if (message.is_bot) {
        format = true;
      }
      
      if (message.nps || message.user_name?.startsWith('BOT ')) {
        format = true;
      }

      if (format) {
        const contentReplaced = message.content?.replace(/(\n)+$/, '').replace(/(\n)/g, '<br />');
        return contentReplaced;
      } else {
        return message.content;
      }
    }
    // const contentReplaced = content.replace(/(\n)+$/, '').replace(/(\n)/g, '<br />');
    // const contentReplaced = content.replace(/\n{3,}/g, '<br /><br />').replace(/\n{1,2}/g, '<br />');
    // const cleanContent = sanitizeHtml(content);
    // const cleanContent = sanitizeHTML(contentReplaced, {
    //   allowedTags: sanitizeHTML.defaults.allowedTags.concat([]),
    //   allowedAttributes: {
    //     'div': ['dir', 'style'],
    //     'img': ['src', 'style', 'width', 'border', 'alt'],
    //     'a': [ 'href', 'style' ]
    //   },
    //   parseStyleAttributes: false
    // });
    // console.log(JSON.stringify(contentReplaced))
    // console.log(JSON.stringify(cleanContent))
    // return cleanContent;
    // return content;
    // return contentReplaced;
  }

  // const generateURL = (content: string) => {
  //   const regexURL = /(\b(?:https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

  //   return content.replace(regexURL, '<a href="$1" target="_blank">$1</a>');
  // }

  const getDateSystemMsg = (date: string): string => {

    const texts2 = {
      today: 'system_date.today',
      yesterday: 'system_date.yesterday',
      0: 'system_date.sunday',
      1: 'system_date.monday',
      2: 'system_date.tuesday',
      3: 'system_date.wednesday',
      4: 'system_date.thursday',
      5: 'system_date.friday',
      6: 'system_date.saturday',
    };

    const today = new Date();
    const target = new Date(date);
    today.setHours(0, 0, 0, 0);
    target.setHours(0, 0, 0, 0);

    const totalDays = Math.abs(Math.ceil((target.getTime() - today.getTime()) / (1000 * 3600 * 24)));
    const lang = localStorage.getItem('i18nextLng')?.split('-')[0] || 'pt';

    if (today.toLocaleDateString(lang) === target.toLocaleDateString(lang)) {
      return t(texts2.today);
    } else if (totalDays === 1) {
      return t(texts2.yesterday);
    } else {
      if (totalDays < 7) {
        const new_today = new Date();
        new_today.setHours(0, 0, 0, 0);
        new_today.setDate(new_today.getDate() - totalDays);
        const day = new_today.getDay();
        return t(texts2[day]);
      } else {
        return target.toLocaleDateString(lang);
      }
    }
  }

  const getDateFormatted = (date: string): { time: string, date: string, datetime: string } => {
    const lang = localStorage.getItem('i18nextLng')?.split('-')[0] || 'pt';
    const auxDate = new Date(date);
    return { 
      time: auxDate.toLocaleTimeString(lang).slice(0, 5), 
      date: auxDate.toLocaleDateString(lang),
      datetime: auxDate.toLocaleString(lang) 
    };
  }

  const getUserMessage = (message: TicketMessageInterface): { name: string, type: string, icon: JSX.Element, tooltip: string } => {
    let user = { name: '', type: '', icon: null, tooltip: '' };

    if (message.is_agent) {
      user.name = message.user_name;
      user.type = 'agent';
      user.icon = <IconUserBolt />
      user.tooltip = t('agent');
    }

    if (message.is_bot || message.nps || message.user_name?.startsWith('BOT ')) {
      user.name = 'Bot';
      user.type = 'bot';
      user.icon = <IconRobotFace />;
      user.tooltip = t('bot');
    }

    if (message.is_internal_response) {
      user.name = message.user_name;
      user.type = 'internal';
      user.icon = <IconWriting />;
      user.tooltip = t('internal_note');
    }

    if (!message.date && !message.is_agent && !message.is_bot && !message.is_internal_response) {
      // user.name = message.user_name;
      user.name = userNames && userNames[message.user_id] ? userNames[message.user_id] : message.user_name;
      user.type = 'requester';
      user.icon = <IconUser />;
      user.tooltip = t('consumer');
    }

    return user;
  }

  const getUserName = (user_id: string, message_name: string) => {
    return userNames[user_id] || message_name;
  }

  const onScroll = (event) => {
    // console.log(props.elementRef);
    if (props.elementRef.current) {
      const target = props.elementRef.current;
      // console.log(target.scrollHeight, target.clientHeight, target.scrollTop)
      // console.log(props.elementRef)
      // props.elementRef.current.scrollTo({ top: target.scrollHeight - target.clientHeight });
      // console.log(target.scrollTop);
      if (target.scrollTop === 0) {
        // props.fetchMore();
        // console.log('scroll top')
      }
    }
  }

  const handlePopoverMenuAction = (event: MouseEvent<HTMLDivElement, MouseEvent>, itemId: string) => {
    event.stopPropagation();
    const popoverElement = document.querySelector('.popover-custom-action-menu-view-ticket-message') as HTMLDivElement;
    if (popoverElement) {
      if (!popoverElement.classList.contains('show')) {
        popoverElement.classList.add('show');
        popoverElement.style.visibility = '';
        setOpenedPopoverId(itemId);
      } else {
        setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
      }
    } else {
      setOpenedPopoverId(openedPopoverId === itemId ? null : itemId);
    }
  }

  const handlePopoverMessageItemAction = (event: any, data: TicketMessageInterface, type: 'reply-message') => {
    event.stopPropagation();
    setOpenedPopoverId(null);
    setTimeout(() => {
      if (type === 'reply-message') {
        setReplyMessage(data);
      }
    }, 50);
  }

  const popoverItemAction = (item: TicketMessageInterface) => {
    const showReplyMessage = true;

    return (
      <Popover bsPrefix='popover-custom-action-menu popover-custom-action-menu-view-ticket-message'>
        <Popover.Body>
          { showReplyMessage &&
            <span className='popover-item' onClick={(e) => handlePopoverMessageItemAction(e, item, 'reply-message')}>
              {t('popup_message.reply')}
            </span>
          }
        </Popover.Body>
      </Popover>
    );
  }

  return (
    <MessagesComponent 
      t={t}
      chatMessagesRef={props.elementRef}
      onScroll={onScroll}
      ticketMessages={ticketMessages}
      transitionStyleClass={props.transitionStyleClass}
      dynamicStyle={props.dynamicStyle}
      getMessageStyleClass={getMessageStyleClass}
      sanitizeContent={sanitizeContent}
      getDateSystemMsg={getDateSystemMsg}
      getDateFormatted={getDateFormatted}
      getUserMessage={getUserMessage}
      getUserName={getUserName}
      loadingFetchData={loadingFetchData}
      hasMoreMessage={props.hasMoreMessage}
      fetchMore={props.fetchMore}
      showFilePreview={showFilePreview}
      setShowFilePreview={setShowFilePreview}
      previewFirstAttachment={previewFirstAttachment}
      currentAudioPlayingUrl={currentAudioPlayingUrl}
      setCurrentAudioPlayingUrl={setCurrentAudioPlayingUrl}
      currentUserId={currentUserId}
      handlePopoverMenuAction={handlePopoverMenuAction}
      popoverItemAction={popoverItemAction}
      openedPopoverId={openedPopoverId}
      setOpenedPopoverId={setOpenedPopoverId}
      ticketIsBlocked={ticketIsBlocked}
    />
  );
}

export default MessagesComponentController;