import HomeApp from ".";
import { useDispatch, useSelector } from "react-redux";
import { getAppOpenSidebar } from "../../store/app_sidebar";
import UserService from "../../services/user-service";
import { AppRequesterController } from "../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { setEmployeeSectors } from "../../store/employee";
import { getIdEmployee } from "../../store/employee";
import { FetchEmployeeSectors } from "./indexModel";

const AppRequesterConst = new AppRequesterController();

const HomeAppController = () => {
  const sidebarOpened = useSelector(getAppOpenSidebar);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [, setIsLoadingUserSectors] = useState(false);
  const loggedEmployeeId = useSelector(getIdEmployee);

  useEffect(() => {
    if (UserService.token) {
      fetchUserSectors(UserService.getHeaders());
    }
  }, [UserService.token]);

  const fetchUserSectors = (headers) => {
    const config = { headers };

    AppRequesterConst.Get(
      '/agent/sector/list', config,
      () => {},
      (response: FetchEmployeeSectors) => {
        if (response.status === 200 && response.data?.sectors?.length > 0) {
          const employeeSectors = response.data?.sectors.filter(item => item.employee_id === loggedEmployeeId);
          dispatch(setEmployeeSectors(employeeSectors));
        } else {
          dispatch(setEmployeeSectors([]));
        }
      },
      () => {},
      navigate, dispatch, setIsLoadingUserSectors, {}
    );
  }

  return (
    <HomeApp 
      sidebarOpened={sidebarOpened}
    />
  );
}

export default HomeAppController;