import { FC, useEffect, useState } from 'react';
import ModalTriggerType from './index';
import { ModalTriggerTypeControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalTriggerTypeController: FC<ModalTriggerTypeControllerInterface> = (props) => {
  const { t } = useTranslation('ModalTriggerType');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ id: string, name: string, active: boolean }>(null);
  const [loadingEdit, setLoadingEdit] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-trigger-type', data => {
      if (data) {
        setData(data);
      } else {
        setData({ id: null, name: '', active: true });
      }
    });

    return () => {
      emitter.off('open-modal-trigger-type');
    }
  }, []);

  const isValid = () => {
    return data?.name?.length > 0;
  }

  const handleSubmit = () => {
    if (isValid()) {
      const headers  = UserService.getHeaders();
  
      const config = { headers };
      
      const payload = {
        name: data.name,
        active: data.active
      };
  
      if (data?.id) {
        payload['id'] = data.id;
  
        AppRequesterConst.Put(
          '/message-sending-type', payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_edit'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: any) => {
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          },
          navigate, dispatch, setLoadingEdit
        );
      } else {
        AppRequesterConst.Post(
          '/message-sending-type', payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.successfully_create'), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: any) => {
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          },
          navigate, dispatch, setLoadingEdit
        );
      }
    }
  }

  const setDataValue = (property: string, value: any) => {
    setData((dataObject: any) => ({
      ...dataObject,
      [property]: value,
    }));
  }

  return (
    <ModalTriggerType 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingEdit={loadingEdit}
      isValid={isValid}
      handleSubmit={handleSubmit}
      setDataValue={setDataValue}
    />
  );
};

export default ModalTriggerTypeController;
