import './styles.scss';
import { FC } from 'react';
import { AppLoadingInterface } from './indexModel';

const AppLoading: FC<AppLoadingInterface> = (props) => {
  const loadingClass = props.fadingOut ? 'loading-fade-out' : '';

  return (
    <div className={`app-loading-component ${loadingClass}`} >
      <img src="https://storage.googleapis.com/cxpress-assets/images/Logo2.svg" alt="cx-logo" />
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default AppLoading;
