import './styles.scss';
import { FC } from 'react';
import { ModalActiveTriggerReportInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../../../../../components/loading2';
import { IconCircleCheck, IconExclamationCircle, IconFileDescription, IconSend, IconTicket, IconUsers, IconX } from '@tabler/icons-react';
import CustomTableListController from '../../../../../components/customTableList/indexController';

const ModalActiveTriggerReport: FC<ModalActiveTriggerReportInterface> = (props) => {
  const btnExportClass = props.generatingPDF ? 'span-btn-disabled' : '';

  return (
    <Modal
      show={props.show}
      onHide={() => props.hide()}
      size="lg"
      centered={true}
      dialogClassName="modal-active-trigger-report-component"
    >
      <Modal.Header>
        <Modal.Title>{props.t('title')}</Modal.Title>
        <div className="modal-active-trigger-report-header-actions">
          {/* <span className="modal-active-trigger-report-header-generating">
            Gerando arquivo <span className="circle-loader"></span>
          </span> */}
          { !props.errorLoadingReport && props.reportData &&
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{props.t('btn_download_tooltip')}</Tooltip>}> 
              <span className={`modal-active-trigger-report-header-actions-download-btn ${btnExportClass}`} onClick={props.downloadFile}>
                { props.generatingPDF && <>{props.t('btn_generating')} <span className="circle-loader"></span></>}
                { !props.generatingPDF && props.t('btn_download')}
              </span>
            </OverlayTrigger>
          }
          <IconX className="modal-active-trigger-report-header-actions-close" onClick={() => props.hide()} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || !props.reportData || props.loadingReport) && !props.errorLoadingReport && <Loading blur={true}/>}
        { props.data && props.reportData &&
          <div className="modal-active-trigger-report-info">
            <div className="modal-active-trigger-report-info-fields">
              <div className="modal-active-trigger-report-info-fields-item">
                <span className="modal-active-trigger-report-info-fields-item-label">
                  {props.t('labels.title')}
                </span>
                <span className="modal-active-trigger-report-info-fields-item-value">
                  {props.data.title}
                </span>
              </div>
              <div className="modal-active-trigger-report-info-fields-row">
                <div className="modal-active-trigger-report-info-fields-item">
                  <span className="modal-active-trigger-report-info-fields-item-label">
                    {props.t('labels.type')}
                  </span>
                  <span className="modal-active-trigger-report-info-fields-item-value">
                    {props.data.type}
                  </span>
                </div>
                <div className="modal-active-trigger-report-info-fields-item">
                  <span className="modal-active-trigger-report-info-fields-item-label">
                    {props.t('labels.channel')}
                  </span>
                  <span className="modal-active-trigger-report-info-fields-item-value">
                    {props.getChannel(props.data.channel_id).icon}
                    {props.getChannel(props.data.channel_id).title}
                  </span>
                </div>
                <div className="modal-active-trigger-report-info-fields-item">
                  <span className="modal-active-trigger-report-info-fields-item-label">
                    {props.t('labels.date_scheduled')}
                  </span>
                  <span className="modal-active-trigger-report-info-fields-item-value">
                    {props.formatDateScheduled(props.data.date_scheduled)}
                  </span>
                </div>
              </div>
            </div>
            <div className="modal-active-trigger-report-info-counters">
              <div className="modal-active-trigger-report-info-counters-item">
                <div className="modal-active-trigger-report-info-counters-item-header">
                  <span className="modal-active-trigger-report-info-counters-item-value">{props.data.number_of_recipients}</span>
                  <IconUsers />
                </div>
                <div className="modal-active-trigger-report-info-counters-item-body">
                  <span className="modal-active-trigger-report-info-counters-item-label">
                    {props.t(props.data.number_of_recipients !== 1 ? 'labels.recipients_plural' : 'labels.recipients_singular')}
                  </span>
                </div>
              </div>
              <div className="modal-active-trigger-report-info-counters-item">
                <div className="modal-active-trigger-report-info-counters-item-header">
                  <span className="modal-active-trigger-report-info-counters-item-value">{props.reportData.total_sent}</span>
                  <IconSend />
                </div>
                <div className="modal-active-trigger-report-info-counters-item-body">
                  <span className="modal-active-trigger-report-info-counters-item-label">
                    {props.t(props.reportData.total_sent !== 1 ? 'labels.sent_total_plural' : 'labels.sent_total_singular')}
                  </span>
                </div>
              </div>
              <div className="modal-active-trigger-report-info-counters-item">
                <div className="modal-active-trigger-report-info-counters-item-header">
                  <span className="modal-active-trigger-report-info-counters-item-value">{props.reportData.sent_success}</span>
                  <IconCircleCheck />
                </div>
                <div className="modal-active-trigger-report-info-counters-item-body">
                  <span className="modal-active-trigger-report-info-counters-item-label">
                    {props.t(props.reportData.sent_success !== 1 ? 'labels.sent_success_plural' : 'labels.sent_success_singular')}
                  </span>
                </div>
              </div>
              <div className="modal-active-trigger-report-info-counters-item">
                <div className="modal-active-trigger-report-info-counters-item-header">
                  <span className="modal-active-trigger-report-info-counters-item-value">{props.reportData.sent_failure}</span>
                  <IconExclamationCircle />
                </div>
                <div className="modal-active-trigger-report-info-counters-item-body">
                  <span className="modal-active-trigger-report-info-counters-item-label">
                    {props.t(props.reportData.sent_failure !== 1 ? 'labels.sent_fail_plural' : 'labels.sent_fail_singular')}
                  </span>
                </div>
              </div>
              <div className="modal-active-trigger-report-info-counters-item">
                <div className="modal-active-trigger-report-info-counters-item-header">
                  <span className="modal-active-trigger-report-info-counters-item-value">{props.reportData.total_answers}</span>
                  <IconTicket />
                </div>
                <div className="modal-active-trigger-report-info-counters-item-body">
                  <span className="modal-active-trigger-report-info-counters-item-label">
                    {props.t(props.reportData.total_answers !== 1 ? 'labels.tickets_plural' : 'labels.tickets_singular')}
                  </span>
                </div>
              </div>
            </div>
            <CustomTableListController 
              component='modal-messages-trigger-report'
              text_empty_list={props.t('no_sent')}
              text_loading_list={props.t('loading_report')}
              text_error_list={props.t('error_loading_report')}
              icon_entity_list={<IconFileDescription className='tabler-icon-entity' />}
              itemWrap={true}
              scrollbarThin={true}
              headers={props.listHeaders}
              data={props.reportData.messages}
              paginationDetails={props.paginationDetails}
              itemClickDisabled={true}
            />
          </div>
        }
        { props.errorLoadingReport &&
          <div className="modal-body-loading">
            <div className="modal-body-loading-info modal-body-loading-error">
              <IconFileDescription />
              <span className="modal-body-loading-info-message">
                {props.t('loading_error_message')}
              </span>
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-active-trigger-report-btn-close' onClick={() => props.hide()}>{props.t('btn_close')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalActiveTriggerReport;
