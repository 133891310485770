import { FC } from 'react';
import AcquirePlatformBar from './index';
import { AcquirePlatformBarControllerInterface } from './indexModel';
import { useSelector } from 'react-redux';
import { getNameUser } from '../../../../store/user';
import { getSubdomainCompany } from '../../../../store/company';
import { useTranslation } from 'react-i18next';

const AcquirePlatformBarController: FC<AcquirePlatformBarControllerInterface> = (props) => {
  const { t } = useTranslation('AcquirePlatformBar');

  const userName = useSelector(getNameUser);
  const subdomain = useSelector(getSubdomainCompany);

  return (
    <AcquirePlatformBar 
      t={t}
      limitDays={props.limitDays}
      userName={userName}
      subdomain={subdomain}
    />
  );
};

export default AcquirePlatformBarController;
