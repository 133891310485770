import { FC } from 'react';
import CreateButton from '.';
import { CreateButtonControllerInterface } from './indexModel';
import { useMainAppContext } from '../../../../core/context/main-app-context';

const CreateButtonController: FC<CreateButtonControllerInterface> = () => {

  const { header } = useMainAppContext();

  const handleAction = () => {
    header.createButtonAction();
  }

  return (
    <CreateButton 
      text={header?.createButtonText?.toLowerCase() || ''}
      tooltip={header?.createButtonTooltip || ''}
      action={handleAction}
      disabled={!!header?.createButtonDisabled}
    />
  );
}

export default CreateButtonController;