import { FC, useEffect, useRef, useState } from 'react';
import PopupSearchFilter from './index';
import { AutomationInterface, ChatbotInterface, CustomFieldFormattedInterface, CustomFieldInterface, ErrorResponseInterface, FetchAutomationInterface, FetchChatbotInterface, FetchCustomFieldsInterface, FilterDateType, FilterFieldsType, PopupSearchFilterControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { Channels } from '../../../../../core/enums/channels';
import { IconMail, IconBrandFacebook, IconBrandInstagram, IconPhone, IconMessages, IconBrandWhatsapp, IconRobotFace, IconUserBolt, IconTicket, IconUsers, IconStar } from '@tabler/icons-react';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EmployeeInterface, FetchEmployeesInterface, FetchSectorsInterface, FetchTagsInterface, SectorInterface, TagInterface } from '../../../homeTickets2/viewTicket/components/detailsComponent/indexModel';
import { getIdUser, getProfileId } from '../../../../../store/user';
import { CustomFieldEntity, CustomFieldsType } from '../../../../../core/enums/custom-field-type';
import { SearchPagesType } from '../../sidebarAppComponent/indexModel';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { FetchMessageTriggerType, MessageTriggerType } from '../../../homeMessageTrigger2/listOfMessageTriggers/indexModel';
import { MessageTriggerStatus } from '../../../../../core/enums/message-trigger-status';
import { UserProfile } from '../../../../../core/enums/user-profile';
import { getEmployeeSectors } from '../../../../../store/employee';

const AppRequesterConst = new AppRequesterController();
const LIMIT_ITEMS_PAGINATION = 20;
const selectIconSize = 20;
const selectIconColor = '#707070';

const PopupSearchFilterController: FC<PopupSearchFilterControllerInterface> = (props) => {
  const { t } = useTranslation('PopupSearchFilter');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const popupRef = useRef<HTMLDivElement>(null);
  const loggedUserId = useSelector(getIdUser);
  const loggedUserProfile = useSelector(getProfileId);
  const loggedEmployeeSectors = useSelector(getEmployeeSectors);

  const [tab, setTab] = useState<'main' | 'custom'>('main');
  const [loading, setLoading] = useState(false);
  const [filterFields, setFilterFields] = useState<FilterFieldsType[]>([]);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  // Canal do ticket
  const [selectedChannel, setSelectedChannel] = useState<CustomSelectOptionType[]>(null);
  
  // Automação
  const [selectedAutomation, setSelectedAutomation] = useState<CustomSelectOptionType[]>(null);
  const [automationsList, setAutomationsList] = useState<AutomationInterface[]>([]);
  const [hasMoreAutomations, setHasMoreAutomations] = useState(false);
  const [isLoadingAutomationsSelect, setIsLoadingAutomationsSelect] = useState(false);

  // Chatbots
  const [selectedChatbot, setSelectedChatbot] = useState<CustomSelectOptionType[]>(null);
  const [chatbotsList, setChatbotsList] = useState<ChatbotInterface[]>([]);
  const [hasMoreChatbots, setHasMoreChatbots] = useState(false);
  const [isLoadingChatbotsSelect, setIsLoadingChatbotsSelect] = useState(false);

  // Agente
  const [selectedAgent, setSelectedAgent] = useState<CustomSelectOptionType[]>(null);
  const [agentsList, setAgentsList] = useState<EmployeeInterface[]>([]);
  const [agentsListPage, setAgentsListPage] = useState(1);
  const [hasMoreAgents, setHasMoreAgents] = useState(false);
  const [isLoadingAgentsSelect, setIsLoadingAgentsSelect] = useState(false);

  // Agente - Tarefas - Atribuído
  const [selectedAgentTaskAssigned, setSelectedAgentTaskAssigned] = useState<CustomSelectOptionType[]>(null);
  const [agentsTaskAssignedList, setAgentsTaskAssignedList] = useState<EmployeeInterface[]>([]);
  const [agentsTaskAssignedListPage, setAgentsTaskAssignedListPage] = useState(1);
  const [hasMoreAgentsTaskAssigned, setHasMoreAgentsTaskAssigned] = useState(false);
  const [isLoadingAgentsTaskAssignedSelect, setIsLoadingAgentsTaskAssignedSelect] = useState(false);

  // Status do ticket
  const [selectedStatus, setSelectedStatus] = useState<CustomSelectOptionType[]>(null);

  // Setor do ticket
  const [selectedSector, setSelectedSector] = useState<CustomSelectOptionType[]>(null);
  const [sectorsList, setSectorsList] = useState<SectorInterface[]>([]);
  const [sectorsListPage, setSectorsListPage] = useState(1);
  const [hasMoreSectors, setHasMoreSectors] = useState(false);
  const [isLoadingSectorsSelect, setIsLoadingSectorsSelect] = useState(false);

  // Tag do ticket
  const [selectedTag, setSelectedTag] = useState<CustomSelectOptionType[]>(null);
  const [tagsList, setTagsList] = useState<TagInterface[]>([]);
  const [tagsListPage, setTagsListPage] = useState(1);
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);
  
  // Tipos de disparo ativo
  const [selectedTriggerType, setSelectedTriggerType] = useState<CustomSelectOptionType[]>(null);
  const [triggerTypesList, setTriggerTypesList] = useState<MessageTriggerType[]>([]);
  const [triggerTypesListPage, setTriggerTypesListPage] = useState(1);
  const [hasMoreTriggerTypes, setHasMoreTriggerTypes] = useState(false);
  const [isLoadingTriggerTypesSelect, setIsLoadingTriggerTypesSelect] = useState(false);

  // Data
  const [selectedFilterDate, setSelectedFilterDate] = useState<CustomSelectOptionType>();
  const [selectedDate, setSelectedDate] = useState<{ start: Date, end: Date }>({ start: null, end: null });

  // Aba de campos customizados
  const [customFieldsListAll, setCustomFieldsListAll] = useState<CustomFieldInterface[]>([]);
  const [selectedCustomFieldTypeTemp, setSelectedCustomFieldTypeTemp] = useState<CustomSelectOptionType>({
    id: CustomFieldEntity.Ticket, value: t('custom_field_type_ticket'), icon: <IconTicket stroke={1.5} size={selectIconSize} color={selectIconColor}/>});
  const [selectedCustomFieldItemTemp, setSelectedCustomFieldItemTemp] = useState<CustomSelectOptionType>(null);
  const [selectedCustomFieldListFilter, setSelectedCustomFieldListFilter] = useState<CustomFieldFormattedInterface[]>([]);
  const [selectedCustomFieldListValuesObject, setSelectedCustomFieldListValuesObject] = useState<any>({});

  // Listas estáticas
  // Channels
  const channelsList = [
    { id: Channels.Email, value: t("channels.email"), icon: <IconMail stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Facebook, value: t("channels.facebook"), icon: <IconBrandFacebook stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Instagram, value: t("channels.instagram"), icon: <IconBrandInstagram stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Telefonia, value: t("channels.telephony"), icon: <IconPhone stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Webchat, value: t("channels.webchat"), icon: <IconMessages stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.WhatsApp, value: t("channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  const channelsHistoryChatbotList = [
    { id: Channels.Facebook, value: t("channels.facebook"), icon: <IconBrandFacebook stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Instagram, value: t("channels.instagram"), icon: <IconBrandInstagram stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Webchat, value: t("channels.webchat"), icon: <IconMessages stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.WhatsApp, value: t("channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  // Channels - Disparo ativo
  const channelsListMessageTrigger = [
    { id: Channels.Email, value: t("channels.email"), icon: <IconMail stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.WhatsApp, value: t("channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  // Status - Lista de Tickets
  const statusListTicket = [
    { id: TicketStatus.New, value: t('status.new') },
    { id: TicketStatus.Open, value: t('status.open') },
    { id: TicketStatus.Pending, value: t('status.pending') },
    { id: TicketStatus.OnHold, value: t('status.on_hold') }
  ];

  // Status - Histórico de Tickets
  const statusHistoryTicket = [
    { id: TicketStatus.Resolved, value: t('status.resolved') },
    { id: TicketStatus.Abandoned, value: t('status.abandoned') },
  ];

  // Status - Consumidor e agente
  const statusEntityTicket = [
    { id: TicketStatus.New, value: t('status.new') },
    { id: TicketStatus.Open, value: t('status.open') },
    { id: TicketStatus.Pending, value: t('status.pending') },
    { id: TicketStatus.OnHold, value: t('status.on_hold') },
    { id: TicketStatus.Resolved, value: t('status.resolved') },
    { id: TicketStatus.Abandoned, value: t('status.abandoned') },
  ]

  // Status - Tarefas
  const statusTask = [
    { id: TicketStatus.New, value: t('status.new') },
    { id: TicketStatus.Open, value: t('status.open') },
    { id: TicketStatus.Pending, value: t('status.pending') },
    { id: TicketStatus.OnHold, value: t('status.on_hold') },
    { id: TicketStatus.OverdueTicketTask, value: t('status.overdue') },
    { id: TicketStatus.Resolved, value: t('status.resolved') },
    { id: TicketStatus.Abandoned, value: t('status.abandoned') },
  ];

  // Status - Disparo ativo
  const statusMessageTrigger = [
    { id: MessageTriggerStatus.Scheduled, value: t('status_trigger.scheduled') },
    { id: MessageTriggerStatus.Triggered, value: t('status_trigger.triggered') },
    { id: MessageTriggerStatus.Canceled, value: t('status_trigger.canceled') },
  ];

  // Filter Date
  const filterDateList = [
    { id: 'today', value: t('filter_date.today') },
    { id: 'yesterday', value: t('filter_date.yesterday') },
    { id: 'current_week', value: t('filter_date.current_week') },
    { id: 'last_week', value: t('filter_date.last_week') },
    { id: 'current_month', value: t('filter_date.current_month') },
    { id: 'last_month', value: t('filter_date.last_month') },
    { id: 'custom', value: t('filter_date.custom') },
  ];

  // Custom Fields Type
  const customFieldListTypes: CustomSelectOptionType[] = [
    { id: CustomFieldEntity.Ticket, value: t('custom_field_type_ticket'), icon: <IconTicket stroke={1.5} size={selectIconSize} color={selectIconColor} />  },
    // { id: CustomFieldEntity.Consumer, value: t('custom_field_type_consumer'), icon: <IconUsers stroke={1.5} size={selectIconSize} color={selectIconColor} />  },
  ];

  useEffect(() => {
    if (!abortController) {
      setAbortController(new AbortController());
    }
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }
  }, []);

  useEffect(() => {
    if (props.showFilter) {
      if (props.filteredMode === 'advanced') {
        setTab('custom');
      } else {
        setTab('main');
      }
    }
  }, [props.showFilter]);

  useEffect(() => {
    loadFilterFields();
  }, [props.page]);

  useEffect(() => {
    switch (props.page) {
      case 'ticket-list':
      case 'consumer-tickets':
      case 'agent-tickets':
      case 'messages-trigger-tickets':
      case 'sector-tickets':
      case 'tag-tickets': {
        fetchFiltersTicketList();
        break;
      };
      case 'history-general': {
        fetchFiltersHistoryGeneral();
        break;
      };
      case 'history-chatbot': {
        fetchFiltersHistoryChatbot();
        break;
      };
      case 'history-telephony': {
        fetchFiltersHistoryTelephony();
        break;
      };
      case 'consumers':
      case 'tag-consumers': {
        fetchFiltersConsumers();
        break;
      };
      case 'agents': {
        fetchFiltersAgents();
        break;
      }
      case 'tasks': {
        fetchFiltersTasks();
        break;
      };
      case 'messages-trigger-schedules': {
        fetchFiltersMessageTrigger();
        break;
      }
    }
  }, []);

  useEffect(() => {
    const ticketEntityPages = [
      'consumer-tickets',
      'agent-tickets',
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];
    
    if (props.page === 'ticket-list') {
      setSelectedChannel(props.filterApplied?.selectedChannel || []);
      setSelectedAutomation(props.filterApplied?.selectedAutomation || []);
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
      setSelectedStatus(props.filterApplied?.selectedStatus || []);
      setSelectedSector(props.filterApplied?.selectedSector || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });

      if (props.filterApplied?.selectedCustomFieldListFilter?.length > 0) {
        setSelectedCustomFieldListValuesObject(props.filterApplied?.selectedCustomFieldListValuesObject || {});

        props.filterApplied.selectedCustomFieldListFilter.forEach(item => {
          item.setValue = setCustomFieldValue
        });

        setSelectedCustomFieldListFilter(props.filterApplied?.selectedCustomFieldListFilter || []);
      }
    } else if (props.page === 'history-general') {
      setSelectedChannel(props.filterApplied?.selectedChannel || []);
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
      setSelectedStatus(props.filterApplied?.selectedStatus || []);
      setSelectedSector(props.filterApplied?.selectedSector || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });

      if (props.filterApplied?.selectedCustomFieldListFilter?.length > 0) {
        setSelectedCustomFieldListValuesObject(props.filterApplied?.selectedCustomFieldListValuesObject || {});

        props.filterApplied.selectedCustomFieldListFilter.forEach(item => {
          item.setValue = setCustomFieldValue
        });

        setSelectedCustomFieldListFilter(props.filterApplied?.selectedCustomFieldListFilter || []);
      }
    } else if (props.page === 'history-chatbot') {
      setSelectedChannel(props.filterApplied?.selectedChannel || []);
      setSelectedChatbot(props.filterApplied?.selectedChatbot || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });
    } else if (props.page === 'history-telephony') {
      // setSelectedStatus(props.filterApplied?.selectedStatus || []);
      // setSelectedSector(props.filterApplied?.selectedSector || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });

      if (props.filterApplied?.selectedCustomFieldListFilter?.length > 0) {
        setSelectedCustomFieldListValuesObject(props.filterApplied?.selectedCustomFieldListValuesObject || {});

        props.filterApplied.selectedCustomFieldListFilter.forEach(item => {
          item.setValue = setCustomFieldValue
        });

        setSelectedCustomFieldListFilter(props.filterApplied?.selectedCustomFieldListFilter || []);
      }
    } else if (props.page === 'consumers') {
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
    } else if (props.page === 'agents') {
      setSelectedSector(props.filterApplied?.selectedSector || []);
    } else if (props.page === 'tag-consumers') {
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
    } else if (props.page === 'tasks') {
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
      setSelectedAgentTaskAssigned(props.filterApplied?.selectedAgentTaskAssigned || []);
      setSelectedStatus(props.filterApplied?.selectedStatus || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
    } else if (ticketEntityPages.includes(props.page)) {
      setSelectedChannel(props.filterApplied?.selectedChannel || []);
      setSelectedAutomation(props.filterApplied?.selectedAutomation || []);
      if (props.page !== 'agent-tickets') {
        setSelectedAgent(props.filterApplied?.selectedAgent || []);
      }
      setSelectedStatus(props.filterApplied?.selectedStatus || []);
      if (props.page !== 'sector-tickets') {
        setSelectedSector(props.filterApplied?.selectedSector || []);
      }
      if (props.page !== 'tag-tickets') {
        setSelectedTag(props.filterApplied?.selectedTag || []);
      }
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });
    } else if (props.page === 'messages-trigger-schedules') {
      setSelectedChannel(props.filterApplied?.selectedChannel || []);
      setSelectedTriggerType(props.filterApplied?.selectedTriggerType || []);
      setSelectedAgent(props.filterApplied?.selectedAgent || []);
      setSelectedStatus(props.filterApplied?.selectedStatus || []);
      setSelectedTag(props.filterApplied?.selectedTag || []);
      setSelectedFilterDate(props.filterApplied?.selectedFilterDate || null);
      setSelectedDate(props.filterApplied?.selectedDate || { start: null, end: null });
    }
  }, [props.filterApplied]);

  const handleFilter = (mode: 'simple' | 'advanced') => {
    switch (props.page) {
      case 'ticket-list':
      case 'consumer-tickets':
      case 'messages-trigger-tickets':
      case 'sector-tickets':
      case 'tag-tickets': {
        props.applyFilter({
          selectedChannel,
          selectedAutomation,
          selectedAgent,
          selectedStatus,
          selectedSector,
          selectedTag,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
          selectedCustomFieldListFilter,
          selectedCustomFieldListValuesObject
        }, mode);
        break;
      };
      case 'agent-tickets': {
        props.applyFilter({
          selectedChannel,
          selectedAutomation,
          selectedStatus,
          selectedSector,
          selectedTag,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
          selectedCustomFieldListFilter,
          selectedCustomFieldListValuesObject
        }, mode);
        break;
      }
      case 'history-general': {
        props.applyFilter({
          selectedChannel,
          selectedAgent,
          selectedStatus,
          selectedSector,
          selectedTag,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
          selectedCustomFieldListFilter,
          selectedCustomFieldListValuesObject
        }, mode);
        break;
      };
      case 'history-chatbot': {
        props.applyFilter({
          selectedChannel,
          selectedChatbot,
          selectedTag,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
        }, mode);
        break;
      };
      case 'history-telephony': {
        props.applyFilter({
          // selectedStatus,
          // selectedSector,
          selectedTag,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
          selectedCustomFieldListFilter,
          selectedCustomFieldListValuesObject
        }, mode);
        break;
      };
      case 'consumers': {
        props.applyFilter({
          selectedAgent,
          selectedTag
        }, mode);
        break;
      };
      case 'agents': {
        props.applyFilter({
          selectedSector
        }, mode);
        break;
      };
      case 'tag-consumers': {
        props.applyFilter({
          selectedAgent
        }, mode);
        break;
      };
      case 'tasks': {
        props.applyFilter({
          selectedAgent,
          selectedAgentTaskAssigned,
          selectedStatus,
          selectedTag
        }, mode);
        break;
      };
      case 'messages-trigger-schedules': {
        props.applyFilter({
          selectedChannel,
          selectedTriggerType,
          selectedAgent,
          selectedStatus,
          selectedFilterDate,
          selectedDate: getFormattedFilterDate(),
        }, mode);
      }
    }
  }

  const loadFilterFields = () => {
    switch (props.page) {
      case 'ticket-list':
      case 'consumer-tickets': 
      case 'messages-trigger-tickets': {
        if (loggedUserProfile === UserProfile.Employee) {
          if (loggedEmployeeSectors?.length === 1) {
            setFilterFields(['channel-ticket', 'automation', 'status-ticket', 'tag', 'date']);
          } else {
            setFilterFields(['channel-ticket', 'automation', 'status-ticket', 'sector', 'tag', 'date']);
          }
        } else {
          setFilterFields(['channel-ticket', 'automation', 'agent-ticket', 'status-ticket', 'sector', 'tag', 'date']);
        }
        break;
      };
      case 'agent-tickets': {
        if (loggedUserProfile === UserProfile.Employee && loggedEmployeeSectors?.length === 1) {
          setFilterFields(['channel-ticket', 'automation', 'status-ticket', 'tag', 'date']);
        } else {
          setFilterFields(['channel-ticket', 'automation', 'status-ticket', 'sector', 'tag', 'date']);
        }
        break;
      };
      case 'sector-tickets': {
        setFilterFields(['channel-ticket', 'automation', 'agent-ticket', 'status-ticket', 'tag', 'date']);
        break;
      };
      case 'tag-tickets': {
        if (loggedUserProfile === UserProfile.Employee && loggedEmployeeSectors?.length === 1) {
          setFilterFields(['channel-ticket', 'automation', 'agent-ticket', 'status-ticket', 'date']);
        } else {
          setFilterFields(['channel-ticket', 'automation', 'agent-ticket', 'status-ticket', 'sector', 'date']);
        }
        break;
      };
      case 'history-general': {
        // const isDeletedCounter = sessionStorage.getItem('is_deleted_counter');
        // if (isDeletedCounter === 'true') {
        //   setFilterFields(['channel-ticket', 'agent-ticket', 'sector', 'tag', 'date']);
        // } else {
        // }
        if (loggedUserProfile === UserProfile.Employee && loggedEmployeeSectors?.length === 1) {
          setFilterFields(['channel-ticket', 'agent-ticket', 'status-ticket', 'tag', 'date']);
        } else {
          setFilterFields(['channel-ticket', 'agent-ticket', 'status-ticket', 'sector', 'tag', 'date']);
        }
        break;
      };
      case 'history-chatbot': {
        setFilterFields(['channel-ticket', 'chatbot', 'tag', 'date']);
        break;
      };
      case 'history-telephony': {
        setFilterFields(['tag', 'date']);
        // if (loggedUserProfile === UserProfile.Employee && loggedEmployeeSectors?.length === 1) {
        //   setFilterFields(['status-ticket', 'tag', 'date']);
        // } else {
        //   setFilterFields(['status-ticket', 'sector', 'tag', 'date']);
        // }
        break;
      };
      case 'consumers': {
        setFilterFields(['agent-consumer-assigned', 'tag']);
        break;
      };
      case 'agents': {
        setFilterFields(['sector']);
        break;
      };
      case 'tag-consumers': {
        setFilterFields(['agent-consumer-assigned']);
        break;
      };
      case 'tasks': {
        setFilterFields(['agent-task-requester', 'agent-task-assigned', 'status-task', 'tag']);
        break;
      };
      case 'messages-trigger-schedules': {
        setFilterFields(['trigger-type', 'trigger-channel', 'trigger-requester', 'trigger-status', 'date']);
        break;
      }
    }
  }

  // Fetch all filters by page - ticket-list
  const fetchFiltersTicketList = () => {
    fetchAutomation();
    if (props.page !== 'agent-tickets' && loggedUserProfile !== UserProfile.Employee) {
      fetchAgents(false, 1);
    }
    if (loggedUserProfile !== UserProfile.Employee) {
      fetchSectors(false, 1);
    } else {
      setSectorsList(loggedEmployeeSectors.map(item => ({ id: item.sector_id, name: item.sector_name })));
    }
    fetchTags(false, 1);
    fetchCustomFields();
  }

  // Fetch all filters by page - history-general
  const fetchFiltersHistoryGeneral = () => {
    fetchAgents(false, 1);
    if (loggedUserProfile !== UserProfile.Employee) {
      fetchSectors(false, 1);
    } else {
      setSectorsList(loggedEmployeeSectors.map(item => ({ id: item.sector_id, name: item.sector_name })));
    }
    fetchTags(false, 1);
    fetchCustomFields();
  }

  // Fetch all filters by page - history-chatbot
  const fetchFiltersHistoryChatbot = () => {
    fetchChatbot();
    fetchTags(false, 1);
    // fetchCustomFields();
  }

  // Fetch all filters by page - history-telephony
  const fetchFiltersHistoryTelephony = () => {
    // if (loggedUserProfile !== UserProfile.Employee) {
    //   fetchSectors(false, 1);
    // } else {
    //   setSectorsList(loggedEmployeeSectors.map(item => ({ id: item.sector_id, name: item.sector_name })));
    // }
    fetchTags(false, 1);
    // fetchCustomFields();
  }

  // Fetch all filters by page - consumers
  const fetchFiltersConsumers = () => {
    fetchAgents(false, 1);

    if (props.page !== 'tag-consumers') {
      fetchTags(false, 1);
    }
  }

  const fetchFiltersAgents = () => {
    fetchSectors(false, 1);
  }

  // Fetch all filters by page - tasks
  const fetchFiltersTasks = async () => {
    fetchAgents(false, 1);
    fetchAgentsTaskAssigned(false, 1);
    fetchTags(false, 1);
  }

  // Fetch all filters by page - tasks
  const fetchFiltersMessageTrigger = async () => {
    fetchTriggerTypes(false, 1);
    fetchAgents(false, 1);
  }

  // ===================================
  // Request Automation

  const fetchAutomation = () => {
    const headers = UserService.getHeaders();

    const config = { headers };

    AppRequesterConst.Get('/channel/keys', config, 
      (_response: any) => {},
      (response: FetchAutomationInterface) => {
        if (response.status === 200 && response.data.channel_keys.length > 0) {
          setHasMoreAutomations(false);
          setAutomationsList(response.data.channel_keys || []);
        } else {
          setAutomationsList([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAutomationsSelect, {}
    );
  }

  // ===================================
  // Request Chatbot

  const fetchChatbot = () => {
    const headers = UserService.getHeaders();

    const config = { headers };

    AppRequesterConst.Get('/bot-cxpress', config, 
      (_response: any) => {},
      (response: FetchChatbotInterface) => {
        if (response.status === 200 && response.data.bot_cxpress.length > 0) {
          setHasMoreChatbots(false);
          setChatbotsList(response.data.bot_cxpress || []);
        } else {
          setChatbotsList([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingChatbotsSelect, {}
    );
  }

  // ===================================
  // Request Agents

  const fetchAgents = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || agentsListPage };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setAgentsListPage(newPage);

    AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreAgents(true);
          } else {
            setHasMoreAgents(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsList, ...response.data.employees];
            setAgentsList(new_array);
          } else {
            setAgentsList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setAgentsList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAgentsSelect, {}
    );
  }

  const fetchAgentsPagination = () => {
    try {
      if (hasMoreAgents) {
        const newPage = agentsListPage + 1;
        setAgentsListPage(newPage);
  
        fetchAgents(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchAgents = (term: string) => {
    if (term) {
      fetchAgents(false, 1, term);
    } else {
      fetchAgents(false, 1, '');
    }
  }

  // ===================================
  // Request Agents - Task Assigned

  const fetchAgentsTaskAssigned = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || agentsListPage };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setAgentsTaskAssignedListPage(newPage);

    AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreAgentsTaskAssigned(true);
          } else {
            setHasMoreAgentsTaskAssigned(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsTaskAssignedList, ...response.data.employees];
            setAgentsTaskAssignedList(new_array);
          } else {
            setAgentsTaskAssignedList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setAgentsTaskAssignedList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAgentsTaskAssignedSelect, {}
    );
  }

  const fetchAgentsTaskAssignedPagination = () => {
    try {
      if (hasMoreAgentsTaskAssigned) {
        const newPage = agentsTaskAssignedListPage + 1;
        setAgentsTaskAssignedListPage(newPage);
  
        fetchAgentsTaskAssigned(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchAgentsTaskAssigned = (term: string) => {
    if (term) {
      fetchAgentsTaskAssigned(false, 1, term);
    } else {
      fetchAgentsTaskAssigned(false, 1, '');
    }
  }

  // ===================================
  // Request Sectors

  const fetchSectors = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || sectorsListPage };
    const signal = abortController?.signal;
    
    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setSectorsListPage(newPage);

    const apiRoute = props.page === 'agents' ? '/sector' : '/sector/actives';

    AppRequesterConst.Get(apiRoute, config, 
      (_response: any) => {},
      (response: FetchSectorsInterface) => {
        if (response.status === 200 && response.data.sectors.length > 0) {
          if (response.data.sectors.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreSectors(true);
          } else {
            setHasMoreSectors(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...sectorsList, ...response.data.sectors];
            setSectorsList(new_array);
          } else {
            setSectorsList(response.data.sectors || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setSectorsList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingSectorsSelect, {}
    );
  }

  const fetchSectorsPagination = () => {
    if (hasMoreSectors) {
      const newPage = sectorsListPage + 1;
      setSectorsListPage(newPage);

      fetchSectors(true, newPage);
    }
  }

  const fetchSearchSectors = (term: string) => {
    if (term) {
      fetchSectors(false, 1, term);
    } else {
      fetchSectors(false, 1, '');
    }
  }

  // ===================================
  // Request Tags

  const fetchTags = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || tagsListPage };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setTagsListPage(newPage);

    const routePath = props.page === 'consumers' ? '/consumer-tag' : '/tag';

    AppRequesterConst.Get(routePath, config, 
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTags(true);
          } else {
            setHasMoreTags(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...tagsList, ...response.data.tags];
            setTagsList(new_array);
          } else {
            setTagsList(response.data.tags || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTagsList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingTagsSelect, {}
    );
  }

  const fetchTagsPagination = () => {
    if (hasMoreTags) {
      const newPage = tagsListPage + 1;
      setTagsListPage(newPage);

      fetchTags(true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  // ===================================
  // Request Trigger Types

  const fetchTriggerTypes = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || triggerTypesListPage, active: true };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setTriggerTypesListPage(newPage);

    AppRequesterConst.Get('message-sending-type', config, 
      (_response: any) => {},
      (response: FetchMessageTriggerType) => {
        if (response.status === 200 && response.data.messageSendingTypes.length > 0) {
          if (response.data.messageSendingTypes.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTriggerTypes(true);
          } else {
            setHasMoreTriggerTypes(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...triggerTypesList, ...response.data.messageSendingTypes];
            setTriggerTypesList(new_array);
          } else {
            setTriggerTypesList(response.data.messageSendingTypes || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setTriggerTypesList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingTriggerTypesSelect, {}
    );
  }

  const fetchTriggerTypesPagination = () => {
    if (hasMoreTriggerTypes) {
      const newPage = triggerTypesListPage + 1;
      setTriggerTypesListPage(newPage);

      fetchTriggerTypes(true, newPage);
    }
  }

  const fetchSearchTriggerTypes = (term: string) => {
    if (term) {
      fetchTriggerTypes(false, 1, term);
    } else {
      fetchTriggerTypes(false, 1, '');
    }
  }

  // ===================================
  // Request Custom Fields

  const fetchCustomFields = () => {
    const headers = UserService.getHeaders();
    const signal = abortController?.signal;

    const config = { headers, signal };

    AppRequesterConst.Get('/custom-fields', config, 
      (_response: any) => {},
      (response: FetchCustomFieldsInterface) => {
        if (response.status === 200 && response.data.custom_fields.length > 0) {
          setHasMoreAutomations(false);
          setCustomFieldsListAll(response.data.custom_fields || []);
        } else {
          setCustomFieldsListAll([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAutomationsSelect, {}
    );
  }

  const handleAddCustomField = () => {
    if (selectedCustomFieldTypeTemp && selectedCustomFieldItemTemp) {
      const customField = customFieldsListAll.find(item => item.id === selectedCustomFieldItemTemp.id);

      if (customField) {
        const itemFieldFormatted: CustomFieldFormattedInterface = {
          id: customField.id,
          name: customField.name,
          placeholder: customField.placeholder,
          options: formatCustomFieldOptions(customField.custom_field_type_id, customField.options),
          entity_type_id: customField.entity_type_id,
          custom_field_type_id: customField.custom_field_type_id,
          custom_field_datatype_id: customField.custom_field_datatype_id,
          value: '',
          setValue: setCustomFieldValue
        }

        setSelectedCustomFieldListFilter(oldValues => ([...oldValues, itemFieldFormatted]));
        setSelectedCustomFieldListValuesObject((oldValues: any) => ({
          ...oldValues,
          [itemFieldFormatted.id]: ''
        }));

        setSelectedCustomFieldItemTemp(null);
      }
    }
  }

  const handleRemoveCustomField = (custom_field_id: string) => {
    const customFieldIndex = selectedCustomFieldListFilter.findIndex(item => item.id === custom_field_id);

    if (customFieldIndex >= 0) {
      const tempSelectedCustomFieldListFilter = [...selectedCustomFieldListFilter];
      tempSelectedCustomFieldListFilter.splice(customFieldIndex, 1);
      setSelectedCustomFieldListFilter(tempSelectedCustomFieldListFilter);

      if (selectedCustomFieldListValuesObject[custom_field_id]) {
        const tempSelectedCustomFieldListValuesObject = { ...selectedCustomFieldListValuesObject };
        delete tempSelectedCustomFieldListValuesObject[custom_field_id];
        setSelectedCustomFieldListValuesObject(tempSelectedCustomFieldListValuesObject);
      }
    }
  }

  const handleClearFilter = () => {
    const ticketEntityPages = [
      'ticket-list',
      'consumer-tickets',
      'agent-tickets',
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];

    if (ticketEntityPages.includes(props.page)) {
      setSelectedChannel([]);
      setSelectedAutomation([]);
      setSelectedAgent([]);
      setSelectedStatus([]);
      setSelectedSector([]);
      setSelectedTag([]);
      setSelectedFilterDate(null);
      setSelectedDate({ start: null, end: null });
      setSelectedCustomFieldListValuesObject({});
      setSelectedCustomFieldListFilter([]);
      props.applyFilter({});
    } else if (props.page === 'history-general' || props.page === 'history-telephony') {
      // setSelectedChannel([]);
      // setSelectedAgent([]);
      // setSelectedStatus([]);
      // setSelectedSector([]);
      setSelectedTag([]);
      setSelectedFilterDate(null);
      setSelectedDate({ start: null, end: null });
      setSelectedCustomFieldListValuesObject({});
      setSelectedCustomFieldListFilter([]);
      props.applyFilter({});
    } else if (props.page === 'history-chatbot') {
      setSelectedChannel([]);
      setSelectedChatbot([]);
      setSelectedTag([]);
      setSelectedFilterDate(null);
      setSelectedDate({ start: null, end: null });
      props.applyFilter({});
    } else if (props.page === 'consumers' || props.page === 'tag-consumers') {
      setSelectedAgent([]);
      if (props.page !== 'tag-consumers') {
        setSelectedTag([]);
      }
      props.applyFilter({});
    } else if (props.page === 'agents') {
      setSelectedSector([]);
      props.applyFilter({});
    } else if (props.page === 'tasks') {
      setSelectedAgent([]);
      setSelectedAgentTaskAssigned([]);
      setSelectedStatus([]);
      setSelectedTag([]);
      props.applyFilter({});
    } else if (props.page === 'messages-trigger-schedules') {
      setSelectedChannel([]);
      setSelectedTriggerType([]);
      setSelectedAgent([]);
      setSelectedStatus([]);
      setSelectedFilterDate(null);
      setSelectedDate({ start: null, end: null });
      props.applyFilter({});
    } else if (props.page === 'messages-trigger-types') {
      props.applyFilter({});
    } else if (props.page === 'sectors') {
      props.applyFilter({});
    } else if (props.page === 'tags-ticket' || props.page === 'tags-consumer') {
      props.applyFilter({});
    }
  }

  const formatCustomFieldOptions = (custom_field_type_id: string, options: string[]) => {
    const selectType = [CustomFieldsType.Dropdown.toString(), CustomFieldsType.DropdownMultiselect.toString()];
    if (selectType.includes(custom_field_type_id) && options) {
      return formatDataSelect(options, 'custom-field-options');
    } else {
      return null;
    }
  }

  const setCustomFieldValue = (custom_field_id: string, value: any) => {
    setSelectedCustomFieldListValuesObject((prevValues) => ({
      ...prevValues,
      [custom_field_id]: value
    }));
  }

  const getCustomFieldValue = (custom_field_id: string) => {
    return selectedCustomFieldListValuesObject[custom_field_id];
  }

  const getStatusListType = (page: SearchPagesType) => {
    const ticketEntityPages = [
      'history-telephony',
      'consumer-tickets',
      'agent-tickets',
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets',
    ];

    if (page === 'ticket-list') {
      return statusListTicket;
    } else if (ticketEntityPages.includes(page)) {
      return statusEntityTicket;
    } else if (page === 'history-general' || page === 'history-chatbot') {
      return statusHistoryTicket;
    } else if (page === 'tasks') {
      return statusTask;
    } else if (page === 'messages-trigger-schedules') {
      return statusMessageTrigger;
    }
  }

  const getChannelsListType = (page: SearchPagesType) => {
    if (page === 'messages-trigger-schedules') {
      return channelsListMessageTrigger;
    } else if (page === 'history-chatbot') {
      return channelsHistoryChatbotList;
    } else {
      return channelsList;
    }
  }

  // =================================================================================================== //

  const onSelectDate = (dates: [ Date, Date ]) => {
    const [start, end] = dates;
    setSelectedDate({ start, end });
  }

  // =================================================================================================== //

  const getChannelSelect = () => {
    return {
      channelsList: getChannelsListType(props.page),
      selectedChannel,
      setSelectedChannel,
    }
  }

  const getAutomationsSelect = () => {
    return {
      automationsList: formatDataSelect(automationsList, 'automation'),
      selectedAutomation,
      setSelectedAutomation,
      isLoadingAutomationsSelect,
      hasMoreAutomations,
    };
  }

  const getChatbotsSelect = () => {
    return {
      chatbotsList: formatDataSelect(chatbotsList, 'chatbot'),
      selectedChatbot,
      setSelectedChatbot,
      isLoadingChatbotsSelect,
      hasMoreChatbots,
    };
  }

  const getAgentsSelect = () => {
    return {
      agentsList: formatDataSelect(agentsList, 'agent'),
      selectedAgent,
      setSelectedAgent,
      isLoadingAgentsSelect,
      fetchAgentsPagination,
      fetchSearchAgents,
      hasMoreAgents,
    };
  }

  const getAgentsTaskAssignedSelect = () => {
    return {
      agentsTaskAssignedList: formatDataSelect(agentsTaskAssignedList, 'agent'),
      selectedAgentTaskAssigned,
      setSelectedAgentTaskAssigned,
      isLoadingAgentsTaskAssignedSelect,
      fetchAgentsTaskAssignedPagination,
      fetchSearchAgentsTaskAssigned,
      hasMoreAgentsTaskAssigned,
    };
  }

  const getStatusSelect = () => {
    return {
      statusList: getStatusListType(props.page),
      selectedStatus,
      setSelectedStatus,
    }
  }

  const getSectorsSelect = () => {
    return {
      sectorsList: formatDataSelect(sectorsList, 'sector'),
      selectedSector,
      setSelectedSector,
      isLoadingSectorsSelect,
      fetchSectorsPagination,
      fetchSearchSectors,
      hasMoreSectors,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList: formatDataSelect(tagsList, 'tag'),
      selectedTag,
      setSelectedTag,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTags,
    };
  }

  const getTriggerTypesSelect = () => {
    return {
      triggerTypesList: formatDataSelect(triggerTypesList, 'trigger-type'),
      selectedTriggerType,
      setSelectedTriggerType,
      isLoadingTriggerTypesSelect,
      fetchTriggerTypesPagination,
      fetchSearchTriggerTypes,
      hasMoreTriggerTypes,
    };
  }

  const getFilterDateSelect = () => {
    return {
      filterDateList,
      selectedFilterDate,
      setSelectedFilterDate
    }
  }

  const getDateInput = () => {
    return {
      selectedDate,
      onSelectDate,
    }
  }

  const getCustomFieldsData = () => {
    return {
      customFieldListTypes,
      customFieldsListAll: formatDataSelect(customFieldsListAll, 'custom-field'),
      hasCustomField: customFieldsListAll.length > 0,
      selectedCustomFieldTypeTemp, setSelectedCustomFieldTypeTemp,
      selectedCustomFieldItemTemp, setSelectedCustomFieldItemTemp,
      handleAddCustomField,
      handleRemoveCustomField,
      selectedCustomFieldListFilter, 
      setSelectedCustomFieldListFilter,
      selectedCustomFieldListValuesObject,
      getCustomFieldValue,
      isValidCustomFieldsFilter,
    }
  }

  // =================================================================================================== //

  const formatDataSelect = (data: any[], type: 'consumer' | 'agent' | 'sector' | 'automation' | 'tag' | 'custom-field' | 'custom-field-options' | 'chatbot' | 'trigger-type'): CustomSelectOptionType[] => {
    const formatted: CustomSelectOptionType[] = [];

    const filterWithNA = ['sector'];
    
    const pageFilterWithoutNA = [
      'consumer-tickets',
      'agent-tickets',
      'messages-trigger-tickets',
      'sector-tickets',
      'tag-tickets'
    ];

    if (filterWithNA.includes(type) && !pageFilterWithoutNA.includes(props.page)) {
      formatted.push({ 
        id: 'NA', 
        value: 'N/A', 
        // icon: <IconStar size={16} style={{ minWidth: 16, minHeight: 16}} color="#4a69bd" />,
        // iconPosition: 'end',
        isOptionSystem: true
      });
    }

    if (type === 'automation') {
      (data as AutomationInterface[]).forEach(item => {
        const dataOption: CustomSelectOptionType = { id: item.id, value: item.name, iconPosition: 'start' };

        const icons = { botIcon: null, typeIcon: null};

        const botIcon = (
          <OverlayTrigger placement='top' overlay={<Tooltip>{t('automation_has_bot')}</Tooltip>}>
            <IconRobotFace stroke={1.5} size={selectIconSize} color={selectIconColor} />
          </OverlayTrigger>
        );

        if (item.facebook_key) {
          icons.typeIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('channels.facebook')}</Tooltip>}>
              <IconBrandFacebook stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          if (item.facebook_key.has_bot) {
            icons.botIcon = botIcon;
          }
        } else if (item.instagram_key) {
          icons.typeIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('channels.instagram')}</Tooltip>}>
              <IconBrandInstagram stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          if (item.instagram_key.has_bot) {
            icons.botIcon = botIcon;
          }
        } else if (item.webchat_key) {
          icons.typeIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('channels.webchat')}</Tooltip>}>
              <IconMessages stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          if (item.webchat_key.has_bot) {
            icons.botIcon = botIcon;
          }
        } else if (item.whatsapp_key) {
          icons.typeIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('channels.whatsapp')}</Tooltip>}>
              <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          if (item.whatsapp_key.has_bot) {
            icons.botIcon = botIcon;
          }
        }

        if (icons.botIcon) {
          dataOption['icon'] = <>{icons.botIcon}{icons.typeIcon}</>;
        } else {
          dataOption['icon'] = <>{icons.typeIcon}</>;
        }

        if (!dataOption.value) {
          dataOption.value = t('automation_no_name');
        }

        formatted.push(dataOption);
      });
    } else if (type === 'custom-field') {
      const alreadySelected = selectedCustomFieldListFilter?.map(item => item.id) || [];
      (data as CustomFieldInterface[]).filter(item => item.entity_type_id === selectedCustomFieldTypeTemp?.id && !alreadySelected.includes(item.id)).forEach(item => {
        formatted.push({ id: item.id, value: item.name });
      });
    } else {
      data.forEach(item => {
        if (type === 'consumer' || type === 'agent') {
          const dataOption = { id: item.user_id, value: item.name };
  
          if (item.user_id === loggedUserId) {
            dataOption['icon'] = (
              <OverlayTrigger placement='right' overlay={<Tooltip>{t('agent_logged_user')}</Tooltip>}>
                <IconUserBolt stroke={1.5} size={selectIconSize} color={selectIconColor} />
              </OverlayTrigger>
            );
            
            dataOption['iconPosition'] = 'end';
          }
  
          formatted.push(dataOption);
        } else if (type === 'sector' || type === 'tag' || type === 'trigger-type') {
          if (props.page === 'agents') {
            formatted.push({ id: item.sector_id, value: item.sector_name });
          } else {
            formatted.push({ id: item.id, value: item.name });
          }
        } else if (type === 'custom-field-options') {
          formatted.push({ id: item, value: item });
        } else if (type === 'chatbot') {
          formatted.push({ id: item.id, value: item.bot_name });
        }
      });
    }

    return formatted;
  }

  const isValidCustomFieldsFilter = () => {
    let isValid = true;

    if (Object.keys(selectedCustomFieldListValuesObject || {}).length > 0) {
      Object.keys(selectedCustomFieldListValuesObject).forEach(key => {
        if (!selectedCustomFieldListValuesObject[key]) {
          isValid = false;
        }
      });
    }

    return isValid;
  }

  const getFormattedFilterDate = () => {
    const period = selectedFilterDate?.id as FilterDateType;
    const filter: { start: Date, end: Date } = { start: null, end: null };

    if (period === 'today') {
      filter.start = new Date();
      filter.end = new Date(filter.start);
    } else if (period === 'yesterday') {
      filter.start = new Date();
      filter.start.setDate(filter.start.getDate() - 1);
      filter.end = new Date(filter.start);
    } else if (period === 'current_week') {
      filter.end = new Date();
      filter.start = new Date();
      filter.start.setDate(filter.start.getDate() - filter.end.getDay());
    } else if (period === 'last_week') {
      const today = new Date();
      filter.end = new Date();
      filter.end.setDate(filter.end.getDate() - today.getDay() - 1);
      filter.start = new Date(filter.end);
      filter.start.setDate(filter.end.getDate() - 6);
    } else if (period === 'current_month') {
      filter.end = new Date();
      filter.start = new Date();
      filter.start.setDate(1);
    } else if (period === 'last_month') {
      filter.end = new Date();
      filter.end.setDate(0);
      filter.start = new Date();
      filter.start.setDate(0);
      filter.start.setDate(1);
    } else {
      filter.start = selectedDate.start;
      filter.end = selectedDate.end;
    }

    if (filter.start && !filter.end) {
      filter.end = new Date(filter.start);
    }

    if (filter.start) {
      filter.start.setHours(0, 0, 0, 0);
    }

    if (filter.end) {
      filter.end.setHours(0, 0, 0, 0);
    }

    return filter;
  }

  return (
    <PopupSearchFilter
      t={t}
      page={props.page}
      popupRef={popupRef}
      tab={tab}
      setTab={setTab}
      loading={loading}
      isSimpleEmployee={loggedUserProfile === UserProfile.Employee}
      handleFilter={handleFilter}
      handleClearFilter={handleClearFilter}
      isSearched={props.isSearched}
      filteredMode={props.filteredMode}
      filterFields={filterFields}
      getChannelSelect={getChannelSelect}
      getAutomationsSelect={getAutomationsSelect}
      getChatbotsSelect={getChatbotsSelect}
      getAgentsSelect={getAgentsSelect}
      getAgentsTaskAssignedSelect={getAgentsTaskAssignedSelect}
      getStatusSelect={getStatusSelect}
      getSectorsSelect={getSectorsSelect}
      getTagsSelect={getTagsSelect}
      getTriggerTypesSelect={getTriggerTypesSelect}
      getFilterDateSelect={getFilterDateSelect}
      getDateInput={getDateInput}
      getCustomFieldsData={getCustomFieldsData}
    />
  );
};

export default PopupSearchFilterController;
