import './styles.scss';
import { FC } from 'react';
import { ListOfTasksInterface } from './indexModel';
import FilteredViewBarController from '../../components-fixed/searchComponent/filteredViewBar/indexController';
import CustomTableListController from '../../../../components/customTableList/indexController';
import ModalDeleteTaskController from '../components/modalDeleteTask/indexController';
import ModalListTasksController from '../components/modalListTasks/indexController';
import { IconCheckupList } from '@tabler/icons-react';

const ListOfTasks: FC<ListOfTasksInterface> = (props) => {
  return (
    <div className="list-tasks-component">
      { props.showModalTask && <ModalListTasksController show={props.showModalTask} hide={props.closeModalTask} /> }
      { props.showModalDeleteTask && <ModalDeleteTaskController show={props.showModalDeleteTask} hide={props.closeModalDeleteTask} /> }

      <div className="list-tasks-page-info">
        { props.filters?.length > 0 && 
          <FilteredViewBarController pageType='tasks' filters={props.filters} filterMode="simple" clearSearch={props.clearSearchTasks} clearSpecificFilter={props.clearSpecificFilter} />
        }
      </div>
      <div className="list-tasks-page-content">
        <CustomTableListController 
          component='tasks'
          text_empty_list={props.t('no_tasks')}
          text_loading_list={props.t('loading_tasks')}
          text_error_list={props.t('error_loading_tasks')}
          icon_entity_list={<IconCheckupList className='tabler-icon-entity' />}
          headers={props.listHeaders}
          data={props.listData}
          paginationDetails={props.paginationDetails}
          fetchMore={props.getTasksPagination}
          isLoading={props.isLoading}
          errorLoading={props.errorLoading}
          popoverItemAction={props.popoverItemAction}
          reloadData={props.reloadTasks}
          handleItemClick={props.openModalEditTask}
        />
      </div>
    </div>
  );
};

export default ListOfTasks;
