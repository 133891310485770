import './styles.scss';
import { FC } from 'react';
import { ToolbarAudioRecorderInterface } from './indexModel';
import { IconMicrophone, IconPaperclip, IconTrash } from '@tabler/icons-react';
import { formatTime } from '../../../messagesComponent/components/audioPlayer/indexModel';
import AudioPlayerController from '../../../messagesComponent/components/audioPlayer/indexController';

const ToolbarAudioRecorder: FC<ToolbarAudioRecorderInterface> = (props) => {
  return (
    <div className="toolbar-audio-recorder-component" ref={props.audioRecorderRef}>
      { !props.hasPermission && 
        <span className="toolbar-audio-recorder-permission-denied">
          <span className="toolbar-audio-recorder-permission-denied-title">{props.t('no_microphone_permission')}</span> 
          <span className="toolbar-audio-recorder-permission-denied-description">
          {props.t('grant_microphone_permission')}
          </span>
        </span>
      }
      { props.hasPermission &&
        <div className='toolbar-audio-recorder-container'>
          { !props.isRecording && !props.audioURL &&
            <button onClick={props.startRecording} className="toolbar-audio-recorder-action-start">{props.t('start_recording')}</button>
          }
          {
            props.isRecording &&
            <div className="toolbar-audio-recorder-container-recording">
              <span className="toolbar-audio-recorder-container-recording-time">
                <span className="toolbar-audio-recorder-icon">
                  <IconMicrophone />
                </span>
                <span className="toolbar-audio-recorder-current-time">
                  {formatTime(props.recordingTime)}
                </span>
              </span>
              <button onClick={props.stopRecording} className="toolbar-audio-recorder-action-stop">{props.t('stop_recording')}</button>
            </div>
          }
          {props.audioURL && (
            <div className='toolbar-audio-recorder-player-container'>
              <div className="toolbar-audio-recorder-player-controller">
                <AudioPlayerController 
                  audioUrl={props.audioURL} 
                  filename={props.currentAudioInfo?.name || 'audio.aac'} 
                  currentUrlPlaying={props.audioURL} 
                  setCurrentAudioPlayingUrl={props.setCurrentAudioPlayingUrl}
                  duration={props.currentAudioInfo?.recordingTime || props.recordingTime}
                />
              </div>
              <div className="toolbar-audio-recorder-player-actions">
                <button onClick={props.attachRecording} className="toolbar-audio-recorder-player-attach">
                  <IconPaperclip />
                  <span>{props.t('attach')}</span>
                </button>
                <button onClick={props.discardRecording} className="toolbar-audio-recorder-player-remove">
                  <IconTrash />
                  <span>{props.t('discard')}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default ToolbarAudioRecorder;
