import './styles.scss';
import { FC } from 'react';
import { ListOfSectorsInterface } from './indexModel';
import FilteredViewBarController from '../../../components-fixed/searchComponent/filteredViewBar/indexController';
import CustomTableListController from '../../../../../components/customTableList/indexController';
import { IconSitemap } from '@tabler/icons-react';
import ModalSectorController from '../components/modalSector/indexController';
import ModalChangeStatusSectorController from '../components/modalChangeStatusSector/indexController';

const ListOfSectors: FC<ListOfSectorsInterface> = (props) => {
  return (
    <div className="list-sectors-component">
      { props.showModalSector && <ModalSectorController show={props.showModalSector} hide={props.closeModalSector} />}
      { props.showModalChangeStatusSector && <ModalChangeStatusSectorController show={props.showModalChangeStatusSector} hide={props.closeModalChangeStatusSector} /> }

      <div className="list-sectors-page-info">
        { props.filters?.length > 0 &&
          <FilteredViewBarController 
            pageType='sectors'
            filters={props.filters}
            filterMode="simple"
            clearSearch={props.clearSearchSectors}
            clearSpecificFilter={props.clearSpecificFilter}
          />
        }
      </div>
      <div className="list-sectors-page-content">
        <CustomTableListController 
          component='sectors'
          text_empty_list={props.t('no_sector')}
          text_loading_list={props.t('loading_sector')}
          text_error_list={props.t('error_loading_sector')}
          icon_entity_list={<IconSitemap className='tabler-icon-entity' />}
          headers={props.listHeaders}
          data={props.listData}
          paginationDetails={props.paginationDetails}
          fetchMore={props.getSectorsPagination}
          isLoading={props.isLoading}
          errorLoading={props.errorLoading}
          popoverItemAction={props.popoverItemAction}
          reloadData={props.reloadSectors}
          handleItemClick={props.openModalSector}
          itemWrap={true}
        />
      </div>
    </div>
  );
};

export default ListOfSectors;
