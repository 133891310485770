import './styles.scss';
import { FC } from 'react';
import { ModalCancelActiveTriggerInterface } from './indexModel';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';

const ModalCancelActiveTrigger: FC<ModalCancelActiveTriggerInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      size="sm"
      centered={true}
      dialogClassName="modal-cancel-active-trigger-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.t('title')}
        </Modal.Title>
        <div className="modal-cancel-active-trigger-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingDelete) && <Loading blur={true}/>}
        { props.data &&
          <div className="modal-cancel-active-trigger-info">
            <span className="modal-cancel-active-trigger-info-message">
              {props.t('message')}
            </span>
            <div className="modal-cancel-active-trigger-info-fields">
              <div className="modal-cancel-active-trigger-info-fields-item">
                <span className="modal-cancel-active-trigger-info-fields-item-label">
                  {props.t('labels.title')}
                </span>
                <span className="modal-cancel-active-trigger-info-fields-item-value">
                  {props.data.title}
                </span>
              </div>
              <div className="modal-cancel-active-trigger-info-fields-row">
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.type')}
                  </span>
                  <span className="modal-cancel-active-trigger-info-fields-item-value">
                    {props.data.type}
                  </span>
                </div>
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.channel')}
                  </span>
                  <span className="modal-cancel-active-trigger-info-fields-item-value">
                    {props.getChannel(props.data.channel_id).icon}
                    {props.getChannel(props.data.channel_id).title}
                  </span>
                </div>
              </div>
              <div className="modal-cancel-active-trigger-info-fields-row">
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.employee')}
                  </span>
                  <OverlayTrigger overlay={<Tooltip>{props.data.employee}</Tooltip>}>
                    <span className="modal-cancel-active-trigger-info-fields-item-value">
                      {props.data.employee}
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.recipients')}
                  </span>
                  <span className="modal-cancel-active-trigger-info-fields-item-value">
                    {props.data.number_of_recipients}
                  </span>
                </div>
              </div>
              <div className="modal-cancel-active-trigger-info-fields-row">
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.date_scheduled')}
                  </span>
                  <span className="modal-cancel-active-trigger-info-fields-item-value">
                    {props.formatDateScheduled(props.data.date_scheduled)}
                  </span>
                </div>
                <div className="modal-cancel-active-trigger-info-fields-item">
                  <span className="modal-cancel-active-trigger-info-fields-item-label">
                    {props.t('labels.status')}
                  </span>
                  <span className="modal-cancel-active-trigger-info-fields-item-value">
                    {props.getStatusName(props.data.status_id)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-cancel-active-trigger-btn-close' onClick={() => props.hide(false)}>{props.t('btn_close')}</button>
        <button className='modal-cancel-active-trigger-btn-cancel' disabled={props.loadingDelete} onClick={() => props.handleSubmit()}>{props.t('btn_cancel')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCancelActiveTrigger;
