import { FC, useEffect, useState } from 'react';
import ModalSector from './index';
import { I_FetchSectorDetails, I_FetchSectorEmails, I_FetchSectorInstaFaceKeys, I_FetchSectorWhatsappKeys, I_SectorEmail, I_SectorInstaFaceKeys, I_SectorPost, I_WhatsappKeys, ModalSectorControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { I_SectorDetails } from './indexModel';
import emitter from '../../../../../../core/shared/emitter';
import UserService from '../../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../../store/internal';
import { CustomSelectOptionType } from '../../../../../../components/customSelect/indexModel';
import { EmployeeInterface, FetchEmployeesInterface } from '../../../../homeAgents2/listOfAgents/indexModel';
import { ErrorResponseInterface } from '../../../../components-fixed/searchComponent/popupSearchFilter/indexModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getIdUser } from '../../../../../../store/user';
import { IconAlertCircle, IconCircleCheckFilled, IconRobotFace, IconUserBolt } from '@tabler/icons-react';

const AppRequesterConst = new AppRequesterController();

const LIMIT_ITEMS_PAGINATION = 20;
const selectIconSize = 20;
const selectIconColor = '#707070';

const ModalSectorController: FC<ModalSectorControllerInterface> = (props) => {
  const { t } = useTranslation('ModalSector');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedUserId = useSelector(getIdUser);

  const [data, setData] = useState<{ id: string, name?: string }>(null);
  const [sector, setSector] = useState<I_SectorDetails>(null);
  const [mode, setMode] = useState<'create' | 'edit'>(null);
  const [loadingSector, setLoadingSector] = useState(false);
  const [errorLoadingSector, setErrorLoadingSector] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  // Form states
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<boolean>(true);
  const [keywords, setKeywords] = useState<string[]>([]);

  // Agents
  const [selectedAgents, setSelectedAgents] = useState<CustomSelectOptionType[]>(null);
  const [agentsList, setAgentsList] = useState<EmployeeInterface[]>([]);
  const [agentsListPage, setAgentsListPage] = useState(1);
  const [hasMoreAgents, setHasMoreAgents] = useState(false);
  const [isLoadingAgentsSelect, setIsLoadingAgentsSelect] = useState(false);

  // Emails
  const [selectedEmails, setSelectedEmails] = useState<CustomSelectOptionType[]>(null);
  const [emailsList, setEmailsList] = useState<I_SectorEmail[]>([]);
  const [emailsListPage, setEmailsListPage] = useState(1);
  const [hasMoreEmails, setHasMoreEmails] = useState(false);
  const [isLoadingEmailsSelect, setIsLoadingEmailsSelect] = useState(false);
  const [hasEmailInUse, setHasEmailInUse] = useState<boolean>();

  // Whatsapp Keys
  const [selectedWhatsappKeys, setSelectedWhatsappKeys] = useState<CustomSelectOptionType[]>(null);
  const [whatsappKeysList, setWhatsappKeysList] = useState<I_WhatsappKeys[]>([]);
  const [whatsappKeysListPage, setWhatsappKeysListPage] = useState(1);
  const [hasMoreWhatsappKeys, setHasMoreWhatsappKeys] = useState(false);
  const [isLoadingWhatsappKeysSelect, setIsLoadingWhatsappKeysSelect] = useState(false);
  const [hasWhatsappKeyInUse, setHasWhatsappKeyInUse] = useState<boolean>();

  // Instagram Keys
  const [selectedInstagramKeys, setSelectedInstagramKeys] = useState<CustomSelectOptionType[]>(null);
  const [instagramKeysList, setInstagramKeysList] = useState<I_SectorInstaFaceKeys[]>([]);
  const [instagramKeysListPage, setInstagramKeysListPage] = useState(1);
  const [hasMoreInstagramKeys, setHasMoreInstagramKeys] = useState(false);
  const [isLoadingInstagramKeysSelect, setIsLoadingInstagramKeysSelect] = useState(false);
  const [hasInstagramKeyInUse, setHasInstagramKeyInUse] = useState<boolean>();

  // Facebook Keys
  const [selectedFacebookKeys, setSelectedFacebookKeys] = useState<CustomSelectOptionType[]>(null);
  const [facebookKeysList, setFacebookKeysList] = useState<I_SectorInstaFaceKeys[]>([]);
  const [facebookKeysListPage, setFacebookKeysListPage] = useState(1);
  const [hasMoreFacebookKeys, setHasMoreFacebookKeys] = useState(false);
  const [isLoadingFacebookKeysSelect, setIsLoadingFacebookKeysSelect] = useState(false);
  const [hasFacebookKeyInUse, setHasFacebookKeyInUse] = useState<boolean>();


  useEffect(() => {
    emitter.on('open-modal-sector', data => {
      if (data?.id) {
        setMode('edit');
        setData({ id: data.id, name: data.name });

        fetchSector(data.id).then(sector => {
          setSector(sector);

          setName(sector.sector_name || '');
          setKeywords(sector.keyword || []);
          setStatus(!!sector.active);

          setSelectedAgents(sector.agents?.map(item => ({ id: item.user_id, value: item.name })) || []);
          setSelectedEmails(sector.emails?.map(item => ({ id: item.id, value: item.email })) || []);
          setSelectedWhatsappKeys(sector.whatsapp_keys?.map(item => ({ id: item.id, value: `${item.name} (${item.phone_number})` })) || []);
          setSelectedInstagramKeys(sector.instagram_keys?.map(item => ({ id: item.id, value: item.name })) || []);
          setSelectedFacebookKeys(sector.facebook_keys?.map(item => ({ id: item.id, value: item.name })) || []);
        }).catch(error => {
          dispatch(setShowAlertFeedback({ message: t('alert.error_loading'), visibility: true, signalIcon: false }));
        });
      } else {
        setMode('create');
        setName(data?.name || '');
      }
    });

    return () => {
      emitter.off('open-modal-sector');
    }
  }, []);

  useEffect(() => {
    fetchAgents(false, 1);
    fetchEmails(false, 1);
    fetchWhatsappKeys(false, 1);
    fetchInstagramKeys(false, 1);
    fetchFacebookKeys(false, 1);
  }, []);

  useEffect(() => {
    if (selectedEmails) {
      const selectedIds = selectedEmails.map(item => item.id);
      const selectedInfo = emailsList.filter(item => selectedIds.includes(item.id));
      let inUse = false;
      for (let i = 0; i < selectedInfo.length; i++) {
        if (selectedInfo[i].sector?.id && (!sector?.sector_id || selectedInfo[i].sector?.id !== sector?.sector_id)) {
          inUse = true;
          break;
        }
      }
      setHasEmailInUse(inUse);
    }
  }, [selectedEmails, emailsList]);

  useEffect(() => {
    if (selectedWhatsappKeys) {
      const selectedIds = selectedWhatsappKeys.map(item => item.id);
      const selectedInfo = whatsappKeysList.filter(item => selectedIds.includes(item.id));
      let inUse = false;
      for (let i = 0; i < selectedInfo.length; i++) {
        if (selectedInfo[i].sector?.id && (!sector?.sector_id || selectedInfo[i].sector?.id !== sector?.sector_id)) {
          inUse = true;
          break;
        }
      }
      setHasWhatsappKeyInUse(inUse);
    }
  }, [selectedWhatsappKeys, whatsappKeysList]);

  useEffect(() => {
    if (selectedInstagramKeys) {
      const selectedIds = selectedInstagramKeys.map(item => item.id);
      const selectedInfo = instagramKeysList.filter(item => selectedIds.includes(item.id));
      let inUse = false;
      for (let i = 0; i < selectedInfo.length; i++) {
        if (selectedInfo[i].sector?.id && (!sector?.sector_id || selectedInfo[i].sector?.id !== sector?.sector_id)) {
          inUse = true;
          break;
        }
      }
      setHasInstagramKeyInUse(inUse);
    }
  }, [selectedInstagramKeys, instagramKeysList]);

  useEffect(() => {
    if (selectedFacebookKeys) {
      const selectedIds = selectedFacebookKeys.map(item => item.id);
      const selectedInfo = facebookKeysList.filter(item => selectedIds.includes(item.id));
      let inUse = false;
      for (let i = 0; i < selectedInfo.length; i++) {
        if (selectedInfo[i].sector?.id && (!sector?.sector_id || selectedInfo[i].sector?.id !== sector?.sector_id)) {
          inUse = true;
          break;
        }
      }
      setHasFacebookKeyInUse(inUse);
    }
  }, [selectedFacebookKeys, facebookKeysList]);

  const fetchSector = async (id: string): Promise<I_SectorDetails> => {
    const headers = UserService.getHeaders();
    const config = { headers };
    setLoadingSector(true);
    setErrorLoadingSector(false);

    return new Promise(async (resolve, reject) => {
      await AppRequesterConst.Get(`/sector/${id}`, config,
        () => {},
        (response: I_FetchSectorDetails) => {
          setErrorLoadingSector(false);
          if (response.status === 200 && response.data?.sectors.length > 0) {
            resolve(response.data.sectors[0]);
          } else {
            resolve(null);
          }
        },
        (error: { response: { status: number; data: { message: any []; code_cxpress: number } }, message?: string }) => {
          setErrorLoadingSector(true);
          dispatch(setShowAlertFeedback({ message: t('alert.error'), visibility: true, signalIcon: false }));
          reject(error);
        }, navigate, dispatch, setLoadingSector, { }
      );
    });
  }

  const isValid = () => {
    return name.length > 0;
  }

  const handleSubmit = () => {
    if (isValid()) {
      const headers  = UserService.getHeaders();

      const payload = { // É preciso enviar todos os dados do setor mesmo para atualizar só o status
        id: data?.id,
        active: status,
        name: name,
        emails_id: selectedEmails?.map(item => item.id) || [],
        whatsapp_keys_id: selectedWhatsappKeys?.map(item => item.id) || [],
        instagram_keys_id: selectedInstagramKeys?.map(item => item.id) || [],
        facebook_keys_id: selectedFacebookKeys?.map(item => item.id) || []
      };

      const config = {
        headers
      };

      if (keywords?.length > 0) { // Só envia o campo se tiver pelo menos 1
        payload['key_words'] = keywords || [];
      }

      if (selectedAgents?.length > 0) { // Só envia o campo se tiver pelo menos 1
        payload['employees_user_ids'] = selectedAgents?.map(item => item.id);
      }

      if (mode === 'create') {
        delete payload.id;

        AppRequesterConst.Post(
          '/sector', payload, config,
          () => {},
          (response: I_SectorPost) => {
            if (response.data?.sector) {
              dispatch(setShowAlertFeedback({ message: t('alert.create_successfully', { sectorName: name }), visibility: true, signalIcon: true }));
              props.hide(true, { id: response.data.sector.id, name: payload.name, active: status });
            } else {
              props.hide(false);
            }
          },
          (error: any) => {
            console.log(error);
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          },
          navigate, dispatch, setLoadingRequest
        );
      } else {
        AppRequesterConst.Put(
          '/sector', payload, config,
          () => {},
          () => {
            dispatch(setShowAlertFeedback({ message: t('alert.update_successfully', { sectorName: name }), visibility: true, signalIcon: true }));
            props.hide(true);
          },
          (error: any) => {
            console.log(error);
            dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
          },
          navigate, dispatch, setLoadingRequest
        );
      }
    }
  }

  // ##################################################
  // Preenchimento de selects
  // ##################################################

  // Agents
  const fetchAgents = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || agentsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setAgentsListPage(newPage);

    AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreAgents(true);
          } else {
            setHasMoreAgents(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsList, ...response.data.employees];
            setAgentsList(new_array);
          } else {
            setAgentsList(response.data.employees || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setAgentsList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAgentsSelect, {}
    );
  }

  const fetchAgentsPagination = () => {
    try {
      if (hasMoreAgents) {
        const newPage = agentsListPage + 1;
        setAgentsListPage(newPage);
  
        fetchAgents(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchAgents = (term: string) => {
    if (term) {
      fetchAgents(false, 1, term);
    } else {
      fetchAgents(false, 1, '');
    }
  }

  const getAgentsSelect = () => {
    return {
      agentsList: formatDataSelect(agentsList, 'agent'),
      selectedAgents,
      setSelectedAgents,
      isLoadingAgentsSelect,
      fetchAgentsPagination,
      fetchSearchAgents,
      hasMoreAgents,
    };
  }

  // ##################################################
  // ##################################################
  // Emails
  const fetchEmails = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || emailsListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setEmailsListPage(newPage);

    AppRequesterConst.Get('/company/email', config,
      () => {},
      (response: I_FetchSectorEmails) => {
        if (response.status === 200 && response.data.emails.length > 0) {
          if (response.data.emails.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreEmails(true);
          } else {
            setHasMoreEmails(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...emailsList, ...response.data.emails];
            setEmailsList(new_array);
          } else {
            setEmailsList(response.data.emails || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setEmailsList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingEmailsSelect, {}
    );
  }

  const fetchEmailsPagination = () => {
    try {
      if (hasMoreEmails) {
        const newPage = emailsListPage + 1;
        setEmailsListPage(newPage);
  
        fetchEmails(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchEmails = (term: string) => {
    if (term) {
      fetchEmails(false, 1, term);
    } else {
      fetchEmails(false, 1, '');
    }
  }

  const getEmailsSelect = () => {
    return {
      emailsList: formatDataSelect(emailsList, 'email'),
      selectedEmails,
      setSelectedEmails,
      isLoadingEmailsSelect,
      fetchEmailsPagination,
      fetchSearchEmails,
      hasMoreEmails,
      selectedInUse: hasEmailInUse,
    };
  }

  // ##################################################
  // ##################################################
  // Whatsapp Keys

  const fetchWhatsappKeys = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setWhatsappKeysListPage(newPage);

    AppRequesterConst.Get('/channel-whatsapp/whatsapp-numbers', config,
      () => {},
      (response: I_FetchSectorWhatsappKeys) => {
        if (response.status === 200 && response.data.whatsapp_numbers.length > 0) {
          if (response.data.whatsapp_numbers.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreWhatsappKeys(true);
          } else {
            setHasMoreWhatsappKeys(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...whatsappKeysList, ...response.data.whatsapp_numbers];
            setWhatsappKeysList(new_array);
          } else {
            setWhatsappKeysList(response.data.whatsapp_numbers || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setWhatsappKeysList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingWhatsappKeysSelect, {}
    );
  }

  const fetchWhatsappKeysPagination = () => {
    try {
      if (hasMoreWhatsappKeys) {
        const newPage = whatsappKeysListPage + 1;
        setWhatsappKeysListPage(newPage);
  
        fetchWhatsappKeys(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchWhatsappKeys = (term: string) => {
    if (term) {
      fetchWhatsappKeys(false, 1, term);
    } else {
      fetchWhatsappKeys(false, 1, '');
    }
  }

  const getWhatsappKeysSelect = () => {
    return {
      whatsappKeysList: formatDataSelect(whatsappKeysList, 'whatsapp'),
      selectedWhatsappKeys,
      setSelectedWhatsappKeys,
      isLoadingWhatsappKeysSelect,
      fetchWhatsappKeysPagination,
      fetchSearchWhatsappKeys,
      hasMoreWhatsappKeys,
      selectedInUse: hasWhatsappKeyInUse,
    };
  }

  // ##################################################
  // ##################################################
  // Instagram Keys

  const fetchInstagramKeys = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || instagramKeysListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setInstagramKeysListPage(newPage);

    AppRequesterConst.Get('/channel-instagram/list-pages-by-user', config,
      () => {},
      (response: I_FetchSectorInstaFaceKeys) => {
        if (response.status === 200 && response.data.keys.length > 0) {
          if (response.data.keys.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreInstagramKeys(true);
          } else {
            setHasMoreInstagramKeys(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...instagramKeysList, ...response.data.keys];
            setInstagramKeysList(new_array);
          } else {
            setInstagramKeysList(response.data.keys || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setInstagramKeysList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingInstagramKeysSelect, {}
    );
  }

  const fetchInstagramKeysPagination = () => {
    try {
      if (hasMoreInstagramKeys) {
        const newPage = instagramKeysListPage + 1;
        setInstagramKeysListPage(newPage);
  
        fetchInstagramKeys(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchInstagramKeys = (term: string) => {
    if (term) {
      fetchInstagramKeys(false, 1, term);
    } else {
      fetchInstagramKeys(false, 1, '');
    }
  }

  const getInstagramKeysSelect = () => {
    return {
      instagramKeysList: formatDataSelect(instagramKeysList, 'instagram'),
      selectedInstagramKeys,
      setSelectedInstagramKeys,
      isLoadingInstagramKeysSelect,
      fetchInstagramKeysPagination,
      fetchSearchInstagramKeys,
      hasMoreInstagramKeys,
      selectedInUse: hasInstagramKeyInUse,
    };
  }

   // ##################################################
  // ##################################################
  // Facebook Keys

  const fetchFacebookKeys = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || facebookKeysListPage };

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params };

    setFacebookKeysListPage(newPage);

    AppRequesterConst.Get('/channel-facebook/list-pages-by-user', config,
      () => {},
      (response: I_FetchSectorInstaFaceKeys) => {
        if (response.status === 200 && response.data.keys.length > 0) {
          if (response.data.keys.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreFacebookKeys(true);
          } else {
            setHasMoreFacebookKeys(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...instagramKeysList, ...response.data.keys];
            setFacebookKeysList(new_array);
          } else {
            setFacebookKeysList(response.data.keys || []);
          }
        } else {
          if (!isInfiniteScroll) {
            setFacebookKeysList([]);
          }
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingFacebookKeysSelect, {}
    );
  }

  const fetchFacebookKeysPagination = () => {
    try {
      if (hasMoreFacebookKeys) {
        const newPage = facebookKeysListPage + 1;
        setFacebookKeysListPage(newPage);
  
        fetchFacebookKeys(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchFacebookKeys = (term: string) => {
    if (term) {
      fetchFacebookKeys(false, 1, term);
    } else {
      fetchFacebookKeys(false, 1, '');
    }
  }

  const getFacebookKeysSelect = () => {
    return {
      facebookKeysList: formatDataSelect(facebookKeysList, 'facebook'),
      selectedFacebookKeys,
      setSelectedFacebookKeys,
      isLoadingFacebookKeysSelect,
      fetchFacebookKeysPagination,
      fetchSearchFacebookKeys,
      hasMoreFacebookKeys,
      selectedInUse: hasFacebookKeyInUse,
    };
  }

  // ##################################################
  // ##################################################

  const formatDataSelect = (data: any[], type: 'agent' | 'email' | 'whatsapp' | 'instagram' | 'facebook'): CustomSelectOptionType[] => {
    const formatted: CustomSelectOptionType[] = [];

    if (type === 'agent') {
      data.forEach((item: EmployeeInterface) => {
        const dataOption = { id: item.user_id, value: item.name };

        if (item.user_id === loggedUserId) {
          dataOption['icon'] = (
            <OverlayTrigger placement='right' overlay={<Tooltip>{t('form.agent_tooltip_you')}</Tooltip>}>
              <IconUserBolt stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      });
    } else if (type === 'email') {
      data.forEach((item: I_SectorEmail) => {
        const dataOption = { id: item.id, value: item.email };

        if (item.sector?.id && (!sector?.sector_id || item.sector?.id !== sector?.sector_id)) {
          dataOption['icon'] = (
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{t('form.email_in_use_tooltip', { sectorName: item.sector.name})}</Tooltip>}>
              <IconAlertCircle stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      });
    } else if (type === 'whatsapp') {
      data.forEach((item: I_WhatsappKeys) => {
        const dataOption = { id: item.id, value: `${item.subaccount_name} (${item.phone_number})` };

        if (item.sector?.id && (!sector?.sector_id || item.sector?.id !== sector?.sector_id)) {
          dataOption['icon'] = (
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{t('form.automation_in_use_tooltip', { sectorName: item.sector.name})}</Tooltip>}>
              <IconAlertCircle stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      });
    } else if (type === 'instagram' || type === 'facebook') {
      data.forEach((item: I_SectorInstaFaceKeys) => {
        // const dataOption = { id: item.id, value: `${item.subaccount_name} (${item.phone_number})` };
        const icons = { botIcon: null, enabledIcon: null, inUseIcon: null };

        if (item.hasBot) {
          icons.botIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_has_bot')}</Tooltip>}>
              <IconRobotFace stroke={1.5} size={20} color={'#4a69bd'} />
            </OverlayTrigger>
          );
        }
        if (item.isActive) {
          icons.botIcon = (
            <OverlayTrigger placement='top' overlay={<Tooltip>{t('form.template_is_enabled')}</Tooltip>}>
              <IconCircleCheckFilled stroke={1.5} size={20} color={'#4a69bd'} />
            </OverlayTrigger>
          );
        }
        if (item.sector?.id) {
          icons.inUseIcon = (
            <OverlayTrigger placement='bottom' overlay={<Tooltip>{t('form.automation_in_use', { sectorName: item.sector.name})}</Tooltip>}>
              <IconAlertCircle stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
        }

        const newItem = { id: item.id, value: item.pageName };

        if (icons.botIcon || icons.enabledIcon) {
          newItem['icon'] = <>{icons.botIcon}{icons.enabledIcon}{icons.inUseIcon}</>;
          newItem['iconPosition'] = 'end';
        }

        formatted.push(newItem);
      });
    }

    return formatted;
  }

  return (
    <ModalSector 
      t={t}
      show={props.show}
      hide={props.hide}
      mode={mode}
      sector={sector}
      loadingSector={loadingSector}
      loadingRequest={loadingRequest}
      errorLoadingSector={errorLoadingSector}
      isValid={isValid}
      handleSubmit={handleSubmit}
      name={name}
      setName={setName}
      status={status}
      setStatus={setStatus}
      keywords={keywords}
      setKeywords={setKeywords}
      getAgentsSelect={getAgentsSelect}
      getEmailsSelect={getEmailsSelect}
      getWhatsappKeysSelect={getWhatsappKeysSelect}
      getInstagramKeysSelect={getInstagramKeysSelect}
      getFacebookKeysSelect={getFacebookKeysSelect}
    />
  );
};

export default ModalSectorController;
