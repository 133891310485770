import { FC, useEffect, useState } from 'react';
import ModalDeleteTag from './index';
import { ModalDeleteTagControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../../services/appRequester/appRequesterController';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import emitter from '../../../../../../core/shared/emitter';
import UserService from '../../../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../../../store/internal';

const AppRequesterConst = new AppRequesterController();

const ModalDeleteTagController: FC<ModalDeleteTagControllerInterface> = (props) => {
  const { t } = useTranslation('ModalDeleteTag');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ id: string, name?: string }[]>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [mode, setMode] = useState<'single' | 'multiple'>('single');

  useEffect(() => {
    emitter.on('open-modal-delete-tag', data => {
      setData(data);
      if (data.length > 1) {
        setMode('multiple');
      } else {
        setMode('single');
      }
    });

    return () => {
      emitter.off('open-modal-delete-tag');
    }
  }, []);

  const handleSubmit = () => {
    if (data.length > 0) {
      const headers  = UserService.getHeaders();
      const ids = data.map(item => item.id);
  
      const config = {
        headers,
        data: { ids }
      };

      if (props.type === 'ticket') {
        config.data['type'] = 'ticket';
      }

      const requestPath = props.type === 'ticket' ? 'tag' : 'consumer-tag';

      AppRequesterConst.Delete(
        requestPath, config,
        () => {},
        () => {
          if (data.length === 1) {
            dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully_single', { tagName: data[0].name }), visibility: true, signalIcon: true }));
          } else {
            dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully_multi', { deletedCount: data.length }), visibility: true, signalIcon: true }));
          }
          props.hide(true, ids);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingDelete
      );
    }
  }

  return (
    <ModalDeleteTag 
      t={t}
      mode={mode}
      type={props.type}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingDelete={loadingDelete}
      handleSubmit={handleSubmit}
    />
  );
};

export default ModalDeleteTagController;
