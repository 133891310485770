import { FC, useContext, useState } from 'react';
import { CallcenterLoginFormControllerInterface } from './indexModel';
import CallcenterLoginForm from '.';
import { CallcenterContext } from '../../../../../../core/context/callcenter-context';
import { useTranslation } from 'react-i18next';

const CallcenterLoginFormController: FC<CallcenterLoginFormControllerInterface> = (props) => {
  const { t } = useTranslation();

  const callcenterCtx = useContext(CallcenterContext);

  const [showPassword, setShowPassword] = useState(false);
  

  const getMicrophonePermission = async () => {
    if('MediaRecorder' in window) {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        })
      } catch (err) {
        console.log(err)
      }
    } else {
      alert ('The MediaRecorder API is not suported in your browser.')
    }
  }

  const loginExtension = () => {
    getMicrophonePermission()
    callcenterCtx?.instance?.register();
  }

  const closeLoginForm = () => {
    props.show(false);
  }
  
  return (
    <CallcenterLoginForm 
      t={t}
      callcenterCtx={callcenterCtx}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      loginExtension={loginExtension}
      closeLoginForm={closeLoginForm}
    />)
  ;
}

export default CallcenterLoginFormController;