import './styles.scss';
import { FC } from "react";
import NotificationsComponentController from "../notifications/indexController";
import SearchComponentController from "../searchComponent/indexController";
import { MainHeaderInterface } from "./indexModel";
import PhoneButtonController from '../phoneCallcenter/components/PhoneButton/indexController';
import StatusAgentComponentController from '../statusAgent/indexController';
import CreateButtonController from '../createButton/indexController';
import AcquirePlatformBarController from '../acquirePlatformBar/indexController';

const MainHeader: FC<MainHeaderInterface> = (props) => {
  return (
    <div className="main-header-component" ref={props.headerMainRef} id='fixed-main-header'>
      <div className="main-header-actions-bar">
        <div className="main-header-actions-search">
          { props.showSearch() && <SearchComponentController page={props.searchPage} />}
          { props.showAcquirePlatform.visibility && <AcquirePlatformBarController limitDays={props.showAcquirePlatform.limitDays} /> }
        </div>
        <div className="main-header-actions-buttons">
          { props.showCreateButton() && <CreateButtonController /> }
          <NotificationsComponentController />
          { props.showPhone && <PhoneButtonController /> }
          <StatusAgentComponentController />
        </div>
      </div>
      <div className="main-header-info" style={props.customBackgroundColor ? { backgroundColor: props.customBackgroundColor } : {}}>
        {
          props.headerTitle &&
          <span className='main-header-info-title'>{props.headerTitle}</span>
        }
        {
          props.headerSubtitle &&
          <span className='main-header-info-subtitle'>{props.headerSubtitle}</span>
        }
      </div>
    </div>
  );
}

export default MainHeader;