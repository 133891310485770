import { FC, useEffect, useState } from 'react';
import ModalExportAll from './index';
import { I_FetchTicketExportAll, ModalExportAllControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomSelectOptionType } from '../../../../../components/customSelect/indexModel';
import { EmployeeInterface, FetchSectorsInterface, FetchTagsInterface, SectorInterface, TagInterface } from '../../viewTicket/components/detailsComponent/indexModel';
import { IconMail, IconBrandFacebook, IconBrandInstagram, IconPhone, IconMessages, IconBrandWhatsapp, IconStar, IconUserBolt } from '@tabler/icons-react';
import { Channels } from '../../../../../core/enums/channels';
import { getIdUser } from '../../../../../store/user';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import UserService from '../../../../../services/user-service';
import { FetchEmployeesInterface } from '../../../homeAgents2/listOfAgents/indexModel';
import { ErrorResponseInterface, FilterDateType } from '../../../components-fixed/searchComponent/popupSearchFilter/indexModel';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { TicketStatus } from '../../../../../core/enums/ticket-status';
import { getTicketListSortBy } from '../../../../../store/table_sort_order';
import emitter from '../../../../../core/shared/emitter';

const AppRequesterConst = new AppRequesterController();

const LIMIT_ITEMS_PAGINATION = 20;
const selectIconSize = 20;
const selectIconColor = '#707070';

const ModalExportAllController: FC<ModalExportAllControllerInterface> = (props) => {
  const { t } = useTranslation('ModalExportAll');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loggedUserId = useSelector(getIdUser);
  const ticketListSortBy = useSelector(getTicketListSortBy);

  // Outros estados
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [step, setStep] = useState<'export' | 'links'>('export');
  const [links, setLinks] = useState<string[]>([]);
  const [abortController, setAbortController] = useState<AbortController>(new AbortController());

  // #########################################
  // ########## Estados dos selects ##########

  // Canal do ticket
  const [selectedChannels, setSelectedChannels] = useState<CustomSelectOptionType[]>(null);

  // Agents
  const [selectedAgents, setSelectedAgents] = useState<CustomSelectOptionType[]>(null);
  const [agentsList, setAgentsList] = useState<EmployeeInterface[]>([]);
  const [agentsListPage, setAgentsListPage] = useState(1);
  const [hasMoreAgents, setHasMoreAgents] = useState(false);
  const [isLoadingAgentsSelect, setIsLoadingAgentsSelect] = useState(false);

  // Sectors (active)
  const [selectedSectors, setSelectedSectors] = useState<CustomSelectOptionType[]>(null);
  const [sectorsList, setSectorsList] = useState<SectorInterface[]>([]);
  const [sectorsListPage, setSectorsListPage] = useState(1);
  const [hasMoreSectors, setHasMoreSectors] = useState(false);
  const [isLoadingSectorsSelect, setIsLoadingSectorsSelect] = useState(false);

  // Tags
  const [selectedTags, setSelectedTags] = useState<CustomSelectOptionType[]>(null);
  const [tagsList, setTagsList] = useState<TagInterface[]>([]);
  const [tagsListPage, setTagsListPage] = useState(1);
  const [hasMoreTags, setHasMoreTags] = useState(false);
  const [isLoadingTagsSelect, setIsLoadingTagsSelect] = useState(false);

  // Data
  const [selectedFilterDate, setSelectedFilterDate] = useState<CustomSelectOptionType>();
  const [selectedDate, setSelectedDate] = useState<{ start: Date, end: Date }>({ start: null, end: null });

  // Content
  const [hasContent, setHasContent] = useState(false);

  // #########################################
  // ########### Listas estáticas ############

  // Channels
  const channelsList = [
    { id: Channels.Email, value: t("form.channels.email"), icon: <IconMail stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Facebook, value: t("form.channels.facebook"), icon: <IconBrandFacebook stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Instagram, value: t("form.channels.instagram"), icon: <IconBrandInstagram stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Telefonia, value: t("form.channels.telephony"), icon: <IconPhone stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.Webchat, value: t("form.channels.webchat"), icon: <IconMessages stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
    { id: Channels.WhatsApp, value: t("form.channels.whatsapp"), icon: <IconBrandWhatsapp stroke={1.5} size={selectIconSize} color={selectIconColor} /> },
  ];

  // Filter Date
  const filterDateList = [
    { id: 'today', value: t('form.period_type.today') },
    { id: 'yesterday', value: t('form.period_type.yesterday') },
    { id: 'current_week', value: t('form.period_type.current_week') },
    { id: 'last_week', value: t('form.period_type.last_week') },
    { id: 'current_month', value: t('form.period_type.current_month') },
    { id: 'last_month', value: t('form.period_type.last_month') },
    { id: 'custom', value: t('form.period_type.custom') },
  ];
  // #########################################

  // #########################################
  // ############## useEffects ###############

  useEffect(() => {
    emitter.on('open-modal-export-all-tickets', data => {
      if (data?.filter?.selectedChannel?.length > 0) {
        setSelectedChannels(data.filter.selectedChannel);
      }
      if (data?.filter?.selectedFilterDate?.id) {
        setSelectedFilterDate(data.filter.selectedFilterDate);
      }
      if (data?.filter?.selectedDate?.start) {
        setSelectedDate(data.filter.selectedDate);
      }
      if (data?.filter.selectedAgent?.length > 0) {
        setSelectedAgents(data.filter.selectedAgent);
      }
      if (data?.filter.selectedSector?.length > 0) {
        setSelectedSectors(data.filter.selectedSector);
      }
      if (data?.filter.selectedTag?.length > 0) {
        setSelectedTags(data.filter.selectedTag);
      }
    });

    return () => {
      emitter.off('open-modal-export-all-tickets');
    }
  }, []);

  useEffect(() => {
    if (!abortController) {
      setAbortController(new AbortController());
    }
    return () => {
      if (abortController) {
        abortController.abort();
      }
    }
  }, []);

  useEffect(() => {
    Promise.all([
      fetchAgents(false, 1),
      fetchSectors(false, 1),
      fetchTags(false, 1),
    ]);
  }, []);

  // #########################################

  // #########################################
  // ################ Funções ################

  const isValid = (): boolean => {
    if (selectedFilterDate?.id) {
      return isValidDate();
    } else {
      return false;
    }
  }

  const isValidDate = (): boolean => {

    if (selectedFilterDate?.id === 'custom') {
      if (selectedDate.start && selectedDate.end) {
        const msPerDay = 1000 * 60 * 60 * 24; // Milissegundos em um dia
        const diffMs = Math.abs(new Date(selectedDate.end).getTime() - new Date(selectedDate.start).getTime());
        const diffDays = Math.ceil(diffMs / msPerDay);
  
        if (diffDays <= 31) {
          return true;
        } else { 
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const handleSubmit = () => {
    const selectedStatus = [TicketStatus.New, TicketStatus.Open, TicketStatus.Pending, TicketStatus.OnHold];
    const date = getFormattedFilterDate();
    
    const payload = {
      level: 2,
      start_date: date.start.toISOString().split('T')[0],
      end_date: date.end.toISOString().split('T')[0],
      has_additional_content: hasContent,
    };

    if (selectedChannels?.length > 0) {
      payload['channel'] = selectedChannels.map(item => item.id).join(',');
    }
    if (selectedAgents?.length > 0) {
      payload['employee'] = selectedAgents.map(item => item.id).join(',');
    }
    if (selectedSectors?.length > 0) {
      payload['sector'] = selectedSectors.map(item => item.id).join(',');
    }
    if (selectedTags?.length > 0) {
      payload['tags'] = selectedTags.map(item => item.id).join(',');
    }
    if (props.pageType === 'ticket-list') {
      payload['status'] = selectedStatus.join(',');
      payload['sort_by'] = ticketListSortBy || 'updated_level2_at';
    }

    if (isValid()) {
      const headers = UserService.getHeaders();
      const config = { headers };
      setErrorRequest(false);

      const routeApi = props.pageType === 'history-general' ? '/ticket/historic/export' : '/ticket/export';
      
      if (props.pageType === 'history-general') {
        const isDeletedCounter = sessionStorage.getItem('is_deleted_counter');
        payload['deleted'] = isDeletedCounter === 'true';
      }

      AppRequesterConst.Post(
        routeApi, payload, config,
        () => {},
        (response: I_FetchTicketExportAll) => {
          setErrorRequest(false);
          if (response.status === 200 && response.data?.links?.length > 0) {
            setStep('links');
            setLinks(response.data.links);
            dispatch(setShowAlertFeedback({ message: t('alert.export_successfully'), visibility: true, signalIcon: true }));
          } else {
            setStep('links');
            setLinks([]);
            dispatch(setShowAlertFeedback({ message: t('alert.export_successfully_empty'), visibility: true, signalIcon: true }));
          }
        },
        () => {
          setErrorRequest(true);
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingRequest
      )
    }
  }

  // #########################################
  // ######## Requisições dos selects ########

  // Agents
  const fetchAgents = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || agentsListPage };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setAgentsListPage(newPage);

    AppRequesterConst.Get('/agent', config, 
      (_response: any) => {},
      (response: FetchEmployeesInterface) => {
        if (response.status === 200 && response.data.employees.length > 0) {
          if (response.data.employees.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreAgents(true);
          } else {
            setHasMoreAgents(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...agentsList, ...response.data.employees];
            setAgentsList(new_array);
          } else {
            setAgentsList(response.data.employees || []);
          }
        } else {
          setAgentsList([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingAgentsSelect, {}
    );
  }

  const fetchAgentsPagination = () => {
    try {
      if (hasMoreAgents) {
        const newPage = agentsListPage + 1;
        setAgentsListPage(newPage);
  
        fetchAgents(true, newPage);
      }
    } catch (error) { }
  }

  const fetchSearchAgents = (term: string) => {
    if (term) {
      fetchAgents(false, 1, term);
    } else {
      fetchAgents(false, 1, '');
    }
  }

  // Sectors

  const fetchSectors = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || sectorsListPage };
    const signal = abortController?.signal;
    
    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setSectorsListPage(newPage);

    AppRequesterConst.Get('/sector/actives', config, 
      (_response: any) => {},
      (response: FetchSectorsInterface) => {
        if (response.status === 200 && response.data.sectors.length > 0) {
          if (response.data.sectors.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreSectors(true);
          } else {
            setHasMoreSectors(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...sectorsList, ...response.data.sectors];
            setSectorsList(new_array);
          } else {
            setSectorsList(response.data.sectors || []);
          }
        } else {
          setSectorsList([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingSectorsSelect, {}
    );
  }

  const fetchSectorsPagination = () => {
    if (hasMoreSectors) {
      const newPage = sectorsListPage + 1;
      setSectorsListPage(newPage);

      fetchSectors(true, newPage);
    }
  }

  const fetchSearchSectors = (term: string) => {
    if (term) {
      fetchSectors(false, 1, term);
    } else {
      fetchSectors(false, 1, '');
    }
  }

  // Tags
  
  const fetchTags = (isInfiniteScroll: boolean, newPage: number, termSearch?: string) => {
    const headers = UserService.getHeaders();
    const params = { limit: LIMIT_ITEMS_PAGINATION, page: newPage || tagsListPage };
    const signal = abortController?.signal;

    if (termSearch) {
      params['search'] = termSearch;
    }

    const config = { headers, params, signal };

    setTagsListPage(newPage);

    AppRequesterConst.Get('/tag', config, 
      (_response: any) => {},
      (response: FetchTagsInterface) => {
        if (response.status === 200 && response.data.tags.length > 0) {
          if (response.data.tags.length === LIMIT_ITEMS_PAGINATION) {
            setHasMoreTags(true);
          } else {
            setHasMoreTags(false);
          }

          if (isInfiniteScroll) {
            const new_array = [...tagsList, ...response.data.tags];
            setTagsList(new_array);
          } else {
            setTagsList(response.data.tags || []);
          }
        } else {
          setTagsList([]);
        }
      },
      (error: ErrorResponseInterface) => {
        if (error.name !== 'CanceledError') {
          dispatch(setShowAlertFeedback({ message: t('errors.default'), visibility: true, signalIcon: false }));
        } else {
          console.error('Requisição cancelada -', error.name);
        }
      }, navigate, dispatch, setIsLoadingTagsSelect, {}
    );
  }

  const fetchTagsPagination = () => {
    if (hasMoreTags) {
      const newPage = tagsListPage + 1;
      setTagsListPage(newPage);

      fetchTags(true, newPage);
    }
  }

  const fetchSearchTags = (term: string) => {
    if (term) {
      fetchTags(false, 1, term);
    } else {
      fetchTags(false, 1, '');
    }
  }

  // #########################################
  const back = () => {
    setStep('export');
  }

  const onSelectDate = (dates: [ Date, Date ]) => {
    const [start, end] = dates;
    setSelectedDate({ start, end });
  }

  const getFormattedFilterDate = () => {
    const period = selectedFilterDate?.id as FilterDateType;
    const filter: { start: Date, end: Date } = { start: null, end: null };

    if (period === 'today') {
      filter.start = new Date();
      filter.end = new Date(filter.start);
    } else if (period === 'yesterday') {
      filter.start = new Date();
      filter.start.setDate(filter.start.getDate() - 1);
      filter.end = new Date(filter.start);
    } else if (period === 'current_week') {
      filter.end = new Date();
      filter.start = new Date();
      filter.start.setDate(filter.start.getDate() - filter.end.getDay());
    } else if (period === 'last_week') {
      const today = new Date();
      filter.end = new Date();
      filter.end.setDate(filter.end.getDate() - today.getDay() - 1);
      filter.start = new Date(filter.end);
      filter.start.setDate(filter.end.getDate() - 6);
    } else if (period === 'current_month') {
      filter.end = new Date();
      filter.start = new Date();
      filter.start.setDate(1);
    } else if (period === 'last_month') {
      filter.end = new Date();
      filter.end.setDate(0);
      filter.start = new Date();
      filter.start.setDate(0);
      filter.start.setDate(1);
    } else {
      filter.start = selectedDate.start;
      filter.end = selectedDate.end;
    }

    if (filter.start && !filter.end) {
      filter.end = new Date(filter.start);
    }

    if (filter.start) {
      filter.start.setHours(0, 0, 0, 0);
    }

    if (filter.end) {
      filter.end.setHours(0, 0, 0, 0);
    }

    return filter;
  }
  // #########################################

  const getChannelSelect = () => {
    return {
      channelsList,
      selectedChannels,
      setSelectedChannels,
    }
  }

  const getAgentsSelect = () => {
    return {
      agentsList: formatDataSelect(agentsList, 'agent'),
      selectedAgents,
      setSelectedAgents,
      isLoadingAgentsSelect,
      fetchAgentsPagination,
      fetchSearchAgents,
      hasMoreAgents,
    };
  }

  const getSectorsSelect = () => {
    return {
      sectorsList: formatDataSelect(sectorsList, 'sector'),
      selectedSectors,
      setSelectedSectors,
      isLoadingSectorsSelect,
      fetchSectorsPagination,
      fetchSearchSectors,
      hasMoreSectors,
    };
  }

  const getTagsSelect = () => {
    return {
      tagsList: formatDataSelect(tagsList, 'tag'),
      selectedTags,
      setSelectedTags,
      isLoadingTagsSelect,
      fetchTagsPagination,
      fetchSearchTags,
      hasMoreTags,
    };
  }

  const getFilterDateSelect = () => {
    return {
      filterDateList,
      selectedFilterDate,
      setSelectedFilterDate
    }
  }

  const getDateInput = () => {
    return {
      selectedDate,
      onSelectDate,
    }
  }

  const formatDataSelect = (data: any[], type: 'agent' | 'sector' | 'tag'): CustomSelectOptionType[] => {
    const formatted: CustomSelectOptionType[] = [];

    const filterWithNA = ['sector'];

    if (filterWithNA.includes(type)) {
      formatted.push({ 
        id: 'NA', 
        value: 'N/A', 
        // icon: <IconStar size={16} style={{ minWidth: 16, minHeight: 16}} color="#4a69bd" />, 
        // iconPosition: 'end',
        isOptionSystem: true
      });
    }

    if (type === 'agent') {
      data.map((item: EmployeeInterface) => {
        const dataOption = { id: item.user_id, value: item.name };

        if (item.user_id === loggedUserId) {
          dataOption['icon'] = (
            <OverlayTrigger placement='right' overlay={<Tooltip>Você</Tooltip>}>
              <IconUserBolt stroke={1.5} size={selectIconSize} color={selectIconColor} />
            </OverlayTrigger>
          );
          
          dataOption['iconPosition'] = 'end';
        }

        formatted.push(dataOption);
      })
    } else if (type === 'sector') {
      data.map((item: SectorInterface) => {
        formatted.push({ id: item.id, value: item.name });
      });
    } else if (type === 'tag') {
      data.map((item: TagInterface) => {
        formatted.push({ id: item.id, value: item.name });
      });
    }

    return formatted;
  }


  return (
    <ModalExportAll 
      t={t}
      show={props.show}
      hide={props.hide}
      back={back}
      step={step}
      loadingRequest={loadingRequest}
      isValid={isValid}
      handleSubmit={handleSubmit}
      links={links}
      hasContent={hasContent}
      setHasContent={setHasContent}

      getChannelSelect={getChannelSelect}
      getAgentsSelect={getAgentsSelect}
      getSectorsSelect={getSectorsSelect}
      getTagsSelect={getTagsSelect}
      getFilterDateSelect={getFilterDateSelect}
      getDateInput={getDateInput}
    />
  );
};

export default ModalExportAllController;
