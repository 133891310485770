import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationsPtBr from './locales/pt-BR/translations.json';
import translationsPtPt from './locales/pt-PT/translations.json';
import translationsEnUk from './locales/en/translations.json';
import translationsEs from './locales/es/translations.json';
import translationsFr from './locales/fr/translations.json';
import componentDirs from './pathTranslations.json';
import emitter from './core/shared/emitter';

const resources = {
  'pt-BR': { translations: translationsPtBr },
  'pt-PT': { translations: translationsPtPt },
  'en': { translations: translationsEnUk },
  'es': { translations: translationsEs },
  'fr': { translations: translationsFr },
}

const i18nConfig = {
  resources,
  fallbackLng: ['pt-BR', 'pt-PT', 'pt', 'en', 'es', 'fr'],
  defaultNS: 'translations',
  detection: {
    order: [
      'localStorage',
      'navigator',
      'sessionStorage',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },

  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'],
  interpolation: {
    escapeValue: false, // Desativa a interpolação de segurança para permitir HTML/JSX
  },
  debug: false
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig);

const loadTranslations = async () => {
  localStorage.setItem('i18n-loaded', 'false');
  emitter.emit('i18n-status', { status: 'loading' });

  const isProduction = process.env.NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'production';

  if (isProduction) {
    const combinedTranslations = await import('./build_i18n.json');

    for (const [componentName, translation] of Object.entries(combinedTranslations)) {
      if (componentName !== 'default') {
        i18n.addResourceBundle('pt-BR', componentName, translation['pt-br']);
        i18n.addResourceBundle('pt-PT', componentName, translation['pt-pt']);
        i18n.addResourceBundle('es', componentName, translation['es']);
        i18n.addResourceBundle('en', componentName, translation['en']);
        i18n.addResourceBundle('fr', componentName, translation['fr']);
      }
    }
  } else {
    for (const { path, componentName} of componentDirs.file_paths) {
      try {
        const translation: TranslationFile = await import(`${path}`);
        i18n.addResourceBundle('pt-BR', componentName, translation['pt-br']);
        i18n.addResourceBundle('pt-PT', componentName, translation['pt-pt']);
        i18n.addResourceBundle('es', componentName, translation['es']);
        i18n.addResourceBundle('en', componentName, translation['en']);
        i18n.addResourceBundle('fr', componentName, translation['fr']);
  
      } catch (error) {
        console.error({ 
          message: `Error loading translation file for component ${componentName} at path ${path}`,
          error 
        });
      }
    }
  }

  emitter.emit('i18n-status', { status: 'loaded' });
  emitter.off('app-loaded');
  localStorage.setItem('i18n-loaded', 'true');
}

emitter.on('app-loaded', () => {
  loadTranslations();
});

export default i18n;

type TranslationFile = {
  'pt-br': any,
  'pt-pt': any,
  'es': any,
  'en': any,
  'fr': any,
}
