import './styles.scss';
import { FC } from 'react';
import { ListOfAgentsInterface } from './indexModel';
import FilteredViewBarController from '../../components-fixed/searchComponent/filteredViewBar/indexController';
import CustomTableListController from '../../../../components/customTableList/indexController';
import { IconUserBolt } from '@tabler/icons-react';
import ModalAgentController from '../components/modalAgent/indexController';
import ModalDeleteAgentController from '../components/modalDeleteAgent/indexController';
import ModalAgentConsumersController from '../components/modalAgentConsumers/indexController';

const ListOfAgents: FC<ListOfAgentsInterface> = (props) => {
  return (
    <div className="list-agents-component">
      { props.showModalAgent && <ModalAgentController show={props.showModalAgent} hide={props.closeModalAgent} /> }
      { props.showModalDeleteAgent && <ModalDeleteAgentController show={props.showModalDeleteAgent} hide={props.closeModalDeleteAgent} /> }
      { props.showModalAgentConsumers && <ModalAgentConsumersController show={props.showModalAgentConsumers} hide={props.closeModalAgentConsumers} /> }

      <div className="list-agents-page-info">
        { props.filters?.length > 0 && 
          <FilteredViewBarController pageType='agents' filters={props.filters} filterMode="simple" clearSearch={props.clearSearchAgents} clearSpecificFilter={props.clearSpecificFilter} />
        }
      </div>
      <div className="list-agents-page-content">
        <CustomTableListController 
          component='agents'
          text_empty_list={props.t('no_agents')}
          text_loading_list={props.t('loading_agents')}
          text_error_list={props.t('error_loading_agents')}
          icon_entity_list={<IconUserBolt className='tabler-icon-entity' />}
          headers={props.listHeaders}
          data={props.listData}
          paginationDetails={props.paginationDetails}
          fetchMore={props.getAgentsPagination}
          isLoading={props.isLoading}
          errorLoading={props.errorLoading}
          popoverItemAction={props.popoverItemAction}
          reloadData={props.reloadAgents}
          handleItemClick={props.openModalEditAgent}
          itemClickDisabled={props.getDisabledItemClick()}
          hasItemCheckSelection={!props.getDisabledItemClick()}
          selectItem={props.selectItem}
          selectAllItems={props.selectAllItems}
          isSelectedItem={props.isSelectedItem} 
          selectedItemsLength={props.selectedItemsLength}
          hasSomeSelected={props.hasSomeSelected}
          hasSomeSelectedCheck={props.hasSomeSelectedCheck}
          isAllSelectedItems={props.isAllSelectedItems}
          hasItemIconInfo={false}
          itemIconInfoTooltip={props.t('icon_agent_tooltip')}
          hasDeleteSelected={true}
          deleteSelected={props.deleteSelected}
          clearSelected={props.clearSelected}
          clearPageSelected={props.clearPageSelected}
        />
      </div>
    </div>
  );
};

export default ListOfAgents;
