import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderByType, SortByType } from '../core/enums/order-sort-by';

interface State {
  ticketListSortBy: SortByType;
  ticketListOrderBy: OrderByType;
  historyGeneralSortBy: SortByType;
  historyGeneralOrderBy: OrderByType;
  historyChatbotSortBy: SortByType;
  historyChatbotOrderBy: OrderByType;
  historyTelephonySortBy: SortByType;
  historyTelephonyOrderBy: OrderByType;
  consumerListOrderBy: OrderByType;
  agentListSortBy: SortByType;
  agentListNameOrderBy: OrderByType;
  agentListProfileOrderBy: OrderByType;
  messageTriggerScheduleListSortBy: SortByType;
  messageTriggerScheduleListTypeOrderBy: OrderByType;
  messageTriggerScheduleListDateOrderBy: OrderByType;
  messageTriggerScheduleListStatusOrderBy: OrderByType;
  messageTriggerTypeListSortBy: SortByType;
  messageTriggerTypeListNameOrderBy: OrderByType;
  messageTriggerTypeListStatusOrderBy: OrderByType;
  tagsTicketListSortBy: SortByType;
  tagsTicketListIdOrderBy: OrderByType;
  tagsTicketListNameOrderBy: OrderByType;
  tagsTicketListDateOrderBy: OrderByType;
  tagsTicketListStatusOrderBy: OrderByType;
  tagsConsumerListSortBy: SortByType;
  tagsConsumerListIdOrderBy: OrderByType;
  tagsConsumerListNameOrderBy: OrderByType;
  tagsConsumerListDateOrderBy: OrderByType;
  tagsConsumerListStatusOrderBy: OrderByType;
  sectorsListSortBy: SortByType;
  sectorsListNameOrderBy: OrderByType;
  sectorsListStatusOrderBy: OrderByType;
}

const initialState: State = {
  ticketListSortBy: 'updated_level2_at',
  ticketListOrderBy: 'desc',
  historyGeneralSortBy: 'finished_at',
  historyGeneralOrderBy: 'desc',
  historyChatbotSortBy: 'finished_at',
  historyChatbotOrderBy: 'desc',
  historyTelephonySortBy: 'finished_at',
  historyTelephonyOrderBy: 'desc',
  consumerListOrderBy: 'asc',
  agentListSortBy: 'name',
  agentListNameOrderBy: 'asc',
  agentListProfileOrderBy: 'asc',
  messageTriggerScheduleListSortBy: 'date_scheduled',
  messageTriggerScheduleListTypeOrderBy: 'asc',
  messageTriggerScheduleListDateOrderBy: 'asc',
  messageTriggerScheduleListStatusOrderBy: 'asc',
  messageTriggerTypeListSortBy: 'name',
  messageTriggerTypeListNameOrderBy: 'asc',
  messageTriggerTypeListStatusOrderBy: 'asc',
  tagsTicketListSortBy: 'reference_id',
  tagsTicketListIdOrderBy: 'asc',
  tagsTicketListNameOrderBy: 'asc',
  tagsTicketListDateOrderBy: 'asc',
  tagsTicketListStatusOrderBy: 'asc',
  tagsConsumerListSortBy: 'reference_id',
  tagsConsumerListIdOrderBy: 'asc',
  tagsConsumerListNameOrderBy: 'asc',
  tagsConsumerListDateOrderBy: 'asc',
  tagsConsumerListStatusOrderBy: 'asc',
  sectorsListSortBy: 'name',
  sectorsListNameOrderBy: 'asc',
  sectorsListStatusOrderBy: 'asc',
}

type SortByPayload = PayloadAction<SortByType>;
type OrderByPayload = PayloadAction<OrderByType>;
type SliceState = { table_sort_order: State };

export const tableSortOrderSlice = createSlice({
  name: 'table_sort_order',
  initialState,
  reducers: {
    setTicketListSortBy: (state, action: SortByPayload) => {
      state.ticketListSortBy = action.payload;
    },
    setTicketListOrderBy: (state, action: OrderByPayload) => {
      state.ticketListOrderBy = action.payload;
    },
    setHistoryGeneralSortBy: (state, action: SortByPayload) => {
      state.historyGeneralSortBy = action.payload;
    },
    setHistoryGeneralOrderBy: (state, action: OrderByPayload) => {
      state.historyGeneralOrderBy = action.payload;
    },
    setHistoryChatbotSortBy: (state, action: SortByPayload) => {
      state.historyChatbotSortBy = action.payload;
    },
    setHistoryChatbotOrderBy: (state, action: OrderByPayload) => {
      state.historyChatbotOrderBy = action.payload;
    },
    setHistoryTelephonySortBy: (state, action: SortByPayload) => {
      state.historyTelephonySortBy = action.payload;
    },
    setHistoryTelephonyOrderBy: (state, action: OrderByPayload) => {
      state.historyTelephonyOrderBy = action.payload;
    },
    setConsumerListOrderBy: (state, action: OrderByPayload) => {
      state.consumerListOrderBy = action.payload;
    },
    setAgentListSortBy: (state, action: SortByPayload) => {
      state.agentListSortBy = action.payload;
    },
    setAgentListNameOrderBy: (state, action: OrderByPayload) => {
      state.agentListNameOrderBy = action.payload;
    },
    setAgentListProfileOrderBy: (state, action: OrderByPayload) => {
      state.agentListProfileOrderBy = action.payload;
    },
    setMessageTriggerScheduleListSortBy: (state, action: SortByPayload) => {
      state.messageTriggerScheduleListSortBy = action.payload;
    },
    setMessageTriggerScheduleListTypeOrderBy: (state, action: OrderByPayload) => {
      state.messageTriggerScheduleListTypeOrderBy = action.payload;
    },
    setMessageTriggerScheduleListDateOrderBy: (state, action: OrderByPayload) => {
      state.messageTriggerScheduleListDateOrderBy = action.payload;
    },
    setMessageTriggerScheduleListStatusOrderBy: (state, action: OrderByPayload) => {
      state.messageTriggerScheduleListStatusOrderBy = action.payload;
    },
    setMessageTriggerTypeListSortBy: (state, action: SortByPayload) => {
      state.messageTriggerTypeListSortBy = action.payload;
    },
    setMessageTriggerTypeListNameOrderBy: (state, action: OrderByPayload) => {
      state.messageTriggerTypeListNameOrderBy = action.payload;
    },
    setMessageTriggerTypeListStatusOrderBy: (state, action: OrderByPayload) => {
      state.messageTriggerTypeListStatusOrderBy = action.payload;
    },
    setTagsTicketListSortBy: (state, action: SortByPayload) => {
      state.tagsTicketListSortBy = action.payload;
    },
    setTagsTicketListIdOrderBy: (state, action: OrderByPayload) => {
      state.tagsTicketListIdOrderBy = action.payload;
    },
    setTagsTicketListNameOrderBy: (state, action: OrderByPayload) => {
      state.tagsTicketListNameOrderBy = action.payload;
    },
    setTagsTicketListDateOrderBy: (state, action: OrderByPayload) => {
      state.tagsTicketListDateOrderBy = action.payload;
    },
    setTagsTicketListStatusOrderBy: (state, action: OrderByPayload) => {
      state.tagsTicketListStatusOrderBy = action.payload;
    },
    setTagsConsumerListSortBy: (state, action: SortByPayload) => {
      state.tagsConsumerListSortBy = action.payload;
    },
    setTagsConsumerListIdOrderBy: (state, action: OrderByPayload) => {
      state.tagsConsumerListIdOrderBy = action.payload;
    },
    setTagsConsumerListNameOrderBy: (state, action: OrderByPayload) => {
      state.tagsConsumerListNameOrderBy = action.payload;
    },
    setTagsConsumerListDateOrderBy: (state, action: OrderByPayload) => {
      state.tagsConsumerListDateOrderBy = action.payload;
    },
    setTagsConsumerListStatusOrderBy: (state, action: OrderByPayload) => {
      state.tagsConsumerListStatusOrderBy = action.payload;
    },
    setSectorsListSortBy: (state, action: SortByPayload) => {
      state.sectorsListSortBy = action.payload;
    },
    setSectorsListNameOrderBy: (state, action: OrderByPayload) => {
      state.sectorsListNameOrderBy = action.payload;
    },
    setSectorsListStatusOrderBy: (state, action: OrderByPayload) => {
      state.sectorsListStatusOrderBy = action.payload;
    },
  }
});

export const {
  setTicketListSortBy,
  setTicketListOrderBy,
  setHistoryGeneralSortBy,
  setHistoryGeneralOrderBy,
  setHistoryChatbotSortBy,
  setHistoryChatbotOrderBy,
  setHistoryTelephonySortBy,
  setHistoryTelephonyOrderBy,
  setConsumerListOrderBy,
  setAgentListSortBy,
  setAgentListNameOrderBy,
  setAgentListProfileOrderBy,
  setMessageTriggerScheduleListSortBy,
  setMessageTriggerScheduleListTypeOrderBy,
  setMessageTriggerScheduleListDateOrderBy,
  setMessageTriggerScheduleListStatusOrderBy,
  setMessageTriggerTypeListSortBy,
  setMessageTriggerTypeListNameOrderBy,
  setMessageTriggerTypeListStatusOrderBy,
  setTagsTicketListSortBy,
  setTagsTicketListIdOrderBy,
  setTagsTicketListNameOrderBy,
  setTagsTicketListDateOrderBy,
  setTagsTicketListStatusOrderBy,
  setTagsConsumerListSortBy,
  setTagsConsumerListIdOrderBy,
  setTagsConsumerListNameOrderBy,
  setTagsConsumerListDateOrderBy,
  setTagsConsumerListStatusOrderBy,
  setSectorsListSortBy,
  setSectorsListNameOrderBy,
  setSectorsListStatusOrderBy,
} = tableSortOrderSlice.actions;

export const getTicketListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.ticketListSortBy !== undefined ? state.table_sort_order?.ticketListSortBy : tableSortOrderSlice.getInitialState().ticketListSortBy);
export const getTicketListOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.ticketListOrderBy !== undefined ? state.table_sort_order?.ticketListOrderBy : tableSortOrderSlice.getInitialState().ticketListOrderBy);
export const getHistoryGeneralSortBy = (state: SliceState): SortByType => (state.table_sort_order?.historyGeneralSortBy !== undefined ? state.table_sort_order?.historyGeneralSortBy : tableSortOrderSlice.getInitialState().historyGeneralSortBy);
export const getHistoryGeneralOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.historyGeneralOrderBy !== undefined ? state.table_sort_order?.historyGeneralOrderBy : tableSortOrderSlice.getInitialState().historyGeneralOrderBy);
export const getHistoryChatbotSortBy = (state: SliceState): SortByType => (state.table_sort_order?.historyChatbotSortBy !== undefined ? state.table_sort_order?.historyChatbotSortBy : tableSortOrderSlice.getInitialState().historyChatbotSortBy);
export const getHistoryChatbotOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.historyChatbotOrderBy !== undefined ? state.table_sort_order?.historyChatbotOrderBy : tableSortOrderSlice.getInitialState().historyChatbotOrderBy);
export const getHistoryTelephonySortBy = (state: SliceState): SortByType => (state.table_sort_order?.historyTelephonySortBy !== undefined ? state.table_sort_order?.historyTelephonySortBy : tableSortOrderSlice.getInitialState().historyTelephonySortBy);
export const getHistoryTelephonyOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.historyTelephonyOrderBy !== undefined ? state.table_sort_order?.historyTelephonyOrderBy : tableSortOrderSlice.getInitialState().historyTelephonyOrderBy);
export const getConsumerListOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.consumerListOrderBy !== undefined ? state.table_sort_order?.consumerListOrderBy : tableSortOrderSlice.getInitialState().consumerListOrderBy);
export const getAgentListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.agentListSortBy !== undefined ? state.table_sort_order?.agentListSortBy : tableSortOrderSlice.getInitialState().agentListSortBy);
export const getAgentListNameOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.agentListNameOrderBy !== undefined ? state.table_sort_order?.agentListNameOrderBy : tableSortOrderSlice.getInitialState().agentListNameOrderBy);
export const getAgentListProfileOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.agentListProfileOrderBy !== undefined ? state.table_sort_order?.agentListProfileOrderBy : tableSortOrderSlice.getInitialState().agentListProfileOrderBy);
export const getMessageTriggerScheduleListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.messageTriggerScheduleListSortBy !== undefined ? state.table_sort_order?.messageTriggerScheduleListSortBy : tableSortOrderSlice.getInitialState().messageTriggerScheduleListSortBy);
export const getMessageTriggerScheduleListTypeOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.messageTriggerScheduleListTypeOrderBy !== undefined ? state.table_sort_order?.messageTriggerScheduleListTypeOrderBy : tableSortOrderSlice.getInitialState().messageTriggerScheduleListTypeOrderBy);
export const getMessageTriggerScheduleListDateOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.messageTriggerScheduleListDateOrderBy !== undefined ? state.table_sort_order?.messageTriggerScheduleListDateOrderBy : tableSortOrderSlice.getInitialState().messageTriggerScheduleListDateOrderBy);
export const getMessageTriggerScheduleListStatusOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.messageTriggerScheduleListStatusOrderBy !== undefined ? state.table_sort_order?.messageTriggerScheduleListStatusOrderBy : tableSortOrderSlice.getInitialState().messageTriggerScheduleListStatusOrderBy);
export const getMessageTriggerTypeListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.messageTriggerTypeListSortBy !== undefined ? state.table_sort_order?.messageTriggerTypeListSortBy : tableSortOrderSlice.getInitialState().messageTriggerTypeListSortBy);
export const getMessageTriggerTypeListNameOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.messageTriggerTypeListNameOrderBy !== undefined ? state.table_sort_order?.messageTriggerTypeListNameOrderBy : tableSortOrderSlice.getInitialState().messageTriggerTypeListNameOrderBy);
export const getMessageTriggerTypeListStatusOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.messageTriggerTypeListStatusOrderBy !== undefined ? state.table_sort_order?.messageTriggerTypeListStatusOrderBy : tableSortOrderSlice.getInitialState().messageTriggerTypeListStatusOrderBy);
export const getTagsTicketListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.tagsTicketListSortBy !== undefined ? state.table_sort_order?.tagsTicketListSortBy : tableSortOrderSlice.getInitialState().tagsTicketListSortBy);
export const getTagsTicketListIdOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsTicketListIdOrderBy !== undefined ? state.table_sort_order?.tagsTicketListIdOrderBy : tableSortOrderSlice.getInitialState().tagsTicketListIdOrderBy);
export const getTagsTicketListNameOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsTicketListNameOrderBy !== undefined ? state.table_sort_order?.tagsTicketListNameOrderBy : tableSortOrderSlice.getInitialState().tagsTicketListNameOrderBy);
export const getTagsTicketListDateOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsTicketListDateOrderBy !== undefined ? state.table_sort_order?.tagsTicketListDateOrderBy : tableSortOrderSlice.getInitialState().tagsTicketListDateOrderBy);
export const getTagsTicketListStatusOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsTicketListStatusOrderBy !== undefined ? state.table_sort_order?.tagsTicketListStatusOrderBy : tableSortOrderSlice.getInitialState().tagsTicketListStatusOrderBy);
export const getTagsConsumerListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.tagsConsumerListSortBy !== undefined ? state.table_sort_order?.tagsConsumerListSortBy : tableSortOrderSlice.getInitialState().tagsConsumerListSortBy);
export const getTagsConsumerListIdOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsConsumerListIdOrderBy !== undefined ? state.table_sort_order?.tagsConsumerListIdOrderBy : tableSortOrderSlice.getInitialState().tagsConsumerListIdOrderBy);
export const getTagsConsumerListNameOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsConsumerListNameOrderBy !== undefined ? state.table_sort_order?.tagsConsumerListNameOrderBy : tableSortOrderSlice.getInitialState().tagsConsumerListNameOrderBy);
export const getTagsConsumerListDateOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsConsumerListDateOrderBy !== undefined ? state.table_sort_order?.tagsConsumerListDateOrderBy : tableSortOrderSlice.getInitialState().tagsConsumerListDateOrderBy);
export const getTagsConsumerListStatusOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.tagsConsumerListStatusOrderBy !== undefined ? state.table_sort_order?.tagsConsumerListStatusOrderBy : tableSortOrderSlice.getInitialState().tagsConsumerListStatusOrderBy);
export const getSectorsListSortBy = (state: SliceState): SortByType => (state.table_sort_order?.sectorsListSortBy !== undefined ? state.table_sort_order?.sectorsListSortBy : tableSortOrderSlice.getInitialState().sectorsListSortBy);
export const getSectorsListNameOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.sectorsListNameOrderBy !== undefined ? state.table_sort_order?.sectorsListNameOrderBy : tableSortOrderSlice.getInitialState().sectorsListNameOrderBy);
export const getSectorsListStatusOrderBy = (state: SliceState): OrderByType => (state.table_sort_order?.sectorsListStatusOrderBy !== undefined ? state.table_sort_order?.sectorsListStatusOrderBy : tableSortOrderSlice.getInitialState().sectorsListStatusOrderBy);

export default tableSortOrderSlice.reducer;