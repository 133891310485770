const constsRouters = {
    routers: {
        home: {
            path: '/',
            informations: "Tela principal"
        },
        register: {
            path: '/register',
            informations: "Registro"
        },
        forgoturl: {
            path: '/forgoturl',
            informations: "Esqueceu o URL"
        },
        validateemail: {
            path: '/validateemail',
            informations: "Validar email de confirmação"
        },
        newpassword: {
            path: '/newpassword',
            informations: "Criação da nova senha"
        },
        forgotpassword: {
            path: '/forgotpassword',
            informations: "Esqueceu a senha"
        },
        dashboard: {
            path: '/dashboard',
            informations: "Controle de dashboard"
        },
        ticketsOld: {
            path: '/old-tickets',
            informations: "Lista de tickets"
        },
        tickets: {
            path: '/tickets',
            informations: "Lista de tickets"
        },
        ticketsView: {
            path: '/tickets/:id',
            informations: "Detalhes do ticket"
        },
        createTicket: {
            path: '/tickets/create',
            informations: "Cadastro de ticket"
        },
        viewTicket: {
            path: '/tickets/view',
            informations: "Edição e visualização do ticket"
        },
        historyOld: {
            path: '/old-history',
            informations: "Histórico dos tickets"
        },
        history: {
            path: '/history',
            informations: "Histórico dos tickets"
        },
        historyView: {
            path: '/history/:id',
            informations: "Detalhes do ticket"
        },
        historyChatbotOld: {
            path: '/old-history-chatbot',
            informations: "Controle do historico do chatbot"
        },
        historyChatbot: {
            path: '/history-chatbot',
            informations: "Controle do historico do chatbot"
        },
        historyChatbotView: {
            path: '/history-chatbot/:id',
            informations: "Detalhes do ticket"
        },
        historyTelephony: {
            path: '/history-telephony',
            informations: "Controle do historico de telefonia"
        },
        historyTelephonyView: {
            path: '/history-telephony/:id',
            informations: "Detalhes do ticket"
        },
        // historyFinalized: {
        //     path: '/history/finalized',
        //     informations: "Histórico dos tickets finalizados"
        // },
        // historyDeleted: {
        //     path: '/history/deleted',
        //     informations: "Histórico dos tickets excluídos"
        // },
        viewFinalizedTicket: {
            path: '/history/finalized/view',
            informations: "Visualização dos tickets finalizados"
        },
        viewDeletedTicket: {
            path: '/history/deleted/view',
            informations: "Visualização dos tickets excluídos"
        },
        consumersOld: {
            path: '/old-consumers',
            informations: "Controle de consumidores"
        },
        consumers: {
            path: '/consumers',
            informations: "Controle de consumidores"
        },
        consumerTickets: {
            path: '/consumer-tickets',
            informations: 'Tickets do consumidor'
        },
        consumerTicketsView: {
            path: '/consumer-tickets/:id',
            informations: 'Visualização do ticket do consumidor'
        },
        consumersView: {
            path: '/old-consumers/view',
            informations: "View dos consumidores"
        },
        consumersViewAnalysisSimilar: {
            path: '/consumers/similar',
            informations: "View para análise de consumidores similares"
        },
        agentsOld: {
            path: '/old-agents',
            informations: "Controle de agentes"
        },
        agents: {
            path: '/agents',
            informations: "Controle de agentes"
        },
        agentTickets: {
            path: '/agent-tickets',
            informations: "Tickets do agente"
        },
        agentTicketsView: {
            path: '/agent-tickets/:id',
            informations: "Tickets do agente"
        },
        agentsView: {
            path: '/agents/view',
            informations: "View dos agentes"
        },
        tasksOld: {
            path: '/old-tasks',
            informations: "Tasks do ticket selecionado"
        },
        tasks: {
            path: '/tasks',
            informations: "Lista de tarefas"
        },
        taskTicket: {
            path: '/task-ticket',
            informations: "Lista de tickets da tarefa"
        },
        taskTicketView: {
            path: '/task-ticket/:id',
            informations: "Detalhes do ticket"
        },
        activeTrigger: {
            path: '/messages-trigger',
            informations: 'Lista de disparo ativos e tipos de disparo ativo'
        },
        activeTriggerTickets: {
            path: '/messages-trigger-tickets',
            informations: 'Lista de tickets do disparo ativo'
        },
        activeTriggerTicketsView: {
            path: '/messages-trigger-tickets/:id',
            informations: 'Detalhes do ticket do disparo ativo'
        },
        ticketsMessageTrigger: {
            path: '/old-messages-trigger',
            informations: "Tela de disparo ativo de mensagem"
        },
        ticketsMessageTriggerView: {
            path: '/old-messages-trigger/view',
            informations: "Tela de tickets associados a um disparo ativo"
        },
        ticketsMessageTriggerReport: {
            path: '/messages-trigger/report',
            informations: "Relatório do disparo ativo"
        },
        configurationTags: {
            path: '/configuration/tags',
            informations: "Configurações das tags"
        },
        configurationTagsTickets: {
            path: '/configuration/tag-tickets',
            informations: "Tickets da tag"
        },
        configurationTagsTicketsView: {
            path: '/configuration/tag-tickets/:id',
            informations: "Detalhes do ticket de tag"
        },
        configurationTagsConsumers: {
            path: '/configuration/tag-consumers',
            informations: "Consumidores da tag"
        },
        configurationTagsOld: {
            path: '/configuration/old-tags',
            informations: "Configurações das tags"
        },
        configurationTagsViewOld: {
            path: '/configuration/old-tags/view',
            informations: "View das tags"
        },
        configurationChannels: {
            path: '/configuration/channels',
            informations: "Configurações dos canais"
        },
        configurationSectors: {
            path: '/configuration/sectors',
            informations: "Configurações dos setores"
        },
        configurationSectorsTickets: {
            path: '/configuration/sector-tickets',
            informations: "Tickets do setor"
        },
        configurationSectorsTicketsView: {
            path: '/configuration/sector-tickets/:id',
            informations: "Detalhes do ticket do setor"
        },
        configurationTickets: {
            path: '/configuration/tickets',
            informations: "Configurações dos tickets"
        },
        configurationDepartments: {
            path: '/configuration/departments',
            informations: "Configurações dos setores"
        },
        configurationCustomFields: {
            path: '/configuration/custom-fields',
            informations: "Configurações dos campos customizados"
        },
        configurationDepartmentsView: {
            path: '/configuration/departments/view',
            informations: "View dos setores"
        },
        configurationChatbot: {
            path: '/configuration/chatbot',
            informations: 'Página do chatbot'
        },
        configurationChatbotView: {
            path: '/configuration/chatbot/bot',
            informations: 'Página do bot'
        },
        configurationSecurity: {
            path: '/configuration/security',
            informations: 'Configurações de segurança'
        },
        configurationTriage: {
            path: '/configuration/triage',
            informations: 'Configurações de triagem'
        },
        chatBotIFrame: {
            path: '/chat-bot-iframe/:id',
            informations: 'Iframe do chatbot',
        },
        pageDontFind: {
            path: '/error',
            informations: "Erro 404"
        },
        endTrial: {
            path: '/end-trial',
            informations: "Período de teste encerrado"
        },
        controlPanelHome: {
            path: '/',
            information: 'Página inicial do painel de controle'
        },
        controlPanelclients: {
            path: '/clients',
            informations: "Listagem de clientes"
        },
        controlPanelclientsView: {
            path: '/clients/view',
            information: "Informações dos clientes"
        },
        controlPanelAccounts: {
            path: '/accounts',
            information: "Contas do painel"
        },
        controlPanelValidateEmail: {
            path: '/validate-control-panel',
            information: "Validar e-mail de confirmação"
        },
        controlPanelNewPassword: {
            path: '/newpassword-control-panel',
            informations: "Criação da nova senha"
        },
        controlPanelForgotPassword: {
            path: '/forgotpassword-control-panel',
            informations: "Criação da nova senha"
        },
        controlPanelSecurity: {
            path: '/security',
            informations: "Configurações de segurança do painel de CS"
        },
        dashboardChatbot: {
            path: '/dashboard-chatbot',
            informations: "Controle de dashboard do chatbot"
        },
        dashboardSummary: {
            path: '/dashboard-summary',
            informations: "Controle de dashboard do sumário"
        },
        summaryView: {
            path: '/summary/view',
            informations: "View dos sumários"
        },
        controlPanelConfig: {
            path: '/configurations',
            informations: "Configurações de utilidades da API do Sendgrid"
        }

        // loginControlPanel: {
        //     path: '/control-panel/login',
        //     informations: "Login no painel de controle"
        // }
    }
};


export default constsRouters;
