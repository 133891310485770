import './styles.scss';
import { FC } from 'react';
import { ModalDeleteAgentInterface } from './indexModel';
import { Modal } from 'react-bootstrap';
import { IconX } from '@tabler/icons-react';
import Loading from '../../../../../components/loading2';
import { Trans } from 'react-i18next';

const ModalDeleteAgent: FC<ModalDeleteAgentInterface> = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.hide(false)}
      aria-labelledby=""
      size="sm"
      centered={true}
      dialogClassName="modal-delete-agent-component"
    >
      <Modal.Header>
        <Modal.Title>
          {props.mode === 'single' ? props.t('title') : props.t('title_multi')}
        </Modal.Title>
        <div className="modal-delete-agent-header-actions">
          <IconX onClick={() => props.hide(false)} />
        </div>
      </Modal.Header>
      <Modal.Body>
        { (!props.data || props.loadingDelete) && <Loading blur={true}/>}
        { props.data && props.data.length === 1 &&
          <span className="modal-delete-agent-message">
            <Trans 
              i18nKey='ModalDeleteAgent:message_single'
              values={{ agentName: props.data[0].agentName }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
        {
          props.data && props.data.length > 1 &&
          <span className="modal-delete-agent-message">
            <Trans 
              i18nKey='ModalDeleteAgent:message_multi'
              values={{ deletedCount: props.data.length }}
              components={[ <span /> ]} // Um elemento para cada valor em values
            />
          </span>
        }
      </Modal.Body>
      <Modal.Footer>
        <button className='modal-delete-agent-btn-cancel' onClick={() => props.hide(false)}>{props.t('btn_cancel')}</button>
        <button className='modal-delete-agent-btn-delete' disabled={props.loadingDelete} onClick={() => props.handleSubmit()}>{props.t('btn_delete')}</button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteAgent;
