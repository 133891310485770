import { FC, useEffect, useState } from 'react';
import ModalDeleteTask from './index';
import { ModalDeleteTaskControllerInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import emitter from '../../../../../core/shared/emitter';
import UserService from '../../../../../services/user-service';
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../../store/internal';
import { TicketStatus } from '../../../../../core/enums/ticket-status';

const AppRequesterConst = new AppRequesterController();

const ModalDeleteTaskController: FC<ModalDeleteTaskControllerInterface> = (props) => {
  const { t } = useTranslation('ModalDeleteTask');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState<{ 
    taskId: string, 
    date_limit: string, 
    status: string, 
    description: string, 
    requesterName: string, 
    assignedName: string 
  }>(null);

  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    emitter.on('open-modal-delete-task', data => {
      setData(data);
    });

    return () => {
      emitter.off('open-modal-delete-task');
    }
  }, []);

  const handleSubmit = () => {
    deleteTask();
  }

  const deleteTask = () => {
    if (data) {
      const headers  = UserService.getHeaders();
      const task_id = `${data.taskId}`;

      AppRequesterConst.Delete(
        `/task/${task_id}`, { headers },
        () => {},
        () => {
          dispatch(setShowAlertFeedback({ message: t('alert.deleted_successfully'), visibility: true, signalIcon: true }));
          props.hide(true);
        },
        (error: any) => {
          dispatch(setShowAlertFeedback({ message: t('alert.unexpected_error'), visibility: true, signalIcon: false }));
        },
        navigate, dispatch, setLoadingDelete
      );
    }
  }

  const formatDateLimit = (date_limit: string) => {
    const [date, hours] = date_limit.replace('Z', '').split('T');
    const formattedDate = new Date(`${date} ${hours}`).toISOString();

    return `${new Date(formattedDate).toLocaleDateString()} - ${new Date(formattedDate).toLocaleTimeString()}`;
  }

  const getStatusName = (id: string) => {
    switch (id) {
      case TicketStatus.New:
        return t('labels.status.new');
      case TicketStatus.Open:
        return t('labels.status.open');
      case TicketStatus.Pending:
        return t('labels.status.pending');
      case TicketStatus.OnHold:
        return t('labels.status.on_hold');
      case TicketStatus.Resolved:
        return t('labels.status.resolved');
      case TicketStatus.Abandoned:
        return t('labels.status.abandoned');
      case TicketStatus.OverdueTicketTask:
        return t('labels.status.overdue');
    }
  }

  return (
    <ModalDeleteTask 
      t={t}
      show={props.show}
      hide={props.hide}
      data={data}
      loadingDelete={loadingDelete}
      handleSubmit={handleSubmit}
      formatDateLimit={formatDateLimit}
      getStatusName={getStatusName}
    />
  );
};

export default ModalDeleteTaskController;
