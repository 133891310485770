import React from "react";
import { io, Socket } from "socket.io-client";
import { BACKEND_URL } from "../shared/backend_url";
import NotificationSocket from "../../sockets/notification-socket";


export class SocketService {
    public socket: Socket;
    private notificationAgent;
    private notificationEye
    private notificationSocket;
    private notificationStatusAgent;
    private notificationLogin;
    private callback: (notificationService) => void;
    areLoadedMessageEvents = false;

    public getInstance = () => this.socket;

    public createSocket(token?: string) {

        if (token && !this.socket) {
            /*
             * A instância do socket será criada somente se
             * houver um token e não houver outra instância do socket criada. 
             */
            this.socket = io(
                BACKEND_URL,
                {
                    path: "/api/socket",
                    transports: ['websocket'],
                    auth: { token: token }
                }
            )


            this.socket.on("connect", () => {
                if (this.callback) {
                    console.log("socket connected");
                    this.notificationSocket = new NotificationSocket()
                    this.notificationSocket.setSocketContext(this);
                    this.notificationSocket.init();

                    this.callback(this.notificationSocket);
                }
            });

            this.socket.on("error", (error) => {
                console.log('error')
                console.log(error);
            });

            this.socket.on("reconnect", (attempt) => {
                console.log('reconnect')
                console.log(attempt);
            });

            this.socket.on("reconnect_failed", () => {
                console.log("reconnect_failed");
            });

            this.socket.on("connect_error", () => {
                console.log("Não foi possível atualizar as mensagens");
            });

            this.socket.on("disconnect", (reason) => {
                console.log("disconnect " + reason);
            });


        }
    }

    public getNotificationSocket(): NotificationSocket {
        return this.notificationSocket;
    }

    public addOnConnectCallback(callback: (notificationService) => void) {
        this.callback = callback;
    }

    public setNotificationLogin(notification: boolean) {
        this.notificationLogin = notification
    }

    public getNotificationLogin() {
        return this.notificationLogin
    }

    public disconnectSocket(): void {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = undefined;
        }
    }

    public emitEvent(api_event: string, data?: any) {
        if (this.socket) {
            this.socket.emit(api_event, data);
        }
    }

    public registerEvent(event: string, callback: (data?: any) => void) {
        if (this.socket) {
            if (!this.socket.hasListeners(event)) {
                // console.log(':: register event:', event);
                this.socket.on(event, callback);
            }
        } else {
            console.log('without socket')
        }
    }

    public unregisterEvent(event: string) {
        if (this.socket) {
            if (this.socket.hasListeners) {
                // console.log(':: unregister event:', event);
                this.socket.off(event);
            }
        }
    }
}

export const socket = new SocketService();

export const SocketContext = React.createContext(socket);