import './styles.scss';
import { FC } from 'react';
import { ConsumerInfoInterface } from './indexModel';
import { IconArrowUpRight, IconChevronDown, IconReload, IconTicket, IconUserCircle, IconUserShare } from '@tabler/icons-react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loading from '../../../../../../../../components/loading2';
import InfiniteScroll from 'react-infinite-scroll-component';

const ConsumerInfo: FC<ConsumerInfoInterface> = (props) => {

  const showHistoryClass = props.showHistory ? 'consumer-info-section-collapsed' : '';
  const deletedConsumerClass = props.isDeletedConsumer ? 'consumer-details-deleted' : ''

  return (
    <div className="consumer-info-component">
      <div className="consumer-info-section">
        <OverlayTrigger overlay={<Tooltip>{props.t(props.isDeletedConsumer ? 'deleted_consumer' : 'view_consumer')}</Tooltip>}>
          <div className={`consumer-info-details ${deletedConsumerClass}`} onClick={props.openConsumerModal}>
            <IconUserCircle className='consumer-info-icon' />
            <div className="consumer-details-identifier">
              <span className="consumer-details-name" title={props.consumer?.name}>{props.consumer?.name}</span>
              { props.consumer?.email && <span className="consumer-details-email" title={props.consumer?.email}>{props.consumer?.email}</span> }
              { !props.consumer?.email && <span className="consumer-details-email">{props.t('email_not_defined')}</span> }
            </div>
            <IconArrowUpRight className='consumer-info-icon-open'/>
          </div>
        </OverlayTrigger>
        <div className="details-consumer-info-item">
          <span className="consumer-info-item-label">
            {props.t('channels')}
          </span>
          <div className="details-consumer-info-channels">
            { props.channels.length > 0 && props.channels.map(item => {
              const channel = props.getChannel(item.channel_id);
              const userContainsClass = item.contains ? 'consumer-info-item-channel-contains' : '';

              return (
                <OverlayTrigger key={item.channel_id} overlay={<Tooltip>{channel.title}</Tooltip>}>
                  <span className={`consumer-info-item-channel ${userContainsClass}`}>
                    {channel.icon}
                  </span>
                </OverlayTrigger>
              );
            })}
            { props.channels.length === 0 &&
              <span className='details-consumer-info-channels-empty'>{props.t('channels_empty')}</span>
            }
          </div>
        </div>
      </div>
      <div className="consumer-info-separator"></div>
      <div className="consumer-info-section consumer-info-history">
        <span className='consumer-info-section-title'>
          {props.t('ticket_history')}
          <div className="consumer-info-section-actions">
            <IconReload className='consumer-info-section-reload' onClick={() => props.refreshConsumerTickets()} />
            <IconChevronDown className={`consumer-info-section-collapse ${showHistoryClass}`} onClick={() => props.setShowHistory(!props.showHistory)} />
          </div>
        </span>
        { !props.showHistory &&
          <div className="consumer-info-history-open" onClick={() => props.setShowHistory(!props.showHistory)}>
            <IconTicket />
            <span className="consumer-info-history-open-text">{props.t('view_consumer_tickets')}</span>
          </div>
        }
        { props.showHistory &&
          <div className="consumer-info-history-list" style={{ maxHeight: `calc(100vh - ${props.getComponentHeighDiff()}px)` }} id="consumer-info-history-list" ref={props.historyListRef}>
            { props.loadingHistory && <Loading blur={true} /> }
            { props.tickets?.length > 0 && 
              <InfiniteScroll
                dataLength={props.tickets.length}
                next={props.fetchTickets}
                hasMore={props.hasMoreTickets}
                loader={null}
                scrollableTarget="consumer-info-history-list"
                scrollThreshold="5px"
              >
                {props.tickets?.map((item, index) => {
                  const ticket_status = props.getStatusIcon(item.status_id);
                  const ticket_channel = props.getChannel(item.channel_id);
                  const selectedClass = props.selectedTicket === item.id ? 'consumer-info-history-item-selected' : '';

                  return (
                    <div className={`consumer-info-history-item ${selectedClass}`} key={`${item.id}_${index}`}>
                      <OverlayTrigger container={props.historyListRef} overlay={<Tooltip>{ticket_status.tooltip}</Tooltip>}>
                        <span className='consumer-info-history-item-status' style={{ backgroundColor: ticket_status.color }}>
                          {ticket_status.symbol}
                          <span className="consumer-info-history-item-border"></span>
                        </span>
                      </OverlayTrigger>
                      <div className="consumer-info-history-item-content" onClick={() => props.openTicket(item)}>
                        <span className="consumer-info-history-item-content-header">
                          <span className="consumer-info-history-ticket-id">#{item.ticket_reference_id}</span>
                          <span className="consumer-info-history-ticket-subject" title={item.subject}>{item.subject}</span>
                        </span>
                        <span className="consumer-info-history-item-content-footer">
                          <OverlayTrigger container={props.historyListRef} overlay={<Tooltip>{ticket_channel.title}</Tooltip>}>
                            {ticket_channel.icon}
                          </OverlayTrigger>
                          <span className="consumer-info-history-ticket-date" title={props.getFormattedDate(item.updated_level2_at)}>
                            {props.getFormattedDate(item.updated_level2_at)}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default ConsumerInfo;